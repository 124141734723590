import { getEnYear, getNepYear } from "./function";
import { API_URL } from "./url";

const strings = {
	url: API_URL,
	gender: [
		{ id: "male", value: "Male" },
		{ id: "female", value: "Female" },
		{ id: "other", value: "Others" },
	],
	role: [
		{ id: "owner", value: "Owner" },
		{ id: "user", value: "User" },
	],
	userStatus: [
		{ id: "active", value: "Active" },
		{ id: "inactive", value: "Inactive" },
	],
	paymentMode: [
		{ id: "cash", value: "Cash" },
		{ id: "cheque", value: "Cheque" },
		{ id: "e-pay", value: "E-Pay" },
		{ id: "credit", value: "Credit" },
	],
	nepaliYear: getNepYear(),
	englishYear: getEnYear(),
	nepaliMonth: [
		{ id: 1, value: "Baisakh" },
		{ id: 2, value: "Jestha" },
		{ id: 3, value: "Ashar" },
		{ id: 4, value: "Shrawan" },
		{ id: 5, value: "Bhadra" },
		{ id: 6, value: "Ashoj" },
		{ id: 7, value: "Kartik" },
		{ id: 8, value: "Mangsir" },
		{ id: 9, value: "Poush" },
		{ id: 10, value: "Magh" },
		{ id: 11, value: "Falgun" },
		{ id: 12, value: "Chaitra" },
	],
	englishMonth: [
		{ id: 0, value: "Jan" },
		{ id: 1, value: "Feb" },
		{ id: 2, value: "Mar" },
		{ id: 3, value: "Apr" },
		{ id: 4, value: "May" },
		{ id: 5, value: "Jun" },
		{ id: 6, value: "Jul" },
		{ id: 7, value: "Aug" },
		{ id: 8, value: "Sept" },
		{ id: 9, value: "Oct" },
		{ id: 10, value: "Nov" },
		{ id: 11, value: "Dec" },
	],
	bill_void: [
		{ id: "null", value: "All Bills" },
		{ id: "true", value: "Void" },
		{ id: "false", value: "Not Void" },
	],
	customerType: [
		{ id: "individual", value: "Individual" },
		{ id: "business", value: "Business" },
	],
	city: [
		{ id: "kathmandu", value: "Kathmandu" },
		{ id: "pokhara", value: "Pokhara" },
		{ id: "lalitpur", value: "Lalitpur" },
		{ id: "biratnagar", value: "Biratnagar" },
		{ id: "birgunj", value: "Birgunj" },
		{ id: "bharatpur", value: "Bharatpur" },
		{ id: "chitwan", value: "Chitwan" },
		{ id: "janakpur", value: "Janakpur" },
		{ id: "hetauda", value: "Hetauda" },
		{ id: "dharan", value: "Dharan" },
		{ id: "butwal", value: "Butwal" },
		{ id: "bhaktapur", value: "Bhaktapur" },
		{ id: "birendranagar", value: "Birendranagar" },
		{ id: "itahari", value: "Itahari" },
		{ id: "nepalganj", value: "Nepalganj" },
		{ id: "ghorahi", value: "Ghorahi" },
		{ id: "siddharthanagar", value: "Siddharthanagar" },
		{ id: "rajbiraj", value: "Rajbiraj" },
		{ id: "dhangadhi", value: "Dhangadhi" },
		{ id: "mahendranagar", value: "Mahendranagar" },
		{ id: "gulariya", value: "Gulariya" },
		{ id: "tulsipur", value: "Tulsipur" },
		{ id: "narayangarh", value: "Narayangarh" },
		{ id: "ithari", value: "Ithari" },
		{ id: "baglung", value: "Baglung" },
		{ id: "banepa", value: "Banepa" },
	],
	province: [
		{ id: "p1", value: "Province No. 1" },
		{ id: "madhesh", value: "Madhesh " },
		{ id: "bagmati", value: "Bagmati" },
		{ id: "gandaki", value: "Gandaki" },
		{ id: "lumbini", value: "Lumbini" },
		{ id: "karnali", value: "Karnali" },
		{ id: "sudurpaschim", value: "Sudurpaschim" },
	],
	irdStatus: {
		100: "User credentials do not match",
		101: "Bill already exists",
		102: "Exception while saving bill details! Please check model fields and values",
		103: "Unknown exceptions! Please check API URL and model fields and values",
		104: "Invalid model",
		105: "Bill does not exists (for sales return)",
		200: "Bill created successfully",
	}
};
export default strings;
