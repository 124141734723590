import React, { useEffect, useMemo } from "react";

import "./sales-book.style.scss";
import logoDark from "../../assets/logo-dark-BILLME.png";

// for date purpose
import moment from "moment";
import { adToBs } from "@sbmdkl/nepali-date-converter";
import { nepaliDateMMMM } from "../../global/function";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchBillLogById } from "../../redux/actions/activity.action";

function BillAuditLogTemplate({ selectedBill }) {
	const { billLog } = useSelector((state) => state.activityReducer);
	const { companyDetails } = useSelector((state) => state.companyReducer);

	const dispatch = useDispatch();

	useEffect(() => {
		async function getBillLogs() {
			await dispatch(fetchBillLogById(selectedBill.bill_id));
		}
		getBillLogs();
	}, [dispatch, selectedBill.bill_id]);

	const _renderFormattedDate = (date) => {
		const nepDate = adToBs(moment(date).format("YYYY-MM-DD"));
		const billNepDate = `${nepaliDateMMMM(nepDate).split(" ")[0]} ${
			nepaliDateMMMM(nepDate).split(" ")[1]
		}, ${nepaliDateMMMM(nepDate).split(" ")[2]}`;
		const billEnDate = `${
			moment(date).format("DD") +
			" " +
			moment(date).format("MMM") +
			", " +
			moment(date).format("YYYY")
		}`;
		return { billNepDate, billEnDate };
	};

	function splitArray(array, chunkSize) {
		const chunks = [];
		for (let i = 0; i < array.length; i += chunkSize) {
			chunks.push(array.slice(i, i + chunkSize));
		}

		return chunks;
	}

	const slicedArrays = useMemo(
		() =>
			Array.isArray(billLog) &&
			billLog.length > 0 &&
			splitArray(billLog, 15),
		[billLog]
	);

	function _renderAuditLogTemplate(activityArr, page) {
		return (
			<div className="pdf-template-wrapper">
				<div className="pdf-template-container">
					{/* header part */}
					<div className="pdf-template-header-container">
						<div className="header-row-wrapper">
							<div className="header-item">
								<div className="brand-logo-container">
									<img
										crossOrigin="anonymous"
										src={
											companyDetails?.company_logo ||
											logoDark
										}
										alt="logo"
										className="brand-image"
									/>
									<div className="brand-text-container">
										<div
											className="brand-text brand-title"
											style={{
												color: companyDetails?.company_color_code,
											}}
										>
											{companyDetails?.company_name}
										</div>

										<div className="brand-text">
											<b>VAT / PAN No.: </b>
											{
												companyDetails?.identification
													?.registration_number
											}
										</div>
									</div>
								</div>
							</div>
							<div className="header-item">
								<div className="pdf-container">
									<div
										className="pdf-title"
										style={{
											color: companyDetails?.company_color_code,
										}}
									>
										Audit Log
									</div>
									<div className="text-section">
										<div className="dark-text">
											<b>Invoice #:</b>
										</div>
										<div className="light-text">
											{selectedBill?.invoice_number}
										</div>
									</div>
									<div className="text-section">
										<div className="dark-text">
											<b>Invoice Date:</b>
										</div>
										<div className="light-text text-col">
											{selectedBill?.invoice_date && (
												<>
													<span>
														{
															_renderFormattedDate(
																selectedBill?.invoice_date
															).billNepDate
														}
													</span>
													<span>
														{
															_renderFormattedDate(
																selectedBill?.invoice_date
															).billEnDate
														}
													</span>
												</>
											)}
										</div>
									</div>
									<div className="text-section">
										<div className="dark-text">
											<b>Page:</b>
										</div>
										<div className="light-text">{page}</div>
									</div>
								</div>
							</div>
						</div>
						<div className="header-row-wrapper">
							<div className="header-item">
								<div className="text-container">
									<h5 className="section-title">
										Invoice To:
									</h5>
									<div className="light-text capitalize">
										{selectedBill?.buyer_name}
									</div>
								</div>

								{selectedBill?.buyer_pan && (
									<div className="text-section">
										<div className="dark-text">
											Customer VAT/PAN:
										</div>
										<div className="light-text">
											{selectedBill?.buyer_pan}
										</div>
									</div>
								)}
								{selectedBill?.bill_for && (
									<div className="text-container">
										<h5 className="section-title">
											Bill For:
										</h5>
										<div className="light-text capitalize">
											{selectedBill?.bill_for}
										</div>
									</div>
								)}
							</div>
							<div className="header-item">
								<div className="text-container">
									<h5 className="section-title">
										Issued By:
									</h5>
									<div className="light-text capitalize">
										{/* {selectedBill?.user &&
										`${selectedBill?.user?.first_name} ${selectedBill?.user?.last_name}`} */}
										Emp ID #{" "}
										{selectedBill?.user &&
											selectedBill?.user?.user_id}
									</div>
									{/* {selectedBill?.user?.user_email && (
									<div className="light-text lowercase">
										<i className="fa-solid fa-envelope"></i>
										&nbsp;
										{selectedBill?.user?.user_email}
									</div>
								)} */}
								</div>
							</div>
						</div>
					</div>

					<div className="pdf-template-table-container">
						<table className="table-container">
							<tbody>
								<tr>
									<td className="header-item">
										Activity Time
									</td>
									<td className="header-item">
										Activity Date
									</td>
									<td className="header-item">
										Activity Type
									</td>
									<td className="header-item">User</td>
								</tr>
								{activityArr?.map((activity, index) => {
									const activityDay = moment(
										activity?.createdAt
									).format("dddd");
									const activityTime = moment(
										activity?.createdAt
									).format("h:mm A");
									const nepDate = adToBs(
										moment(activity?.createdAt).format(
											"YYYY-MM-DD"
										)
									);

									const activityDate = `${
										nepaliDateMMMM(nepDate).split(" ")[0]
									} ${
										nepaliDateMMMM(nepDate).split(" ")[1]
									}, ${
										nepaliDateMMMM(nepDate).split(" ")[2]
									} | ${
										moment(activity?.createdAt).format(
											"DD"
										) +
										" " +
										moment(activity?.createdAt).format(
											"MMMM"
										) +
										", " +
										moment(activity?.createdAt).format(
											"YYYY"
										)
									}`;

									const activityType = () => {
										switch (activity?.activity_type) {
											case "bill":
												return `bill ${activity?.request_method}`;
											case "print":
											case "email":
											case "download":
												return `bill ${activity?.activity_type}ed`;
											case "creditNote":
												return `bill voided`;
											default:
												return "";
										}
									};

									return (
										<tr key={index}>
											<td>
												{activityDay} {activityTime}
											</td>
											<td>{activityDate}</td>
											<td>{activityType()}</td>
											<td>
												{activity?.user?.first_name}{" "}
												{activity?.user?.last_name}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>

					<div className="pdf-template-footer-container">
						<div className="footer-text">Powered by BillMe</div>
						<img
							crossOrigin="anonymous"
							src={logoDark}
							alt="logo"
							className="footer-image"
						/>
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			{slicedArrays.length > 0 &&
				slicedArrays.map((slicedArray, index) => (
					<div key={index}>
						{_renderAuditLogTemplate(
							slicedArray,
							index + 1 + " of " + slicedArrays?.length
						)}
					</div>
				))}
		</>
	);
}

export default BillAuditLogTemplate;
