import React, { useMemo, useState } from "react";
import "./bill-list.style.scss";

// for date purpose
import moment from "moment";
import { adToBs } from "@sbmdkl/nepali-date-converter";
import { nepaliDateMMMM, rupeeConverter } from "../../global/function";

// components
import EmptyRecord from "../empty-record/empty-record.component";
import SideModal from "../side-modal/side-modal.component";
import BillBulkPrint from "./bill-bulk-print.component";

// redux
import { useSelector } from "react-redux";

function BillList({ selectedBillId, onSelectBill, billFilterValues }) {
	const { billList, additionalBillInfo } = useSelector(
		(state) => state.billReducer
	);
	const [bulkPrint, setBulkPrint] = useState(false);

	const billMonth = (date) => {
		let enMonth = moment(date).format("MMMM");

		let nepDate = adToBs(moment(date).format("YYYY-MM-DD"));

		let nepMonth = nepaliDateMMMM(nepDate).split(" ")[1];
		return `${enMonth}/${nepMonth}`;
	};

	const billMonths = useMemo(() => {
		if (
			billFilterValues?.fiscal_year === "" &&
			billFilterValues?.date_from &&
			billFilterValues?.date_to
		) {
			let fromMonth = billMonth(billFilterValues?.date_from);
			let toMonth = billMonth(billFilterValues?.date_to);

			return {
				start: fromMonth,
				end: toMonth,
			};
		}
	}, [
		billFilterValues?.fiscal_year,
		billFilterValues?.date_from,
		billFilterValues?.date_to,
	]);

	const _renderBillDate = (date) => {
		const nepDate = adToBs(moment(date).format("YYYY-MM-DD"));
		const billDate = `${nepaliDateMMMM(nepDate).split(" ")[0]} ${
			nepaliDateMMMM(nepDate).split(" ")[1]
		}, ${nepaliDateMMMM(nepDate).split(" ")[2]} | ${
			moment(date).format("DD") +
			" " +
			moment(date).format("MMMM") +
			", " +
			moment(date).format("YYYY")
		}`;
		return billDate;
	};

	return (
		<div className="bill-list-container">
			<div className="bill-section-header">
				<div className="header-title">
					<h2>Generated Bills</h2>
					{billList?.length > 0 && (
						<div
							className="print-all-btn"
							onClick={() => setBulkPrint(true)}
							disabled={billList?.length < 1}
						>
							<i className="fa-solid fa-print"></i>
							Print All
						</div>
					)}
				</div>

				<div className="separator"></div>

				<div className="header-info-container">
					<div className="header-info">
						<h3 style={{ color: "#3498DB" }}>
							{additionalBillInfo?.billCount}
						</h3>
						<span>Bill Invoices</span>
					</div>
					<div className="header-info">
						<h3 style={{ color: "#d35a47" }}>
							{additionalBillInfo?.creditNoteCount}
						</h3>
						<span>Credit Notes</span>
					</div>
				</div>
				{billFilterValues?.fiscal_year === "" ? (
					<div className="bill-date-info">
						<div className="date-info-container">
							<div className="label-info">
								{billMonths?.start}
							</div>
							<div className="label">Bills From</div>
						</div>
						<div className="date-separator"></div>
						<div className="date-info-container">
							<div className="label-info">{billMonths?.end}</div>
							<div className="label">Bills To</div>
						</div>
					</div>
				) : (
					<div className="bill-date-info">
						<div className="date-info-container">
							<div className="label-info">
								{billFilterValues?.fiscal_year}
							</div>
							<div className="label">Fiscal Year</div>
						</div>
					</div>
				)}
			</div>
			<div style={{ overflowY: "auto" }}>
				<div className="bill-list-items">
					{billList?.length > 0 ? (
						billList.map((bill, i) => (
							<div
								className={`list-item-container ${
									selectedBillId === bill.bill_id &&
									"selected"
								}`}
								key={i}
								onClick={() => onSelectBill(bill, i)}
							>
								<div className="bill-icon">
									<i className="fa-regular fa-file-lines"></i>
									<div
										className={`icon-indicator ${
											bill.bill_void && "void"
										}`}
									>
										{bill.bill_void ? (
											<i className="fa-solid fa-exclamation"></i>
										) : (
											<i className="fa-solid fa-check"></i>
										)}
									</div>
								</div>
								<div className="bill-item-details">
									<div className="details-row">
										<div className="bill-mainText">
											{bill.buyer_name}
										</div>
										<div className="bill-subText font-xs">
											# {bill?.invoice_number}
										</div>
									</div>
									<div className="bill-subText">
										{_renderBillDate(bill?.invoice_date)}
									</div>
									<div className="details-row flex-end">
										<div
											className={`bill-status ${
												bill.bill_void
													? "credited"
													: "bill"
											}`}
										>
											{bill.bill_void
												? "Credit Note"
												: "Bill"}
										</div>
										<div className="bill-mainText font-xl">
											Rs.{" "}
											{rupeeConverter(bill.grand_total)}
										</div>
									</div>
								</div>
							</div>
						))
					) : (
						<EmptyRecord />
					)}
				</div>
			</div>

			<SideModal
				isVisible={bulkPrint}
				onModalClose={() => setBulkPrint(false)}
				containerStyle={{ minWidth: "320px", maxWidth: "380px" }}
			>
				{bulkPrint && (
					<BillBulkPrint
						onBulkPrintModalClose={() => setBulkPrint(false)}
						filterValues={billFilterValues}
						onSelectBill={onSelectBill}
					/>
				)}
			</SideModal>
		</div>
	);
}

export default BillList;
