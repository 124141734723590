import React, { useMemo } from "react";
import "./activity-item.style.scss";

// for date purpose
import moment from "moment";
import { adToBs } from "@sbmdkl/nepali-date-converter";

// global functions and strings
import { isEmptyObject, nepaliDateMMMM } from "../../global/function";

function ActivityItem({ activity }) {
	const activityDay = useMemo(() => {
		if (!isEmptyObject(activity)) {
			// Parse the date string using Moment.js
			const date = moment(activity?.createdAt);

			// Get the day of the week as a string
			const dayOfWeekString = date.isSame(moment(), "day")
				? "Today"
				: date.format("dddd"); // 'dddd' format gives the full day name
			return dayOfWeekString;
		}
	}, [activity]);

	// date show
	const nepDate = useMemo(
		() =>
			activity?.createdAt !== "" &&
			adToBs(moment(activity?.createdAt).format("YYYY-MM-DD")),
		[activity?.createdAt]
	);
	const activityDate = useMemo(
		() =>
			activity?.createdAt !== "" &&
			`${nepaliDateMMMM(nepDate).split(" ")[0]} ${
				nepaliDateMMMM(nepDate).split(" ")[1]
			}, ${nepaliDateMMMM(nepDate).split(" ")[2]} | ${
				moment(activity?.createdAt).format("DD") +
				" " +
				moment(activity?.createdAt).format("MMMM") +
				", " +
				moment(activity?.createdAt).format("YYYY")
			}`,
		[activity?.createdAt, nepDate]
	);

	const activityMessage = useMemo(() => {
		switch (activity?.activity_type) {
			case "loggedIn":
				return "logged in to the system.";
			case "loggedOut":
				return "logged out from the system.";
			case "view":
				return `visited ${activity?.request_model} page.`;
			case "draft":
				return `has ${activity?.request_method} draft.`;
			case "company":
				return `has ${activity?.request_method} company information.`;

			case "preference":
				if (activity?.request_model === "identification") {
					return `has ${activity?.request_method} company identification ${activity?.activity_note}.`;
				} else {
					return `has ${activity?.request_method} company preferences.`;
				}

			case "customer":
				if (activity?.request_method === "created") {
					return `has ${activity?.request_method} new customer ${activity?.activity_note}`;
				} else {
					return `has ${activity?.request_method} ${activity?.customer_info?.customer_name} (${activity?.customer_info?.customer_id}) information.`;
				}

			case "user":
				if (activity?.request_method === "created") {
					return `has ${activity?.request_method} new company user ${activity?.activity_note}`;
				} else if (activity?.request_method === "updated") {
					return `has ${activity?.request_method} ${activity?.companyUser?.first_name} ${activity?.companyUser?.last_name} (#${activity?.companyUser?.user_id}) ${activity?.activity_note}.`;
				} else {
					return `has ${activity?.request_method} ${activity?.activity_note} `;
				}

			case "bill":
				return `has ${activity?.request_method} bill with invoice number # ${activity?.bill?.invoice_number}.`;
			case "failed":
				return `${activity?.activity_note}.`;
			case "creditNote":
				if (activity?.activity_note !== "-") {
					return activity?.activity_note;
				} else {
					return `has voided bill # ${activity?.creditNote?.ref_invoice_number} and created credit note # ${activity?.creditNote?.credit_note_number}.`;
				}

			case "print":
			case "email":
				let billNumber = "";
				if (activity?.request_model === "bill") {
					billNumber = activity?.bill?.invoice_number;
				} else if (activity?.request_model === "creditNote") {
					billNumber = activity?.creditNote?.credit_note_number;
				} else {
					billNumber = "";
				}
				return `has ${activity?.activity_type}ed ${
					activity.activity_type === "email"
						? activity?.activity_note !== "-" &&
						  activity?.activity_note
						: ""
				} ${activity?.request_model}${
					billNumber && ` # ${billNumber}`
				}.`;
			case "download":
				let downloadedBillNumber = "";
				if (activity?.request_model === "bill") {
					downloadedBillNumber = activity?.bill?.invoice_number;
				} else if (activity?.request_model === "creditNote") {
					downloadedBillNumber =
						activity?.creditNote?.credit_note_number;
				} else {
					downloadedBillNumber = "";
				}
				if (activity?.activity_note !== "-") {
					return `has ${activity?.activity_note}.`;
				} else {
					return `has downloaded ${activity?.request_model}${
						downloadedBillNumber && ` # ${downloadedBillNumber}`
					}.`;
				}
			default:
				return "";
		}
	}, [activity]);

	const activityFlag = useMemo(() => {
		switch (activity?.activity_type) {
			case "loggedIn":
				return {
					color: "#523563",
					info: "System Login",
				};
			case "loggedOut":
				return {
					color: "#523563",
					info: "Logout Request",
				};

			case "view":
				return {
					color: "#e2b60c",
					info: "Request",
				};
			case "draft":
				return {
					color: "#ff8774",
					info: "Draft",
				};
			case "bill":
				return {
					color: "#46BE46",
					info: "New Invoice Bill",
				};
			case "creditNote":
				return {
					color: "#D35A47",
					info: "Credit Note",
				};

			case "print":
			case "email":
				return {
					color: "#3498DB",
					info: `${activity?.request_model} ${activity?.activity_type}`,
				};
			case "download":
				if (activity?.activity_note !== "-") {
					return {
						color: "#3498DB",
						info: `${activity?.request_model} Export`,
					};
				} else {
					return {
						color: "#3498DB",
						info: `${activity?.request_model} ${activity?.activity_type}`,
					};
				}

			case "company":
			case "customer":
			case "user":
			case "preference":
				return {
					color: "#3498DB",
					info: `${activity?.activity_type} ${activity?.request_method}`,
				};

			case "failed":
				if (activity?.request_model === "creditNote") {
					return {
						color: "#B53E2C",
						info: "Credit Note Failed",
					};
				} else {
					return {
						color: "#B53E2C",
						info: "Bill Failed",
					};
				}
			default:
				return {};
		}
	}, [activity]);

	return (
		<div className="activity-item-container">
			<div className="activity-separator-container">
				<div className="activity-bullet"></div>
				<div className="activity-separator"></div>
			</div>
			<div className="activity-details-container">
				<div className="activity-timing">
					{activityDay},{" "}
					{moment(activity?.createdAt).format("h:mm A")}
				</div>
				<div className="activity-info-container">
					<div className="activity-user">
						<div className="user-img">
							{activity.user.user_image ? (
								<img
									crossOrigin="anonymous"
									src={activity.user.user_image}
									alt="user"
								/>
							) : (
								<i className="fa-solid fa-user fa-2x"></i>
							)}
						</div>
					</div>
					<div className="activity-details">
						{!isEmptyObject(activityFlag) && (
							<div
								className="activity-flag"
								style={{ color: activityFlag?.color }}
							>
								<i className="fa-solid fa-flag"></i>
								{activityFlag?.info}
							</div>
						)}

						<div className="activity-message">
							<span>{`${activity?.user?.first_name} ${activity?.user?.last_name}`}</span>{" "}
							{activityMessage}
						</div>
						<div className="activity-time">{activityDate}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ActivityItem;
