import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import "./activity-monitor.style.scss";
import "../../components/bill-list/bill-filter.style.scss";

// for date purpose
import moment from "moment";

// components
import ActivityItem from "../../components/activity-item/activity-item.component";
import SelectBox from "../../components/form-inputs/select-box.component";
import Pagination from "../../components/pagination/pagination.component";
import EmptyRecord from "../../components/empty-record/empty-record.component";

// form handle
import { useFormik } from "formik";
import * as yup from "yup";

// global strings and fuctions
import strings from "../../global/strings";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchActivity } from "../../redux/actions/activity.action";
import { exportFile } from "../../redux/actions/export-import.action";

const activitySchema = yup.object().shape({
	year: yup.string().nullable().required("Year is required"),
	month: yup.string().nullable().required("Month is required"),
});

function ActivityMonitor() {
	const { activityLog } = useSelector((state) => state.activityReducer);
	const { isLoading } = useSelector((state) => state.exportImportReducer);

	const [totalPages, setTotalPages] = useState(0); // Total number of pages
	const [currentPageNum, setCurrentPageNum] = useState(1); // current page no
	const itemsPerPage = 10;

	const dispatch = useDispatch();
	const filterFormik = useFormik({
		validationSchema: activitySchema,
		initialValues: {
			year: new Date().getFullYear(),
			month: new Date().getMonth(),
		},
		onSubmit: async (filterValues) => {
			let startMonth = moment({
				year: filterValues.year,
				month: filterValues.month,
				day: 1,
			}).format("YYYY-MM-DD");
			const totalCount = await dispatch(
				fetchActivity(startMonth, itemsPerPage, 1)
			);
			setTotalPages(Math.ceil(totalCount / itemsPerPage));
		},
		onReset: async () => {
			const startMonth = moment()
				.clone()
				.startOf("month")
				.format("YYYY-MM-DD hh:mm");
			const totalCount = await dispatch(
				fetchActivity(startMonth, itemsPerPage, 1)
			);
			setTotalPages(Math.ceil(totalCount / itemsPerPage));
		},
	});

	const getActivityLog = useCallback(
		async (year, month, page = 1) => {
			let startMonth = moment({
				year: year,
				month: month,
				day: 1,
			}).format("YYYY-MM-DD");
			const totalCount = await dispatch(
				fetchActivity(startMonth, itemsPerPage, page)
			);
			setTotalPages(Math.ceil(totalCount / itemsPerPage));
		},
		[dispatch]
	);

	useEffect(() => {
		let year = new Date().getFullYear();
		let month = new Date().getMonth();

		getActivityLog(year, month, 1);
	}, [dispatch, getActivityLog]);

	const handleActivityExport = async () => {
		Swal.fire({
			title: "Export Activity logs",
			text: "All your filtered activity logs will be exported to excel file. Are you sure?",
			icon: "info",
			showDenyButton: true,
			confirmButtonText: "Export",
			denyButtonText: "Cancel",
			customClass: {
				popup: "alert-modal",
				actions: "btn-container",
				confirmButton: "alert-btn btn-confirm",
				denyButton: "alert-btn btn-cancel",
			},
		}).then(async (result) => {
			if (result.isConfirmed) {
				if (!isLoading) {
					let activityFilter = {
						startMonth: moment({
							year: filterFormik.values?.year,
							month: filterFormik.values?.month,
							day: 1,
						}).format("YYYY-MM-DD"),
						endMonth: moment([
							filterFormik.values?.year,
							filterFormik.values?.month,
						])
							.endOf("month")
							.format("YYYY-MM-DD"),
					};
					await dispatch(exportFile("activity", activityFilter));
				}
			}
		});
	};

	const handlePageClick = (selected) => {
		let currentPage = selected.selected + 1; // selected.selected is the new page index
		setCurrentPageNum(currentPage);
		getActivityLog(
			filterFormik.values?.year,
			filterFormik.values?.month,
			currentPage
		);
	};

	return (
		<div className="activity-monitor-wrapper">
			<div className="activity-monitor-container">
				<div className="filter-container">
					<div className="filter-container-inner">
						<div className="filter-items-container">
							<h2 className="filter-section-header">
								Filter Activity Monitor
							</h2>
							<p className="action-description">
								The export to excel is based on filtered
								activity logs.
							</p>
							<div
								className="action-btn"
								disabled={isLoading}
								onClick={handleActivityExport}
							>
								<i className="fa-solid fa-file-export"></i>
								Export to excel
							</div>
						</div>
						<div className="filter-items-container">
							<div
								className="filter-row"
								style={{ flexWrap: "wrap" }}
							>
								<div className="input-item">
									<p className="input-label">Year</p>
									<SelectBox
										name="year"
										onChange={filterFormik.handleChange}
										onBlur={filterFormik.handleBlur}
										value={filterFormik.values?.year}
										options={strings.englishYear}
									/>
									{filterFormik.submitCount > 0 && (
										<div className="validation-error">
											{filterFormik?.errors?.year}
										</div>
									)}
								</div>
								<div className="input-item">
									<p className="input-label">Month</p>
									<SelectBox
										name="month"
										onChange={filterFormik.handleChange}
										onBlur={filterFormik.handleBlur}
										value={filterFormik.values?.month}
										options={strings.englishMonth}
									/>
									{filterFormik.submitCount > 0 && (
										<div className="validation-error">
											{filterFormik?.errors?.month}
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="filter-btn-container">
							<div
								className="filter-btn inverted"
								onClick={filterFormik.resetForm}
							>
								<i className="fa-solid fa-eraser"></i>Reset
							</div>
							<div
								className="filter-btn"
								onClick={filterFormik.submitForm}
							>
								<i className="fa-solid fa-magnifying-glass"></i>
								Search
							</div>
						</div>
					</div>
				</div>
				<div className="activities-list-wrapper">
					<div className="section-header-container">
						<h2 className="section-header-title">
							Activity Monitor
						</h2>
					</div>
					<div className="activities-container">
						{Array.isArray(activityLog) &&
						activityLog.length > 0 ? (
							activityLog.map((activity, index) => (
								<ActivityItem key={index} activity={activity} />
							))
						) : (
							<EmptyRecord />
						)}
						{totalPages > 0 && (
							<Pagination
								totalPages={totalPages}
								onPageClick={handlePageClick}
								forcePage={currentPageNum - 1}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default ActivityMonitor;
