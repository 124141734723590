import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import { sessionInfo } from "../../global/function";

import { isEmptyObject } from "../../global/function";
import { fetchLoginError, setNavigationPath } from "./auth-user.action";

export const FETCH_COMPANY_REQUEST = "FETCH_COMPANY_REQUEST";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_ERROR = "FETCH_COMPANY_ERROR";

export const FETCH_PREFERENCE_SUCCESS = "FETCH_PREFERENCE_SUCCESS";

export const FETCH_COMPANY_DASHBOARD_SUCCESS = "FETCH_COMPANY_DASHBOARD_SUCCESS";
export const SET_COMPANY_DATA_CORRUPT_STATUS = "SET_COMPANY_DATA_CORRUPT_STATUS";

export const fetchCompanyRequest = () => {
	return {
		type: FETCH_COMPANY_REQUEST,
	};
};

export const fetchCompanySuccess = (companyDetails) => {
	return {
		type: FETCH_COMPANY_SUCCESS,
		payload: companyDetails,
	};
};

export const fetchPreferenceSuccess = (preference) => {
	return {
		type: FETCH_PREFERENCE_SUCCESS,
		payload: preference,
	};
};

export const fetchCompanyError = (error) => {
	return {
		type: FETCH_COMPANY_ERROR,
		payload: error,
	};
};

export const fetchCompanyDashboardSuccess = (dashboardData) => {
	return {
		type: FETCH_COMPANY_DASHBOARD_SUCCESS,
		payload: dashboardData,
	};
}

export const setCompanyDataCorruptStatus = (status) => {
	return {
		type: SET_COMPANY_DATA_CORRUPT_STATUS,
		payload: status,
	}
}

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		await dispatch(setNavigationPath("/login"));
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchCompanyError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchCompanyError(error.message));
	}
}

export const fetchCompanyDetails = (activityFlag = false) => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}company/id?activityFlag=${activityFlag}`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCompanySuccess(res.data.result));
				} else {
					await dispatch(fetchCompanyError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const addCompany = (companyData) => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		let sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}company/add`, companyData, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCompanySuccess(res.data.result));
					showToastMessage(
						"Success! Company added successfully",
						"S"
					);
				} else {
					await dispatch(fetchCompanyError(res.data.message));

					showToastMessage(res.data.message, "E");
				}
				return res.data;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updateCompany = (updatedData) => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		let sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}company/update`, updatedData, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCompanySuccess(res.data.result));
					showToastMessage(
						"Success! Company updated successfully",
						"S"
					);
				} else {
					await dispatch(fetchCompanyError(res.data.message));

					showToastMessage(res.data.message, "E");
				}
				return res.data.success;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

// company preference update
export const updateCompanyPreference = (updatedData) => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		let sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}company/update/preference`, updatedData, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchPreferenceSuccess(res.data.result));
					showToastMessage(res.data.message, "S");
				} else {
					await dispatch(fetchCompanyError(res.data.message));

					showToastMessage(res.data.message, "E");
				}
				return res.data.success;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

// dashboard data api
export const fetchCompanyDashboard = (activityFlag = false) => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}bill/dashboard?activityFlag=${activityFlag}`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCompanyDashboardSuccess(res.data.result));
				} else {
					await dispatch(fetchCompanyError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};