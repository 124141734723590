import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import { sessionInfo } from "../../global/function";

import { isEmptyObject } from "../../global/function";
import { fetchLoginError, setNavigationPath } from "./auth-user.action";

export const FETCH_IMAGE_UPLOAD_REQUEST = "FETCH_IMAGE_UPLOAD_REQUEST";
export const FETCH_IMAGE_UPLOAD_SUCCESS = "FETCH_IMAGE_UPLOAD_SUCCESS";
export const FETCH_IMAGE_UPLOAD_ERROR = "FETCH_IMAGE_UPLOAD_ERROR";

export const fetchImageUploadRequest = () => {
	return {
		type: FETCH_IMAGE_UPLOAD_REQUEST,
	};
};

export const fetchImageUploadSuccess = () => {
	return {
		type: FETCH_IMAGE_UPLOAD_SUCCESS,
	};
};

export const fetchImageUploadError = () => {
	return {
		type: FETCH_IMAGE_UPLOAD_ERROR,
	};
};


async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		await dispatch(setNavigationPath("/login"));
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchImageUploadError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchImageUploadError(error.message));
	}
}

export const uploadImage = (imageData) => {
	const data = new FormData();
	data.append("image", imageData.image);
	data.append("id", imageData.id);
	data.append("type", imageData.type);
	return async (dispatch) => {
		dispatch(fetchImageUploadRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}multer/image/upload`, data, {
				headers: {
					authorization: `${sessionData.token}`,
					"Content-Type": "multipart/form-data",
				},
			})
			.then(async (res) => {
				await dispatch(fetchImageUploadSuccess());

				if (res.data.success) {
					showToastMessage(res.data.message, "S");
				} else {
					showToastMessage(res.data.message, "E");
					await dispatch(fetchImageUploadError());
				}
				return res.data;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
