import React, { useMemo, useRef, useState } from "react";
import "./direct-bill.style.scss";
import ReactToPrint from "react-to-print";
// import generatePDF from "react-to-pdf";
// import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

// for date purpose
import moment from "moment";
import { adToBs } from "@sbmdkl/nepali-date-converter";

// components
import InvoiceForm from "../invoice-form/invoice-form.component";

// global functions
import {
	getSelectedTemplate,
	isEmptyObject,
	nepaliDateMMMM,
	splitArray,
} from "../../global/function";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
	addBill,
	incrementPrintCount,
	setLastBillDateSuccess,
} from "../../redux/actions/bill.action";
import {
	fetchDraftItemSuccess,
	// saveDraft,
} from "../../redux/actions/drafts.action";

function DirectBill({ onModalClose }) {
	const {
		addedBill,
		lastBillDate,
		// earliestDraftDate
	} = useSelector((state) => state.billReducer);
	const { preferenceDetails } = useSelector((state) => state.companyReducer);
	const { draftItem } = useSelector((state) => state.draftsReducer);

	const [billResponse, setBillResponse] = useState(null);
	const [billPreview, setBillPreview] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const invoiceBillTemplateRef = useRef(null);

	// date validation before sending to ird
	// function compareDates(billDate, readyDate) {
	// 	// date1 is earliest invoice date from draftsList array
	// 	const new_bill_date = new Date(billDate);
	// 	const earliest_draft_date = new Date(readyDate);

	// 	if (new_bill_date > earliest_draft_date) {
	// 		return false;
	// 	} else {
	// 		return true;
	// 	}
	// }

	const handleCreateBill = async (billData, saveTo) => {
		// setBillResponse(null);
		// let dateValid = true;
		// if (earliestDraftDate !== "") {
		// 	dateValid = compareDates(billData?.invoice_date, earliestDraftDate);
		// }
		if (
			saveTo === "bill"
			//  && dateValid
		) {
			let billToAdd = { bills: [billData] };
			// dispatch addBill with "individual" as second parameter as individual bill request from direct bill component
			const result = await dispatch(addBill(billToAdd, "individual"));
			if (result?.success) {
				setBillResponse("success");
				await dispatch(setLastBillDateSuccess(billData?.invoice_date));
			} else {
				await dispatch(
					fetchDraftItemSuccess({
						...billData,
						draft_id: result?.draft_id || null,
					})
				);
				setBillResponse("failed");
			}
		}
		// else {
		// 	setBillResponse("warn");
		// 	await dispatch(fetchDraftItemSuccess(billData));
		// }
	};

	// const handleSaveToReadyDrafts = async () => {
	// 	let readyDrafts = {
	// 		drafts: [draftItem],
	// 		draft_bill_status: "ready",
	// 	};
	// 	const saveResult = await dispatch(saveDraft(readyDrafts));
	// 	saveResult && navigate("/invoice/create");
	// };

	const handleModalClose = () => {
		onModalClose();
		setBillResponse(null);
		setBillPreview(false);
	};

	// increase print count of (bill/creditNote) after print or download
	const updatePrintCount = async (id, model_type, print_type) => {
		let updatePayload = [{ id, model_type, print_type }];

		await dispatch(incrementPrintCount(updatePayload, -1)); // -1 is index value that updates print count for recent added bill
	};

	// print new bill
	const handleAfterPrint = async () => {
		await updatePrintCount(addedBill?.bill_id, "bill", "print");
	};

	// download new bill
	// const handleBillDownload = () => {
	// 	Swal.fire({
	// 		title: "Download PDF",
	// 		icon: "info",
	// 		text: "A copy of bill invoice will be downloaded",
	// 		showDenyButton: true,
	// 		denyButtonText: "Cancel",
	// 		confirmButtonText: "Download",
	// 		customClass: {
	// 			popup: "alert-modal",
	// 			actions: "btn-container",
	// 			confirmButton: "alert-btn btn-confirm",
	// 			denyButton: "alert-btn btn-cancel",
	// 		},
	// 	}).then((result) => {
	// 		if (result.isConfirmed) {
	// 			generatePDF(invoiceBillTemplateRef, {
	// 				filename: `bill_invoice_${addedBill?.invoice_number}.pdf`,
	// 			});
	// 			updatePrintCount(addedBill?.bill_id, "bill", "download");
	// 		}
	// 	});
	// };

	// to populate data in the invoice form
	const currentInvoiceData = useMemo(
		() =>
			billResponse === "failed"
				? //  || billResponse === "warn"
				  draftItem
				: {
						invoice_date: moment().format("YYYY-MM-DD"),
						billed_items: [
							{
								item_name: "",
								hs_code: "",
								item_price: 0,
								item_qty: 0,
								total_price: 0,
								vatable: preferenceDetails?.vat,
							},
						],
				  },
		[billResponse, draftItem, preferenceDetails?.vat]
	);

	const slicedArrays = useMemo(
		() =>
			!isEmptyObject(addedBill) &&
			Array.isArray(addedBill?.billed_items) &&
			addedBill?.billed_items.length > 0 &&
			splitArray(addedBill?.billed_items, 12),
		[addedBill]
	);

	// date show
	const nepDate = useMemo(
		() =>
			lastBillDate !== "" &&
			adToBs(moment(lastBillDate).format("YYYY-MM-DD")),
		[lastBillDate]
	);
	const last_bill_date = useMemo(
		() =>
			lastBillDate !== "" &&
			`${nepaliDateMMMM(nepDate).split(" ")[0]} ${
				nepaliDateMMMM(nepDate).split(" ")[1]
			}, ${nepaliDateMMMM(nepDate).split(" ")[2]} | ${moment(
				lastBillDate
			).format("DD MMMM YYYY")}`,
		[lastBillDate, nepDate]
	);

	// render invoice template with selected template ID for e.g. 1
	const _renderTemplate = (slicedArray, index) => {
		let selected_bill, showBillFooter, page, fontStyle;
		selected_bill = {
			...addedBill,
			...addedBill.customer_info,
			sliced_items: slicedArray,
		};
		showBillFooter = index === slicedArrays?.length - 1 ? true : false;

		page = index + 1 + " of " + slicedArrays?.length;

		fontStyle = { fontSize: "14px" };

		return getSelectedTemplate(
			1,
			selected_bill,
			showBillFooter,
			page,
			fontStyle
		);
	};

	return (
		<div className="create-invoice-modal">
			<div className="modal-header">
				<div className="modal-action" onClick={handleModalClose}>
					<i className="fa-solid fa-arrow-left"></i> Back
				</div>

				{billResponse === "success" && billPreview && (
					<div className="action-container">
						{/* <div
							className="action-btn inverted"
							onClick={handleBillDownload}
						>
							<i className="fa-solid fa-download"></i>
							Download
						</div> */}

						<ReactToPrint
							trigger={() => (
								<div className="action-btn inverted">
									<i className="fa-solid fa-print"></i>
									Print
								</div>
							)}
							content={() => invoiceBillTemplateRef.current}
							onAfterPrint={handleAfterPrint}
						/>
						<div
							className="action-btn"
							onClick={() => navigate("/invoice/bills")}
						>
							View All Bills
						</div>
					</div>
				)}
			</div>
			<div className="modal-body">
				{billResponse && (
					<div className={`bill-add-response ${billResponse}`}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								gap: "16px",
							}}
						>
							<div className="response-icon">
								{billResponse === "success" && (
									<i className="fa-solid fa-thumbs-up"></i>
								)}
								{billResponse === "failed" && (
									<i className="fa-solid fa-triangle-exclamation"></i>
								)}
								{/* {billResponse === "warn" && (
									<i className="fa-solid fa-circle-info"></i>
								)} */}
							</div>
							<div className="response-message">
								{billResponse === "success" &&
									"Success! Bill generated successfully"}
								{billResponse === "failed" &&
									"Failed! Bill generation failure. Try again"}
								{/* {billResponse === "warn" &&
									"You have pending draft bills with earlier dates."} */}
							</div>
						</div>
						{billResponse === "success" && (
							<>
								{billPreview ? (
									<div
										className="response-action-btn"
										onClick={() => {
											setBillPreview(false);
											setBillResponse(null);
										}}
									>
										Generate New Bill
										<i className="fa-solid fa-arrow-right"></i>
									</div>
								) : (
									<div
										className="response-action-btn"
										onClick={() => setBillPreview(true)}
									>
										View Generated Bill
										<i className="fa-solid fa-arrow-right"></i>
									</div>
								)}
							</>
						)}
						{/* {billResponse === "warn" && (
							<div
								className="response-action-btn"
								onClick={handleSaveToReadyDrafts}
							>
								Save to Invoice Draft
								<i className="fa-regular fa-paper-plane"></i>
							</div>
						)} */}
					</div>
				)}

				{billPreview ? (
					<div
						className="modal-template-wrapper"
						ref={invoiceBillTemplateRef}
					>
						{!isEmptyObject(addedBill) &&
							slicedArrays.length > 0 &&
							slicedArrays.map((slicedArray, index) => (
								<div
									className="modal-template-container"
									key={index}
								>
									<div className="modal-template-container-inner">
										{_renderTemplate(slicedArray, index)}
									</div>
								</div>
							))}
					</div>
				) : (
					<div style={{ padding: "16px" }}>
						<div className="invoice-form-header">
							<h2 className="form-header-text">
								Create Bill Invoice
							</h2>
							{lastBillDate !== "" && (
								<div className="form-header-subtext">
									<div className="label">Last Bill Date:</div>
									<div className="label-info">
										{last_bill_date}
									</div>
								</div>
							)}
						</div>
						<div className="header-divider"></div>
						<InvoiceForm
							formType="individual"
							draftSave={handleCreateBill}
							currentDraft={currentInvoiceData}
							onSetCurrent={() => {}}
							onUnsavedChanges={() => {}}
						/>
					</div>
				)}
			</div>
		</div>
	);
}

export default DirectBill;
