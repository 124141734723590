import * as actionTypes from "../actions/company.action";

const initial_state = {
	isLoading: false,
	companyDetails: {},
	preferenceDetails: {},
	dashboardDetails: {},
	error: "",
};

export default function companyReducer(state = initial_state, action) {
	switch (action.type) {
		case actionTypes.FETCH_COMPANY_REQUEST:
			return {
				...state,
				isLoading: true,
				error: "",
			};
		case actionTypes.FETCH_COMPANY_SUCCESS:
			return {
				...state,
				isLoading: false,
				companyDetails: action.payload || {},
				preferenceDetails: action.payload?.preference || {},
				error: "",
			};
		case actionTypes.FETCH_PREFERENCE_SUCCESS:
			return {
				...state,
				isLoading: false,
				companyDetails: { ...state.companyDetails, preference: action.payload },
				preferenceDetails: action.payload || {},
				error: "",
			};
		case actionTypes.FETCH_COMPANY_DASHBOARD_SUCCESS:
			return {
				...state,
				isLoading: false,
				dashboardDetails: action.payload || {},
				error: "",
			};
		case actionTypes.FETCH_COMPANY_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		case actionTypes.SET_COMPANY_DATA_CORRUPT_STATUS:
			return {
				...state,
				isLoading: false,
				companyDetails: { ...state.companyDetails, company_data_corrupt: action.payload },
			};
		default:
			return state;
	}
}
