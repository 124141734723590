import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

// components
import Sidebar from "../components/side-navigation/side-navigation.component";

export default function ProtectedRoute() {
	let isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
	let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));

	const location = useLocation();

	if (isLoggedIn) {
		if (loginInfo?.company_verified) {
			return (
				<Sidebar>
					<Outlet />
				</Sidebar>
			);
		} else {
			return <Navigate to="/verification" replace />;
		}
	} else {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}
}
