import React from "react";
import "./login.style.scss";
import { useNavigate } from "react-router-dom";

// form handle
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// components
import { PasswordInputField } from "./login.page";

// redux
import { useDispatch } from "react-redux";
import { updateCompanyIdentifications } from "../../redux/actions/auth-user.action";

const verificationSchema = yup.object().shape({
	username: yup.string().required("Username cannot be empty"),
	password: yup.string().required("Password cannot be empty"),
});

export default function Verification() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			username: "",
			password: "",
		},
		mode: "onSubmit",
		resolver: yupResolver(verificationSchema),
	});

	const handleIRDAccountSave = async (verificationData) => {
		let verificationSuccess = await dispatch(
			updateCompanyIdentifications({
				...verificationData,
				verification: true,
			})
		);
		if (verificationSuccess) {
			navigate("/");
		}
	};

	function _renderIRDVerification() {
		return (
			<div className="form-container">
				<h2 className="title-text">IRD Account</h2>

				<p className="description-text">
					Enter your IRD verified account credentials.
				</p>

				<div className="form-inputs-container">
					<div className="input-wrapper">
						<p className="input-label">Username</p>
						<div className="input-wrapper-inner">
							<i className="fa-solid fa-user"></i>
							<input
								{...register("username")}
								type="text"
								className="input-container"
								placeholder="Username"
							/>
						</div>
						<span className="form-validation-error">
							{errors?.username?.message}
						</span>
					</div>
					<PasswordInputField
						{...register("password")}
						placeholder="Password"
						error={errors?.password?.message}
						label="Password"
					/>
				</div>

				<div
					className="form-submit-btn"
					onClick={handleSubmit(handleIRDAccountSave)}
				>
					Save Credentials
				</div>
			</div>
		);
	}

	return (
		<div className="login-wrapper">
			<div className="login-container">
				<div className="login-container-inner">
					{_renderIRDVerification()}
					<div className="description-container">
						<h2 className="title-text inverted">
							IRD Verification
						</h2>
						<p className="description-text">
							Enter your IRD account details, so you can start
							e-billing.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
