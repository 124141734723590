import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import { sessionInfo } from "../../global/function";

import { isEmptyObject } from "../../global/function";
import { fetchLoginError, setNavigationPath } from "./auth-user.action";

export const FETCH_DRAFT_REQUEST = "FETCH_DRAFT_REQUEST";
export const FETCH_DRAFT_SUCCESS = "FETCH_DRAFT_SUCCESS";
export const FETCH_DRAFT_ITEM_SUCCESS = "FETCH_DRAFT_ITEM_SUCCESS";
export const FETCH_DRAFT_ERROR = "FETCH_DRAFT_ERROR";

export const SET_LOCAL_DRAFTS = "SET_LOCAL_DRAFTS";
export const ADD_LOCAL_DRAFT = "ADD_LOCAL_DRAFT";
export const UPDATE_LOCAL_DRAFT = "UPDATE_LOCAL_DRAFT";

export const UPDATE_DRAFTS_LIST = "UPDATE_DRAFTS_LIST";

export const fetchDraftRequest = () => {
	return {
		type: FETCH_DRAFT_REQUEST,
	};
};

export const fetchDraftSuccess = (drafts) => {
	return {
		type: FETCH_DRAFT_SUCCESS,
		payload: drafts,
	};
};

export const fetchDraftItemSuccess = (draft) => {
	return {
		type: FETCH_DRAFT_ITEM_SUCCESS,
		payload: draft,
	};
};

export const fetchDraftError = (error) => {
	return {
		type: FETCH_DRAFT_ERROR,
		payload: error,
	};
};
// add individual draft
export const addLocalDrafts = (draft) => {
	return {
		type: ADD_LOCAL_DRAFT,
		payload: draft,
	};
};
// set whole drafts array
export const setLocalDrafts = (drafts) => {
	return {
		type: SET_LOCAL_DRAFTS,
		payload: drafts,
	};
};

// update individual draft local draft
export const updateLocalDrafts = (draft) => {
	return {
		type: UPDATE_LOCAL_DRAFT,
		payload: draft,
	};
};
// update individual draft saved in DB with status "draft"
export const updateDraftsList = (draft) => {
	return {
		type: UPDATE_DRAFTS_LIST,
		payload: draft,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		await dispatch(setNavigationPath("/login"));
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchDraftError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchDraftError(error.message));
	}
}

export const fetchDrafts = (activityFlag = false) => {
	return async (dispatch) => {
		dispatch(fetchDraftRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}draft/company?activityFlag=${activityFlag}`,

				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchDraftSuccess(res.data.result));
				} else {
					await dispatch(fetchDraftError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const fetchDraftById = (id) => {
	return async (dispatch) => {
		dispatch(fetchDraftRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}draft/id?draft_id=${id}`,

				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchDraftItemSuccess(res.data.result));
				} else {
					await dispatch(fetchDraftError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const saveDraft = (drafts) => {
	return async (dispatch) => {
		dispatch(fetchDraftRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}draft/add`, drafts, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchDraftSuccess(response.data.result));
					showToastMessage("Success! Invoice Draft Created", "S");
				} else {
					await dispatch(fetchDraftError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
				return response.data.success;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updateDraft = (drafts) => {
	return async (dispatch) => {
		dispatch(fetchDraftRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}draft/update`, drafts, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchDraftSuccess(response.data.result));
					showToastMessage("Draft Detail Updated", "S");
				} else {
					await dispatch(fetchDraftError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
				return response.data.success;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const deleteDraft = (drafts) => {
	return async (dispatch) => {
		dispatch(fetchDraftRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}draft/delete`, drafts, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchDraftSuccess(response.data.result));
					showToastMessage("Success! Drafts Deleted", "S");
				} else {
					await dispatch(fetchDraftError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
				return response.data.success;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
