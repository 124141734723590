import * as actionTypes from "../actions/bill.action";

const initial_state = {
  isLoading: false,
  billList: [],
  addedBill: {},
  lastBillDate: "",
  earliestDraftDate: "",
  additionalBillInfo: {},
  error: "",
};

export default function billReducer(state = initial_state, action) {
  switch (action.type) {
    case actionTypes.FETCH_BILL_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case actionTypes.FETCH_BILL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        billList: action.payload || [],
        error: "",
      };
    case actionTypes.FETCH_ADD_BILL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addedBill: action.payload || {},
        error: "",
      };
    case actionTypes.FETCH_BILL_DATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lastBillDate: action.payload.lastInvoiceDate,
        earliestDraftDate: action.payload.firstDraftDate,
      };
    case actionTypes.SET_LAST_BILL_DATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lastBillDate: action.payload,
      };
    case actionTypes.SET_BILL_PRINT_COUNT:
      let index = action.payload.index;
      const updatedBillList = [...state.billList]; // Create a shallow copy of the billList array
      if (action.payload.type === "credit") {
        updatedBillList[index] = {
          ...updatedBillList[index], // Create a shallow copy of the specific bill object
          credit_note: {
            ...updatedBillList[index].credit_note,
            print_copies: updatedBillList[index]?.credit_note?.print_copies + 1,
          }, // Update the print_copies property inside credit_note
        };
      } else {
        updatedBillList[index] = {
          ...updatedBillList[index], // Create a shallow copy of the specific bill object
          print_copies: updatedBillList[index].print_copies + 1, // Update the print_copies property
        };
      }
      return {
        ...state,
        billList: updatedBillList, // Update the billList with the new array
      };
    case actionTypes.SET_ADDED_BILL_PRINT_COUNT:
      let updatedAddedBill = { ...state.addedBill }; // Create a shallow copy of the addedBill object
      updatedAddedBill = {
        ...updatedAddedBill,
        print_copies: updatedAddedBill.print_copies + 1,
      };
      return {
        ...state,
        addedBill: updatedAddedBill,
      };
    case actionTypes.FETCH_ADDITIONAL_BILL_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        additionalBillInfo: action.payload,
      };
    case actionTypes.FETCH_BILL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
