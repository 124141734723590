import React, { useState } from "react";
import "./form-inputs.style.scss";

function PasswordInput(props) {
	const [pswrdVisible, setPswrdVisible] = useState(false);
	return (
		<div className="input-item">
			{props?.label && (
				<label className="input-label">{props.label}</label>
			)}
			<div
				className={`input-pswrd-wrapper ${
					props.disabled && "disabled"
				}`}
			>
				<input
					className="input-pswrd-container"
					type={pswrdVisible ? "text" : "password"}
					{...props}
					autoComplete="off"
				/>
				<div
					className="input-action"
					onClick={() => setPswrdVisible(!pswrdVisible)}
				>
					{pswrdVisible ? (
						<i className="fa-regular fa-eye-slash"></i>
					) : (
						<i className="fa-regular fa-eye"></i>
					)}
				</div>
			</div>
			{props?.error && (
				<div className="validation-error">{props?.error}</div>
			)}
		</div>
	);
}

export default PasswordInput;
