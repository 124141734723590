let API_URL;

const APP = process.env.REACT_APP_ENV;

if (APP === "development") {
	// development environment
	API_URL = "http://localhost:3005/";
} else if (APP === "testing") {
	API_URL = "https://api.test-billme.nibjar.com/";
} else if (APP === "production") {
	API_URL = "https://api.billme.nibjar.com/";
}

export { API_URL };