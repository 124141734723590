import * as actionTypes from "../actions/image-upload.action";

const initial_state = {
	isLoading: false,
};

export default function imageUploadReducer(state = initial_state, action) {
	switch (action.type) {
		case actionTypes.FETCH_IMAGE_UPLOAD_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case actionTypes.FETCH_IMAGE_UPLOAD_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case actionTypes.FETCH_IMAGE_UPLOAD_ERROR:
			return {
				...state,
				isLoading: false,
			};
		default:
			return state;
	}
}
