import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import { sessionInfo } from "../../global/function";

import { isEmptyObject } from "../../global/function";
import { fetchLoginError, setNavigationPath } from "./auth-user.action";

export const FETCH_STATUS_REQUEST = "FETCH_STATUS_REQUEST";
export const FETCH_STATUS_REQUEST_SUCCESS = "FETCH_STATUS_REQUEST_SUCCESS";
export const FETCH_STATUS_ERROR = "FETCH_STATUS_ERROR";

export const fetchStatusRequest = () => {
	return {
		type: FETCH_STATUS_REQUEST,
	};
};

export const fetchStatusRequestSuccess = (status) => {
	return {
		type: FETCH_STATUS_REQUEST_SUCCESS,
		payload: status,
	};
};

export const fetchStatusError = (error) => {
	return {
		type: FETCH_STATUS_ERROR,
		payload: error,
	};
};


async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		await dispatch(setNavigationPath("/login"));
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchStatusError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchStatusError(error.message));
	}
}

export const checkIRDStatus = () => {
	return async (dispatch) => {
		await dispatch(fetchStatusRequest());
		let sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}bill/check/ird`, {}, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				showToastMessage(res.data.message, "I");
				await dispatch(fetchStatusRequestSuccess(res.data.status))
				await localStorage.setItem("irdStatus", JSON.stringify(res.data.status));

				return res.data.status;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
