import * as actionTypes from "../actions/customer.action";

const initial_state = {
  isLoading: false,
  customerDetails: {},
  customersList: [],
  error: "",
};

export default function customerReducer(state = initial_state, action) {
  switch (action.type) {
    case actionTypes.FETCH_CUSTOMER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case actionTypes.FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customersList: action.payload || [],
        error: "",
      };
    case actionTypes.SET_CUSTOMERS_LIST:
      return {
        ...state,
        isLoading: false,
        customersList: [action.payload, ...state.customersList] || [],
        error: "",
      };
    case actionTypes.FETCH_SINGLE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customerDetails: action.payload,
        error: "",
      };
    case actionTypes.FETCH_CUSTOMER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
