import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import { sessionInfo } from "../../global/function";

import { isEmptyObject } from "../../global/function";
import { fetchLoginError, setNavigationPath } from "./auth-user.action";

export const FETCH_EMPLOYEE_REQUEST = "FETCH_EMPLOYEE_REQUEST";
export const FETCH_EMPLOYEE_SUCCESS = "FETCH_EMPLOYEE_SUCCESS";
export const FETCH_SINGLE_EMPLOYEE_SUCCESS = "FETCH_SINGLE_EMPLOYEE_SUCCESS";
export const FETCH_EMPLOYEE_ERROR = "FETCH_EMPLOYEE_ERROR";

export const SET_EMPLOYEES_LIST = "SET_EMPLOYEES_LIST";

export const fetchEmployeeRequest = () => {
	return {
		type: FETCH_EMPLOYEE_REQUEST,
	};
};

export const fetchEmployeeSuccess = (employees) => {
	return {
		type: FETCH_EMPLOYEE_SUCCESS,
		payload: employees,
	};
};

export const fetchSingleEmployeeSuccess = (employee) => {
	return {
		type: FETCH_SINGLE_EMPLOYEE_SUCCESS,
		payload: employee,
	};
};

export const fetchEmployeeError = (error) => {
	return {
		type: FETCH_EMPLOYEE_ERROR,
		payload: error,
	};
};

export const setEmployeesList = (employee) => {
	return {
		type: SET_EMPLOYEES_LIST,
		payload: employee,
	}
}

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		await dispatch(setNavigationPath("/login"));
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchEmployeeError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchEmployeeError(error.message));
	}
}

export const fetchCompanyEmployees = (limit, page, activityFlag = false) => {
	return async (dispatch) => {
		dispatch(fetchEmployeeRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}user?limit=${limit}&page=${page}&activityFlag=${activityFlag}`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchEmployeeSuccess(res.data.result.rows));
					return res.data.result.count;
				} else {
					await dispatch(fetchEmployeeError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const fetchEmployeeById = (userInfoId, limit = 5) => {
	return async (dispatch) => {
		dispatch(fetchEmployeeRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}user/id?user_info_id=${userInfoId}&limit=${limit}`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchSingleEmployeeSuccess(res.data.result));
				} else {
					await dispatch(fetchEmployeeError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const addEmployee = (employeeData) => {
	return async (dispatch) => {
		dispatch(fetchEmployeeRequest());
		let sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}user/add`, employeeData, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					showToastMessage("Success! Employee added successfully", "S");
				} else {
					await dispatch(fetchEmployeeError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
				return res.data; // require success and added data
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updateEmployee = (employeeData, page = 1, activityFlag = false) => {
	return async (dispatch) => {
		dispatch(fetchEmployeeRequest());
		let sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}user/update?limit=${10}&page=${page}&activityFlag=${activityFlag}`, employeeData, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchEmployeeSuccess(res.data.result.rows));
					showToastMessage("Success! Employee updated successfully", "S");
				} else {
					await dispatch(fetchEmployeeError(res.data.message));

					showToastMessage(res.data.message, "E");
				}
				return res.data.success;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const searchEmployees = (searchData, limit = 10, page = 1, activityFlag = false) => {
	return async (dispatch) => {
		dispatch(fetchEmployeeRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}user/search?user_id=${searchData.user_id}&first_name=${searchData.first_name}&last_name=${searchData.last_name}&user_mobile=${searchData.user_mobile}&user_email=${searchData.user_email}&limit=${limit}&page=${page}&activityFlag=${activityFlag}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchEmployeeSuccess(res.data.result.rows));
					return res.data.result.count;
				} else {
					await dispatch(fetchEmployeeError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const deleteEmployee = (employeeInfoId) => {
	return async (dispatch) => {
		dispatch(fetchEmployeeRequest());
		const sessionData = await sessionInfo();

		return axios
			.delete(`${strings.url}user/delete?user_info_id=${employeeInfoId}`, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchEmployeeSuccess(response.data.result.rows));
					showToastMessage("Success! User Deleted", "S");
				} else {
					await dispatch(fetchEmployeeError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
				return response.data.success;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
