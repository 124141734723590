import React from "react";
import "./empty-record.style.scss";

// image
import emptyRecord from "../../assets/no-record.png";

function EmptyRecord() {
	return (
		<div className="empty-container">
			<img src={emptyRecord} alt="empty" />
			<div className="empty-message">
				Looks like you don't have any records
			</div>
		</div>
	);
}

export default EmptyRecord;
