import * as actionTypes from "../actions/bill-server-status.action";

const initial_state = {
	isLoading: false,
	irdStatus: true,
	error: "",
};

export default function billServerStatusReducer(state = initial_state, action) {
	switch (action.type) {
		case actionTypes.FETCH_STATUS_REQUEST:
			return {
				...state,
				isLoading: true,
				error: "",
			};
		case actionTypes.FETCH_STATUS_REQUEST_SUCCESS:
			return {
				...state,
				isLoading: false,
				irdStatus: action.payload,
				error: "",
			};
		case actionTypes.FETCH_STATUS_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
