import React, { useEffect, useMemo, useState } from "react";
import "./create-invoice.style.scss";
import Swal from "sweetalert2";
import { useNavigationType } from "react-router-dom";

// components
import InvoiceForm from "../../components/invoice-form/invoice-form.component";
import DraftList from "../../components/draft-list/draft-list.component";
import ReadyDraftList from "../../components/draft-list/ready-draft-list.component";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
	fetchDrafts,
	saveDraft,
	setLocalDrafts,
	updateDraft,
	updateDraftsList,
	updateLocalDrafts,
} from "../../redux/actions/drafts.action";

// global functions
import { isEmptyObject, nepaliDateMMMM } from "../../global/function";

// for date purpose
import { adToBs } from "@sbmdkl/nepali-date-converter";
import moment from "moment";

export default function CreateInvoice() {
	const { localDraftsList } = useSelector((state) => state.draftsReducer);
	const { lastBillDate } = useSelector((state) => state.billReducer);
	const [currentDraft, setCurrentDraft] = useState({});

	// stores invoice number of draft that has been changed
	const [unsavedChanges, setUnsavedChanges] = useState({});

	const dispatch = useDispatch();
	const navType = useNavigationType();

	useEffect(() => {
		const getDrafts = async () => {
			let activityFlag = false;
			navType === "PUSH" ? (activityFlag = true) : (activityFlag = false);
			await dispatch(fetchDrafts(activityFlag));
		};
		getDrafts();
	}, [dispatch, navType]);

	function validateInvoice(buyer_name, billed_items, payment_mode) {
		const isBuyerNameNotEmpty = !!buyer_name && buyer_name.trim() !== "";
		const isBilledItemsNotEmpty =
			Array.isArray(billed_items) && billed_items?.length > 0;
		const isPaymentModeNotEmpty =
			!!payment_mode && payment_mode.trim() !== "";

		if (
			!isBuyerNameNotEmpty &&
			!isBilledItemsNotEmpty &&
			!isPaymentModeNotEmpty
		) {
			return false; // All conditions are not met
		}

		if (isBilledItemsNotEmpty) {
			const areBilledItemsFieldsNotEmpty = billed_items.every(
				(item) =>
					item.item_name &&
					item.item_name.trim() !== "" &&
					item.item_price !== undefined &&
					item.item_price > 0 &&
					item.item_qty !== undefined &&
					item.item_qty > 0
			);

			return (
				isBuyerNameNotEmpty &&
				areBilledItemsFieldsNotEmpty &&
				isPaymentModeNotEmpty
			);
		}

		return isBuyerNameNotEmpty && isPaymentModeNotEmpty;
	}

	// choose draft object for edit or add
	const handleDraftSelection = async (draft) => {
		if (!isEmptyObject(currentDraft) && !isEmptyObject(unsavedChanges)) {
			const draft_valid = validateInvoice(
				unsavedChanges?.buyer_name,
				unsavedChanges?.billed_items,
				unsavedChanges?.payment_mode
			);

			// if the changes have been done in drafts from DB
			if (
				currentDraft?.draft_bill_status === "draft" &&
				currentDraft?.invoice_number === unsavedChanges?.invoice_number
			) {
				Swal.fire({
					title: "Save Changes?",
					text: "Do you want to save the changes?",
					showDenyButton: true,
					confirmButtonText: "Save",
					denyButtonText: "Cancel",
					customClass: {
						popup: "alert-modal",
						actions: "btn-container",
						confirmButton: "alert-btn btn-confirm",
						denyButton: "alert-btn btn-cancel",
					},
				}).then(async (result) => {
					/* Read more about isConfirmed, isDenied below */
					if (result.isConfirmed) {
						let drafts = {
							drafts: [{ ...unsavedChanges, draft_valid }],
							draft_bill_status: "draft",
						};
						let updateResult = await dispatch(updateDraft(drafts));
						if (updateResult) {
							setUnsavedChanges({});
							Swal.fire({
								title: "Draft changes saved!",
								icon: "success",
								customClass: {
									popup: "alert-modal",
									actions: "btn-container",
									confirmButton: "alert-btn btn-confirm",
								},
							}).then(
								(result) =>
									result.isConfirmed && setCurrentDraft(draft)
							);
						} else {
							await dispatch(
								updateDraftsList({
									...unsavedChanges,
									draft_valid,
								})
							);
							return;
						}
					} else if (result.isDenied) {
						setUnsavedChanges({});
						Swal.fire({
							title: "Changes are not saved",
							icon: "info",
							customClass: {
								popup: "alert-modal",
								actions: "btn-container",
								confirmButton: "alert-btn btn-confirm",
								denyButton: "alert-btn btn-cancel",
							},
						}).then(
							(result) =>
								result.isConfirmed && setCurrentDraft(draft)
						);
					}
				});
				return;
			} else if (
				currentDraft?.invoice_number === unsavedChanges?.invoice_number
			) {
				// if changes have been done in drafts stored in redux, simply update redux
				let localUpdate = false;
				const isBuyerNameNotEmpty =
					!!unsavedChanges?.buyer_name &&
					unsavedChanges?.buyer_name.trim() !== "";
				const isBilledItemsNotEmpty =
					Array.isArray(unsavedChanges?.billed_items) &&
					unsavedChanges?.billed_items?.length > 0;

				if (isBuyerNameNotEmpty) {
					localUpdate = true;
				} else {
					if (isBilledItemsNotEmpty) {
						const areBilledItemsFieldsNotEmpty =
							unsavedChanges?.billed_items.every(
								(item) =>
									item.item_name &&
									item.item_name.trim() !== "" &&
									item.item_price !== undefined &&
									item.item_price > 0 &&
									item.item_qty !== undefined &&
									item.item_qty > 0
							);
						localUpdate = areBilledItemsFieldsNotEmpty;
					}
				}
				localUpdate &&
					(await dispatch(
						updateLocalDrafts({ ...unsavedChanges, draft_valid })
					));
				setCurrentDraft(draft);
				setUnsavedChanges({});
			} else {
				setCurrentDraft(draft);
				setUnsavedChanges({});
			}
		} else {
			setCurrentDraft(draft);
		}
	};

	// individual draft save / update (draft and ready status) [handle currentDraft]
	const handleSaveDraft = async (draftData, saveTo) => {
		let saveResult = false;
		let updateResult = false;
		if (saveTo === "draft") {
			let drafts = { drafts: [draftData], draft_bill_status: "draft" };
			if (draftData?.draft_bill_status === "draft") {
				// to show updated draft data in the form
				setCurrentDraft(draftData);
				updateResult = await dispatch(updateDraft(drafts));
			} else {
				saveResult = await dispatch(saveDraft(drafts));
				// reset current draft since data changes after draft creation
				setCurrentDraft({});
			}
		} else {
			let readyDrafts = {
				drafts: [draftData],
				draft_bill_status: "ready",
			};
			if (draftData?.draft_bill_status === "draft") {
				updateResult = await dispatch(updateDraft(readyDrafts));
				// only show updated draft data in the form if failed
				updateResult ? setCurrentDraft({}) : setCurrentDraft(draftData);
			} else {
				saveResult = await dispatch(saveDraft(readyDrafts));
				// only show updated draft data in the form if failed
				saveResult ? setCurrentDraft({}) : setCurrentDraft(draftData);
			}
		}

		if (saveResult) {
			// remove drafts from local drafts
			let newLocalDrafts = localDraftsList.filter(
				(localDraft) =>
					localDraft.invoice_number !== draftData.invoice_number
			);
			dispatch(setLocalDrafts(newLocalDrafts));
		} else {
			// save updated draft(local drafts) in redux ---> will rerender the form
			await dispatch(updateLocalDrafts(draftData));
		}

		if (!updateResult) {
			// save updated draft(from DB with status(draft)) in redux ---> will rerender the form
			await dispatch(updateDraftsList(draftData));
		}
	};

	// identify change and handle changes in invoice form when selected draft changes

	// date show
	const nepDate = useMemo(
		() =>
			lastBillDate !== "" &&
			adToBs(moment(lastBillDate).format("YYYY-MM-DD")),
		[lastBillDate]
	);
	const last_bill_date = useMemo(
		() =>
			lastBillDate !== "" &&
			`${nepaliDateMMMM(nepDate).split(" ")[0]} ${
				nepaliDateMMMM(nepDate).split(" ")[1]
			}, ${nepaliDateMMMM(nepDate).split(" ")[2]} | ${
				moment(lastBillDate).format("DD") +
				" " +
				moment(lastBillDate).format("MMMM") +
				", " +
				moment(lastBillDate).format("YYYY")
			}`,
		[lastBillDate, nepDate]
	);

	return (
		<div className="create-invoice-wrapper">
			<DraftList
				currentDraft={currentDraft}
				onSetCurrent={handleDraftSelection}
				unsavedInvoiceNumber={unsavedChanges?.invoice_number || ""}
			/>
			<div className="draft-form-container">
				<div className="draft-form-header">
					<div className="section-title-text">Create New Invoice</div>
					{lastBillDate !== "" && (
						<div className="form-header-subtext">
							<div className="label-info">{last_bill_date}</div>
							<div className="label">Last Bill Date</div>
						</div>
					)}
				</div>
				<div className="header-divider"></div>
				<InvoiceForm
					formType="bulk"
					draftSave={handleSaveDraft}
					currentDraft={currentDraft}
					onSetCurrent={handleDraftSelection}
					onUnsavedChanges={setUnsavedChanges}
				/>
			</div>
			<ReadyDraftList recentBillDate={last_bill_date} />
		</div>
	);
}
