import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import { sessionInfo } from "../../global/function";

import { isEmptyObject } from "../../global/function";
import { fetchLoginError, logout, setLoginInfo, setNavigationPath } from "./auth-user.action";
import { fetchAdditionalBillsInfoSuccess, fetchBillSuccess } from "./bill.action";
import { setCompanyDataCorruptStatus } from "./company.action";
import { fetchStatusRequestSuccess } from "./bill-server-status.action";

export const FETCH_CREDIT_NOTE_REQUEST = "FETCH_CREDIT_NOTE_REQUEST";
export const FETCH_CREDIT_NOTE_SUCCESS = "FETCH_CREDIT_NOTE_SUCCESS";
export const FETCH_ADD_CREDIT_NOTE_SUCCESS = "FETCH_ADD_CREDIT_NOTE_SUCCESS";
export const FETCH_CREDIT_NOTE_ERROR = "FETCH_CREDIT_NOTE_ERROR";

export const fetchCreditNoteRequest = () => {
	return {
		type: FETCH_CREDIT_NOTE_REQUEST,
	};
};

export const fetchCreditNoteSuccess = (CreditNotes) => {
	return {
		type: FETCH_CREDIT_NOTE_SUCCESS,
		payload: CreditNotes,
	};
};

export const fetchAddCreditNoteSuccess = (CreditNotes) => {
	return {
		type: FETCH_ADD_CREDIT_NOTE_SUCCESS,
		payload: CreditNotes,
	};
};

export const fetchCreditNoteError = (error) => {
	return {
		type: FETCH_CREDIT_NOTE_ERROR,
		payload: error,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		await dispatch(setNavigationPath("/login"));
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchCreditNoteError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchCreditNoteError(error.message));
	}
}

export const fetchCreditNotes = () => {
	return async (dispatch) => {
		dispatch(fetchCreditNoteRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}creditNote/company`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCreditNoteSuccess(res.data.result));
				} else {
					await dispatch(fetchCreditNoteError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const addCreditNote = (creditNoteData) => {
	return async (dispatch) => {
		dispatch(fetchCreditNoteRequest());
		let sessionData = await sessionInfo();
		let creditNote = {
			...creditNoteData,
			username: sessionData?.username,
			password: sessionData?.password,
			seller_pan:
				sessionData?.registration_type === "pan"
					? sessionData?.registration_number
					: "",
		};

		return axios
			.post(`${strings.url}creditNote/add`, creditNote, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchBillSuccess(res.data.result));
					let moreBillInfo = {
						maxSales:
							res.data.count?.maxSales === 0
								? 10000
								: res.data.count?.maxSales,
						billCount: res.data.result?.length,
						creditNoteCount: res.data.count?.totalCreditNote
					};
					await dispatch(
						fetchAdditionalBillsInfoSuccess(moreBillInfo)
					);
					showToastMessage(
						`Success! Credit Note generated for ${creditNoteData?.invoice_number}`,
						"S"
					);
				} else {
					switch (res.data.errorStatus) {
						case 100:
							if (sessionData?.user_role === "user") {
								await dispatch(logout());
							} else {
								await dispatch(setLoginInfo(false));
							}
							break;
						case 101:
						case 105:
							await dispatch(setCompanyDataCorruptStatus(true))
							break;
						// case 102:
						// case 103:
						// case 104:
						// 	await dispatch(fetchStatusRequestSuccess(false));
						// 	await localStorage.setItem("irdStatus", JSON.stringify(false));
						// 	break;
						default:
							break;
					}

					await dispatch(fetchCreditNoteError(res.data.message));
					showToastMessage(res.data.message, "E");

				}
				return res.data.success;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const searchCreditNotes = (searchData) => {
	return async (dispatch) => {
		dispatch(fetchCreditNoteRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}creditNote/search`, searchData, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCreditNoteSuccess(res.data.result));
				} else {
					await dispatch(fetchCreditNoteError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
