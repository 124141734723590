import React, { useEffect, useMemo, useRef } from "react";
import ReactToPrint from "react-to-print";

import "./sales-book.style.scss";
import logoDark from "../../assets/logo-dark-BILLME.png";

// for date purpose
import moment from "moment";
import { adToBs } from "@sbmdkl/nepali-date-converter";
import {
	nepaliDateMMMM,
	rupeeConverter,
	splitArray,
} from "../../global/function";

// redux
import { useDispatch, useSelector } from "react-redux";
import { searchBills } from "../../redux/actions/bill.action";

function SalesBookTemplate({ billsArr, filterValues, page, showTotal }) {
	const { companyDetails } = useSelector((state) => state.companyReducer);
	const { billList } = useSelector((state) => state.billReducer);

	const _renderBillDate = (date) => {
		const nepDate = adToBs(moment(date).format("YYYY-MM-DD"));
		const billNepDate = `${nepaliDateMMMM(nepDate).split(" ")[0]} ${
			nepaliDateMMMM(nepDate).split(" ")[1]
		}, ${nepaliDateMMMM(nepDate).split(" ")[2]}`;
		const billEnDate = `${
			moment(date).format("DD") +
			" " +
			moment(date).format("MMM") +
			", " +
			moment(date).format("YYYY")
		}`;
		return { billNepDate, billEnDate };
	};

	const totals = useMemo(() => {
		if (showTotal) {
			const initialTotals = {
				grandTotalSales: 0,
				totalNonTaxableAmt: 0,
				totalExportSales: 0,
				totalDiscount: 0,
				totalTax: 0,
				totalTaxableAmount: 0,
			};

			const resultObj = billList.reduce((totals, bill) => {
				totals.grandTotalSales += bill.total_sales;
				totals.totalNonTaxableAmt += bill.non_taxable_amt;
				totals.totalExportSales += bill.export_sales;
				totals.totalDiscount +=
					bill.taxable_discount + bill.non_taxable_discount;
				totals.totalTax += bill.vat;
				totals.totalTaxableAmount += bill.taxable_sales_vat;
				return totals;
			}, initialTotals);

			return resultObj;
		}
	}, [billList, showTotal]);

	return (
		<div className="pdf-template-wrapper">
			<div className="pdf-template-container">
				{/* header part */}
				<div className="pdf-template-header-container">
					<div className="header-row-wrapper">
						<div className="header-item">
							<div className="brand-logo-container">
								<img
									crossOrigin="anonymous"
									src={
										companyDetails?.company_logo || logoDark
									}
									alt="logo"
									className="brand-image"
								/>
								<div className="brand-text-container">
									<div
										className="brand-text brand-title"
										style={{
											color: companyDetails?.company_color_code,
										}}
									>
										{companyDetails?.company_name}
									</div>

									<div className="brand-text">
										<b>VAT / PAN No.: </b>
										{
											companyDetails?.identification
												?.registration_number
										}
									</div>
								</div>
							</div>
						</div>
						<div className="header-item">
							<div className="pdf-container">
								<div
									className="pdf-title"
									style={{
										color: companyDetails?.company_color_code,
									}}
								>
									Sales Book
								</div>
								{filterValues?.date_from && (
									<div className="text-section">
										<div className="dark-text">
											<b>Date From:</b>
										</div>
										<div className="light-text text-col">
											{filterValues?.date_from && (
												<>
													<span>
														{
															_renderBillDate(
																filterValues?.date_from
															).billNepDate
														}
													</span>
													<span>
														{
															_renderBillDate(
																filterValues?.date_from
															).billEnDate
														}
													</span>
												</>
											)}
										</div>
									</div>
								)}
								{filterValues?.date_to && (
									<div className="text-section">
										<div className="dark-text">
											<b>Date To:</b>
										</div>
										<div className="light-text text-col">
											{filterValues?.date_to && (
												<>
													<span>
														{
															_renderBillDate(
																filterValues?.date_to
															).billNepDate
														}
													</span>
													<span>
														{
															_renderBillDate(
																filterValues?.date_to
															).billEnDate
														}
													</span>
												</>
											)}
										</div>
									</div>
								)}
								<div className="text-section">
									<div className="dark-text">
										<b>Page:</b>
									</div>
									<div className="light-text">{page}</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="pdf-template-table-container">
					<table className="table-container">
						<tbody>
							<tr>
								<td className="header-item">Invoice Details</td>
								<td className="header-item">Total Sales</td>
								<td className="header-item">
									Non Taxable Sales
								</td>
								<td className="header-item">Export Sales</td>
								<td className="header-item">Discount</td>
								<td className="header-item">Taxable Sales</td>
							</tr>
							{billsArr?.map((bill, index) => {
								const formattedDate = _renderBillDate(
									bill?.invoice_date
								);
								return (
									<tr key={index}>
										<td>
											<span className="table-data-container">
												<span className="data-item">
													<span className="data-label">
														Invoice Date:
													</span>
													<span>
														{
															formattedDate.billEnDate
														}{" "}
														|{" "}
														{
															formattedDate.billNepDate
														}
													</span>
												</span>
												<span className="data-item">
													<span className="data-label">
														Invoice No.:
													</span>
													<span>
														{bill?.invoice_number}
													</span>
												</span>
												<span
													className="data-item"
													style={{ marginTop: "8px" }}
												>
													<span className="data-label">
														Buyer Name:
													</span>
													<span>
														{bill?.buyer_name}
													</span>
												</span>
												{bill?.buyer_pan && (
													<span className="data-item">
														<span className="data-label">
															Buyer PAN:
														</span>
														<span>
															{bill?.buyer_pan}
														</span>
													</span>
												)}
											</span>
										</td>
										<td>
											<span>Rs. {bill?.total_sales}</span>
										</td>
										<td>
											<span>
												Rs. {bill?.non_taxable_amt}
											</span>
										</td>
										<td>
											<span>Rs. 0</span>
										</td>
										<td>
											<span>Rs. {bill?.discount}</span>
										</td>
										<td>
											<span className="table-data-container">
												<span className="data-item">
													<span className="data-label">
														Taxable Amount:
													</span>
													<span>
														Rs.{" "}
														{
															bill?.taxable_sales_vat
														}
													</span>
												</span>
												<span className="data-item">
													<span className="data-label">
														Tax Amount:
													</span>
													<span>Rs. {bill?.vat}</span>
												</span>
											</span>
										</td>
									</tr>
								);
							})}
							{showTotal && (
								<tr className="table-footer">
									<td>
										<b>Total Amount:</b>
									</td>
									<td>
										<span>
											Rs.{" "}
											{rupeeConverter(
												totals?.grandTotalSales || 0
											)}
										</span>
									</td>
									<td>
										<span>
											Rs.{" "}
											{rupeeConverter(
												totals?.totalNonTaxableAmt || 0
											)}
										</span>
									</td>
									<td>
										<span>
											Rs.{" "}
											{rupeeConverter(
												totals?.totalExportSales || 0
											)}
										</span>
									</td>
									<td>
										<span>
											Rs.{" "}
											{rupeeConverter(
												totals?.totalDiscount || 0
											)}
										</span>
									</td>
									<td>
										<span className="table-data-container">
											<span className="data-item">
												<span className="data-label">
													Total Taxable Amount:
												</span>
												<span>
													Rs.{" "}
													{rupeeConverter(
														totals?.totalTaxableAmount ||
															0
													)}
												</span>
											</span>
											<span className="data-item">
												<span className="data-label">
													Total Tax Amount:
												</span>
												<span>
													Rs.{" "}
													{rupeeConverter(
														totals?.totalTax || 0
													)}
												</span>
											</span>
										</span>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>

				<div className="pdf-template-footer-container">
					<div className="footer-text">Powered by BillMe</div>
					<img
						crossOrigin="anonymous"
						src={logoDark}
						alt="logo"
						className="footer-image"
					/>
				</div>
			</div>
		</div>
	);
}

function SalesBookModal({ onSalesModalClose, filterValues }) {
	const { billList } = useSelector((state) => state.billReducer);

	const dispatch = useDispatch();

	// ref for print of component outside
	const salesBookTemplateRef = useRef(null);

	// fetch filtered clients
	useEffect(() => {
		const fetchFilteredClients = async () => {
			await dispatch(searchBills(filterValues));
		};
		fetchFilteredClients();
	}, [dispatch, filterValues]);

	const nonVoidBillList = useMemo(
		() => billList.filter((bill) => !bill.bill_void),
		[billList]
	);

	const slicedArrays = useMemo(
		() =>
			Array.isArray(nonVoidBillList) &&
			nonVoidBillList.length > 0 &&
			splitArray(nonVoidBillList, 4),
		[nonVoidBillList]
	);

	return (
		<div className="sales-book-modal-container">
			<div className="modal-header">
				<div className="modal-action" onClick={onSalesModalClose}>
					<i className="fa-solid fa-arrow-left"></i> Back
				</div>
				<div className="action-container">
					<ReactToPrint
						trigger={() => (
							<div className="action-btn">
								<i className="fa-solid fa-print"></i>
								Print
							</div>
						)}
						content={() => salesBookTemplateRef.current}
						// onAfterPrint={handleAfterPrint}
					/>
				</div>
			</div>
			<div className="modal-body">
				<div className="sales-book-description">
					<b>*Note: </b>Salesbook offers a date range-based system for
					efficient sales tracking and analysis.
					<br />
					Users can specify periods to access detailed insights into
					performance trends, aiding informed decision-making and
					growth strategies.
				</div>

				<div className="sales-audit-container">
					<div className="sales-audit-container-inner">
						<div ref={salesBookTemplateRef}>
							{slicedArrays.length > 0 &&
								slicedArrays.map((slicedArray, index) => (
									<SalesBookTemplate
										key={index}
										billsArr={slicedArray}
										filterValues={filterValues}
										page={
											index +
											1 +
											" of " +
											slicedArrays?.length
										}
										showTotal={
											index === slicedArrays?.length - 1
												? true
												: false
										}
									/>
								))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SalesBookModal;
