import React from "react";
import "./side-modal.style.scss";

export default function SideModal({
	isVisible,
	onModalClose,
	children,
	containerStyle,
}) {
	return (
		<div className={`modal-wrapper ${isVisible ? "expanded" : ""}`}>
			<div className="modal_overlay" onClick={onModalClose}></div>
			<div className="modal-container" style={containerStyle}>
				{children}
			</div>
		</div>
	);
}
