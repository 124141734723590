import React, { useMemo, useState } from "react";
import Swal from "sweetalert2";
import "./bill-filter.style.scss";
import "../form-inputs/form-inputs.style.scss";

// components
import MultipleTagsInput from "../form-inputs/multiple-tags-input.component";
import SelectBox from "../form-inputs/select-box.component";
import SideModal from "../side-modal/side-modal.component";
import BillDuplicate from "./bill-duplicate.component";
import SalesBookModal from "../pdf-template/sales-book.component";
import EnNpDatePicker from "../form-inputs/en-np-datePicker.component";

// global functions and strings
import strings from "../../global/strings";

// redux
import { useDispatch, useSelector } from "react-redux";
import { exportFile } from "../../redux/actions/export-import.action";

function BillFilter({ filterFormik }) {
	const { additionalBillInfo, billList } = useSelector(
		(state) => state.billReducer
	);
	const { isLoading } = useSelector((state) => state.exportImportReducer);
	const [billSideModal, setBillSideModal] = useState(null);

	const dispatch = useDispatch();

	const handleBillsExport = async () => {
		Swal.fire({
			title: "Export Bills",
			text: "All your filtered bills will be exported to excel file. Are you sure?",
			icon: "info",
			showDenyButton: true,
			confirmButtonText: "Export",
			denyButtonText: "Cancel",
			customClass: {
				popup: "alert-modal",
				actions: "btn-container",
				confirmButton: "alert-btn btn-confirm",
				denyButton: "alert-btn btn-cancel",
			},
		}).then(async (result) => {
			if (result.isConfirmed) {
				if (!isLoading) {
					await dispatch(exportFile("bill", filterFormik.values));
				}
			}
		});
	};

	const handleMinValueChanged = (event) => {
		const value = parseInt(event.target.value);
		if (value < filterFormik.values.total_sales_max) {
			filterFormik.setFieldValue("total_sales_min", value);
		} else {
			filterFormik.setFieldValue(
				"total_sales_min",
				filterFormik.values.total_sales_max
			);
		}
	};

	const handleMaxValueChanged = (event) => {
		const value = parseInt(event.target.value);
		if (value > filterFormik.values.total_sales_min) {
			filterFormik.setFieldValue("total_sales_max", value);
		} else {
			filterFormik.setFieldValue(
				"total_sales_max",
				filterFormik.values.total_sales_min
			);
		}
	};

	const isSalesBookDisabled = useMemo(
		() =>
			!!(
				filterFormik.values.customer_id ||
				filterFormik.values.buyer_name ||
				filterFormik.values.buyer_pan ||
				filterFormik.values.invoice_number ||
				filterFormik.values.total_sales_min ||
				filterFormik.values.total_sales_max ||
				filterFormik.values.fiscal_year ||
				filterFormik.values.bill_void
			) ||
			(Array.isArray(filterFormik.values.bill_for) &&
				filterFormik.values.bill_for.length > 0),
		[filterFormik.values]
	);

	function handleTagAdd(e) {
		// Get the value of the input
		const value = e.target.value;
		// If the value is empty, return
		if (!value.trim()) return;
		// cleaning up all hash values
		let newValue = value?.split("#").filter(Boolean);

		// Convert the arrays to Sets to remove duplicates
		const set1 = new Set(filterFormik.values?.bill_for);
		const set2 = new Set(newValue);

		// Merge the Sets
		const mergedSet = new Set([...set1, ...set2]);

		// Convert the merged Set back to an array
		const resultArray = Array.from(mergedSet);

		// Add the value to the tags array
		filterFormik.setFieldValue("bill_for", resultArray);
		// Clear the input
		e.target.value = "";
	}

	function handleKeyDown(e) {
		// If user did not press enter key, return
		if (e.key !== "Enter") return;
		handleTagAdd(e);
	}

	function handleTagRemove(index) {
		let tags = [...filterFormik.values?.bill_for];
		filterFormik.setFieldValue(
			"bill_for",
			tags.filter((el, i) => i !== index)
		);
	}

	const handleInvoiceDateChange = (fieldName, fieldValue) => {
		filterFormik.setFieldValue(fieldName, fieldValue);
	};

	return (
		<div className="filter-container">
			<div className="filter-container-inner">
				<div className="filter-items-container">
					<h2 className="filter-section-header">Filter Your Bills</h2>
					<p className="action-description">
						The export to excel and duplicate bills are based on
						filtered data.
					</p>
					<div className="action-row">
						<div
							className="action-btn"
							disabled={isLoading || billList?.length < 1}
							onClick={handleBillsExport}
						>
							<i className="fa-solid fa-file-export"></i>Export
						</div>
						<div
							className="action-btn"
							disabled={billList.length < 1}
							onClick={() => setBillSideModal("duplicate")}
						>
							<i className="fa-solid fa-copy"></i>Duplicate
						</div>
					</div>
					<div className="action-row">
						<div
							className="action-btn"
							disabled={
								billList.length < 1 || isSalesBookDisabled
							}
							onClick={() => setBillSideModal("salesBook")}
						>
							<i className="fa-solid fa-book"></i>Generate Sales
							Book
						</div>
					</div>
				</div>
				<div className="filter-items-container">
					<MultipleTagsInput
						label="Invoice Tags"
						name="bill_for"
						onKeyDown={handleKeyDown}
						onBlur={handleTagAdd}
						onTagRemove={handleTagRemove}
						tagsArray={filterFormik.values?.bill_for}
						autoComplete="off"
					/>
				</div>
				<div className="filter-items-container">
					<div className="filter-items-label">Invoice Details</div>
					<div className="input-row">
						<div className="input-item">
							<p className="input-label">Invoice Number</p>
							<input
								name="invoice_number"
								className="input-box-wrapper"
								type="text"
								placeholder="e.g. A0001 or 0001"
								onChange={filterFormik.handleChange}
								onBlur={filterFormik.handleBlur}
								value={
									filterFormik.values?.invoice_number || ""
								}
							/>
							{filterFormik.submitCount > 0 && (
								<div className="validation-error">
									{filterFormik?.errors?.invoice_number}
								</div>
							)}
						</div>
						<div className="input-item" style={{ flex: 2 }}>
							<p className="input-label">Fiscal Year</p>
							<input
								name="fiscal_year"
								className="input-box-wrapper"
								type="text"
								placeholder="e.g. 2080.081"
								onChange={filterFormik.handleChange}
								onBlur={filterFormik.handleBlur}
								value={filterFormik.values?.fiscal_year || ""}
							/>
							{filterFormik.submitCount > 0 && (
								<div className="validation-error">
									{filterFormik?.errors?.fiscal_year}
								</div>
							)}
						</div>
					</div>
					<div className="input-row">
						<div className="input-item">
							<p className="input-label">Invoice Date From</p>
							<EnNpDatePicker
								name="date_from"
								className="input-box-wrapper"
								type="date"
								onChange={handleInvoiceDateChange}
								onBlur={filterFormik.handleBlur}
								value={
									filterFormik.values?.fiscal_year === ""
										? filterFormik.values?.date_from
										: ""
								}
								disabled={
									filterFormik.values?.fiscal_year !== ""
								}
							/>
							{filterFormik.submitCount > 0 && (
								<div className="validation-error">
									{filterFormik?.errors?.date_from}
								</div>
							)}
						</div>
						<div className="input-item">
							<p className="input-label">Invoice Date To</p>
							<EnNpDatePicker
								name="date_to"
								className="input-box-wrapper"
								type="date"
								onChange={handleInvoiceDateChange}
								onBlur={filterFormik.handleBlur}
								value={
									filterFormik.values?.fiscal_year === ""
										? filterFormik.values?.date_to
										: ""
								}
								disabled={
									filterFormik.values?.fiscal_year !== ""
								}
							/>
							{filterFormik.submitCount > 0 && (
								<div className="validation-error">
									{filterFormik?.errors?.date_to}
								</div>
							)}
						</div>
					</div>
					<div className="input-item">
						<p className="input-label">Bills</p>
						<SelectBox
							name="bill_void"
							onChange={filterFormik.handleChange}
							onBlur={filterFormik.handleBlur}
							value={filterFormik.values?.bill_void || ""}
							options={strings.bill_void}
						/>
						{filterFormik.submitCount > 0 && (
							<div className="validation-error">
								{filterFormik?.errors?.bill_void}
							</div>
						)}
					</div>
				</div>
				<div className="filter-items-container">
					<div className="filter-items-label">Customer Details</div>
					<div className="input-row">
						<div className="input-item">
							<p className="input-label">Customer Id</p>
							<input
								name="customer_id"
								className="input-box-wrapper"
								type="text"
								placeholder="#"
								onChange={filterFormik.handleChange}
								onBlur={filterFormik.handleBlur}
								value={filterFormik.values?.customer_id || ""}
							/>
							{filterFormik.submitCount > 0 && (
								<div className="validation-error">
									{filterFormik?.errors?.customer_id}
								</div>
							)}
						</div>
						<div className="input-item" style={{ flex: 2 }}>
							<p className="input-label">Customer Name</p>
							<input
								name="buyer_name"
								className="input-box-wrapper"
								type="text"
								placeholder="Customer Name"
								onChange={filterFormik.handleChange}
								onBlur={filterFormik.handleBlur}
								value={filterFormik.values?.buyer_name || ""}
							/>
							{filterFormik.submitCount > 0 && (
								<div className="validation-error">
									{filterFormik?.errors?.buyer_name}
								</div>
							)}
						</div>
					</div>
					<div className="input-item">
						<p className="input-label">Customer PAN</p>
						<input
							name="buyer_pan"
							className="input-box-wrapper"
							type="text"
							placeholder="Customer PAN"
							onChange={filterFormik.handleChange}
							onBlur={filterFormik.handleBlur}
							value={filterFormik.values?.buyer_pan || ""}
						/>
						{filterFormik.submitCount > 0 && (
							<div className="validation-error">
								{filterFormik?.errors?.buyer_pan}
							</div>
						)}
					</div>
				</div>
				<div className="filter-items-container">
					<div className="filter-items-label">Total Sales Range</div>
					<div style={{ padding: "20px 0", overflow: "hidden" }}>
						<div className="input-slider">
							<div
								className="input-progress"
								style={{
									left:
										(filterFormik.values?.total_sales_min /
											(additionalBillInfo?.maxSales ||
												10000)) *
											100 +
										"%",
									right:
										100 -
										(filterFormik.values?.total_sales_max /
											(additionalBillInfo?.maxSales ||
												10000)) *
											100 +
										"%",
								}}
							></div>
						</div>
						<div className="range-input">
							<input
								name="total_sales_min"
								type="range"
								className="range-min"
								min={0}
								max={additionalBillInfo?.maxSales || 10000}
								onChange={handleMinValueChanged}
								value={
									filterFormik.values?.total_sales_min || 0
								}
							/>
							<input
								name="total_sales_max"
								type="range"
								className="range-max"
								min={0}
								max={additionalBillInfo?.maxSales || 10000}
								onChange={handleMaxValueChanged}
								value={
									filterFormik.values?.total_sales_max || 0
								}
							/>
						</div>
						{filterFormik.submitCount > 0 && (
							<div className="validation-error">
								{filterFormik?.errors?.total_sales_min ||
									filterFormik?.errors?.total_sales_max}
							</div>
						)}
					</div>

					<div className="filter-row">
						<div
							className="input-item"
							style={{
								flexDirection: "row",
								alignItems: "center",
								gap: "8px",
							}}
						>
							Rs.
							<input
								name="total_sales_min"
								className="input-box-wrapper"
								type="number"
								onChange={filterFormik.handleChange}
								onBlur={filterFormik.handleBlur}
								value={filterFormik.values?.total_sales_min}
							/>
						</div>
						<div className="range-divider" />
						<div
							className="input-item"
							style={{
								flexDirection: "row",
								alignItems: "center",
								gap: "8px",
							}}
						>
							Rs.
							<input
								name="total_sales_max"
								className="input-box-wrapper"
								type="number"
								onChange={filterFormik.handleChange}
								onBlur={filterFormik.handleBlur}
								value={filterFormik.values?.total_sales_max}
							/>
						</div>
					</div>
				</div>
				<div className="filter-btn-container">
					<div
						className="filter-btn inverted"
						onClick={filterFormik.resetForm}
					>
						<i className="fa-solid fa-eraser"></i>Reset all filters
					</div>
					<div
						className="filter-btn"
						onClick={filterFormik.submitForm}
					>
						<i className="fa-solid fa-magnifying-glass"></i>
						Search
					</div>
				</div>
			</div>
			<SideModal
				isVisible={billSideModal}
				onModalClose={() => setBillSideModal(null)}
				containerStyle={
					billSideModal === "duplicate"
						? { minWidth: "320px", maxWidth: "380px" }
						: {}
				}
			>
				{billSideModal === "duplicate" && (
					<BillDuplicate
						onDuplicateModalClose={() => setBillSideModal(null)}
						filterValues={filterFormik.values}
					/>
				)}
				{billSideModal === "salesBook" && (
					<SalesBookModal
						onSalesModalClose={() => setBillSideModal(null)}
						filterValues={filterFormik.values}
					/>
				)}
			</SideModal>
		</div>
	);
}

export default BillFilter;
