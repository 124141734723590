import * as actionTypes from "../actions/drafts.action";

const initial_state = {
	isLoading: false,
	draftItem: {},
	draftsList: [],
	readyDraftsList: [],
	localDraftsList: [],
	error: "",
};

export default function draftsReducer(state = initial_state, action) {
	switch (action.type) {
		case actionTypes.FETCH_DRAFT_REQUEST:
			return {
				...state,
				isLoading: true,
				error: "",
			};
		case actionTypes.FETCH_DRAFT_SUCCESS:
			return {
				...state,
				isLoading: false,
				draftsList: JSON.parse(JSON.stringify(action.payload.draft)) || [],
				readyDraftsList: [...action.payload.failed, ...action.payload.ready] || [],
				error: "",
			};
		case actionTypes.FETCH_DRAFT_ITEM_SUCCESS:
			return {
				...state,
				isLoading: false,
				draftItem: action.payload,
				error: "",
			};
		case actionTypes.FETCH_DRAFT_ERROR:
			return {
				isLoading: false,
				error: action.payload,
				...state,
			};
		case actionTypes.ADD_LOCAL_DRAFT:
			return {
				...state,
				isLoading: false,
				localDraftsList: [...state.localDraftsList, action.payload],
				error: "",
			};
		case actionTypes.SET_LOCAL_DRAFTS:
			return {
				...state,
				isLoading: false,
				localDraftsList: action.payload,
				error: "",
			};
		case actionTypes.UPDATE_LOCAL_DRAFT:
			const updatedLocalIndex = state.localDraftsList.findIndex(obj => obj.invoice_number === action.payload.invoice_number);

			if (updatedLocalIndex === -1) {
				// Object not found, return the current state
				return state;
			}

			const updatedLocalObject = {
				...state.localDraftsList[updatedLocalIndex],
				...action.payload, // Properties to update --> updated object is sent in payload
				buyer_pan: action.payload.buyer_pan?.toString() || null,
				invoice_number: action.payload.invoice_number?.toString(),
				discount: parseFloat(action.payload?.discount || 0),
				service_charge: parseFloat(action.payload?.service_charge || 0),
			};

			const updatedLocalArray = [
				...state.localDraftsList.slice(0, updatedLocalIndex),
				updatedLocalObject,
				...state.localDraftsList.slice(updatedLocalIndex + 1)
			];

			return {
				...state,
				isLoading: false,
				localDraftsList: updatedLocalArray,
				error: "",
			};
		case actionTypes.UPDATE_DRAFTS_LIST:
			const updatedIndex = state.draftsList.findIndex(obj => obj.invoice_number === action.payload.invoice_number);

			if (updatedIndex === -1) {
				// Object not found, return the current state
				return state;
			}

			const updatedObject = {
				...state.draftsList[updatedIndex],
				...action.payload // Properties to update --> updated object is sent in payload
			};

			const updatedArray = [
				...state.draftsList.slice(0, updatedIndex),
				updatedObject,
				...state.draftsList.slice(updatedIndex + 1)
			];

			return {
				...state,
				isLoading: false,
				draftsList: updatedArray,
				error: "",
			};
		default:
			return state;
	}
}
