import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./nepali-cal-popup.style.scss";

//
import NepaliDate from "nepali-date-converter";

// global strings and functions
import { isEmptyObject } from "../../global/function";
import strings from "../../global/strings";
import { adToBs } from "@sbmdkl/nepali-date-converter";

function NepaliCalendarPopUp({
	dateSubmitFunction,
	type = "en",
	display = false,
	field = false,
	date = "",
	min = "",
	disabled = false,
}) {
	/* 
	display: true will show calendar as a flex and remove button and its hover property
	type: change how data is returned
	field: true will display as field instead of button
	date: used to display date for (field true)
	*/
	const [selected, setSelected] = useState({});
	const [displayDate, setDisplayDate] = useState(date);
	const [viewDate, setViewDate] = useState("");
	const [today, setToday] = useState({});
	const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

	useEffect(() => {
		let currentday = new NepaliDate().getBS();
		setToday({
			...currentday,
			selectCheck: `${currentday.date}-${currentday.month}-${currentday.year}`,
		});
	}, []);
	useEffect(() => {
		setViewDate(date);
	}, [date]);

	const DatePlotter = useCallback(
		(viewDate) => {
			let arr = [];
			let selDate =
				viewDate === ""
					? new NepaliDate().getBS()
					: new NepaliDate(viewDate).getBS();
			let lastDayPrevMonth = new NepaliDate(
				selDate.year,
				selDate.month,
				0
			).getBS();
			let firstDay = new NepaliDate(
				selDate.year,
				selDate.month,
				1
			).getBS();
			let lastDay = new NepaliDate(
				selDate.year,
				selDate.month + 1,
				0
			).getBS();

			let minDate = min !== "" && new NepaliDate(adToBs(min)).getBS();

			//adding days to calendar array
			//adding past month days
			for (let j = 0; j < firstDay.day; j++) {
				arr.push({
					date: lastDayPrevMonth.date - lastDayPrevMonth.day + j,
					month: lastDayPrevMonth.month,
					active: false,
				});
			}

			//adding current month days
			for (let i = 0; i < lastDay.date; i++) {
				if (min !== "") {
					if (firstDay.year > minDate.year) {
						arr.push({
							date: firstDay.date + i,
							month: firstDay.month,
							year: firstDay.year,
							selectCheck: `${firstDay.date + i}-${
								firstDay.month
							}-${firstDay.year}`,
							active: true,
						});
					} else if (
						firstDay.year >= minDate.year &&
						firstDay.month > minDate.month
					) {
						arr.push({
							date: firstDay.date + i,
							month: firstDay.month,
							year: firstDay.year,
							selectCheck: `${firstDay.date + i}-${
								firstDay.month
							}-${firstDay.year}`,
							active: true,
						});
					} else if (
						firstDay.year === minDate.year &&
						firstDay.month === minDate.month &&
						firstDay.date + i >= minDate.date
					) {
						arr.push({
							date: firstDay.date + i,
							month: firstDay.month,
							year: firstDay.year,
							selectCheck: `${firstDay.date + i}-${
								firstDay.month
							}-${firstDay.year}`,
							active: true,
						});
					} else {
						arr.push({
							date: firstDay.date + i,
							month: firstDay.month,
							year: firstDay.year,
							selectCheck: `${firstDay.date + i}-${
								firstDay.month
							}-${firstDay.year}`,
							active: false,
						});
					}
				} else {
					arr.push({
						date: firstDay.date + i,
						month: firstDay.month,
						year: firstDay.year,
						selectCheck: `${firstDay.date + i}-${firstDay.month}-${
							firstDay.year
						}`,
						active: true,
					});
				}
			}

			//adding future month days
			let maxRows = 35;
			if (arr.length > 35) {
				maxRows = 42;
			}
			if (arr.length !== maxRows) {
				let rem = maxRows - arr.length;
				for (let i = 0; i < rem; i++) {
					arr.push({
						date: 1 + i,
						month: lastDay.month + 1,
						active: false,
					});
				}
			}
			//setting selected date to highlight
			if (!isEmptyObject(selected)) {
				let currentSelection = {
					date: selected.date,
					month: selDate.month,
					year: selDate.year,
					selectCheck: `${selDate.date}-${selDate.month}-${selDate.year}`,
					active: true,
				};
				setSelected(currentSelection);
			} else {
				setSelected({
					...selDate,
					selectCheck: `${selDate.date}-${selDate.month}-${selDate.year}`,
				});
			}
			return arr;
		},
		// eslint-disable-next-line
		[min]
	);

	const dates = useMemo(() => DatePlotter(viewDate), [DatePlotter, viewDate]);

	const _handleDateSelect = (selection) => {
		setSelected(selection);
		let TempDate = new NepaliDate(
			selection.year,
			selection.month,
			selection.date
		);
		let EngDate = TempDate.getAD();
		let NepDate = TempDate.format("YYYY/MM/DD");

		let monthDay = {
			month:
				EngDate.month + 1 < 10
					? `0${EngDate.month + 1}`
					: EngDate.month + 1,

			date: EngDate.date < 10 ? `0${EngDate.date}` : EngDate.date,
		};

		switch (type) {
			case "en":
				dateSubmitFunction(
					`${EngDate.year}-${monthDay.month}-${monthDay.date}`
				);
				break;
			case "np":
				dateSubmitFunction(TempDate.format("YYYY-MM-DD"));
				break;
			case "np-date":
				dateSubmitFunction(TempDate.format("DD MMMM YYYY"));
				break;
			default:
				dateSubmitFunction({
					EngDate: `${EngDate.year}-${monthDay.month}-${monthDay.date}`,
					NepDate: NepDate,
				});
				break;
		}
		if (field) {
			setDisplayDate(TempDate.format("YYYY-MM-DD"));
		}
	};
	const _handleTodayButton = () => {
		if (viewDate === "") {
			let todayDate = new NepaliDate().getBS();
			setSelected({
				...todayDate,
				selectCheck: `${todayDate.date}-${todayDate.month}-${todayDate.year}`,
			});
		} else setViewDate("");
	};
	const _handleMoveNepDate = (movement) => {
		/* NepaliDate() when receiving Month 0-11 when sending 1-12
		Thus, next month => stored 2079-08-01 (Poush) add +2 to go to Magh  */
		switch (movement) {
			case "nextMonth":
				//next month => stored 2079-08-01 (Poush) add (month+2) and send to go to Magh
				setViewDate(`${selected.year}-${selected.month + 2}-01`);
				break;
			case "preMonth":
				//Previous month => stored 2079-08-01 (Poush), send as is to go to Ashoj
				setViewDate(`${selected.year}-${selected.month}-01`);
				break;
			case "nextYear":
				//next year => stored 2079-08-01 (Poush) add (month+1) and send to go to Poush 2078
				setViewDate(`${selected.year + 1}-${selected.month + 1}-01`);
				break;
			case "preYear":
				//Previous year => stored 2079-08-01 (Poush) add (month+1) and send to go to Poush 2078
				setViewDate(`${selected.year - 1}-${selected.month + 1}-01`);
				break;
			default:
				break;
		}
	};
	return (
		<div
			className={`nd-button-wrapper ${display && "nd-display-constant"} ${
				field && "nd-field-wrapper"
			}`}
		>
			{!display && !field && <div className="nd-button">Nepali Date</div>}
			{!display && field && (
				<div className="nd-field">
					<span>{displayDate ? displayDate : "yyyy-mm-dd"}</span>
					<i className="fa-regular fa-calendar"></i>
				</div>
			)}
			{!disabled && (
				<div className={`nd-content-wrapper ${display && "show"}`}>
					<div className="nd-component-wrapper">
						<div className="nd-button-container">
							<div className="nd-calendar-title">
								{selected.year}
							</div>
							<div className="nd-calendar-title">
								{strings.nepaliMonth[selected.month]?.value}
							</div>
						</div>
						<div className="nd-button-container">
							<div className="nd-today-buttons">
								<div
									onClick={() =>
										_handleMoveNepDate("preYear")
									}
								>
									{"<<"}
								</div>
								<div
									onClick={() =>
										_handleMoveNepDate("preMonth")
									}
								>
									{"<"}
								</div>
								<div onClick={_handleTodayButton}>Today</div>
								<div
									onClick={() =>
										_handleMoveNepDate("nextMonth")
									}
								>
									{">"}
								</div>
								<div
									onClick={() =>
										_handleMoveNepDate("nextYear")
									}
								>
									{">>"}
								</div>
							</div>
						</div>
						<div className="nd-calender">
							{Days.map((d, key) => (
								<div className="nd-days" key={key}>
									{d}
								</div>
							))}
							{dates.length > 0 &&
								dates.map((a, key) => (
									<div
										key={key}
										className={`nd-date ${
											a.active && "clickable"
										} ${
											a.selectCheck ===
												selected.selectCheck && "select"
										} ${
											a.selectCheck ===
												today.selectCheck && "today"
										}`}
										onClick={() =>
											a.active && _handleDateSelect(a)
										}
									>
										{a.date}
									</div>
								))}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default NepaliCalendarPopUp;
