import * as actionTypes from "../actions/employee.action";

const initial_state = {
  isLoading: false,
  employeeDetails: {},
  employeesList: [],
  error: "",
};

export default function employeeReducer(state = initial_state, action) {
  switch (action.type) {
    case actionTypes.FETCH_EMPLOYEE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case actionTypes.FETCH_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employeesList: action.payload || [],
        error: "",
      };
    case actionTypes.SET_EMPLOYEES_LIST:
      return {
        ...state,
        isLoading: false,
        employeesList: [action.payload, ...state.employeesList] || [],
        error: "",
      };
    case actionTypes.FETCH_SINGLE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employeeDetails: action.payload,
        error: "",
      };
    case actionTypes.FETCH_EMPLOYEE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
