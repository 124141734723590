import React, { useState } from "react";
import NepaliCalendarPopUp from "../nepali-cal-popup/nepali-cal-popup";
import { adToBs } from "@sbmdkl/nepali-date-converter";
import "./form-inputs.style.scss";

function EnNpDatePicker(props) {
	const [isNepaliDatePicker, setIsNepaliDatePicker] = useState(false);
	return (
		<div className="input-date-wrapper">
			{isNepaliDatePicker ? (
				<NepaliCalendarPopUp
					type="en"
					dateSubmitFunction={(date) => {
						props.onChange(props.name, date);
					}}
					field={true}
					min={props.min}
					date={props.value ? adToBs(props.value) : ""}
					disabled={props.disabled}
				/>
			) : (
				<input
					{...props}
					onChange={(e) => {
						props.onChange(props.name, e.target.value);
					}}
				/>
			)}

			<div className="date-type-options">
				<div
					className={`type-btn ${!isNepaliDatePicker && "en"}`}
					onClick={() => setIsNepaliDatePicker(false)}
				>
					Eng
				</div>
				<div
					className={`type-btn ${isNepaliDatePicker && "np"}`}
					onClick={() => setIsNepaliDatePicker(true)}
				>
					Nep
				</div>
			</div>
		</div>
	);
}

export default EnNpDatePicker;
