import * as actionTypes from "../actions/activity.action";

const initial_state = {
	isLoading: false,
	activityLog: [],
	billLog: [],
	error: "",
};

export default function activityReducer(state = initial_state, action) {
	switch (action.type) {
		case actionTypes.FETCH_ACTIVITY_REQUEST:
			return {
				...state,
				isLoading: true,
				error: "",
			};
		case actionTypes.FETCH_ACTIVITY_SUCCESS:
			return {
				...state,
				isLoading: false,
				activityLog: action.payload || [],
				error: "",
			};

		case actionTypes.FETCH_BILL_LOG_SUCCESS:
			return {
				...state,
				isLoading: false,
				billLog: action.payload || [],
				error: "",
			};
		case actionTypes.FETCH_ACTIVITY_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
