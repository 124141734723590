import React from "react";
import "./form-inputs.style.scss";

function MultipleTagsInput(props) {
	return (
		<div className="input-item">
			{props?.label && (
				<label className="input-label">{props.label}</label>
			)}
			<input
				className="input-box-wrapper"
				type="text"
				name={props.name}
				placeholder="Enter tags separated by #"
				onKeyDown={props.onKeyDown}
				onBlur={props.onBlur}
				autoComplete="off"
				disabled={props.disabled}
			/>
			{props?.error && (
				<div className="validation-error">{props?.error}</div>
			)}
			<div className="tags-wrapper">
				{Array.isArray(props.tagsArray) &&
					props.tagsArray?.length > 0 &&
					props.tagsArray.map((tag, index) => (
						<div className="tag-container" key={index}>
							<span className="tag-item">{tag}</span>
							{!props.disabled && (
								<span
									className="tag-close"
									onClick={() => props.onTagRemove(index)}
								>
									&times;
								</span>
							)}
						</div>
					))}
			</div>
		</div>
	);
}

export default MultipleTagsInput;
