import { useEffect } from "react";
import {
	createBrowserRouter,
	Route,
	RouterProvider,
	Routes,
	useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import "./App.scss";
import BillMeLogo from "./assets/logo-white-BILLME.png";

// pages
import Login from "./pages/login/login.page";
import Verification from "./pages/login/verification.page";
import Dashboard from "./pages/dashboard/dasboard.page";
import CreateInvoice from "./pages/create-invoice/create-invoice.page";
import InvoiceBill from "./pages/invoice-bill/invoice-bill.page";
import ActivityMonitor from "./pages/activity-monitor/activity-monitor.page";
import CustomerList from "./pages/customer-list/customer-list.page";
import EmployeeList from "./pages/employee-list/employee-list.page";
import CompanySetting from "./pages/company-setting/company-setting.page";
import ErrorPage from "./pages/error/error.page";
import MissingRoute from "./pages/error/missing.page";
// import TestPage from "./pages/test/test.page";
// import InvoiceTemplatePage from "./pages/invoice-template/invoice-template.page";

// component
import ProtectedRoute from "./routes/ProtectedRoute";
import VerificationRoute from "./routes/VerificationRoute";

const router = createBrowserRouter([{ path: "*", Component: Root }]);

function App() {
	return (
		<>
			{window.innerWidth > 680 ? (
				<div className="App">
					<RouterProvider router={router} />
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable={false}
						pauseOnHover
					/>
				</div>
			) : (
				<div className="App-no-mobile-view">
					<img src={BillMeLogo} alt="BillMe by Nibjar" />
					Mobile view is not supported!
				</div>
			)}
		</>
	);
}

function Root() {
	// navigation from redux
	const { nav_pathname } = useSelector((state) => state.authUserReducer);
	const navigate = useNavigate();

	useEffect(() => {
		if (nav_pathname) {
			navigate(nav_pathname);
		}
	}, [nav_pathname, navigate]);

	return (
		<Routes>
			<Route
				exact
				path="/login"
				element={<Login />}
				errorElement={<ErrorPage />}
			/>
			<Route element={<VerificationRoute />}>
				<Route
					exact
					path="/verification"
					element={<Verification />}
					errorElement={<ErrorPage />}
				/>
			</Route>
			<Route element={<ProtectedRoute />}>
				<Route
					exact
					path="/"
					element={<Dashboard />}
					errorElement={<ErrorPage />}
				/>
				<Route
					exact
					path="/invoice/create"
					element={<CreateInvoice />}
					errorElement={<ErrorPage />}
				/>
				<Route
					exact
					path="/invoice/bills"
					element={<InvoiceBill />}
					errorElement={<ErrorPage />}
				/>
				<Route
					exact
					path="/activities"
					element={<ActivityMonitor />}
					errorElement={<ErrorPage />}
				/>
				<Route
					exact
					path="/customers"
					element={<CustomerList />}
					errorElement={<ErrorPage />}
				/>
				<Route
					exact
					path="/employees"
					element={<EmployeeList />}
					errorElement={<ErrorPage />}
				/>
				<Route
					exact
					path="/company-setting"
					element={<CompanySetting />}
					errorElement={<ErrorPage />}
				/>
			</Route>

			<Route path="*" element={<MissingRoute />} />

			{/* <Route
				exact
				path="/test"
				element={<TestPage />}
				errorElement={<ErrorPage />}
			/> */}
		</Routes>
	);
}

export default App;
