import React from "react";
import "./spinner.style.scss";

function Spinner({ styles }) {
	return (
		<p className="spinner" id="spinner" style={styles}>
			<i className="fa fa-spinner"></i>
		</p>
	);
}

export default Spinner;
