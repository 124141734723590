import React from "react";
import { Line } from "react-chartjs-2";

function LineChart({ labels, chartData }) {
	const data = {
		labels,
		datasets: [
			{
				label: "Revenue",
				data: chartData,
				borderColor: "#3498DB",
				fill: true,
				backgroundColor: (context) => {
					const gradient = context.chart.ctx.createLinearGradient(
						0,
						0,
						0,
						context.chart.height
					);
					gradient.addColorStop(0, "#3498DB44");
					gradient.addColorStop(1, "#3498DB00");
					return gradient;
				},
				borderWidth: 3,
				tension: 0.3,
			},
		],
	};
	return (
		<Line
			data={data}
			options={{
				plugins: {
					title: {
						display: false,
					},
					legend: {
						display: false,
					},
				},
				gradientColor: "#3498DB22",
				aspectRatio: false,
				scales: {
					x: {
						grid: { display: false },
					},
					y: {
						grid: { color: "#d8d8d844" },
						border: { display: false },
					},
				},
			}}
		/>
	);
}
export default LineChart;
