import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import { sessionInfo } from "../../global/function";

import { isEmptyObject } from "../../global/function";
import {
	fetchLoginError,
	logout,
	setLoginInfo,
	setNavigationPath,
} from "./auth-user.action";
import { fetchDraftSuccess } from "./drafts.action";
import { setCompanyDataCorruptStatus } from "./company.action";
import { fetchStatusRequestSuccess } from "./bill-server-status.action";

export const FETCH_BILL_REQUEST = "FETCH_BILL_REQUEST";
export const FETCH_BILL_SUCCESS = "FETCH_BILL_SUCCESS";
export const FETCH_ADD_BILL_SUCCESS = "FETCH_ADD_BILL_SUCCESS";
export const FETCH_BILL_ERROR = "FETCH_BILL_ERROR";

export const FETCH_BILL_DATES_SUCCESS = "FETCH_BILL_DATES_SUCCESS";
export const FETCH_ADDITIONAL_BILL_INFO_SUCCESS =
	"FETCH_ADDITIONAL_BILL_INFO_SUCCESS";

export const SET_LAST_BILL_DATE_SUCCESS = "SET_LAST_BILL_DATE_SUCCESS";
export const SET_BILL_PRINT_COUNT = "SET_BILL_PRINT_COUNT";
export const SET_ADDED_BILL_PRINT_COUNT = "SET_ADDED_BILL_PRINT_COUNT";

export const fetchBillRequest = () => {
	return {
		type: FETCH_BILL_REQUEST,
	};
};

export const fetchBillSuccess = (bills) => {
	return {
		type: FETCH_BILL_SUCCESS,
		payload: bills,
	};
};

export const fetchAddBillSuccess = (bill) => {
	return {
		type: FETCH_ADD_BILL_SUCCESS,
		payload: bill,
	};
};

export const fetchBillError = (error) => {
	return {
		type: FETCH_BILL_ERROR,
		payload: error,
	};
};

export const fetchBillDatesSuccess = (dates) => {
	return {
		type: FETCH_BILL_DATES_SUCCESS,
		payload: dates,
	};
};

// set from FE
export const setLastBillDateSuccess = (date) => {
	return {
		type: SET_LAST_BILL_DATE_SUCCESS,
		payload: date,
	};
};

export const fetchAdditionalBillsInfoSuccess = (info) => {
	return {
		type: FETCH_ADDITIONAL_BILL_INFO_SUCCESS,
		payload: info,
	};
};

// increment print count of indexed bill in billList from FE
export const setBillPrintCount = (printInfo) => {
	return {
		type: SET_BILL_PRINT_COUNT,
		payload: printInfo,
	};
};

// increment print count of addedBill from FE
export const setAddedBillPrintCount = () => {
	return {
		type: SET_ADDED_BILL_PRINT_COUNT,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		await dispatch(setNavigationPath("/login"));
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchBillError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchBillError(error.message));
	}
}

export const getBillDates = () => {
	return async (dispatch) => {
		dispatch(fetchBillRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}bill/last/date`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchBillDatesSuccess(res.data.result));
				} else {
					await dispatch(fetchBillError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const fetchBills = () => {
	return async (dispatch) => {
		dispatch(fetchBillRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}bill/company`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchBillSuccess(res.data.result));
					let moreBillInfo = {
						maxSales:
							res.data.count?.maxSales === 0
								? 10000
								: res.data.count?.maxSales,
						billCount: res.data.result?.length,
						creditNoteCount: res.data.count?.totalCreditNote,
					};
					await dispatch(
						fetchAdditionalBillsInfoSuccess(moreBillInfo)
					);
				} else {
					await dispatch(fetchBillError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const fetchBillById = (billId) => {
	return async (dispatch) => {
		dispatch(fetchBillRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}bill/id?bill_id=${billId}`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchBillSuccess(res.data.result));
				} else {
					await dispatch(fetchBillError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const addBill = (billData, requestFrom) => {
	return async (dispatch) => {
		dispatch(fetchBillRequest());
		let sessionData = await sessionInfo();
		let bill = {
			...billData,
			username: sessionData?.username,
			password: sessionData?.password,
			seller_pan:
				sessionData?.registration_type === "pan"
					? sessionData?.registration_number
					: "",
		};

		return axios
			.post(`${strings.url}bill/add`, bill, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					requestFrom === "bulk"
						? await dispatch(fetchDraftSuccess(res.data.result))
						: await dispatch(fetchAddBillSuccess(res.data.result));
					// returns new drafts list after adding bill if from drafts page and return added bill if from dashboard direct bill add

					showToastMessage("Success! Bill added successfully", "S");
				} else {
					switch (res.data.errorStatus) {
						case 100:
							if (sessionData?.user_role === "user") {
								await dispatch(logout());
							} else {
								await dispatch(setLoginInfo(false));
							}
							break;
						case 101:
						case 105:
							await dispatch(setCompanyDataCorruptStatus(true));
							break;
						case 102:
						case 103:
						case 104:
							await dispatch(fetchStatusRequestSuccess(false));
							await localStorage.setItem(
								"irdStatus",
								JSON.stringify(false)
							);
							break;
						default:
							break;
					}
					if (res.data.result) {
						await dispatch(fetchDraftSuccess(res.data.result));
					}
					showToastMessage(res.data.message, "E");
					await dispatch(fetchBillError(res.data.message));
				}
				return res.data;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const searchBills = (searchData, activityFlag = false) => {
	return async (dispatch) => {
		dispatch(fetchBillRequest());
		let sessionData = await sessionInfo();

		return axios
			.post(
				`${strings.url}bill/search?activityFlag=${activityFlag}`,
				searchData,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchBillSuccess(res.data.result?.bill));
					let moreBillInfo = {
						maxSales:
							res.data.result?.maxSales === 0
								? 10000
								: res.data.result?.maxSales,
						billCount: res.data.result?.totalBill,
						creditNoteCount: res.data.result?.totalCreditNote,
					};
					await dispatch(
						fetchAdditionalBillsInfoSuccess(moreBillInfo)
					);
				} else {
					await dispatch(fetchBillError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const incrementPrintCount = (updateInfo, index = null) => {
	return async (dispatch) => {
		dispatch(fetchBillRequest());
		let sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}bill/update/print`, updateInfo, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					if (index !== null) {
						// null value is for bulk print
						if (index === -1) {
							// addedBill print count change
							await dispatch(setAddedBillPrintCount());
						} else {
							// individual bill print count to change in billList
							await dispatch(
								setBillPrintCount({
									index,
									type: updateInfo.model_type,
								})
							);
						}
					}
				} else {
					await dispatch(fetchBillError(res.data.message));
				}
				return res.data.success;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
