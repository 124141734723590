import React, { memo, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import "./draft-list.style.scss";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
	addLocalDrafts,
	deleteDraft,
	saveDraft,
	setLocalDrafts,
} from "../../redux/actions/drafts.action";

// global hooks anf functions
import { useOutsideClick } from "../../hooks/useOutsideClick";
import showToastMessage from "../../global/showToastMessage";

const DraftItem = ({
	draft,
	isSelected,
	toggleSelection,
	currentDraft,
	onSetCurrent,
	unsavedInvoiceNumber,
	selectable,
}) => {
	// currentDraft is the one chosen for editing

	function _renderStatus() {
		let status;
		if (unsavedInvoiceNumber === draft?.invoice_number) {
			status = "editing";
		} else {
			if (
				draft?.hasOwnProperty("draft_bill_status") &&
				draft?.draft_bill_status === "draft"
			) {
				status = draft.draft_bill_status;
			} else {
				if (draft?.hasOwnProperty("draft_valid")) {
					status = "unsaved";
				} else {
					status = "new";
				}
			}
		}
		return <div className={`bill_status ${status}`}>{status}</div>;
	}

	return (
		<div
			className={`list-item-container ${
				currentDraft?.invoice_number === draft.invoice_number &&
				"selected"
			}`}
			onClick={() => onSetCurrent(draft)}
		>
			<div className="list-item-container-inner">
				<div
					className={`draft-select ${isSelected && "selected"} ${
						!draft.hasOwnProperty("draft_valid") && !selectable
							? "disabled"
							: null
					}`}
					onClick={(e) => toggleSelection(e, draft)}
				>
					{isSelected && (
						<i
							className="fa-solid fa-check"
							style={{
								color: "#46BE46",
								fontSize: "12px",
							}}
						></i>
					)}
				</div>

				<div className="list-details" style={{ flex: 1 }}>
					<div className="details-col">
						<div className="list-maintext">
							{draft?.buyer_name || "[Customer Name]"}
						</div>
						<div className="list-subtext">
							Sales Date:{" "}
							<span>
								{(draft?.invoice_date &&
									moment(draft?.invoice_date).format("DD") +
										" " +
										moment(draft?.invoice_date).format(
											"MMMM"
										) +
										" " +
										moment(draft?.invoice_date).format(
											"YYYY"
										)) ||
									"[Invoice Date]"}
							</span>
						</div>
					</div>
					<div className="details-divider"></div>
					<div className="details-row">
						<div className="list-smalltext">
							{draft?.draft_bill_status &&
								`# ${draft?.invoice_number}`}
						</div>
						<div
							className="list-smalltext"
							style={{
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
						>
							{draft?.updatedAt
								? `- ${moment(draft?.updatedAt).fromNow()}`
								: null}
						</div>
					</div>
				</div>
				<div className="list-details">
					{_renderStatus()}
					{draft?.draft_valid && (
						<div className="valid-indicator">
							<div className="valid-icon">
								<i className="fa-solid fa-check"></i>
							</div>
							<span>Validated</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
function SelectDropdownModal({ isVisible, onFilterSelect }) {
	return (
		<div
			className="dropdown-modal-container"
			style={isVisible ? { display: "block" } : { display: "none" }}
			onClick={(e) => e.stopPropagation()}
		>
			<div className="container-pointer"></div>
			<div
				className="dropdown-item"
				onClick={() => onFilterSelect("valid")}
			>
				Validated Drafts
			</div>
			<div
				className="dropdown-item"
				onClick={() => onFilterSelect("unsaved")}
			>
				Unsaved Drafts
			</div>
		</div>
	);
}

function DraftList({ currentDraft, onSetCurrent, unsavedInvoiceNumber }) {
	const { draftsList, localDraftsList, isLoading } = useSelector(
		(state) => state.draftsReducer
	);
	// const { lastBillDate } = useSelector((state) => state.billReducer);
	const [drafts, setDrafts] = useState([]);
	// select multiple drafts state
	const [selectedItems, setSelectedItems] = useState(new Set());

	const [selectedInvalid, setSelectedInvalid] = useState(0);
	const [selectedNoStatus, setSelectedNoStatus] = useState(0);
	const [isSelectedNew, setIsSelectedNew] = useState(0);

	const [isDropdownVisible, setIsDropdownVisible] = useState(false);
	const toggleDropdownModal = () => {
		setIsDropdownVisible(!isDropdownVisible);
	};
	const handleClickOutside = () => {
		setIsDropdownVisible(false);
	};
	const ref = useOutsideClick(handleClickOutside);

	const dispatch = useDispatch();

	useEffect(() => {
		let totalDrafts = [...draftsList, ...localDraftsList];
		setDrafts(totalDrafts);
	}, [draftsList, localDraftsList]);

	const handleCreateDraft = async () => {
		let dummyDraft = {
			invoice_number: `DRF_${uuidv4()}`,
			invoice_date: moment().format("YYYY-MM-DD"),
		};
		dispatch(addLocalDrafts(dummyDraft));
		onSetCurrent(dummyDraft);
	};

	// Function to toggle selection of an item
	const toggleSelection = (e, item) => {
		e.stopPropagation();
		let counter = 1;
		const newSelectedItems = new Set(selectedItems);
		if (newSelectedItems.has(item)) {
			counter = -1;
			newSelectedItems.delete(item);
		} else {
			newSelectedItems.add(item);
		}
		if (item?.hasOwnProperty("draft_valid")) {
			if (!item?.draft_valid) {
				setSelectedInvalid((prev) => prev + counter);
			}
			if (!item?.hasOwnProperty("draft_bill_status")) {
				setSelectedNoStatus((prev) => prev + counter);
			}
		} else {
			setIsSelectedNew((prev) => prev + counter);
			setSelectedInvalid((prev) => prev + counter);
		}
		// Update the selected items state
		setSelectedItems(newSelectedItems);
	};

	const isDraftSelected = useMemo(() => {
		return drafts.length > 0 && selectedItems.size > 0;
	}, [drafts, selectedItems]);

	// // Determine if all items are selected
	// const isAllDraftSelected = useMemo(() => {
	// 	return drafts.length > 0 && selectedItems.size === drafts.length;
	// }, [drafts, selectedItems]);

	const filteredDraftSelection = (filterType) => {
		if (filterType === "unsaved") {
			let noStatusCounter = 0;
			let validCounter = 0;
			const filteredDrafts = drafts.reduce((filtered, draft) => {
				if (
					draft.hasOwnProperty("draft_valid") &&
					!draft.hasOwnProperty("draft_bill_status")
				) {
					noStatusCounter += 1;
					filtered.push(draft);
					if (!draft?.draft_valid) {
						validCounter += 1;
					}
				}
				return filtered;
				// Update the selected items state
			}, []);

			setSelectedNoStatus(noStatusCounter);
			setSelectedInvalid(validCounter);

			filteredDrafts.length > 0
				? setSelectedItems(new Set(filteredDrafts))
				: showToastMessage("There are no unsaved drafts", "I");
		} else {
			// filterType === "valid"
			// Create an index for grouping drafts by draft_valid=true value
			let noStatusCounter = 0;
			const draftsIndex = drafts.reduce((index, draft) => {
				const isValid =
					draft.hasOwnProperty("draft_valid") && draft?.draft_valid;
				if (!index[isValid]) {
					index[isValid] = [];
				}
				if (isValid && !draft.hasOwnProperty("draft_bill_status")) {
					noStatusCounter += 1;
				}
				index[isValid].push(draft);
				return index;
			}, {});

			console.log(noStatusCounter);
			console.log(draftsIndex);

			setSelectedNoStatus(noStatusCounter);
			setSelectedInvalid(0);
			const filteredDrafts = draftsIndex[true] || [];
			if (filteredDrafts.length > 0) {
				setSelectedItems(new Set(filteredDrafts));
				setIsDropdownVisible(false);
			} else {
				showToastMessage("There are no validated drafts", "I");
			}
		}
		setIsDropdownVisible(false);
		setIsSelectedNew(0);
	};

	const resetCounter = () => {
		setSelectedItems(new Set());
		setSelectedNoStatus(0);
		setSelectedInvalid(0);
		setIsSelectedNew(0);
	};

	// to reset selection if after selection, user tries any update action in draft
	useEffect(() => {
		resetCounter();
	}, [unsavedInvoiceNumber]);

	const handleDraftDeselect = () => {
		if (isDraftSelected) {
			resetCounter();
		} else {
			toggleDropdownModal();
		}
	};

	// function validateInvDate(value) {
	// 	if (lastBillDate !== "") {
	// 		const draft_date = new Date(value);
	// 		const last_bill_date = new Date(lastBillDate);

	// 		if (draft_date >= last_bill_date) {
	// 			return true; // Date is later than the global date
	// 		} else {
	// 			return false; // Date is not later than the global date
	// 		}
	// 	} else {
	// 		return true;
	// 	}
	// }

	// bulk draft add
	const handleBulkDraftSave = async () => {
		if (!isLoading) {
			// Convert Set to Array
			const selectedDrafts = [...selectedItems];
			let saveDrafts = {
				drafts: selectedDrafts,
				draft_bill_status: "draft",
			};
			const result = await dispatch(saveDraft(saveDrafts));

			if (result) {
				// remove drafts from local drafts
				let newLocalDrafts = localDraftsList.filter(
					(localDraft) => !selectedItems.has(localDraft)
				);
				dispatch(setLocalDrafts(newLocalDrafts));
			}

			// Clear the selected items set
			resetCounter();
			onSetCurrent({});
		}
	};

	// bulk draft update
	const handleBulkDraftReady = async () => {
		if (!isLoading) {
			// Convert Set to Array
			const selectedDrafts = [...selectedItems];

			// const newSelectedDrafts = selectedDrafts.filter((item) => {
			// 	if (!item?.draft_valid) {
			// 		return false;
			// 	}
			// 	const valid = validateInvDate(item?.invoice_date);

			// 	if (!valid) {
			// 		item["draft_valid"] = false;
			// 	}
			// 	return valid;
			// });

			const newSelectedDrafts = selectedDrafts.filter(
				(draft) => draft?.draft_valid
			);

			if (selectedDrafts.length !== newSelectedDrafts.length) {
				showToastMessage(
					"Some of the drafts have not been validated",
					"I"
				);
			}
			if (
				Array.isArray(newSelectedDrafts) &&
				newSelectedDrafts.length > 0
			) {
				let readyDrafts = {
					drafts: selectedDrafts,
					draft_bill_status: "ready",
				};
				const result = await dispatch(saveDraft(readyDrafts));

				if (result) {
					// remove drafts from local drafts
					let newLocalDrafts = localDraftsList.filter(
						(localDraft) => !selectedItems.has(localDraft)
					);
					dispatch(setLocalDrafts(newLocalDrafts));
				}
			}

			// Clear the selected items set
			resetCounter();
			onSetCurrent({});
		}
	};

	// bulk or individual draft Delete
	const handleDeleteDraft = async () => {
		if (!isLoading) {
			const selectedDrafts = [...selectedItems];

			// filter out drafts to be deleted and set localDrafts to new after deletion
			let newLocalDrafts = localDraftsList.filter(
				(localDraft) => !selectedItems.has(localDraft)
			);
			dispatch(setLocalDrafts(newLocalDrafts));

			// filter drafts to be deleted and send to be deleted drafts to backend for deletion
			const selectedDraftsList = [];

			for (const item of selectedDrafts) {
				if ("draft_bill_status" in item) {
					selectedDraftsList.push(item);
				}
			}
			Array.isArray(selectedDraftsList) &&
				selectedDraftsList.length > 0 &&
				(await dispatch(deleteDraft({ drafts: selectedDraftsList })));

			// Clear the selected items set
			resetCounter();
			onSetCurrent({});
		}
	};

	return (
		<div className="drafts-invoices-container">
			<div className="drafts-invoices-header">
				<div className="di-header-title">
					<div className="section-title-text">Invoice Draft</div>
				</div>
				<div className="drafts-action">
					<div
						className={`draft-select-container  ${
							isDropdownVisible && "focused"
						}`}
						ref={ref}
					>
						<div
							className="draft-select"
							onClick={handleDraftDeselect}
						>
							{isDraftSelected && "-"}
						</div>
						<div
							className="select-options"
							onClick={toggleDropdownModal}
						>
							<i className="fa-solid fa-caret-down"></i>
						</div>
						<SelectDropdownModal
							isVisible={isDropdownVisible}
							onFilterSelect={filteredDraftSelection}
						/>
					</div>

					{!isLoading && (
						<div className="drafts-action-btns">
							<div
								className="action-btn action-del"
								disabled={selectedItems.size < 1}
								onClick={handleDeleteDraft}
							>
								<i className="fa-regular fa-trash-can"></i>
							</div>
							{isSelectedNew < 1 &&
								selectedInvalid > 0 &&
								selectedNoStatus > 0 && (
									<div
										className="action-btn action-draft"
										disabled={selectedItems.size < 1}
										onClick={handleBulkDraftSave}
									>
										<i className="fa-regular fa-floppy-disk"></i>
									</div>
								)}
							{isSelectedNew < 1 && selectedInvalid === 0 && (
								<div
									className="action-btn action-ready"
									disabled={selectedItems.size < 1}
									onClick={handleBulkDraftReady}
								>
									<i className="fa-regular fa-paper-plane"></i>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
			<div className="drafts-invoices-list">
				{drafts?.length > 0 ? (
					<>
						{drafts?.map((draft, i) => (
							<DraftItem
								key={i}
								draft={draft}
								isSelected={selectedItems.has(draft)}
								toggleSelection={toggleSelection}
								currentDraft={currentDraft}
								onSetCurrent={onSetCurrent}
								unsavedInvoiceNumber={unsavedInvoiceNumber}
								selectable={
									selectedItems.size > 0
										? isSelectedNew > 0
										: true
								}
							/>
						))}
						<div
							className="new-draft-btn"
							onClick={handleCreateDraft}
						>
							<i className="fa-solid fa-file-circle-plus"></i>
							Generate New Draft
						</div>
					</>
				) : (
					<div className="new-draft-btn" onClick={handleCreateDraft}>
						<i className="fa-solid fa-file-circle-plus"></i>
						Generate New Draft
					</div>
				)}
			</div>
		</div>
	);
}

export default memo(DraftList);
