import {
	legacy_createStore as createStore,
	combineReducers,
	applyMiddleware,
} from "redux";
import thunk from "redux-thunk";

// reducers
import authUserReducer from "./reducer/auth-user.reducer";
import draftsReducer from "./reducer/drafts.reducer";
import billReducer from "./reducer/bill.reducer";
import creditNoteReducer from "./reducer/creditNote.reducer";
import customerReducer from "./reducer/customer.reducer";
import activityReducer from "./reducer/activity.reducer";
import companyReducer from "./reducer/company.reducer";
import imageUploadReducer from "./reducer/image-upload.reducer";
import employeeReducer from "./reducer/employee.reducer";
import exportImportReducer from "./reducer/export-import.reducer";
import billServerStatusReducer from "./reducer/bill-server-status.reducer"

const rootReducer = combineReducers({
	authUserReducer,
	draftsReducer,
	billReducer,
	creditNoteReducer,
	customerReducer,
	employeeReducer,
	activityReducer,
	companyReducer,
	imageUploadReducer,
	exportImportReducer,
	billServerStatusReducer
});

const middleware = [thunk];

const store = createStore(rootReducer, applyMiddleware(...middleware));

export default store;
