import React, { useCallback, useEffect, useState } from "react";
import { useNavigationType } from "react-router-dom";
import Swal from "sweetalert2";
import "./employee-list.style.scss";
import "../../components/form-inputs/form-inputs.style.scss";
import "../../components/bill-list/bill-filter.style.scss";

// for filter form handle
import { useFormik } from "formik";
import * as yup from "yup";

// components
import UserListItem from "../../components/user-list-item/user-list-item.component";
import EmployeeProfile from "../../components/user-profile-sidemodal/employee-profile.component";
import Pagination from "../../components/pagination/pagination.component";
import EmptyRecord from "../../components/empty-record/empty-record.component";

// global function
import { roleAccess } from "../../global/function";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
	fetchCompanyEmployees,
	searchEmployees,
} from "../../redux/actions/employee.action";
import { exportFile } from "../../redux/actions/export-import.action";

const employeeSchema = yup.object().shape({
	user_id: yup.string().nullable(),
	first_name: yup.string().nullable(),
	last_name: yup.string().nullable(),
	user_mobile: yup.string().nullable(),
	user_email: yup.string().email("Please enter valid email").nullable(),
});

const initial_values = {
	user_id: "",
	first_name: "",
	last_name: "",
	user_email: "",
	user_mobile: "",
};

function EmployeeList() {
	const { employeesList } = useSelector((state) => state.employeeReducer);
	const { isLoading } = useSelector((state) => state.exportImportReducer);

	const [addEmployeeView, setAddEmployeeView] = useState(false);
	const [permissions, setPermissions] = useState({});

	const [totalPages, setTotalPages] = useState(0); // Total number of pages
	const [currentPageNum, setCurrentPageNum] = useState(1); // current page no

	const itemsPerPage = 10;
	const dispatch = useDispatch();
	const navType = useNavigationType();

	// fetch company employee
	const getCompanyEmployees = useCallback(
		async (activityFlag = false) => {
			const totalCount = await dispatch(
				fetchCompanyEmployees(itemsPerPage, 1, activityFlag) // (limit, page, activityFlag)
			);
			setTotalPages(Math.ceil(totalCount / itemsPerPage));
		},
		[dispatch]
	);

	useEffect(() => {
		let activityFlag = false;
		navType === "PUSH" ? (activityFlag = true) : (activityFlag = false);

		getCompanyEmployees(activityFlag);
	}, [dispatch, getCompanyEmployees, navType]);

	useEffect(() => {
		const getPermission = async () => {
			setPermissions(await roleAccess("employee"));
		};
		getPermission();
	}, []);

	const filterFormik = useFormik({
		validationSchema: employeeSchema,
		initialValues: initial_values,
		onSubmit: async (filterValues) => {
			const totalCount = await dispatch(
				searchEmployees(filterValues, itemsPerPage, 1)
			);
			setTotalPages(Math.ceil(totalCount / itemsPerPage));
		},
		onReset: async () => {
			await getCompanyEmployees();
		},
	});

	const handleUserExport = async () => {
		Swal.fire({
			title: "Export Users",
			text: "All system users will be exported to excel file. Are you sure?",
			icon: "info",
			showDenyButton: true,
			confirmButtonText: "Export",
			denyButtonText: "Cancel",
			customClass: {
				popup: "alert-modal",
				actions: "btn-container",
				confirmButton: "alert-btn btn-confirm",
				denyButton: "alert-btn btn-cancel",
			},
		}).then(async (result) => {
			if (result.isConfirmed) {
				if (!isLoading) {
					await dispatch(exportFile("user"));
				}
			}
		});
	};

	const handlePageClick = async (selected) => {
		let currentPage = selected.selected + 1; // selected.selected is the new page index
		setCurrentPageNum(currentPage);
		let searchData = {
			...filterFormik.values,
		};
		const totalCount = await dispatch(
			searchEmployees(searchData, itemsPerPage, currentPage)
		);
		setTotalPages(Math.ceil(totalCount / itemsPerPage));
	};

	function _renderEmployeesFilter() {
		return (
			<div className="filter-container">
				<div className="filter-container-inner">
					<div className="filter-items-container">
						<h2 className="filter-section-header">
							Filter Employees
						</h2>
						<p className="action-description">
							The export to excel exports all users information.
						</p>
						<div
							className="action-btn"
							disabled={isLoading}
							onClick={handleUserExport}
						>
							<i className="fa-solid fa-file-export"></i>
							Export to excel
						</div>
					</div>
					<div className="filter-items-container">
						<div className="input-item">
							<p className="input-label">Employee Id</p>
							<input
								name="user_id"
								className="input-box-wrapper"
								type="text"
								placeholder="# Employee ID"
								onChange={filterFormik.handleChange}
								onBlur={filterFormik.handleBlur}
								value={filterFormik.values?.user_id}
							/>
							{filterFormik.submitCount > 0 && (
								<div className="validation-error">
									{filterFormik?.errors?.user_id}
								</div>
							)}
						</div>
						<div className="input-row">
							<div className="input-item">
								<p className="input-label">First Name</p>
								<input
									name="first_name"
									className="input-box-wrapper"
									type="text"
									placeholder="Enter First Name"
									onChange={filterFormik.handleChange}
									onBlur={filterFormik.handleBlur}
									value={filterFormik.values?.first_name}
								/>
								{filterFormik.submitCount > 0 && (
									<div className="validation-error">
										{filterFormik?.errors?.first_name}
									</div>
								)}
							</div>
							<div className="input-item">
								<p className="input-label">Last Name</p>
								<input
									name="last_name"
									className="input-box-wrapper"
									type="text"
									placeholder="Enter Last Name"
									onChange={filterFormik.handleChange}
									onBlur={filterFormik.handleBlur}
									value={filterFormik.values?.last_name}
								/>
								{filterFormik.submitCount > 0 && (
									<div className="validation-error">
										{filterFormik?.errors?.last_name}
									</div>
								)}
							</div>
						</div>
						<div className="input-item">
							<p className="input-label">Mobile Number</p>
							<input
								name="user_mobile"
								className="input-box-wrapper"
								type="text"
								placeholder="Enter Mobile Number"
								onChange={filterFormik.handleChange}
								onBlur={filterFormik.handleBlur}
								value={filterFormik.values?.user_mobile}
							/>
							{filterFormik.submitCount > 0 && (
								<div className="validation-error">
									{filterFormik?.errors?.user_mobile}
								</div>
							)}
						</div>
						<div className="input-item">
							<p className="input-label">Employee Email</p>
							<input
								name="user_email"
								className="input-box-wrapper"
								type="text"
								placeholder="Enter Email"
								onChange={filterFormik.handleChange}
								onBlur={filterFormik.handleBlur}
								value={filterFormik.values?.user_email}
							/>
							{filterFormik.submitCount > 0 && (
								<div className="validation-error">
									{filterFormik?.errors?.user_email}
								</div>
							)}
						</div>
					</div>
					<div className="filter-btn-container">
						<div
							className="filter-btn inverted"
							onClick={filterFormik.resetForm}
						>
							<i className="fa-solid fa-eraser"></i>Reset
						</div>
						<div
							className="filter-btn"
							onClick={filterFormik.submitForm}
						>
							<i className="fa-solid fa-magnifying-glass"></i>
							Search
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="employee-list-wrapper">
			<div className="wrapper-row">
				{_renderEmployeesFilter()}
				<div className="employee-list-container">
					<div className="section-header-container">
						<h2 className="section-header-title">Employees</h2>
						{permissions?.edit && (
							<div
								className="header-action-btn"
								onClick={() => setAddEmployeeView(true)}
							>
								<i className="fa-solid fa-user-plus"></i>
								Add Employee
							</div>
						)}
					</div>
					<div className="employee-list-items">
						{Array.isArray(employeesList) &&
						employeesList?.length > 0 ? (
							employeesList.map((employee, i) => (
								<UserListItem
									key={employee.user_id}
									user={employee}
									type="employee"
									currentPageNum={currentPageNum}
									empPermission={permissions}
								/>
							))
						) : (
							<EmptyRecord />
						)}
					</div>
					{totalPages > 0 && (
						<Pagination
							totalPages={totalPages}
							onPageClick={handlePageClick}
							forcePage={currentPageNum - 1}
						/>
					)}
				</div>
			</div>
			{addEmployeeView && (
				<EmployeeProfile
					isEmployeeModalVisible={addEmployeeView}
					onEmployeeModalClose={() => setAddEmployeeView(false)}
					employee={{}}
					empPermission={permissions}
				/>
			)}
		</div>
	);
}

export default EmployeeList;
