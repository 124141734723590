import React from "react";
import "./form-inputs.style.scss";

function SelectBox(props) {
	const returnOptions = () => {
		return props.options.map((row, i) => (
			<option value={row.id} key={i}>
				{row.value}
			</option>
		));
	};

	return (
		<div className={`select-wrapper ${props.disabled && "disabled"}`}>
			<select key={props.id} className="select-box-wrapper" {...props}>
				<option value="">- Select -</option>
				{returnOptions()}
			</select>
		</div>
	);
}
export default SelectBox;
