import React, { useCallback, useEffect, useState } from "react";
import "./dashboard.style.scss";
import { useNavigate } from "react-router-dom";

//
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";

// for date purpose
import moment from "moment";
import { adToBs } from "@sbmdkl/nepali-date-converter";

// global functions
import {
	isEmptyObject,
	nepaliDateMMMM,
	rupeeConverter,
} from "../../global/function";

// components
import SideModal from "../../components/side-modal/side-modal.component";
import LineChart from "../../components/charts/line-charts.component";
import DirectBill from "../../components/direct-bill/direct-bill.component";
import ActivityItem from "../../components/activity-item/activity-item.component";

// image
import dashboard from "../../assets/dashboard.png";

// icons
import { FaUserCheck } from "react-icons/fa";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyDashboard } from "../../redux/actions/company.action";

Chart.register(CategoryScale);

function Dashboard() {
	const { dashboardDetails } = useSelector((state) => state.companyReducer);
	const { loginDetails } = useSelector((state) => state.authUserReducer);

	const [createInvoiceModal, setCreateInvoiceModal] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		const getCompanyDashboardData = async () => {
			await dispatch(fetchCompanyDashboard());
		};
		getCompanyDashboardData();
	}, [dispatch]);

	const handleCreateInvoice = () => {
		setCreateInvoiceModal(true);
	};

	const handleInvoiceModalClose = () => {
		setCreateInvoiceModal(false);
	};

	// date show
	const _renderDate = useCallback((date) => {
		const nepDate = adToBs(moment(date).format("YYYY-MM-DD"));
		const billNepDate = `${nepaliDateMMMM(nepDate).split(" ")[0]} ${
			nepaliDateMMMM(nepDate).split(" ")[1]
		}, ${nepaliDateMMMM(nepDate).split(" ")[2]}`;
		const billEnDate = `${
			moment(date).format("DD") +
			" " +
			moment(date).format("MMMM") +
			", " +
			moment(date).format("YYYY")
		}`;
		return { billNepDate, billEnDate };
	}, []);

	const handleNavigation = (path) => {
		navigate(path);
	};

	function _renderInvoiceSummary() {
		const currentTotalSales =
			dashboardDetails?.totalBillAmountCurrentFiscalYear
				?.totalBillAmount || 0;
		const currentBillCount =
			dashboardDetails?.totalBillAmountCurrentFiscalYear
				?.totalBillCount || 0;
		const currentCreditCount =
			dashboardDetails?.totalCreditNoteAmountCurrentFiscalYear
				?.totalCreditCount || 0;
		function checkChange(change) {
			if (change > 0) {
				return "increased";
			} else if (change < 0) {
				return "decreased";
			} else {
				return "";
			}
		}
		let creditCountChange,
			totalSalesChange,
			billCountChange,
			creditChangeType,
			salesChangeType,
			billChangeType,
			creditCountMessage = "",
			totalSalesMessage = "",
			billCountMessage = "";
		if (
			dashboardDetails?.totalBillAmountPastFiscalYear?.totalBillCount > 0
		) {
			const pastTotalSales =
				dashboardDetails?.totalBillAmountPastFiscalYear
					?.totalBillAmount;
			const pastBillCount =
				dashboardDetails?.totalBillAmountPastFiscalYear?.totalBillCount;

			totalSalesChange =
				((currentTotalSales - pastTotalSales) / pastTotalSales) * 100;
			billCountChange =
				((currentBillCount - pastBillCount) / pastBillCount) * 100;

			salesChangeType = checkChange(totalSalesChange);
			billChangeType = checkChange(billCountChange);

			totalSalesMessage =
				salesChangeType === ""
					? ""
					: `has ${salesChangeType} by ${totalSalesChange}%`;

			billCountMessage =
				billChangeType === ""
					? ""
					: `has ${billChangeType} by ${billCountChange}%`;
		}

		if (
			dashboardDetails?.totalCreditNoteAmountPastFiscalYear
				?.totalBillCount > 0
		) {
			const pastCreditCount =
				dashboardDetails?.totalCreditNoteAmountPastFiscalYear
					?.totalBillCount;

			creditCountChange =
				((currentBillCount - pastCreditCount) / pastCreditCount) * 100;
			creditChangeType = checkChange(creditCountChange);

			creditCountMessage =
				creditChangeType === ""
					? ""
					: `is ${creditCountChange}% ${
							creditChangeType === "increased"
								? "higher"
								: "lower"
					  } than previous fiscal year`;
		}

		return (
			<div className="invoice-summary-wrapper">
				<div className="invoice-summary-container">
					<div className="summary-highlight amount">
						<div className="summary-icon">
							<i className="fa-solid fa-rupee-sign"></i>
						</div>
						<div className="summary-info">
							<span>Rs.</span> {rupeeConverter(currentTotalSales)}
						</div>
					</div>
					<h4 className="summary-title">Total Sales</h4>
					<p className="summary-description">
						The total transacted amount for current fiscal year{" "}
						{totalSalesMessage}
					</p>
				</div>
				<div className="invoice-summary-container">
					<div className="summary-highlight bill">
						<div className="summary-icon">
							<i className="fa-solid fa-file-invoice-dollar"></i>
						</div>
						<div className="summary-info">
							{currentBillCount} <span>bills</span>
						</div>
					</div>
					<h4 className="summary-title">Invoice Bills</h4>
					<p className="summary-description">
						The total bills created for the current fiscal year{" "}
						{billCountMessage}
					</p>
				</div>
				<div className="invoice-summary-container">
					<div className="summary-highlight credit">
						<div className="summary-icon">
							<i className="fa-solid fa-credit-card"></i>
						</div>
						<div className="summary-info">
							{currentCreditCount} <span>credit notes</span>
						</div>
					</div>
					<h4 className="summary-title">Credit Notes</h4>
					<p className="summary-description">
						The number of bills voided this fiscal year{" "}
						{creditCountMessage}
					</p>
				</div>
			</div>
		);
	}

	function _renderDraftsStatus() {
		let totalDrafts = dashboardDetails?.draftCount?.total || 0;
		const draftPercent =
			totalDrafts > 0
				? (dashboardDetails?.draftCount?.draft / totalDrafts) * 100
				: 0;
		const readyPercent =
			totalDrafts > 0
				? (dashboardDetails?.draftCount?.ready / totalDrafts) * 100
				: 0;
		const failedPercent =
			totalDrafts > 0
				? (dashboardDetails?.draftCount?.failed / totalDrafts) * 100
				: 0;
		return (
			<div className="summary-wrapper flex-2">
				<h4 className="wrapper-title">Drafts Status</h4>
				<div className="draft-status-container draft">
					<div className="status-info">
						<h5 className="status-title">Draft</h5>
						<p className="status-percent">
							{draftPercent?.toFixed(2)}%
						</p>
					</div>
					<div className="status-line">
						<div
							className="line"
							style={{ width: `${draftPercent}%` }}
						></div>
					</div>
					<p className="status-description">
						You have{" "}
						<span>{dashboardDetails?.draftCount?.draft}</span>{" "}
						drafts that needs attention before generating bills.
					</p>
				</div>
				<div className="draft-status-container ready">
					<div className="status-info">
						<h5 className="status-title">Ready</h5>
						<p className="status-percent">
							{readyPercent?.toFixed(2)}%
						</p>
					</div>
					<div className="status-line">
						<div
							className="line"
							style={{ width: `${readyPercent}%` }}
						></div>
					</div>
					<p className="status-description">
						You have{" "}
						<span>{dashboardDetails?.draftCount?.ready}</span> draft
						invoices that are ready to generate bill.
					</p>
				</div>
				<div className="draft-status-container failed">
					<div className="status-info">
						<h5 className="status-title">Failed</h5>
						<p className="status-percent">
							{failedPercent?.toFixed(2)}%
						</p>
					</div>
					<div className="status-line">
						<div
							className="line"
							style={{ width: `${failedPercent}%` }}
						></div>
					</div>
					<p className="status-description">
						Your <span>{dashboardDetails?.draftCount?.failed}</span>{" "}
						invoice bill request have been failed.
					</p>
				</div>
				<div
					className="wrapper-action"
					onClick={() => handleNavigation("/invoice/create")}
				>
					View All
					<i className="fa-solid fa-arrow-right"></i>
				</div>
			</div>
		);
	}

	function _renderRevenueLineChart() {
		const currentFiscalYearSalesObj =
			dashboardDetails?.totalBillAmountCurrentFiscalYearByMonth
				?.fiscalYearSales;

		const fiscalYearMonths = Object.keys(currentFiscalYearSalesObj);
		const fiscalYearMonthSales = Object.values(currentFiscalYearSalesObj);

		// Calculate the summation of sales values
		const totalSales = fiscalYearMonthSales.reduce(
			(acc, sale) => acc + sale,
			0
		);

		return (
			<div className="summary-wrapper">
				<div className="revenue-wrapper-header">
					<h4 className="wrapper-title">Revenue throughout a year</h4>
					<h2 className="revenue-detail">
						<span>Rs.</span> {rupeeConverter(totalSales)}
					</h2>
				</div>
				<div className="revenue-chart-container">
					<LineChart
						chartData={fiscalYearMonthSales}
						labels={fiscalYearMonths}
					/>
				</div>
			</div>
		);
	}

	function _renderRecentActivity() {
		return (
			<div className="table-wrapper">
				<h4 className="wrapper-title">Recent Activities</h4>
				<div className="activities-wrapper">
					{dashboardDetails?.recentActivityLog.map(
						(recentActivity, i) => (
							<ActivityItem key={i} activity={recentActivity} />
						)
					)}
				</div>
				<div
					className="wrapper-action"
					onClick={() => handleNavigation("/activities")}
				>
					View All Activities
					<i className="fa-solid fa-arrow-right"></i>
				</div>
			</div>
		);
	}

	function _renderRecentBills() {
		return (
			<div className="table-wrapper">
				<h4 className="wrapper-title">Recent Invoice Bills</h4>
				<div className="bills-table-container">
					<div className="table-grid-container header">
						<div className="table-item-text align-left">
							<b>Invoice #</b>
						</div>
						<div className="table-item-text">
							<b>Invoice Date</b>
						</div>
						<div className="table-item-text">
							<b>Client</b>
						</div>
						<div className="table-item-text align-right">
							<b>Amount</b>
						</div>
					</div>
					{dashboardDetails?.recentBills.map((recentBill, i) => (
						<div className="table-grid-container" key={i}>
							<div
								className="table-item-text align-left copy"
								onClick={() =>
									navigator.clipboard.writeText(
										recentBill?.invoice_number?.split(
											"_"
										)[2]
									)
								}
							>
								{recentBill?.invoice_number}
							</div>
							<div className="table-item-text">
								{
									_renderDate(recentBill?.invoice_date)
										?.billNepDate
								}
								<span>
									{
										_renderDate(recentBill?.invoice_date)
											?.billEnDate
									}
								</span>
							</div>
							<div className="table-item-text">
								{recentBill?.buyer_name}{" "}
								{!isEmptyObject(recentBill?.customer_info) && (
									<span>
										{
											recentBill?.customer_info
												?.customer_mobile
										}
									</span>
								)}
							</div>
							<div className="table-item-text align-right">
								<div
									className={`bill-price ${
										recentBill?.bill_void && "void"
									}`}
								>
									<b>Rs.</b>
									{rupeeConverter(recentBill?.grand_total)}
								</div>
							</div>
						</div>
					))}
				</div>
				<div
					className="wrapper-action"
					onClick={() => handleNavigation("/invoice/bills")}
				>
					View All Bills
					<i className="fa-solid fa-arrow-right"></i>
				</div>
			</div>
		);
	}

	return (
		<div className="dashboard-wrapper">
			{!isEmptyObject(dashboardDetails) && (
				<div className="dashboard-container">
					<div className="dashboard-header">
						<div className="header-image">
							<img src={dashboard} alt="billing" />
						</div>
						<div className="header-description">
							<h2 className="header-text-dark text-xl">
								Hello, {loginDetails?.first_name}..
							</h2>
							<p className="header-text-dark">
								{`${_renderDate()?.billNepDate} | 
								${_renderDate()?.billEnDate}`}
							</p>
							<p className="header-text-light">
								Simplify your financial transactions and gain
								peace of mind with our secure and efficient
								billing solution.
							</p>
							<div
								className="create-invoice-btn"
								onClick={handleCreateInvoice}
							>
								Generate Bill
							</div>
						</div>
					</div>

					<div className="dashboard-summary-container">
						<div className="flex-item-container flex-3">
							{_renderInvoiceSummary()}
							{_renderRevenueLineChart()}
						</div>
						<div className="flex-item-container flex-row">
							{!isEmptyObject(dashboardDetails?.draftCount) &&
								_renderDraftsStatus()}

							<div className="summary-wrapper bg-dark">
								<div className="client-details">
									<div className="client-icon">
										<FaUserCheck />
									</div>

									<h2 className="client-volume">
										{dashboardDetails.totalCustomer
											? dashboardDetails.totalCustomer
											: 0}
									</h2>
									<h4 className="client-label">
										Total Clients
									</h4>
								</div>
							</div>
						</div>
					</div>

					<div className="dashboard-table-container">
						{Array.isArray(dashboardDetails?.recentActivityLog) &&
							dashboardDetails?.recentActivityLog.length > 0 &&
							_renderRecentActivity()}
						{Array.isArray(dashboardDetails?.recentBills) &&
							dashboardDetails?.recentBills.length > 0 &&
							_renderRecentBills()}
					</div>
				</div>
			)}
			<SideModal isVisible={createInvoiceModal}>
				<DirectBill onModalClose={handleInvoiceModalClose} />
			</SideModal>
		</div>
	);
}

export default Dashboard;
