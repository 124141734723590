import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigationType } from "react-router-dom";
import Swal from "sweetalert2";
import "./customer-list.style.scss";
import "../../components/form-inputs/form-inputs.style.scss";
import "../../components/bill-list/bill-filter.style.scss";

// for filter form handle
import { useFormik } from "formik";
import * as yup from "yup";

// components
import SelectBox from "../../components/form-inputs/select-box.component";
import UserListItem from "../../components/user-list-item/user-list-item.component";
import Pagination from "../../components/pagination/pagination.component";
import CustomerProfile from "../../components/user-profile-sidemodal/customer-profile.component";
import EmptyRecord from "../../components/empty-record/empty-record.component";
import SideModal from "../../components/side-modal/side-modal.component";
import CustomerBulkDraft from "../../components/customer-bulk-draft/customer-bulk-draft.component";
import MultipleTagsInput from "../../components/form-inputs/multiple-tags-input.component";

// global functions and strings
import strings from "../../global/strings";
// import { isEmptyObject } from "../../global/function";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
	fetchCompanyCustomers,
	searchCustomers,
} from "../../redux/actions/customer.action";
import { exportFile } from "../../redux/actions/export-import.action";

const customerSchema = yup.object().shape({
	customer_id: yup.string().nullable(),
	customer_name: yup.string().nullable(),
	customer_mobile: yup.string().nullable(),
	customer_type: yup.string().nullable(),
	customer_email: yup.string().email("Please enter valid email").nullable(),
	customer_pan_vat: yup.string().nullable(),
});

const initial_values = {
	customer_id: "",
	company_customer_id: "",
	customer_info_id: "",
	customer_pan_vat: "",
	customer_name: "",
	customer_email: "",
	customer_mobile: "",
	customer_gender: "",
	customer_type: "",
	customer_city: "",
	customer_address: "",
	customer_tag: "",
};

function CustomerList() {
	const { customersList } = useSelector((state) => state.customerReducer);
	const { isLoading } = useSelector((state) => state.exportImportReducer);

	const [addCustomerView, setAddCustomerView] = useState(false);
	const [draftCreate, setDraftCreate] = useState(false);

	const [totalPages, setTotalPages] = useState(0); // Total number of pages
	const [currentPageNum, setCurrentPageNum] = useState(1); // current page no

	const itemsPerPage = 10;
	const dispatch = useDispatch();
	const navType = useNavigationType();

	// fetch company customer
	const getCompanyClients = useCallback(
		async (page = 1, activityFlag = false) => {
			const totalCount = await dispatch(
				fetchCompanyCustomers(itemsPerPage, page, activityFlag)
			);
			setTotalPages(Math.ceil(totalCount / itemsPerPage));
		},
		[dispatch]
	);

	useEffect(() => {
		let activityFlag = false;
		navType === "PUSH" ? (activityFlag = true) : (activityFlag = false);

		getCompanyClients(1, activityFlag);
	}, [dispatch, getCompanyClients, navType]);

	function convertTagToString(array) {
		const string = array.join(".");
		return string;
	}

	const filterFormik = useFormik({
		validationSchema: customerSchema,
		initialValues: initial_values,
		onSubmit: async (filterValues) => {
			// to filter from only company customers
			let filterData = {
				...filterValues,
				customer_id: filterValues?.customer_id
					? `CUS_${filterValues?.customer_id}`
					: "",
				customer_tag:
					Array.isArray(filterValues?.customer_tag) &&
					filterValues?.customer_tag.length > 0
						? convertTagToString(filterValues?.customer_tag)
						: "",
			};

			const totalCount = await dispatch(
				searchCustomers(filterData, itemsPerPage, 1)
			);
			setTotalPages(Math.ceil(totalCount / itemsPerPage));
		},
		onReset: async () => {
			await getCompanyClients(1);
		},
	});

	const clientFilter = useMemo(() => {
		let filterObject = {
			...filterFormik.values,
			customer_id: filterFormik.values?.customer_id
				? `CUS_${filterFormik.values?.customer_id}`
				: "",
			customer_tag:
				Array.isArray(filterFormik.values?.customer_tag) &&
				filterFormik.values?.customer_tag.length > 0
					? convertTagToString(filterFormik.values?.customer_tag)
					: "",
		};

		// Remove empty string properties
		for (const key in filterObject) {
			if (filterObject[key] === "") {
				delete filterObject[key];
			}
		}

		// Handle total_sales_min and total_sales_max condition
		if (filterObject.total_sales_max === 0) {
			delete filterObject.total_sales_min;
			delete filterObject.total_sales_max;
		}
		return { ...filterObject };
	}, [filterFormik.values]);

	function handleTagAdd(e) {
		// Get the value of the input
		const value = e.target.value;
		// If the value is empty, return
		if (!value.trim()) return;
		// cleaning up all hash values
		let newValue = value?.split("#").filter(Boolean);

		// Convert the arrays to Sets to remove duplicates
		const set1 = new Set(filterFormik.values?.customer_tag);
		const set2 = new Set(newValue);

		// Merge the Sets
		const mergedSet = new Set([...set1, ...set2]);

		// Convert the merged Set back to an array
		const resultArray = Array.from(mergedSet);

		// Add the value to the tags array
		filterFormik.setFieldValue("customer_tag", resultArray);
		// Clear the input
		e.target.value = "";
	}

	function handleKeyDown(e) {
		// If user did not press enter key, return
		if (e.key !== "Enter") return;
		handleTagAdd(e);
	}

	function handleTagRemove(index) {
		let tags = [...filterFormik.values?.customer_tag];
		filterFormik.setFieldValue(
			"customer_tag",
			tags.filter((el, i) => i !== index)
		);
	}

	const handleGenerateDraft = () => {
		setDraftCreate(true);
	};

	const handleClientExport = async () => {
		Swal.fire({
			title: "Export Customers Data",
			text: "All your filtered customer's list will be exported to excel file. Are you sure?",
			icon: "info",
			showDenyButton: true,
			confirmButtonText: "Export",
			denyButtonText: "Cancel",
			customClass: {
				popup: "alert-modal",
				actions: "btn-container",
				confirmButton: "alert-btn btn-confirm",
				denyButton: "alert-btn btn-cancel",
			},
		}).then(async (result) => {
			if (result.isConfirmed) {
				if (!isLoading) {
					await dispatch(exportFile("customer", filterFormik.values));
				}
			}
		});
	};

	const handlePageClick = async (selected) => {
		let currentPage = selected.selected + 1; // selected.selected is the new page index
		setCurrentPageNum(currentPage);
		let searchData = { ...filterFormik.values };
		const totalCount = await dispatch(
			searchCustomers(searchData, itemsPerPage, currentPage)
		);
		setTotalPages(Math.ceil(totalCount / itemsPerPage));
	};

	return (
		<div className="customer-list-wrapper">
			<div className="wrapper-row">
				<div className="filter-container">
					<div className="filter-container-inner">
						<div className="filter-items-container">
							<h2 className="filter-section-header">
								Filter Clients
							</h2>
							<p className="action-description">
								The export button downloads all filtered clients
								data to excel file.
							</p>
							<div className="action-row">
								<div
									className="action-btn"
									onClick={handleClientExport}
									disabled={
										isLoading || customersList?.length < 1
									}
								>
									<i className="fa-solid fa-file-export"></i>
									Export
								</div>
								<div
									className="action-btn"
									onClick={handleGenerateDraft}
									// disabled={isEmptyObject(clientFilter)}
								>
									<i className="fa-solid fa-file-circle-plus"></i>
									Generate draft
								</div>
							</div>
						</div>
						<div className="filter-items-container">
							<MultipleTagsInput
								label="Customer Tags"
								name="customer_tag"
								onKeyDown={handleKeyDown}
								onBlur={handleTagAdd}
								onTagRemove={handleTagRemove}
								tagsArray={filterFormik.values?.customer_tag}
								autoComplete="off"
							/>
						</div>
						<div className="filter-items-container">
							<div className="input-row">
								<div className="input-item">
									<p className="input-label">Customer Id</p>
									<input
										name="customer_id"
										className="input-box-wrapper"
										type="text"
										placeholder="#"
										onChange={filterFormik.handleChange}
										onBlur={filterFormik.handleBlur}
										value={filterFormik.values?.customer_id}
									/>
									{filterFormik.submitCount > 0 && (
										<div className="validation-error">
											{filterFormik?.errors?.customer_id}
										</div>
									)}
								</div>
								<div className="input-item" style={{ flex: 2 }}>
									<p className="input-label">Customer Name</p>
									<input
										name="customer_name"
										className="input-box-wrapper"
										type="text"
										placeholder="Enter Customer Name"
										onChange={filterFormik.handleChange}
										onBlur={filterFormik.handleBlur}
										value={
											filterFormik.values?.customer_name
										}
									/>
									{filterFormik.submitCount > 0 && (
										<div className="validation-error">
											{
												filterFormik?.errors
													?.customer_name
											}
										</div>
									)}
								</div>
							</div>
							<div className="input-item">
								<p className="input-label">Mobile Number</p>
								<input
									name="customer_mobile"
									className="input-box-wrapper"
									type="text"
									placeholder="Enter Mobile Number"
									onChange={filterFormik.handleChange}
									onBlur={filterFormik.handleBlur}
									value={filterFormik.values?.customer_mobile}
								/>
								{filterFormik.submitCount > 0 && (
									<div className="validation-error">
										{filterFormik?.errors?.customer_mobile}
									</div>
								)}
							</div>
							<div className="input-item">
								<p className="input-label">Customer Email</p>
								<input
									name="customer_email"
									className="input-box-wrapper"
									type="text"
									placeholder="Enter Email"
									onChange={filterFormik.handleChange}
									onBlur={filterFormik.handleBlur}
									value={filterFormik.values?.customer_email}
								/>
								{filterFormik.submitCount > 0 && (
									<div className="validation-error">
										{filterFormik?.errors?.customer_email}
									</div>
								)}
							</div>
							<div className="input-item">
								<p className="input-label">Customer Gender</p>
								<SelectBox
									name="customer_gender"
									onChange={filterFormik.handleChange}
									onBlur={filterFormik.handleBlur}
									value={filterFormik.values?.customer_gender}
									options={strings.gender}
								/>
								{filterFormik.submitCount > 0 && (
									<div className="validation-error">
										{filterFormik?.errors?.customer_gender}
									</div>
								)}
							</div>
						</div>
						<div className="filter-items-container">
							<div className="input-item">
								<p className="input-label">Customer Type</p>
								<SelectBox
									name="customer_type"
									onChange={filterFormik.handleChange}
									onBlur={filterFormik.handleBlur}
									value={filterFormik.values?.customer_type}
									options={strings.customerType}
								/>
								{filterFormik.submitCount > 0 && (
									<div className="validation-error">
										{filterFormik?.errors?.customer_type}
									</div>
								)}
							</div>
							<div className="input-item">
								<p className="input-label">
									Customer PAN / VAT
								</p>
								<input
									name="customer_pan_vat"
									className="input-box-wrapper"
									type="text"
									placeholder="# Enter Pan / VAT"
									onChange={filterFormik.handleChange}
									onBlur={filterFormik.handleBlur}
									value={
										filterFormik.values?.customer_pan_vat
									}
								/>
								{filterFormik.submitCount > 0 && (
									<div className="validation-error">
										{filterFormik?.errors?.customer_pan_vat}
									</div>
								)}
							</div>
						</div>
						<div className="filter-btn-container">
							<div
								className="filter-btn inverted"
								onClick={filterFormik.resetForm}
							>
								<i className="fa-solid fa-eraser"></i>Reset
							</div>
							<div
								className="filter-btn"
								onClick={filterFormik.submitForm}
							>
								<i className="fa-solid fa-magnifying-glass"></i>
								Search
							</div>
						</div>
					</div>
				</div>
				<div className="customer-list-container">
					<div className="section-header-container">
						<h2 className="section-header-title">
							Billing Clients
						</h2>
						<div
							className="header-action-btn"
							onClick={() => setAddCustomerView(true)}
						>
							<i className="fa-solid fa-user-plus"></i>
							Add Customer
						</div>
					</div>
					<div className="customer-list-items">
						{Array.isArray(customersList) &&
						customersList?.length > 0 ? (
							customersList.map((customer, i) => (
								<UserListItem
									key={customer.customer_info_id}
									user={customer}
									type="customer"
									currentPageNum={currentPageNum}
								/>
							))
						) : (
							<EmptyRecord />
						)}
					</div>
					{totalPages > 0 && (
						<Pagination
							totalPages={totalPages}
							onPageClick={handlePageClick}
							forcePage={currentPageNum - 1}
						/>
					)}
				</div>
			</div>
			{addCustomerView && (
				<CustomerProfile
					isCustomerVisible={addCustomerView}
					onCustomerModalClose={() => setAddCustomerView(false)}
					customer={initial_values}
					onSelectCustomer={() => {}}
				/>
			)}

			<SideModal
				isVisible={draftCreate}
				onModalClose={() => setDraftCreate(false)}
				containerStyle={{ minWidth: "320px" }}
			>
				{draftCreate && (
					<CustomerBulkDraft
						onDraftCreateModalClose={() => setDraftCreate(false)}
						filterValues={clientFilter}
					/>
				)}
			</SideModal>
		</div>
	);
}

export default CustomerList;
