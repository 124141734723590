import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./side-navigation.style.scss";
import logoNibjar from "../../assets/logo-white-NIBJAR.png";

// components
import TopNav from "../top-navigation/top-navigation.component";

// icons
import { FaTh } from "react-icons/fa";

// redux
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/auth-user.action";

const Sidebar = ({ children }) => {
	const [isSidenavOpen, setIsSidenavOpen] = useState(false);
	const dispatch = useDispatch();
	const menuItem = [
		{
			path: "/",
			name: "Dashboard",
			icon: <FaTh />,
			gap: true,
		},
		{
			path: "/invoice/create",
			name: "Create Invoice",
			icon: (
				<i
					className="fa-solid fa-file-circle-plus"
					style={{ fontSize: "15px" }}
				></i>
			),
		},
		{
			path: "/invoice/bills",
			name: "Invoice Bills",
			icon: <i className="fa-regular fa-rectangle-list"></i>,
			gap: true,
		},
		{
			line: true,
			path: "/customers",
			name: "Billing Clients",
			icon: <i className="fa-solid fa-users"></i>,
		},
		{
			path: "/employees",
			name: "Employees",
			icon: (
				<i
					className="fa-solid fa-user-tie"
					style={{ fontSize: "14px", marginLeft: "2px" }}
				></i>
			),
		},
		// {
		// 	path: "/activities",
		// 	name: "Activity Monitor",
		// 	icon: (
		// 		<i
		// 			className="fa-solid fa-clock-rotate-left"
		// 			style={{ fontSize: "14px" }}
		// 		></i>
		// 	),
		// },

		// {
		// 	path: "/analytics",
		// 	name: "Analytics",
		// 	icon: <FaRegChartBar />,
		// },
		// {
		// 	path: "/test",
		// 	name: "Comment",
		// 	icon: <FaCommentAlt />,
		// },
		// {
		// 	path: "/invoice/template",
		// 	name: "Invoice Template",
		// 	icon: <FaShoppingBag />,
		// },
	];

	const handleLogout = async () => {
		await dispatch(logout());
	};

	return (
		<div className="screen-wrapper">
			<div
				style={{ width: isSidenavOpen ? "220px" : "80px" }}
				className="sidebar-container"
			>
				<div
					className={`sidebar-toggler  ${
						isSidenavOpen && "toggle-rotate"
					}`}
					onClick={() => setIsSidenavOpen(!isSidenavOpen)}
				>
					<i className="fa-solid fa-chevron-right"></i>
				</div>
				<div className="sidebar-container-inner">
					<div className="sidebar-nav-links">
						{menuItem.map((item, index) => (
							<div key={index}>
								{item.line && (
									<hr style={{ marginBottom: "24px" }} />
								)}
								<NavLink
									to={item.path}
									className={`nav-link ${
										item.gap && "link-gap"
									} ${!isSidenavOpen && "tooltip-visible"}`}
									activeclassname="active"
									data-tooltip={item?.name}
									data-tooltip-pos="right"
									data-tooltip-length="medium"
								>
									<div className="link-icon">{item.icon}</div>
									<div
										className={`link-text ${
											!isSidenavOpen && "text-hidden"
										}`}
									>
										{item.name}
									</div>
								</NavLink>
							</div>
						))}
					</div>
					<div className="sidebar-bottom">
						<div
							className="sidebar-nav-links"
							style={{ borderBottom: "1px solid #d8d8d855" }}
						>
							<NavLink
								to="/activities"
								className={`nav-link nav-bottom ${
									!isSidenavOpen && "tooltip-visible"
								}`}
								activeclassname="active"
								data-tooltip={"Activity Monitor"}
								data-tooltip-pos="right"
								data-tooltip-length="medium"
							>
								<div className="link-icon">
									<i
										className="fa-solid fa-clock-rotate-left"
										style={{ fontSize: "14px" }}
									></i>
								</div>
								<div
									className={`link-text ${
										!isSidenavOpen && "text-hidden"
									}`}
								>
									Activity Monitor
								</div>
							</NavLink>
							<NavLink
								to="/company-setting"
								className={`nav-link nav-bottom ${
									!isSidenavOpen && "tooltip-visible"
								}`}
								activeclassname="active"
								data-tooltip={"Settings"}
								data-tooltip-pos="right"
								data-tooltip-length="medium"
							>
								<div className="link-icon">
									<i className="fa-solid fa-gear"></i>
								</div>
								<div
									className={`link-text ${
										!isSidenavOpen && "text-hidden"
									}`}
								>
									Settings
								</div>
							</NavLink>
							<div
								className="nav-link nav-bottom"
								onClick={handleLogout}
							>
								<div className="link-icon">
									<i
										className="fa-solid fa-right-from-bracket"
										style={{ fontSize: "16px" }}
									></i>
								</div>
								<div
									className={`link-text ${
										!isSidenavOpen && "text-hidden"
									}`}
								>
									Logout
								</div>
							</div>
						</div>
						<div className="additional-container">
							<div className="additional-dark-text">
								<img src={logoNibjar} alt="logo" />
								<span
									className={`${
										!isSidenavOpen && "text-hidden"
									}`}
								>
									Nibjar Solutions
								</span>
							</div>
							{isSidenavOpen && (
								<div className="additional-light-text">
									<b>Version 1.0.0</b>
									<br />
									Copyright &copy; 2023 Nibjar Solutions Pvt.
									Ltd. All rights reserved.
								</div>
							)}
							<div className="additional-light-text"></div>
						</div>
					</div>
				</div>
			</div>

			<main className="main-wrapper">
				<TopNav isSidenavOpen={isSidenavOpen} />
				<div className="component-wrapper">{children}</div>
			</main>
		</div>
	);
};

export default Sidebar;
