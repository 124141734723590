import React, { useEffect, useRef, useState, useMemo } from "react";
import { useNavigationType } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import "./company-setting.style.scss";

import logoDark from "../../assets/logo-dark-BILLME.png";

// for filter form handle
import { useFormik } from "formik";
import * as yup from "yup";

// components
import InvoiceTemplate from "../../components/invoice-template/invoice-template.component";
import Switch from "../../components/form-inputs/switch.component";
import SelectBox from "../../components/form-inputs/select-box.component";
import Spinner from "../../components/spinner/spinner.component";

// global strings and functions
import strings from "../../global/strings";
import { isEmptyObject, roleAccess } from "../../global/function";
import showToastMessage from "../../global/showToastMessage";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
	fetchCompanyDetails,
	updateCompany,
	updateCompanyPreference,
} from "../../redux/actions/company.action";
import { uploadImage } from "../../redux/actions/image-upload.action";
import { exportFile } from "../../redux/actions/export-import.action";
import { getBillDates } from "../../redux/actions/bill.action";
import { setLocalDrafts } from "../../redux/actions/drafts.action";

const companySchema = yup.object().shape({
	company_name: yup.string().nullable().required("Company name is required"),
	company_province: yup.string().nullable(),
	company_district: yup.string().nullable(),
	company_city: yup.string().nullable(),
	company_address: yup
		.string()
		.nullable()
		.required("Company address is required"),
	company_country: yup.string().nullable(),
	company_email: yup
		.string()
		.email("Please enter valid email")
		.nullable()
		.required("Company email is required"),
	company_phone: yup
		.number()
		.typeError("Phone number must be a number")
		.nullable()
		.required("Company phone is required"),
	company_mobile: yup
		.number()
		.typeError("Company Mobile must be a number")
		.nullable()
		.required("Company mobile number is required"),
	company_color_code: yup.string().nullable(),
	company_website: yup.string().nullable(),
	company_facebook: yup.string().nullable(),
});

const preferenceSchema = yup.object().shape({
	discount: yup.boolean(),
	vat: yup.boolean(),
	serviceChargeEnabled: yup.boolean(),
	service_charge: yup
		.number()
		.typeError("Must be a number")
		.nullable()
		.when("serviceChargeEnabled", {
			is: (value) => value === true, // Apply validation when serviceChargeEnabled is true
			then: () =>
				yup
					.number()
					.required(
						"This field is required when service charge is enabled"
					),
			otherwise: () => yup.number(), // No validation when serviceChargeEnabled is false
		}),
	terms_condition: yup
		.string()
		.test("wordCount", "Word count exceeds the limit", (value) => {
			// Calculate the word count in the editor content
			const wordCount = value?.split(/\s+/).filter(Boolean).length;
			return wordCount <= 200; // Adjust the word count limit as needed
		}),
});

const company_initial_values = {
	company_name: "",
	company_province: "",
	company_district: "",
	company_city: "",
	company_address: "",
	company_country: "nepal",
	company_email: "",
	company_phone: "",
	company_mobile: "",
	company_color_code: "",
	company_website: "",
	company_facebook: "",
};

function CompanyProfile({ companyEditAccess, identificationViewAccess }) {
	const { companyDetails, isLoading: companyLoading } = useSelector(
		(state) => state.companyReducer
	);
	const { isLoading } = useSelector((state) => state.exportImportReducer);
	const [isEditable, setIsEditable] = useState(false);

	const [selectedImage, setSelectedImage] = useState(null);
	const [passwordVisible, setPasswordVisible] = useState(false);

	const dispatch = useDispatch();
	const fileInputRef = useRef(null);

	const formik = useFormik({
		validationSchema: companySchema,
		initialValues: company_initial_values,
		onSubmit: async (companyData) => {
			if (!companyLoading) {
				let uploadResult = true;
				if (selectedImage) {
					let data = {
						image: selectedImage,
						id: companyData.company_info_id,
						type: "company",
					};
					let response = await dispatch(uploadImage(data));
					uploadResult = response?.success;
				}
				if (uploadResult) {
					const editResult = await dispatch(
						updateCompany(companyData)
					);
					editResult && setIsEditable(false);
				}
			}
		},
	});

	const handleEditCancel = () => {
		setIsEditable(false);
		setSelectedImage(null);
		formik.setValues((prev) => ({ ...prev, ...companyDetails }));
	};

	useEffect(() => {
		!isEmptyObject(companyDetails) &&
			formik.setValues((prev) => ({ ...prev, ...companyDetails }));
		// eslint-disable-next-line
	}, [companyDetails]);

	const handleImageEdit = () => {
		if (isEditable) {
			// Trigger the hidden file input element
			fileInputRef.current.click();
		}
	};

	const handleImageChange = (e) => {
		const selectedFile = e.target.files[0];
		// Check the file size
		if (selectedFile && selectedFile.size <= 2048576) {
			setSelectedImage(selectedFile);
		} else {
			// Handle the case where the file size exceeds the limit
			showToastMessage("File size exceeds the limit", "I");
		}
	};

	const imgSrc = useMemo(() => {
		if (selectedImage) {
			return URL.createObjectURL(selectedImage);
		} else if (
			!isEmptyObject(companyDetails) &&
			companyDetails.company_logo
		) {
			return companyDetails.company_logo;
		} else {
			return logoDark; // company default logo
		}
	}, [companyDetails, selectedImage]);

	const handleAllExport = async () => {
		Swal.fire({
			title: "Backup System Data",
			text: "All your system data will be exported to excel file. Are you sure?",
			icon: "info",
			showDenyButton: true,
			confirmButtonText: "Export",
			denyButtonText: "Cancel",
			customClass: {
				popup: "alert-modal",
				actions: "btn-container",
				confirmButton: "alert-btn btn-confirm",
				denyButton: "alert-btn btn-cancel",
			},
		}).then(async (result) => {
			if (result.isConfirmed) {
				if (!isLoading) {
					await dispatch(exportFile("all"));
				}
			}
		});
	};

	return (
		<div className="profile-container">
			<div className="profile-container-inner">
				<div className="container-title">Company Profile</div>
				<div className="profile-section">
					<div
						className={`img-container ${isEditable && "editable"}`}
						onClick={handleImageEdit}
					>
						<div className="company-image">
							<img
								crossOrigin="anonymous"
								src={imgSrc}
								alt="logo"
							/>
						</div>
						{isEditable ? (
							<div className="img-action">
								<i className="fa-regular fa-pen-to-square"></i>
							</div>
						) : (
							<div
								className={`img-action ${companyDetails?.company_status}`}
							>
								<i className="fa-solid fa-check"></i>
							</div>
						)}
					</div>
					<input
						type="file"
						accept="image/*"
						ref={fileInputRef}
						style={{ display: "none" }}
						onChange={handleImageChange}
					/>
					<input
						name="company_name"
						type="text"
						className="name-input-field section-header"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values?.company_name || ""}
						disabled={!isEditable}
					/>
					{/* <div className="social-section">
						<div
							className="company-social-container"
							style={{ backgroundColor: "#3b5998" }}
						>
							<i className="fa-brands fa-facebook-f"></i>
						</div>
						<div
							className="company-social-container"
							style={{ backgroundColor: "#523563" }}
						>
							<i className="fa-solid fa-globe"></i>
						</div>
						<div
							className="company-social-container"
							style={{ backgroundColor: "#ea4335" }}
						>
							<i className="fa-solid fa-envelope"></i>
						</div>
					</div> */}
				</div>
				<div className="profile-divider" />
				<div className="profile-section">
					<div className="input-items-label">Company Contact</div>
					<div className="input-row">
						<div className="input-item">
							<p className="input-label">Phone Number</p>
							<input
								name="company_phone"
								className="input-box-wrapper minWidth"
								type="text"
								placeholder="Enter phone number"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values?.company_phone || ""}
								disabled={!isEditable}
							/>
							{formik.submitCount > 0 && (
								<div className="validation-error">
									{formik?.errors?.company_phone}
								</div>
							)}
						</div>
						<div className="input-item">
							<p className="input-label">Mobile Number</p>
							<input
								name="company_mobile"
								className="input-box-wrapper minWidth"
								type="text"
								placeholder="Enter mobile number"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values?.company_mobile || ""}
								disabled={!isEditable}
							/>
							{formik.submitCount > 0 && (
								<div className="validation-error">
									{formik?.errors?.company_mobile}
								</div>
							)}
						</div>
						<div className="input-item">
							<p className="input-label">Company Email</p>
							<input
								name="company_email"
								className="input-box-wrapper minWidth"
								type="text"
								placeholder="Enter email address"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values?.company_email || ""}
								disabled={!isEditable}
							/>
							{formik.submitCount > 0 && (
								<div className="validation-error">
									{formik?.errors?.company_email}
								</div>
							)}
						</div>
					</div>

					<div className="input-items-label">Company Location</div>
					<div className="input-row">
						<div className="input-item">
							<p className="input-label">Company Address</p>
							<input
								name="company_address"
								className="input-box-wrapper minWidth"
								type="text"
								placeholder="Enter company address"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values?.company_address || ""}
								disabled={!isEditable}
								style={{ textTransform: "capitalize" }}
							/>
							{formik.submitCount > 0 && (
								<div className="validation-error">
									{formik?.errors?.company_address}
								</div>
							)}
						</div>
						<div className="input-item">
							<p className="input-label">City</p>
							<SelectBox
								name="company_city"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values?.company_city || ""}
								options={strings.city}
								disabled={!isEditable}
							/>
							{formik.submitCount > 0 && (
								<div className="validation-error">
									{formik?.errors?.company_city}
								</div>
							)}
						</div>
						<div className="input-item">
							<p className="input-label">District</p>
							<SelectBox
								name="company_district"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values?.company_district || ""}
								options={strings.city}
								disabled={!isEditable}
							/>
							{formik.submitCount > 0 && (
								<div className="validation-error">
									{formik?.errors?.company_district}
								</div>
							)}
						</div>
						<div className="input-item">
							<p className="input-label">Province</p>
							<SelectBox
								name="company_province"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values?.company_province || ""}
								options={strings.province}
								disabled={!isEditable}
							/>
							{formik.submitCount > 0 && (
								<div className="validation-error">
									{formik?.errors?.company_province}
								</div>
							)}
						</div>
						<div className="input-item">
							<p className="input-label">Country</p>
							<div
								className={`input-box-wrapper minWidth ${
									!isEditable && "disabled"
								}`}
							>
								Nepal
							</div>
						</div>
					</div>

					<div className="input-items-label">Company Social</div>
					<div className="input-row">
						<div className="input-item">
							<p className="input-label">Company Facebook</p>
							<input
								name="company_facebook"
								className="input-box-wrapper minWidth"
								type="text"
								placeholder="@ Enter facebook link"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values?.company_facebook || ""}
								disabled={!isEditable}
							/>
							{formik.submitCount > 0 && (
								<div className="validation-error">
									{formik?.errors?.company_facebook}
								</div>
							)}
						</div>
						<div className="input-item">
							<p className="input-label">Company Website</p>
							<input
								name="company_website"
								className="input-box-wrapper minWidth"
								type="text"
								placeholder="@ Enter website link"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values?.company_website || ""}
								disabled={!isEditable}
							/>
							{formik.submitCount > 0 && (
								<div className="validation-error">
									{formik?.errors?.company_website}
								</div>
							)}
						</div>
						<div className="input-item">
							<p className="input-label">Company Color Code</p>
							<input
								name="company_color_code"
								type="color"
								className="input-box-wrapper minWidth"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={
									formik.values?.company_color_code ||
									"#000000"
								}
								disabled={!isEditable}
							/>
							{formik.submitCount > 0 && (
								<div className="validation-error">
									{formik?.errors?.company_color_code}
								</div>
							)}
						</div>
					</div>
				</div>

				{companyEditAccess && (
					<>
						<div className="profile-divider" />
						{isEditable ? (
							<div className="action-btns">
								<div
									className="profile-btn-container inverted"
									disabled={companyLoading}
									onClick={handleEditCancel}
								>
									Cancel
								</div>
								<div
									className="profile-btn-container"
									disabled={companyLoading}
									onClick={formik.submitForm}
								>
									Save
								</div>
							</div>
						) : (
							<div
								className="profile-btn-container"
								disabled={companyLoading}
								onClick={() => setIsEditable(true)}
							>
								Edit
							</div>
						)}
					</>
				)}
			</div>
			{!isEmptyObject(companyDetails?.identification) && (
				<div className="profile-container-inner">
					<div className="container-title">
						Company Identifications
					</div>
					<div className="profile-details">
						<label className="section-label-text">
							Company{" "}
							<span style={{ textTransform: "uppercase" }}>
								{
									companyDetails?.identification
										?.registration_type
								}
							</span>
						</label>
						<input
							type="email"
							className="profile-input-field"
							value={
								companyDetails?.identification
									?.registration_number
							}
							disabled={true}
						/>
					</div>
					{companyDetails?.identification?.username && (
						<div className="profile-details">
							<label className="section-label-text">
								Username
							</label>
							<input
								type="email"
								className="profile-input-field"
								value={companyDetails?.identification?.username}
								disabled={true}
							/>
						</div>
					)}
					{identificationViewAccess &&
						companyDetails?.identification?.password && (
							<div className="profile-details">
								<label className="section-label-text">
									Password
								</label>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										flex: 1,
									}}
								>
									<input
										type={
											passwordVisible
												? "text"
												: "password"
										}
										className="profile-input-field"
										value={
											companyDetails?.identification
												?.password
										}
										disabled={true}
									/>

									<div
										className="input-view"
										onClick={() =>
											setPasswordVisible((prev) => !prev)
										}
									>
										<i
											className={`fa-regular ${
												passwordVisible
													? "fa-eye-slash"
													: "fa-eye"
											}`}
										></i>
									</div>
								</div>
							</div>
						)}
				</div>
			)}
			{companyEditAccess && (
				<div className="profile-container-inner">
					<p className="container-description">
						Seamlessly export all system data to Excel with a simple
						click.
					</p>
					<div
						className="export-btn"
						disabled={isLoading}
						onClick={handleAllExport}
					>
						<i className="fa-solid fa-file-export"></i>Export to
						Excel
					</div>
				</div>
			)}
		</div>
	);
}

export default function CompanySetting() {
	const { preferenceDetails, isLoading } = useSelector(
		(state) => state.companyReducer
	);
	const { earliestDraftDate } = useSelector((state) => state.billReducer);

	const [permissions, setPermissions] = useState({});
	const dispatch = useDispatch();
	const navType = useNavigationType();

	useEffect(() => {
		const getCompanyInfo = async () => {
			let activityFlag = false;
			navType === "PUSH" ? (activityFlag = true) : (activityFlag = false);
			await dispatch(fetchCompanyDetails(activityFlag));
		};
		getCompanyInfo();
	}, [dispatch, navType]);

	// fetch last bill date and earliest draft date to check if there are any record in drafts
	useEffect(() => {
		const fetchLastBillDate = async () => {
			await dispatch(getBillDates());
		};
		fetchLastBillDate();
	}, [dispatch]);

	useEffect(() => {
		const getPermission = async () => {
			setPermissions(await roleAccess("company"));
		};
		getPermission();
	}, []);

	const companyPreferences = useMemo(
		() => ({
			preference_id: preferenceDetails?.preference_id || "",
			template_id: null,
			vat: preferenceDetails?.vat,
			discount: preferenceDetails?.discount,
			serviceChargeEnabled:
				preferenceDetails?.service_charge > 0 ? true : false,
			service_charge: preferenceDetails?.service_charge || "",
			terms_condition: preferenceDetails?.terms_condition || "",
		}),
		[preferenceDetails]
	);

	const formik = useFormik({
		validationSchema: preferenceSchema,
		initialValues: {
			template_id: null,
			vat: true,
			discount: true,
			serviceChargeEnabled: false,
			service_charge: "",
			terms_condition: "",
		},
		onSubmit: async (preferenceValues, { isSubmitting }) => {
			if (earliestDraftDate === "") {
				if (!isLoading || !isSubmitting) {
					let companyPreferenceData = {
						...preferenceValues,
						service_charge: preferenceValues?.service_charge || 0,
					};
					await dispatch(
						updateCompanyPreference(companyPreferenceData)
					);
				}
				// empty local drafts as preferences are changed
				dispatch(setLocalDrafts([]));
			} else {
				Swal.fire({
					title: "Changes are not saved",
					text: "You have drafts with previous preference settings.",
					icon: "info",
					customClass: {
						popup: "alert-modal",
						actions: "btn-container",
						confirmButton: "alert-btn btn-confirm",
						denyButton: "alert-btn btn-cancel",
					},
				});
				formik.setValues((prev) => ({
					...prev,
					...companyPreferences,
				}));
			}
		},
	});

	const handlePreferenceReset = () => {
		formik.setValues((prev) => ({ ...prev, ...companyPreferences }));
	};

	useEffect(() => {
		if (!isEmptyObject(preferenceDetails)) {
			formik.setValues((prev) => ({ ...prev, ...companyPreferences }));
		}
		// eslint-disable-next-line
	}, [companyPreferences, preferenceDetails]);

	const handleFieldToggle = (e, correspondingFieldName = "") => {
		const { checked } = e.target;
		// Set corresponding Field to '0' when switch is false
		if (correspondingFieldName !== "" && !checked) {
			formik.setFieldValue(correspondingFieldName, "");
		}

		// set target switch toggle
		formik.handleChange(e);
	};

	return (
		<div className="company-profile-setting-wrapper">
			<div className="company-cover-container"></div>

			<div className="company-profile-setting-container">
				<CompanyProfile
					companyEditAccess={permissions?.edit}
					identificationViewAccess={permissions?.viewIdentification}
				/>

				<div style={{ flex: 2.2 }}>
					<div className="company-setting-container">
						<div className="company-setting-header">
							<div className="container-title">
								Company Settings
							</div>
							<div className="container-description">
								Manage company settings and preferences.
							</div>
						</div>
						<div className="settings-wrapper">
							<div className="setting-container">
								<div className="setting-content">
									<h4 className="setting-title">
										Template Design
									</h4>
									<div className="setting-description">
										Choose bill template that suits your
										business.
									</div>
									{/* <div className="more-setting-button">
									View more templates
								</div> */}
								</div>
								<div className="setting-details">
									<div className="templates-preview-wrapper">
										<div className="template-preview-container selected">
											<div className="template-preview-container-inner">
												<InvoiceTemplate
													showBillFooter={true}
													page="1 of 1"
													fontStyle={{
														fontSize: "18px",
													}}
												/>
											</div>
											<div className="template-selection">
												<i className="fa-solid fa-check"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="setting-container">
								<div className="setting-content">
									<h4 className="setting-title">
										Bill Footer Include
									</h4>
									<div className="setting-description">
										Select charges and costs to include in
										the bills.
									</div>

									<div className="setting-action">
										<div className="action-row">
											<div className="action-detail">
												<h5>Discount</h5>
												<p>
													Include discount field in
													the bill
												</p>
											</div>
											<div className="action-input">
												<Switch
													name="discount"
													isToggled={
														formik.values?.discount
													}
													onToggle={(e) =>
														handleFieldToggle(e)
													}
												/>
											</div>
										</div>
									</div>

									<div className="setting-action">
										<div className="action-row">
											<div className="action-detail">
												<h5>Service Charge</h5>
												<p>
													Add service charge in the
													bill
												</p>
											</div>
											<div className="action-input">
												<Switch
													name="serviceChargeEnabled"
													isToggled={
														formik.values
															?.serviceChargeEnabled
													}
													onToggle={(e) =>
														handleFieldToggle(
															e,
															"service_charge"
														)
													}
												/>
											</div>
										</div>
										<div className="input-item">
											<p className="input-label">
												Service Rate
											</p>
											<input
												name="service_charge"
												className="input-box-wrapper"
												type="number"
												placeholder="Enter service rate"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={
													formik.values
														?.service_charge || ""
												}
												disabled={
													!formik.values
														?.serviceChargeEnabled
												}
											/>
											{formik.submitCount > 0 && (
												<div className="validation-error">
													{
														formik?.errors
															?.service_charge
													}
												</div>
											)}
										</div>
									</div>

									<div className="setting-action">
										<div className="action-row">
											<div className="action-detail">
												<h5>VAT</h5>
												<p>
													Include VAT field in the
													bill
												</p>
											</div>
											<div className="action-input">
												<Switch
													name="vat"
													isToggled={
														formik.values?.vat
													}
													onToggle={(e) =>
														handleFieldToggle(e)
													}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="setting-details">
									<div className="input-item">
										<p className="input-label">
											Demo Bill Footer with Sub Total Rs.
											1000 for example
										</p>
										<div className="bill-footer">
											<div className="bill-footer-container">
												<div className="bill-item-text ">
													<b>Sub Total</b>
												</div>
												<div className="bill-item-text  align-right">
													Rs. 1000.00
												</div>
												<div
													className="bill-item-text"
													style={{
														marginRight: "8px",
													}}
												>
													<b>
														Discount (for eg. 100)
													</b>
												</div>
												<div className="bill-item-text">
													<div
														className="input-box-wrapper"
														style={{
															justifyContent:
																"flex-end",
														}}
													>
														Rs.{" "}
														{formik.values.discount
															? "100"
															: "0.00"}
													</div>
												</div>
												<div className="bill-item-text">
													<b>Taxable Amount</b>
												</div>
												<div className="bill-item-text align-right">
													Rs.
													{formik.values.discount
														? "900.00"
														: "1000.00"}
												</div>
												<div className="bill-item-text">
													<b>VAT</b>
												</div>
												<div className="bill-item-text align-right">
													Rs.{" "}
													{formik.values.vat
														? formik.values.discount
															? 0.13 * 900
															: 0.13 * 1000
														: "0.00"}
												</div>
												<div className="bill-item-text highlighted">
													<b>Grand Total</b>
												</div>
												<div className="bill-item-text highlighted align-right">
													Rs.{" "}
													{formik.values.discount
														? formik.values.vat
															? "1017.00"
															: "900.00"
														: formik.values.vat
														? "1130.00"
														: "1000.00"}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="setting-container">
								<div className="setting-content">
									<h4 className="setting-title">
										Terms and Conditions
									</h4>
									<div className="setting-description">
										Add terms and conditions to include in
										the bills.
									</div>

									<div
										className="setting-details"
										style={{ flexGrow: 0 }}
									>
										<div className="tnc-container">
											<div className="tnc-container-inner">
												<div className="dummyTnc">
													<b>Terms & Conditions</b>
													<div
														className="tnc-data"
														dangerouslySetInnerHTML={{
															__html: formik
																.values
																?.terms_condition,
														}}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="setting-description">
										**Note: Above is a sample of how terms
										and conditions is printed in bill.
										Content below the <span>red line</span>{" "}
										will <b>NOT be printed</b> in the bill.
									</div>
								</div>
								<div className="setting-details">
									<div className="input-item">
										<p className="input-label">
											Terms and Conditions
										</p>
										<CKEditor
											name="terms_condition"
											editor={ClassicEditor}
											placeholder="Terms and Conditions..."
											data={
												formik.values
													?.terms_condition || ""
											}
											// onReady={ editor => {
											//     // You can store the "editor" and use when it is needed.
											//     console.log( 'Editor is ready to use!', editor );
											// } }
											onChange={(event, editor) => {
												const data = editor.getData();
												formik.setFieldValue(
													"terms_condition",
													data
												);
											}}
											onBlur={(event, editor) => {
												formik.handleBlur(
													"terms_condition"
												);
											}}
											// onFocus={(event, editor) => {
											//   console.log("Focus.", editor, event);
											// }}
										/>
										{formik.submitCount > 0 && (
											<div className="validation-error">
												{
													formik?.errors
														?.terms_condition
												}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>

						{permissions?.edit ? (
							<div className="settings-btn-wrapper">
								<div
									className="setting-btn inverted"
									onClick={handlePreferenceReset}
									disabled={isLoading}
								>
									Cancel
								</div>
								<div
									className="setting-btn"
									onClick={formik.submitForm}
									disabled={isLoading}
								>
									{isLoading ? (
										<>
											<Spinner /> <span>Saving...</span>
										</>
									) : (
										<>
											<i className="fa-regular fa-paper-plane"></i>
											<span>Save Changes</span>
										</>
									)}
								</div>
							</div>
						) : (
							<div className="settings-btn-wrapper">
								<div className="setting-btn" disabled={true}>
									Cancel
								</div>
								<div className="setting-btn" disabled={true}>
									Save Changes
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
