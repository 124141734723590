import React, { useEffect, useState } from "react";
import "./top-navigation.style.scss";
import logoWhite from "../../assets/logo-white-BILLME.png";

// components
import ProfileModal from "../profile-modal/profile-modal.component";

// custom hooks
import { useOutsideClick } from "../../hooks/useOutsideClick";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchLoginInfo } from "../../redux/actions/auth-user.action";
import { fetchCompanyDetails } from "../../redux/actions/company.action";
import { getBillDates } from "../../redux/actions/bill.action";

export default function TopNav({ isSidenavOpen }) {
	const { loginDetails } = useSelector((state) => state.authUserReducer);
	const [isProfileVisible, setIsProfileVisible] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		async function getLoginInfo() {
			await dispatch(fetchLoginInfo());
		}
		getLoginInfo();
	}, [dispatch]);

	// get preference details
	useEffect(() => {
		const getCompanyPreference = async () => {
			await dispatch(fetchCompanyDetails());
		};
		getCompanyPreference();
	}, [dispatch]);

	// fetch last bill date
	useEffect(() => {
		const fetchLastBillDate = async () => {
			await dispatch(getBillDates());
		};
		fetchLastBillDate();
	}, [dispatch]);

	const toggleProfileModal = () => {
		setIsProfileVisible(!isProfileVisible);
	};

	const handleClickOutside = () => {
		setIsProfileVisible(false);
	};

	const ref = useOutsideClick(handleClickOutside);
	return (
		<div className="topnav-container">
			<div className="topnav-container-inner">
				<div className="topnav-left-section">
					<img
						src={logoWhite}
						alt="logo"
						className={`logo-img ${isSidenavOpen && "logo-rotate"}`}
					/>
					<h1 className="logo-text">{loginDetails?.company_name}</h1>
				</div>
				<div className="topnav-right-section">
					{/* <div className="search-container">
						<input
							className="input-box-wrapper"
							type="text"
							placeholder="Search..."
						/>
						<i className="fa-solid fa-magnifying-glass"></i>
					</div> */}
					{/* <div className="topnav-item">
						<i className="fa-solid fa-bell"></i>
					</div> */}
					<div
						className="user-container"
						ref={ref}
						onClick={toggleProfileModal}
					>
						<i className="fa-solid fa-user"></i>
					</div>
				</div>
				<ProfileModal isVisible={isProfileVisible} />
			</div>
		</div>
	);
}
