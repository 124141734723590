import React, { useMemo, useRef, useState } from "react";
import "./profile-modal.style.scss";

// for filter form handle
import { useFormik } from "formik";
import * as yup from "yup";

// global function
import { isEmptyObject, sessionInfo } from "../../global/function";
import showToastMessage from "../../global/showToastMessage";

// component
import PasswordInput from "../form-inputs/password-input.component";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
	logout,
	setLoginUser,
	updatePassword,
} from "../../redux/actions/auth-user.action";
import { uploadImage } from "../../redux/actions/image-upload.action";

const changePswrdSchema = yup.object().shape({
	oldPassword: yup
		.string()
		.required("Old Password is required")
		.min(8, "Password must be at least 8 characters"),
	password: yup
		.string()
		.required("New Password is required")
		.min(8, "Password must be at least 8 characters"),
	confirm_password: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match")
		.required("Confirm password cannot be empty"),
});

function ProfileModal({ isVisible }) {
	const { loginDetails, isLoading } = useSelector(
		(state) => state.authUserReducer
	);

	const [selectedImage, setSelectedImage] = useState(null);
	const [isChangePswrd, setIsChangePswrd] = useState(false);

	const dispatch = useDispatch();
	const fileInputRef = useRef(null);

	const handleImageEdit = () => {
		// Trigger the hidden file input element
		fileInputRef.current.click();
	};

	const handleImageChange = async (e) => {
		const selectedFile = e.target.files[0];

		let sessionData = await sessionInfo();
		// Check the file size
		if (selectedFile && selectedFile.size <= 2048576) {
			setSelectedImage(selectedFile);
			let data = {
				image: selectedFile,
				id: sessionData?.user_info_id,
				type: "user",
			};
			let uploadResponse = await dispatch(uploadImage(data));

			let updatedUser = {
				user_image: uploadResponse?.result?.user_image,
			};

			await dispatch(setLoginUser(updatedUser));
		} else {
			// Handle the case where the file size exceeds the limit
			showToastMessage("File size exceeds the limit", "I");
		}
	};

	const handleLogout = async () => {
		!isLoading && (await dispatch(logout()));
	};

	const imgSrc = useMemo(() => {
		if (selectedImage) {
			return URL.createObjectURL(selectedImage);
		} else if (!isEmptyObject(loginDetails) && loginDetails.user_image) {
			return loginDetails.user_image;
		} else {
			return "";
		}
	}, [loginDetails, selectedImage]);

	const formik = useFormik({
		validationSchema: changePswrdSchema,
		initialValues: {
			oldPassword: "",
			password: "",
			confirm_password: "",
		},
		onSubmit: async (pswrdData, { isSubmitting }) => {
			if (!isSubmitting || !isLoading) {
				await dispatch(updatePassword(pswrdData));
				setIsChangePswrd(false);
			}
		},
	});

	return (
		<>
			{!isEmptyObject(loginDetails) && (
				<div
					className="modal-profile-container"
					style={
						isVisible ? { display: "block" } : { display: "none" }
					}
					onClick={(e) => e.stopPropagation()}
				>
					<div className="container-pointer"></div>
					{isChangePswrd ? (
						<div className="modal-profile-container-inner">
							<h2 className="profile-maintext">
								Change Password
							</h2>
							<p className="profile-subtext">
								Please enter and confirm your new password to
								access your account.
							</p>

							<div className="change-pswrd-wrapper">
								<PasswordInput
									label="Old Password"
									name="oldPassword"
									placeholder="Enter old password"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values?.oldPassword || ""}
									disabled={!isChangePswrd}
									autoComplete="off"
									error={
										formik.submitCount > 0
											? formik?.errors?.oldPassword
											: ""
									}
								/>
								<PasswordInput
									label="New Password"
									name="password"
									placeholder="Enter new password"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values?.password || ""}
									disabled={!isChangePswrd}
									autoComplete="off"
									error={
										formik.submitCount > 0
											? formik?.errors?.password
											: ""
									}
								/>
								<PasswordInput
									label="Confirm Password"
									name="confirm_password"
									placeholder="Re-enter new password"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={
										formik.values?.confirm_password || ""
									}
									disabled={!isChangePswrd}
									autoComplete="off"
									error={
										formik.submitCount > 0
											? formik?.errors?.confirm_password
											: ""
									}
								/>
								<div className="btns-container">
									<div
										className="change-pswrd-btns"
										onClick={() => setIsChangePswrd(false)}
									>
										Back
									</div>
									<div
										className="change-pswrd-btns"
										disabled={formik.isSubmitting}
										onClick={formik.submitForm}
									>
										Reset Password
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="modal-profile-container-inner">
							{/* <div className="profile-view-btn">
							View Profile
							<i className="fa-solid fa-arrow-right"></i>
						</div> */}
							<div
								className="profile-img-container"
								onClick={handleImageEdit}
							>
								<div className="profile-img">
									{imgSrc ? (
										<img
											crossOrigin="anonymous"
											src={imgSrc}
											alt="profile"
										/>
									) : (
										<i className="fa-solid fa-user fa-5x"></i>
									)}
								</div>
								<div
									className={`profile-verified ${
										loginDetails.user_verified
											? "active"
											: "inactive"
									}`}
								>
									{loginDetails.user_verified ? (
										<i className="fa-solid fa-check"></i>
									) : (
										<i className="fa-solid fa-exclamation"></i>
									)}
								</div>
							</div>
							<input
								type="file"
								accept="image/*"
								ref={fileInputRef}
								style={{ display: "none" }}
								onChange={handleImageChange}
							/>
							<div className="profile-details">
								<h2 className="profile-maintext">
									{loginDetails?.first_name}{" "}
									{loginDetails?.last_name}
								</h2>
								<p className="profile-subtext">
									{loginDetails?.user_email}
								</p>
								<div className="profile-status-role">
									<div className="status-role-container">
										<p className="sr-label">Role</p>
										<h4 className="sr-value">
											{loginDetails?.user_role}
										</h4>
									</div>
									<div className="separator"></div>
									<div className="status-role-container">
										<p className="sr-label">Status</p>
										<h4 className="sr-value">
											{loginDetails?.user_status}
										</h4>
									</div>
								</div>
							</div>
							<div
								className="profile-action-btn"
								onClick={() => setIsChangePswrd(true)}
								disabled={isLoading}
							>
								Change Password
							</div>
							<div
								className="profile-action-btn"
								onClick={handleLogout}
								disabled={isLoading}
							>
								Logout
								<i className="fa-solid fa-right-from-bracket"></i>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
}

export default ProfileModal;
