import React, { useCallback } from "react";

// global functions
import { isEmptyObject, rupeeConverter } from "../../global/function";

// redux
import { useSelector } from "react-redux";

// components
import Switch from "../form-inputs/switch.component";

export default function InvoiceItemsFooter({
	formik,
	onDiscountServiceChange,
	onBilledItemsChange,
	onServiceChargeToggle,
	isServiceChargeEnabled,
	onFieldValueChange,
}) {
	const { preferenceDetails } = useSelector((state) => state.companyReducer);

	const handleAddItem = useCallback(() => {
		if (!isEmptyObject(preferenceDetails)) {
			formik.setFieldValue("billed_items", [
				...formik.values?.billed_items,
				{
					item_name: "",
					hs_code: "",
					item_price: 0,
					item_qty: 0,
					total_price: 0,
					vatable: preferenceDetails?.vat,
				},
			]);
		}
		// eslint-disable-next-line
	}, [formik.values?.billed_items, preferenceDetails]);

	const handleRemoveItem = (index) => {
		const updatedItems = [...formik.values?.billed_items];
		updatedItems.splice(index, 1);
		formik.setFieldValue("billed_items", updatedItems);
	};

	function _renderTotalSection(vatEnabled) {
		if (vatEnabled) {
			return (
				<div className="bill-total-container">
					<div className="bill-total-row">
						<div className="row-item"></div>
						<div className="row-item align_right item-header">
							Non-taxable Amount
						</div>
						<div className="row-item align_right item-header">
							Taxable Amount
						</div>
					</div>
					<div className="bill-total-row">
						<div className="row-item">Subtotal</div>
						<div className="row-item align_right">
							Rs.{" "}
							{rupeeConverter(
								formik.values?.non_taxable_total_sales
							)}
						</div>
						<div className="row-item align_right">
							Rs.{" "}
							{rupeeConverter(formik.values?.taxable_total_sales)}
						</div>
					</div>

					{preferenceDetails?.discount && (
						<div className="bill-total-row">
							<div className="row-item">Discount</div>
							<div className="row-item">
								<input
									name="non_taxable_discount"
									className="input-box-wrapper"
									style={{ textAlign: "right" }}
									type="text"
									placeholder="Rs. 0.00"
									onChange={(e) =>
										onDiscountServiceChange(
											"non_taxable_discount",
											e.target.value
										)
									}
									onBlur={formik.handleBlur}
									value={formik.values?.non_taxable_discount}
								/>
							</div>
							<div className="row-item">
								<input
									name="taxable_discount"
									className="input-box-wrapper"
									style={{ textAlign: "right" }}
									type="text"
									placeholder="Rs. 0.00"
									onChange={(e) =>
										onDiscountServiceChange(
											"taxable_discount",
											e.target.value
										)
									}
									onBlur={formik.handleBlur}
									value={formik.values?.taxable_discount}
								/>
							</div>
						</div>
					)}

					{preferenceDetails?.service_charge > 0 && (
						<div className="bill-total-row">
							<div className="row-item">
								<span>Service Charge</span>
								<Switch
									name="isService"
									isToggled={isServiceChargeEnabled}
									onToggle={onServiceChargeToggle}
								/>
							</div>
							<div className="row-item">
								<input
									name="non_taxable_service_charge"
									className="input-box-wrapper"
									style={{ textAlign: "right" }}
									type="text"
									placeholder="Rs. 0.00"
									onChange={(e) =>
										onDiscountServiceChange(
											"non_taxable_service_charge",
											e.target.value
										)
									}
									onBlur={formik.handleBlur}
									value={
										formik.values
											?.non_taxable_service_charge
									}
									disabled={!isServiceChargeEnabled}
								/>
							</div>
							<div className="row-item">
								<input
									name="taxable_service_charge"
									className="input-box-wrapper"
									style={{ textAlign: "right" }}
									type="text"
									placeholder="Rs. 0.00"
									onChange={(e) =>
										onDiscountServiceChange(
											"taxable_service_charge",
											e.target.value
										)
									}
									onBlur={formik.handleBlur}
									value={
										formik.values?.taxable_service_charge
									}
									disabled={!isServiceChargeEnabled}
								/>
							</div>
						</div>
					)}
					<div className="bill-total-row">
						<div className="row-item">Gross Total</div>
						<div className="row-item align_right">
							Rs.{" "}
							{formik.values?.non_taxable_amt
								? formik.values?.non_taxable_amt?.toFixed(2)
								: "0.0"}
						</div>
						<div className="row-item align_right">
							Rs.{" "}
							{formik.values?.taxable_sales_vat
								? formik.values?.taxable_sales_vat?.toFixed(2)
								: "0.0"}
						</div>
					</div>

					<div className="bill-total-row">
						<div className="row-item">
							<span>VAT @ 13%</span>
						</div>
						<div className="row-item align_right">-</div>

						<div className="row-item align_right">
							{/* {isTaxEnabled
										? `Rs. ${
												formik.values?.vat?.toFixed(
													2
												) || "0.0"
										  }`
										: null} */}
							Rs. {formik.values?.vat?.toFixed(2) || "0.0"}
						</div>
					</div>

					<div className="bill-total-row highlighted">
						<div className="row-item">Total</div>
						<div className="row-item align_right">
							Rs. {rupeeConverter(formik.values?.grand_total)}
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="bill-total-container">
					<div className="bill-total-row">
						<div className="row-item">Subtotal</div>
						<div className="row-item align_right">
							Rs. {rupeeConverter(formik.values?.total_sales)}
						</div>
					</div>

					{preferenceDetails?.discount && (
						<div className="bill-total-row">
							<div className="row-item">Discount</div>
							<div className="row-item">
								<input
									name="taxable_discount"
									className="input-box-wrapper"
									style={{ textAlign: "right" }}
									type="text"
									placeholder="Rs. 0.00"
									onChange={(e) =>
										onDiscountServiceChange(
											"taxable_discount",
											e.target.value
										)
									}
									onBlur={formik.handleBlur}
									value={formik.values?.taxable_discount}
								/>
							</div>
						</div>
					)}

					{preferenceDetails?.service_charge > 0 && (
						<div className="bill-total-row">
							<div className="row-item">
								<span>Service Charge</span>
								<Switch
									name="isService"
									isToggled={isServiceChargeEnabled}
									onToggle={onServiceChargeToggle}
								/>
							</div>

							<div className="row-item">
								<input
									name="taxable_service_charge"
									className="input-box-wrapper"
									style={{ textAlign: "right" }}
									type="text"
									placeholder="Rs. 0.00"
									onChange={(e) =>
										onDiscountServiceChange(
											"taxable_service_charge",
											e.target.value
										)
									}
									onBlur={formik.handleBlur}
									value={
										formik.values?.taxable_service_charge
									}
									disabled={!isServiceChargeEnabled}
								/>
							</div>
						</div>
					)}
					<div className="bill-total-row">
						<div className="row-item">Non-Taxable Amount</div>
						<div className="row-item align_right">
							Rs.{" "}
							{formik.values?.taxable_sales_vat
								? formik.values?.taxable_sales_vat?.toFixed(2)
								: "0.0"}
						</div>
					</div>

					<div className="bill-total-row highlighted">
						<div className="row-item">Total</div>
						<div className="row-item align_right">
							Rs. {rupeeConverter(formik.values?.grand_total)}
						</div>
					</div>
				</div>
			);
		}
	}

	return (
		<>
			<div className="bill-items-container">
				<div className="label-title">Item Descriptions</div>
				<div
					className={`item-category-container ${
						preferenceDetails?.vat ? "vat-col" : ""
					}`}
				>
					<div className="category-text">#</div>
					{preferenceDetails?.vat && (
						<div className="category-text">HS Code</div>
					)}
					<div className="category-text">Items</div>
					<div className="category-text">Qty.</div>
					<div className="category-text">Price</div>
					<div className="category-text" style={{ minWidth: "64px" }}>
						Amount
					</div>
					{preferenceDetails?.vat && (
						<div className="category-text">VAT</div>
					)}
					<div className="category-text"></div>
				</div>
				{formik.values?.billed_items &&
					formik.values?.billed_items.length > 0 &&
					formik.values?.billed_items.map((item, index) => {
						return (
							<div
								className={`item-details-container  ${
									preferenceDetails?.vat ? "vat-col" : ""
								}`}
								key={index}
							>
								<div className="input-wrapper">
									<input
										name={`billed_items[${index}].count`}
										value={index + 1}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										className="input-box-wrapper"
										type="text"
										disabled={true}
									/>
								</div>
								{preferenceDetails?.vat && (
									<div className="input-wrapper">
										<input
											name={`billed_items[${index}].hs_code`}
											value={
												formik.values?.billed_items[
													index
												].hs_code
											}
											onChange={(e) =>
												onBilledItemsChange(
													`billed_items[${index}].hs_code`,
													index,
													"hs_code",
													e.target.value
												)
											}
											onBlur={formik.handleBlur}
											className="input-box-wrapper"
											type="text"
										/>
									</div>
								)}
								<div className="input-wrapper">
									<input
										name={`billed_items[${index}].item_name`}
										value={
											formik.values?.billed_items[index]
												.item_name
										}
										onChange={(e) =>
											onBilledItemsChange(
												`billed_items[${index}].item_name`,
												index,
												"item_name",
												e.target.value
											)
										}
										onBlur={formik.handleBlur}
										className="input-box-wrapper"
										type="text"
										placeholder="Items..."
									/>
									{formik.submitCount > 0 && (
										<div className="validation-error">
											{
												formik.errors?.billed_items?.[
													index
												]?.item_name
											}
										</div>
									)}
								</div>
								<div className="input-wrapper">
									<input
										name={`billed_items[${index}].item_qty`}
										value={
											formik.values?.billed_items[index]
												.item_qty
										}
										onChange={(e) => {
											onBilledItemsChange(
												`billed_items[${index}].item_qty`,
												index,
												"item_qty",
												e.target.value
											);
										}}
										onBlur={formik.handleBlur}
										className="input-box-wrapper"
										type="number"
										placeholder="0"
									/>
									{formik.submitCount > 0 && (
										<div className="validation-error">
											{
												formik.errors?.billed_items?.[
													index
												]?.item_qty
											}
										</div>
									)}
								</div>
								<div className="input-wrapper">
									<input
										name={`billed_items[${index}].item_price`}
										value={
											formik.values?.billed_items[index]
												.item_price
										}
										onChange={(e) => {
											onBilledItemsChange(
												`billed_items[${index}].item_price`,
												index,
												"item_price",
												e.target.value
											);
										}}
										onBlur={formik.handleBlur}
										className="input-box-wrapper"
										type="number"
										placeholder="0.00"
									/>
									{formik.submitCount > 0 && (
										<div className="validation-error">
											{
												formik.errors?.billed_items?.[
													index
												]?.item_price
											}
										</div>
									)}
								</div>
								<div
									className="input-wrapper"
									style={{
										minWidth: "64px",
									}}
								>
									<input
										name={`billed_items[${index}].total_price`}
										value={
											formik.values?.billed_items[index]
												.total_price
										}
										className="input-box-wrapper"
										type="number"
										placeholder="0.00"
										style={{
											textAlign: "right",
										}}
										disabled={true}
									/>
									{formik.submitCount > 0 && (
										<div className="validation-error">
											{
												formik.errors?.billed_items?.[
													index
												]?.total_price
											}
										</div>
									)}
								</div>
								{preferenceDetails?.vat && (
									<label
										htmlFor="vatable"
										className="input-action"
									>
										<input
											type="checkbox"
											id="vatable"
											name={`billed_items[${index}].vatable`}
											onChange={(e) => {
												onBilledItemsChange(
													`billed_items[${index}].vatable`,
													index,
													"vatable",
													e.target.checked
												);
											}}
											checked={
												formik.values?.billed_items[
													index
												].vatable
											}
											onBlur={formik.handleBlur}
										/>
									</label>
								)}
								<div
									className="input-action"
									onClick={() => handleRemoveItem(index)}
								>
									<i className="fa-regular fa-trash-can"></i>
								</div>
							</div>
						);
					})}
				<div className="row-btns">
					<div className="itemfield-btn" onClick={handleAddItem}>
						<i
							className="fa-solid fa-plus"
							style={{ marginBottom: "2px" }}
						></i>
						New invoice line
					</div>
				</div>
			</div>

			<div className="bill-footer-container">
				<div className="footer-description-container">
					<textarea
						name="sale_info"
						className="textarea-wrapper"
						rows={10}
						// cols={80}
						placeholder="Write a description to the recipent..."
						onChange={(e) =>
							onFieldValueChange("sale_info", e.target.value)
						}
						onBlur={formik.handleBlur}
						value={formik.values?.sale_info || ""}
					/>
				</div>
				{_renderTotalSection(preferenceDetails?.vat)}
			</div>
		</>
	);
}
