import React, { useMemo, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import "./bill-duplicate.style.scss";

// global functions
import {
	getSelectedTemplate,
	isEmptyObject,
	splitArray,
} from "../../global/function";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
	incrementPrintCount,
	searchBills,
} from "../../redux/actions/bill.action";

function BillBulkPrint({ onBulkPrintModalClose, filterValues, onSelectBill }) {
	const { billList } = useSelector((state) => state.billReducer);

	const [billsToPrint, setBillsToPrint] = useState({});

	const dispatch = useDispatch();

	// ref for print of all bills component
	const allBillsRef = useRef(null);

	const billFilter = useMemo(() => {
		let billVoid;
		if (filterValues?.bill_void === "true") {
			billVoid = "Voided Bills";
		} else if (filterValues?.bill_void === "false") {
			billVoid = "Non-void Bills";
		} else {
			billVoid = "";
		}

		let filterObject = { ...filterValues, bill_void: billVoid };

		// Remove empty string properties
		for (const key in filterObject) {
			if (filterObject[key] === "") {
				delete filterObject[key];
			}
		}

		// Handle total_sales_min and total_sales_max condition
		if (filterObject.total_sales_max === 0) {
			delete filterObject.total_sales_min;
			delete filterObject.total_sales_max;
		}
		return { ...filterObject };
	}, [filterValues]);

	const handleBillSelection = (bill, index) => {
		let state = { ...billsToPrint };
		if (state[index]) {
			delete state[index];
		} else {
			let selectedBill;
			if (bill?.bill_void) {
				selectedBill = {
					...bill,
					billed_items: bill?.credit_note?.billed_items,
				};
			} else {
				selectedBill = { ...bill };
			}
			state[index] = selectedBill;
		}
		setBillsToPrint(state);
	};

	const handleToggleAll = () => {
		let objState;
		if (Object.keys(billsToPrint).length === billList.length) {
			objState = {};
		} else {
			objState = billList.reduce((acc, value, index) => {
				if (value?.bill_void) {
					acc[index] = {
						...value,
						billed_items: value?.credit_note?.billed_items,
					};
				} else {
					acc[index] = value;
				}

				return acc;
			}, {});
		}
		setBillsToPrint(objState);
	};

	const handleAfterPrint = async () => {
		//using flatMap instead of map here because it create the same number of elements that exists in the values arr.
		//This only works if every element needs to be added
		//Here there is an if/else statement both condition returns an element so length is same.
		const flatmapPayloadArray = Object.values(billsToPrint).flatMap(
			(bill) => {
				if (bill.bill_void) {
					return [
						{
							id: bill.bill_id,
							model_type: "bill",
							print_type: "print",
						},
						{
							id: bill?.credit_note?.credit_note_id,
							model_type: "credit",
							print_type: "print",
						},
					];
				} else {
					return {
						id: bill.bill_id,
						model_type: "bill",
						print_type: "print",
					};
				}
			}
		);

		const result = await dispatch(incrementPrintCount(flatmapPayloadArray));
		// if success fetch all bills with updated print count
		if (result) {
			let filterData = {
				...filterValues,
				bill_void:
					filterValues?.bill_void !== ""
						? JSON.parse(filterValues?.bill_void)
						: "",
				date_from:
					filterValues?.fiscal_year === ""
						? filterValues?.date_from
						: "",
				date_to:
					filterValues?.fiscal_year === ""
						? filterValues?.date_to
						: "",
				customer_id: filterValues?.customer_id
					? `CUS_${filterValues?.customer_id}`
					: "",
			};

			onSelectBill({});
			await dispatch(searchBills(filterData));
			onBulkPrintModalClose();
		}
	};

	function _renderBill(bill, i) {
		const slicedArrays =
			!isEmptyObject(bill) &&
			Array.isArray(bill?.billed_items) &&
			bill?.billed_items.length > 0 &&
			splitArray(bill?.billed_items, 12);

		// render invoice template with selected template ID for e.g. 1
		const _renderTemplate = (slicedArray, index) => {
			let selected_bill, showBillFooter, page, fontStyle, isCreditNote;
			showBillFooter = index === slicedArrays?.length - 1 ? true : false;
			page = index + 1 + " of " + slicedArrays?.length;
			fontStyle = { fontSize: "14px" };
			if (bill?.bill_void) {
				return (
					<>
						{getSelectedTemplate(
							1,
							{
								...bill,
								...bill.customer_info,
								sliced_items: slicedArray,
							},
							showBillFooter,
							page,
							fontStyle,
							false
						)}
						{getSelectedTemplate(
							1,
							{
								...bill.credit_note,
								...bill.customer_info,
								company: bill.company,
								sliced_items: slicedArray,
							},
							showBillFooter,
							page,
							fontStyle,
							true
						)}
					</>
				);
			} else {
				selected_bill = {
					...bill,
					...bill?.customer_info,
					sliced_items: slicedArray,
				};
				isCreditNote = false;

				return getSelectedTemplate(
					1,
					selected_bill,
					showBillFooter,
					page,
					fontStyle,
					isCreditNote
				);
			}
		};

		return (
			<div className="bills-template-wrapper" key={i}>
				{slicedArrays.length > 0 &&
					slicedArrays.map((slicedArray, index) => (
						<div className="bill-template-container" key={index}>
							<div className="bill-template-container-inner">
								{_renderTemplate(slicedArray, index)}
							</div>
						</div>
					))}
			</div>
		);
	}

	return (
		<div className="bill-duplicate-container">
			<div className="modal-header">
				<div className="modal-action" onClick={onBulkPrintModalClose}>
					<i className="fa-solid fa-arrow-left"></i> Back
				</div>
				<div className="action-container">
					<ReactToPrint
						trigger={() => (
							<div
								className="action-btn"
								disabled={isEmptyObject(billsToPrint)}
							>
								<i className="fa-solid fa-print"></i>
								Print Bills
							</div>
						)}
						content={() => allBillsRef.current}
						onAfterPrint={handleAfterPrint}
					/>
				</div>
			</div>

			<div className="modal-body">
				<div className="bill-filters-wrapper">
					<h2 className="section-header">Filter Values</h2>
					<div className="bill-filter-container">
						{Object.values(billFilter).map((value, i) => (
							<div
								key={i}
								className="bill-filter-container-inner"
							>
								<span>{value}</span>
							</div>
						))}
					</div>
				</div>
				<div className="divider" />

				<div className="bills-wrapper">
					<h2 className="section-header">Filtered Bills</h2>
					<p className="section-description">
						Select all or some of the filtered bills to print.
					</p>
					<div className="bill-container" onClick={handleToggleAll}>
						<div
							className={`bill-select ${
								Object.keys(billsToPrint).length ===
									billList.length && "selected"
							}`}
						>
							{Object.keys(billsToPrint).length ===
								billList.length && (
								<i
									className="fa-solid fa-check"
									style={{
										color: "#46BE46",
										fontSize: "12px",
									}}
								></i>
							)}
						</div>
						<div className="select-btn">
							{Object.keys(billsToPrint).length ===
							billList.length
								? "Deselect All"
								: "Select All"}
						</div>
					</div>
					{billList.map((bill, i) => (
						<div
							className="bill-container"
							key={i}
							onClick={() => handleBillSelection(bill, i)}
						>
							<div
								className={`bill-select ${
									billsToPrint[i] && "selected"
								}`}
							>
								{billsToPrint[i] && (
									<i
										className="fa-solid fa-check"
										style={{
											color: "#46BE46",
											fontSize: "12px",
										}}
									></i>
								)}
							</div>

							<div className="bill-container-inner">
								<div className="bill-details">
									<h4 className="bill-client">
										{bill?.buyer_name}
									</h4>
									<div
										className={`bill-status ${
											bill?.bill_void
												? "credited"
												: "bill"
										}`}
									>
										{bill?.bill_void
											? "Credit Note"
											: "Bill"}
									</div>
								</div>
								<div className="bill-details">
									Rs. {bill?.total_sales?.toFixed(2)}
								</div>
							</div>
						</div>
					))}
				</div>

				<div style={{ display: "none" }}>
					<div className="bills-preview-wrapper" ref={allBillsRef}>
						{Object.values(billsToPrint).map((bill, i) =>
							_renderBill(bill, i)
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default BillBulkPrint;
