import React, { memo, useCallback, useEffect, useState } from "react";
import Fuse from "fuse.js";
import { v4 as uuidv4 } from "uuid";
import "./invoice-form.style.scss";
import "../form-inputs/form-inputs.style.scss";
import moment from "moment";

// form handle
import { useFormik } from "formik";
import * as yup from "yup";

// components
import CustomerProfile from "../user-profile-sidemodal/customer-profile.component";
import EnNpDatePicker from "../form-inputs/en-np-datePicker.component";
import MultipleTagsInput from "../form-inputs/multiple-tags-input.component";
import SelectBox from "../form-inputs/select-box.component";
import Spinner from "../spinner/spinner.component";
import InvoiceItemsFooter from "./invoice-items-footer.component";

// global functions and hook
import strings from "../../global/strings";
import { isEmptyObject } from "../../global/function";
import { useOutsideClick } from "../../hooks/useOutsideClick";

// redux
import { useDispatch, useSelector } from "react-redux";
import { searchCustomers } from "../../redux/actions/customer.action";
import { getBillDates } from "../../redux/actions/bill.action";
import { addLocalDrafts } from "../../redux/actions/drafts.action";
import { checkIRDStatus } from "../../redux/actions/bill-server-status.action";

const DataList = ({ array, onSelect }) => {
	return (
		<div className="datalist-container">
			{array.map((data, i) => (
				<div
					key={i}
					className="list-item"
					onClick={() => onSelect(data)}
				>
					<h5>{data.customer_name}</h5>
					<div className="phone-text">
						<i className="fa-solid fa-phone"></i>
						<p>{data.customer_mobile}</p>
					</div>
				</div>
			))}
		</div>
	);
};

function InvoiceForm(props) {
	const { preferenceDetails, companyDetails } = useSelector(
		(state) => state.companyReducer
	);
	const { isLoading: irdStatusLoading, irdStatus } = useSelector(
		(state) => state.billServerStatusReducer
	);
	const { customersList } = useSelector((state) => state.customerReducer);
	// const { lastBillDate } = useSelector((state) => state.billReducer);
	const { loginDetails } = useSelector((state) => state.authUserReducer);

	const [isServiceChargeEnabled, setIsServiceChargeEnabled] = useState(false);

	// State to store the timeout ID
	const [timeoutId, setTimeoutId] = useState(null);
	const [customerAPICalled, setCustomerAPICalled] = useState(false);
	const [filteredClient, setFilteredClient] = useState([]);
	const [selectedCustomer, setSelectedCustomer] = useState({});

	const [addCustomerInfo, setAddCustomerInfo] = useState(false);
	const [isCustomerView, setIsCustomerView] = useState(false);

	const [subTotal, setSubTotal] = useState({
		totalSales: 0,
		mixedVat: false,
	});

	// customer tags and bill for to store temporarily
	const [tags, setTags] = useState([]);

	const dispatch = useDispatch();

	// fetch last bill date
	useEffect(() => {
		const fetchLastBillDate = async () => {
			await dispatch(getBillDates());
		};
		fetchLastBillDate();
	}, [dispatch]);

	function convertTagToString(array) {
		const string = "#" + array.join("#");
		return string;
	}

	function convertTagToArray(string) {
		const array = string?.split("#").filter(Boolean);
		return array;
	}

	function validateInvoice(buyer_name, billed_items, payment_mode) {
		const isBuyerNameNotEmpty = !!buyer_name && buyer_name.trim() !== "";
		const isBilledItemsNotEmpty =
			Array.isArray(billed_items) && billed_items?.length > 0;
		const isPaymentModeNotEmpty =
			!!payment_mode && payment_mode.trim() !== "";

		if (
			!isBuyerNameNotEmpty &&
			!isBilledItemsNotEmpty &&
			!isPaymentModeNotEmpty
		) {
			return false; // All conditions are not met
		}

		if (isBilledItemsNotEmpty) {
			const areBilledItemsFieldsNotEmpty = billed_items.every(
				(item) =>
					item.item_name &&
					item.item_name.trim() !== "" &&
					item.item_price !== undefined &&
					item.item_price > 0 &&
					item.item_qty !== undefined &&
					item.item_qty > 0
			);

			return (
				isBuyerNameNotEmpty &&
				areBilledItemsFieldsNotEmpty &&
				isPaymentModeNotEmpty
			);
		}

		return isBuyerNameNotEmpty && isPaymentModeNotEmpty;
	}

	const invoiceSchema = yup.lazy((values) => {
		if (values.clickedButton === "draft") {
			return yup.object().shape({
				buyer_name: yup.string().when("total_sales", {
					is: (total_sales) => total_sales <= 0,
					then: () => yup.string().required("Buyer name is required"),
				}),
				customer_mobile: yup
					.string()
					.matches(/^\d{7,10}$/, "Must be a valid Phone number")
					.nullable(),
				customer_email: yup
					.string()
					.nullable()
					.email("Please enter a valid email"),
				bill_for: yup.string().nullable(),
				buyer_pan: yup
					.string()
					.matches(/^[0-9]*$/, "Please enter a valid number")
					.nullable()
					.when("customer_type", {
						is: (value) => value === "business",
						then: () =>
							yup.number().nullable().required("Required"),
					}),
				payment_mode: yup.string().nullable(),
				// invoice_date: yup
				// 	.string()
				// 	.required("Invoice Date is required")
				// 	.test(
				// 		"is-later-than-last-bill-date",
				// 		"Date must be later than the last bill date",
				// 		function (value) {
				// 			if (lastBillDate !== "") {
				// 				const draft_date = new Date(value);
				// 				const last_bill_date = new Date(lastBillDate);

				// 				if (draft_date >= last_bill_date) {
				// 					return true; // Date is later than the global date
				// 				} else {
				// 					return false; // Date is not later than the global date
				// 				}
				// 			} else {
				// 				return true;
				// 			}
				// 		}
				// 	)
				// 	.test(
				// 		"is-not-more-than-1-month-later",
				// 		"Date should not exceed 1 month from today",
				// 		function (value) {
				// 			const draft_date = new Date(value);
				// 			const currentDate = new Date();
				// 			const maxDate = new Date();
				// 			maxDate.setMonth(currentDate.getMonth() + 1); // Set max date to 1 month later

				// 			if (draft_date <= maxDate) {
				// 				return true; // Date is valid
				// 			}

				// 			return false; // Date exceeds 1 month from today
				// 		}
				// 	),
				total_sales: yup
					.number()
					.typeError("Must be a number")
					.required("Required"),
				billed_items: yup.array().when("buyer_name", {
					is: (buyerName) => !buyerName || buyerName.trim() === "",
					then: () =>
						yup.array().of(
							yup.object().shape({
								count: yup
									.number()
									.typeError("Must be a number")
									.nullable(),
								item_name: yup
									.string()
									.nullable()
									.max(
										50,
										"Item name must be at most 50 characters"
									)
									.required("Required"),
								item_price: yup
									.number()
									.typeError("Must be a number")
									.nullable()
									.required("Required"),
								item_qty: yup
									.number()
									.typeError("Must be a number")
									.nullable()
									.required("Required"),
								total_price: yup
									.number()
									.typeError("Must be a number")
									.nullable(),
							})
						),
				}),
			});
		} else if (values.clickedButton !== "draft") {
			return yup.object().shape({
				buyer_name: yup.string().required("Buyer name is required"),
				customer_mobile: yup
					.string()
					.matches(/^\d{7,10}$/, "Must be a valid Phone number")
					.nullable(),
				customer_email: yup
					.string()
					.nullable()
					.email("Please enter a valid email"),
				bill_for: yup.string().nullable(),
				buyer_pan: yup
					.string()
					.matches(/^[0-9]*$/, "Please enter a valid number")
					.nullable()
					.when("customer_type", {
						is: (value) => value === "business",
						then: () =>
							yup.number().nullable().required("Required"),
					}),
				payment_mode: yup
					.string()
					.nullable()
					.required("Choose Payment mode"),
				// invoice_date: yup
				// 	.string()
				// 	.required("Invoice Date is required")
				// 	.test(
				// 		"is-later-than-last-bill-date",
				// 		"Date must be later than the last bill date",
				// 		function (value) {
				// 			if (lastBillDate !== "") {
				// 				const draft_date = new Date(value);
				// 				const last_bill_date = new Date(lastBillDate);

				// 				if (draft_date >= last_bill_date) {
				// 					return true; // Date is later than the global date
				// 				} else {
				// 					return false; // Date is not later than the global date
				// 				}
				// 			} else {
				// 				return true;
				// 			}
				// 		}
				// 	)
				// 	.test(
				// 		"is-not-more-than-1-month-later",
				// 		"Date should not exceed 1 month from today",
				// 		function (value) {
				// 			const draft_date = new Date(value);
				// 			const currentDate = new Date();
				// 			const maxDate = new Date();
				// 			maxDate.setMonth(currentDate.getMonth() + 1); // Set max date to 1 month later

				// 			if (draft_date <= maxDate) {
				// 				return true; // Date is valid
				// 			}

				// 			return false; // Date exceeds 1 month from today
				// 		}
				// 	),
				total_sales: yup
					.number()
					.typeError("Must be a number")
					.required("Required"),
				billed_items: yup.array().of(
					yup.object().shape({
						count: yup
							.number()
							.typeError("Must be a number")
							.nullable(),
						item_name: yup
							.string()
							.nullable()
							.max(50, "Item name must be at most 50 characters")
							.required("Required"),
						item_price: yup
							.number()
							.typeError("Must be a number")
							.nullable()
							.required("Required")
							.positive("Required"),
						item_qty: yup
							.number()
							.typeError("Must be a number")
							.nullable()
							.required("Required")
							.positive("Required"),
						total_price: yup
							.number()
							.typeError("Must be a number")
							.nullable(),
					})
				),
			});
		}
		return yup.object().shape({}); // Empty schema as a fallback
	});

	const formik = useFormik({
		validationSchema: invoiceSchema,
		initialValues: {
			bill_for: "",
			buyer_pan: "",
			buyer_name: "",
			company_customer_id: null,
			customer_info: null,
			customer_name: "",
			customer_gender: "",
			customer_pan_vat: "",
			customer_address: "",
			customer_city: "",
			customer_email: "",
			customer_mobile: "",
			customer_type: "individual",
			grand_total: 0,
			invoice_number: "",
			invoice_date: moment().format("YYYY-MM-DD"),
			payment_mode: "",
			total_sales: 0,
			non_taxable_total_sales: 0,
			taxable_total_sales: 0,
			non_taxable_amt: 0,
			taxable_sales_vat: 0,
			vat: 0,
			non_taxable_service_charge: "",
			taxable_service_charge: "",
			non_taxable_discount: "",
			taxable_discount: "",
			draft_valid: "",
			sale_info: "",
			billed_items: [],
			clickedButton: null,
		},
		onSubmit: async (values, { isSubmitting }) => {
			if (!isSubmitting) {
				let draft_valid = validateInvoice(
					values?.buyer_name,
					values?.billed_items,
					values?.payment_mode
				);
				let updatedDraft = {
					...props.currentDraft,
					...values,
					...selectedCustomer,
					buyer_pan: values.buyer_pan?.toString() || null,
					invoice_number: values.invoice_number?.toString(),
					non_taxable_discount: parseFloat(
						values?.non_taxable_discount || 0
					),
					taxable_discount: parseFloat(values?.taxable_discount || 0),
					non_taxable_service_charge: parseFloat(
						values?.non_taxable_service_charge || 0
					),
					taxable_service_charge: parseFloat(
						values?.taxable_service_charge || 0
					),
					draft_valid,
				};
				await props.draftSave(updatedDraft, values.clickedButton);
				if (props.formType === "bulk") {
					props.onUnsavedChanges({});
				}
			}
		},
	});

	useEffect(() => {
		formik.resetForm();
		props.formType === "bulk" && props.onUnsavedChanges({});
		setIsCustomerView(false);
		setTags([]);
		setSubTotal({
			totalSales: 0,
			mixedVat: false,
		});

		// props.formType === "individual" && setSelectedCustomer({});
		if (!isEmptyObject(props.currentDraft)) {
			let draftObj = {
				...formik.initialValues,
				...props.currentDraft,
				...props.currentDraft.customer_info,
				buyer_pan:
					props.currentDraft?.buyer_pan ||
					props.currentDraft?.customer_info?.customer_pan_vat ||
					null,
				billed_items:
					Array.isArray(props.currentDraft?.billed_items) &&
					props.currentDraft?.billed_items?.length > 0
						? props.currentDraft?.billed_items
						: [
								{
									item_name: "",
									hs_code: "",
									item_price: 0,
									item_qty: 0,
									total_price: 0,
									vatable: preferenceDetails?.vat,
								},
						  ],
			};

			formik.setValues(draftObj);
			if (props.currentDraft?.bill_for) {
				setTags(convertTagToArray(props.currentDraft?.bill_for));
			}
			if (props.currentDraft?.service_charge > 0) {
				setIsServiceChargeEnabled(true);
			} else {
				setIsServiceChargeEnabled(false);
			}
		} else {
			setIsServiceChargeEnabled(false);
			setSelectedCustomer({});
		}
		// eslint-disable-next-line
	}, [props.currentDraft, preferenceDetails]);

	// total calculation with all dependancies
	// taxableTotalSales parameter is current taxable_total_sales value
	// nonTaxableTotalSales parameter is current non_taxable_total_sales value
	// taxableDiscount parameter is current taxableDiscount value
	// nonTaxableDiscount parameter is current nonTaxableDiscount value
	// includeService parameter is service charge toggler(true/false)
	// taxableServiceCharge parameter is taxable_service_charge value that had been overridden
	// nonTaxableServiceCharge parameter is non_taxable_service_charge value that had been overridden
	const calculateWithTax = useCallback(
		(
			taxableTotalSales,
			nonTaxableTotalSales,
			taxableDiscount,
			nonTaxableDiscount,
			includeService,
			taxableServiceCharge = 0,
			nonTaxableServiceCharge = 0
		) => {
			let newTaxableServiceCharge = 0,
				newNonTaxableServiceCharge = 0,
				newTaxableGrossTotal = 0,
				newNonTaxableGrossTotal = 0;

			if (taxableServiceCharge <= 0 || nonTaxableServiceCharge <= 0) {
				if (includeService) {
					newTaxableServiceCharge =
						(taxableTotalSales - taxableDiscount) *
						(preferenceDetails?.service_charge / 100);
					newNonTaxableServiceCharge =
						(nonTaxableTotalSales - nonTaxableDiscount) *
						(preferenceDetails?.service_charge / 100);
				} else {
					newTaxableServiceCharge = 0;
					newNonTaxableServiceCharge = 0;
				}
			}
			if (taxableServiceCharge > 0) {
				newTaxableServiceCharge = parseFloat(taxableServiceCharge || 0);
			}
			if (nonTaxableServiceCharge > 0) {
				newNonTaxableServiceCharge = parseFloat(
					nonTaxableServiceCharge || 0
				);
			}

			newTaxableGrossTotal =
				taxableTotalSales - taxableDiscount + newTaxableServiceCharge;
			newNonTaxableGrossTotal =
				nonTaxableTotalSales -
				nonTaxableDiscount +
				newNonTaxableServiceCharge;

			formik.setFieldValue(
				"taxable_service_charge",
				newTaxableServiceCharge.toFixed(2)
			);
			formik.setFieldValue(
				"non_taxable_service_charge",
				newNonTaxableServiceCharge.toFixed(2)
			);

			let eat,
				vat = 0;

			formik.setFieldValue("non_taxable_amt", newNonTaxableGrossTotal);
			formik.setFieldValue("taxable_sales_vat", newTaxableGrossTotal);

			if (newTaxableGrossTotal > 0 && preferenceDetails?.vat) {
				vat = 0.13 * newTaxableGrossTotal;
			} else {
				vat = 0;
			}

			eat = newNonTaxableGrossTotal + newTaxableGrossTotal + vat;

			formik.setFieldValue("vat", vat);
			formik.setFieldValue("grand_total", eat);

			if (
				props.formType === "bulk" &&
				!isEmptyObject(props.currentDraft)
			) {
				// Compare the changed value with initial value
				const initialValue = formik.values?.grand_total;
				if (!areEqualWithTolerance(eat, initialValue)) {
					// the field has changed, so set new formik values to state(unSavedChanges)
					let unsaved = JSON.parse(
						JSON.stringify({
							...formik.values,
							taxable_service_charge: newTaxableServiceCharge,
							non_taxable_service_charge:
								newNonTaxableServiceCharge,
							taxable_sales_vat: newTaxableGrossTotal,
							non_taxable_amt: newNonTaxableGrossTotal,
							vat: vat,
							grand_total: eat,
						})
					);
					props.onUnsavedChanges(unsaved);
				}
			}
		},
		// eslint-disable-next-line
		[preferenceDetails?.service_charge, subTotal, preferenceDetails?.vat]
	);

	// useEffect to rerender changes in total computation when billed_item changed due to which subTotal changes
	useEffect(() => {
		calculateWithTax(
			formik.values?.taxable_total_sales,
			formik.values?.non_taxable_total_sales,
			formik.values?.taxable_discount,
			formik.values?.non_taxable_discount,
			isServiceChargeEnabled,
			parseFloat(formik.values?.taxable_service_charge || 0),
			parseFloat(formik.values?.non_taxable_service_charge || 0)
		);

		// eslint-disable-next-line
	}, [calculateWithTax]);

	useEffect(() => {
		if (
			formik.values?.company_customer_id !== "" &&
			!isEmptyObject(formik.values?.customer_info)
		) {
			setSelectedCustomer(formik.values?.customer_info);
		} else {
			setSelectedCustomer({});
		}
	}, [formik.values?.company_customer_id, formik.values?.customer_info]);

	// add more customer info if new customer
	useEffect(() => {
		const isBuyerNameNotEmpty =
			!!formik.values?.buyer_name &&
			formik.values?.buyer_name.trim() !== "";
		const isBuyerMobileNotEmpty =
			!!formik.values?.customer_mobile &&
			formik.values?.customer_mobile.trim() !== "";

		if (
			(!formik.values?.company_customer_id ||
				formik.values?.company_customer_id === "") &&
			isEmptyObject(formik.values?.customer_info) &&
			isBuyerNameNotEmpty &&
			isBuyerMobileNotEmpty
		) {
			setAddCustomerInfo(true);
		} else {
			setAddCustomerInfo(false);
		}
	}, [
		formik.values?.buyer_name,
		formik.values?.customer_info,
		formik.values?.company_customer_id,
		formik.values?.customer_mobile,
	]);

	const handleDraftClick = async (e) => {
		e.preventDefault();
		formik.setFieldValue("clickedButton", "draft");
		await formik.validateForm(); // Validate the form
		formik.submitForm(); // Submit the form
	};

	const handleInvoiceClick = async (e) => {
		e.preventDefault();
		formik.setFieldValue("clickedButton", "invoice");
		const formError = await formik.validateForm(); // Validate the form
		formik.submitForm(); // Submit the form
		isEmptyObject(formError) && setSelectedCustomer({}); // clear customer selection
	};

	const handleBillClick = async (e) => {
		e.preventDefault();
		formik.setFieldValue("clickedButton", "bill");
		await formik.validateForm(); // Validate the form
		formik.submitForm(); // Submit the form
	};

	const handleIRDStatusCheck = async () => {
		await dispatch(checkIRDStatus());
	};

	// duplicate draft function to save in redux
	const handleDraftDuplicate = async (e) => {
		e.preventDefault();
		formik.setFieldValue("clickedButton", "draft");
		const formError = await formik.validateForm(); // Validate the form

		if (isEmptyObject(formError)) {
			let dummyDraft = {
				...formik.values,
				invoice_number: `DRF_${uuidv4()}`,
			};
			delete dummyDraft?.draft_bill_status;
			delete dummyDraft?.draft_id;
			delete dummyDraft?.createdAt;
			delete dummyDraft?.datetimeClient;
			delete dummyDraft?.updatedAt;
			dispatch(addLocalDrafts(dummyDraft));
			props.onSetCurrent(dummyDraft);
		} else {
			formik.submitForm(); // to show validation error message
		}
	};

	// Track changes to form input values
	const handleFieldChange = (fieldName, fieldValue) => {
		formik.setFieldValue(fieldName, fieldValue);

		if (props.formType === "bulk" && !isEmptyObject(props.currentDraft)) {
			// Compare the changed value with initial value
			const initialValue = props.currentDraft[fieldName];
			if (fieldValue !== initialValue) {
				// the field has changed, so set new formik values to state(unSavedChanges)
				let unsaved = JSON.parse(
					JSON.stringify({
						...formik.values,
						[fieldName]: fieldValue,
					})
				);
				props.onUnsavedChanges(unsaved);
			} else {
				props.onUnsavedChanges({});
			}
		}
	};

	// To compare floating-point numbers with a certain tolerance for equality
	function areEqualWithTolerance(a, b, tolerance = 0.1) {
		return Math.abs(a - b) < tolerance;
	}

	// track changes in discount and service charge, also trigger total computation
	const handleDiscountServiceChange = (fieldName, fieldValue) => {
		let taxableDiscount = 0,
			nonTaxableDiscount = 0,
			taxableServiceCharge = 0,
			nonTaxableServiceCharge = 0;

		switch (fieldName) {
			case "non_taxable_discount":
				nonTaxableDiscount = fieldValue;
				calculateWithTax(
					formik.values?.taxable_total_sales,
					formik.values?.non_taxable_total_sales,
					formik.values?.taxable_discount,
					nonTaxableDiscount,
					isServiceChargeEnabled,
					parseFloat(formik.values?.taxable_service_charge),
					0
				);
				break;
			case "taxable_discount":
				taxableDiscount = fieldValue;
				calculateWithTax(
					formik.values?.taxable_total_sales,
					formik.values?.non_taxable_total_sales,
					taxableDiscount,
					formik.values?.non_taxable_discount,
					isServiceChargeEnabled,
					0,
					parseFloat(formik.values?.non_taxable_service_charge)
				);
				break;
			case "non_taxable_service_charge":
				nonTaxableServiceCharge = fieldValue;
				calculateWithTax(
					formik.values?.taxable_total_sales,
					formik.values?.non_taxable_total_sales,
					formik.values?.taxable_discount,
					formik.values?.non_taxable_discount,
					isServiceChargeEnabled,
					parseFloat(formik.values?.taxable_service_charge),
					parseFloat(nonTaxableServiceCharge)
				);
				break;
			case "taxable_service_charge":
				taxableServiceCharge = fieldValue;
				calculateWithTax(
					formik.values?.taxable_total_sales,
					formik.values?.non_taxable_total_sales,
					formik.values?.taxable_discount,
					formik.values?.non_taxable_discount,
					isServiceChargeEnabled,
					parseFloat(taxableServiceCharge),
					parseFloat(formik.values?.non_taxable_service_charge)
				);
				break;
			default:
				break;
		}

		formik.setFieldValue(fieldName, fieldValue);
	};

	const handleServiceChargeToggle = (e) => {
		setIsServiceChargeEnabled(e.target.checked);
		let taxableServiceCharge = e.target.checked
			? formik.values?.taxable_service_charge
			: 0;
		let nonTaxableServiceCharge = e.target.checked
			? formik.values?.non_taxable_service_charge
			: 0;

		calculateWithTax(
			formik.values?.taxable_total_sales,
			formik.values?.non_taxable_total_sales,
			formik.values?.taxable_discount,
			formik.values?.non_taxable_discount,
			e.target.checked,
			parseFloat(taxableServiceCharge),
			parseFloat(nonTaxableServiceCharge)
		);
	};

	const handleTotalCompute = useCallback(() => {
		const { taxableSalesTotal, nonTaxableSalesTotal } =
			formik.values?.billed_items?.reduce(
				(acc, item) => {
					if (preferenceDetails?.vat) {
						if (item.vatable) {
							acc.taxableSalesTotal += parseFloat(
								item?.total_price || 0
							);
						} else {
							acc.nonTaxableSalesTotal += parseFloat(
								item?.total_price || 0
							);
						}
					} else {
						acc.taxableSalesTotal += parseFloat(
							item?.total_price || 0
						);
					}
					return acc;
				},
				{ taxableSalesTotal: 0, nonTaxableSalesTotal: 0 }
			);

		const totalSales = taxableSalesTotal + nonTaxableSalesTotal;

		formik.setFieldValue("non_taxable_total_sales", nonTaxableSalesTotal);
		formik.setFieldValue("taxable_total_sales", taxableSalesTotal);
		formik.setFieldValue("total_sales", totalSales);

		if (nonTaxableSalesTotal > 0 && taxableSalesTotal > 0) {
			setSubTotal({ totalSales, mixedTax: true });
		} else {
			setSubTotal({
				totalSales,
				mixedTax: false,
			});
		}

		// eslint-disable-next-line
	}, [formik.values?.billed_items, preferenceDetails?.vat]);

	// track changes to billed_items, it is an array so last update was not pushed so manually pushed item value
	const handleBilledItemsChange = (fieldName, index, itemName, newValue) => {
		formik.setFieldValue(fieldName, newValue);

		let billedItems = JSON.parse(
			JSON.stringify(formik.values.billed_items)
		); // due to issue of shallow copy

		billedItems[index][itemName] = newValue;

		billedItems[index].total_price =
			billedItems[index].item_price * billedItems[index].item_qty;

		formik.setFieldValue(
			`billed_items[${index}].total_price`,
			billedItems[index].total_price
		);

		if (props.formType === "bulk") {
			// Compare the changed value with initial value
			const initialValue = props.currentDraft[fieldName];
			if (newValue !== initialValue) {
				// the field has changed, so set new formik values to state(unSavedChanges)
				let unsaved = JSON.parse(
					JSON.stringify({
						...formik.values,
						billed_items: billedItems,
					})
				);
				props.onUnsavedChanges(unsaved);
			}
		}
	};

	useEffect(() => {
		if (
			!isEmptyObject(preferenceDetails) &&
			Array.isArray(formik.values?.billed_items) &&
			formik.values?.billed_items.length > 0
		) {
			handleTotalCompute();
		}
	}, [formik.values?.billed_items, handleTotalCompute, preferenceDetails]);

	const handleCustomerType = (e) => {
		e.target.checked
			? handleFieldChange("customer_type", "business")
			: handleFieldChange("customer_type", "individual");
	};

	const options = {
		// Configure fuse.js options here
		shouldSort: true,
		includeScore: true,
		useExtendedSearch: true,
		keys: ["customer_name", "customer_mobile"], // The properties to search in your data
	};

	const fuse = new Fuse(customersList, options);

	// Debounced search function
	const debouncedSearch = async (inputName, inputValue) => {
		if (inputValue.trim().length > 0) {
			if (customerAPICalled) {
				// Perform the search using Fuse.js
				const results = fuse
					// .search(`="${inputValue}"`) // for exact search
					.search(inputValue)
					.map((result) => result.item);

				setFilteredClient(results);
			} else {
				let filterValues = {
					customer_mobile:
						inputName === "customer_mobile"
							? inputValue
							: formik.values?.customer_mobile,
					customer_name:
						inputName === "buyer_name"
							? inputValue
							: formik.values?.buyer_name,
					customer_id: "",
					customer_pan_vat: "",
					customer_email: "",
					customer_gender: "",
					customer_type: "",
					customer_city: "",
					customer_address: "",
				};
				// Make the API call only on the first search when input value is not empty
				await dispatch(searchCustomers(filterValues));

				setCustomerAPICalled(true);
			}
		} else {
			setCustomerAPICalled(false);
			setFilteredClient([]); // Clear results when input is empty
		}
	};

	// after api call show datalist with filtered customer
	useEffect(() => {
		if (
			customerAPICalled &&
			Array.isArray(customersList) &&
			customersList.length > 0
		) {
			setFilteredClient(customersList);
		}
	}, [customersList, customerAPICalled]);

	const handleCustomerInputChange = (inputName, inputValue) => {
		handleFieldChange(inputName, inputValue);

		// Clear the previous timeout if exists
		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		// Set a new timeout to debounce the function call
		const newTimeoutId = setTimeout(async () => {
			// Call the debounced search function
			await debouncedSearch(inputName, inputValue);
		}, 500); // Adjust the delay time as needed

		// Store the new timeout ID
		setTimeoutId(newTimeoutId);
	};

	const handleCustomerInputBlur = () => {
		setFilteredClient([]);
		setCustomerAPICalled(false);
		clearTimeout(timeoutId);
	};
	const ref = useOutsideClick(handleCustomerInputBlur);

	const handleCustomerSelection = (customer) => {
		delete customer.createdAt;
		delete customer.updatedAt;
		let billForTag =
			formik.values?.bill_for + (customer?.customer_tag || "");
		setTags(convertTagToArray(billForTag));
		setSelectedCustomer(customer);
		let updatedValues = {
			...formik.values,
			...customer,
			buyer_name: customer?.customer_name,
			buyer_pan: customer?.customer_pan_vat,
			bill_for: billForTag,
			customer_info: customer,
		};
		formik.setValues(updatedValues);
		if (props.formType === "bulk") {
			// customer selected, so set new values to be stored in redux;
			props.onUnsavedChanges(updatedValues);
		}
		handleCustomerInputBlur();
	};

	const handleCustomerDeselect = () => {
		let customer = {
			company_customer_id: null,
			customer_address: "",
			customer_city: "",
			customer_email: "",
			customer_gender: "",
			customer_id: "",
			customer_info_id: "",
			customer_mobile: "",
			customer_name: "",
			customer_pan_vat: "",
			customer_tag: "",
			customer_type: "individual",
		};
		setSelectedCustomer({});
		let updatedValues = {
			...formik.values,
			...customer,
			buyer_name: customer?.customer_name,
			buyer_pan: customer?.customer_pan_vat,
			customer_info: null,
		};
		formik.setValues(updatedValues);
		if (props.formType === "bulk") {
			// customer selected, so set new values to be stored in redux;
			props.onUnsavedChanges(updatedValues);
		}
		handleCustomerInputBlur();
	};

	const handleAddCustomer = () => {
		setIsCustomerView(true);
	};

	// bill for tags functions
	function handleTagAdd(e) {
		// Get the value of the input
		const value = e.target.value;
		// If the value is empty, return
		if (!value.trim()) return;
		// cleaning up all hash values
		let newValue = value?.split("#").filter(Boolean);

		// Convert the arrays to Sets to remove duplicates
		const set1 = new Set(tags);
		const set2 = new Set(newValue);

		// Merge the Sets
		const mergedSet = new Set([...set1, ...set2]);

		// Convert the merged Set back to an array
		const resultArray = Array.from(mergedSet);

		// Add the value to the tags array
		setTags(resultArray);
		// Clear the input
		e.target.value = "";

		const billForTag =
			Array.isArray(resultArray) && resultArray.length > 0
				? convertTagToString(resultArray)
				: "";
		formik.setFieldValue("bill_for", billForTag);
		if (props.formType === "bulk") {
			// bill for changed so set new values to be stored in redux;
			props.onUnsavedChanges({ ...formik.values, bill_for: billForTag });
		}
	}

	function handleKeyDown(e) {
		// If user did not press enter key, return
		if (e.key !== "Enter") return;
		handleTagAdd(e);
	}

	function handleTagRemove(index) {
		const newTagArray = tags.filter((el, i) => i !== index);
		setTags(newTagArray);

		const billForTag =
			Array.isArray(newTagArray) && newTagArray.length > 0
				? convertTagToString(newTagArray)
				: "";
		formik.setFieldValue("bill_for", billForTag);
		if (props.formType === "bulk") {
			// bill for changed so set new values to be stored in redux;
			props.onUnsavedChanges({ ...formik.values, bill_for: billForTag });
		}
	}

	const _renderCustomerModal = () => {
		let customerInfo;
		if (!isEmptyObject(selectedCustomer)) {
			// existing customer case
			customerInfo = selectedCustomer;
		} else {
			// new customer case
			customerInfo = {
				company_customer_id: "",
				customer_address: "",
				customer_city: "",
				customer_email: "",
				customer_gender: "",
				customer_id: "",
				customer_info_id: "",
				customer_image: "",
				customer_tag: "",
				customer_mobile: formik.values?.customer_mobile || "",
				customer_name: formik.values?.buyer_name || "",
				customer_pan_vat: formik.values?.buyer_pan || "",
				customer_type: formik.values?.customer_type || "",
			};
		}

		return (
			<CustomerProfile
				isCustomerVisible={isCustomerView}
				onCustomerModalClose={() => setIsCustomerView(false)}
				customer={customerInfo}
				onSelectCustomer={handleCustomerSelection}
			/>
		);
	};

	return (
		<>
			<div className="invoice-form-container">
				<div className="bill-header-container">
					<div
						className="bill-details-container"
						style={{ flexWrap: "wrap-reverse" }}
					>
						<div className="row-item">
							<div className="label-title">Bill From</div>
							<div className="input-wrapper">
								<label className="input-label">
									Company Name
								</label>
								<div
									className="input-box-wrapper"
									style={{ padding: "8px" }}
								>
									{loginDetails?.company_name}
								</div>
							</div>
						</div>
						<div className="row-item">
							<div className="label-title">Invoice Details</div>
							<div className="input-row-wrapper">
								<div className="input-wrapper">
									<label className="input-label">
										Invoice Date
									</label>
									<EnNpDatePicker
										className="input-box-wrapper"
										type="date"
										value={moment().format("YYYY-MM-DD")}
										disabled={true}
										// min={lastBillDate}
										style={{ opacity: 1 }}
									/>
								</div>
								{props.formType === "bulk" && (
									<div className="input-wrapper">
										<label className="input-label">
											Sales Date
										</label>
										<EnNpDatePicker
											className="input-box-wrapper"
											name="invoice_date"
											type="date"
											onChange={handleFieldChange}
											onBlur={formik.handleBlur}
											value={
												formik.values?.invoice_date ||
												""
											}
											// min={lastBillDate}
										/>
										{formik.submitCount > 0 && (
											<div className="validation-error">
												{formik.errors.invoice_date}
											</div>
										)}
									</div>
								)}
								{props.currentDraft?.draft_bill_status ? (
									<div className="input-wrapper">
										<label className="input-label">
											Invoice Number #
										</label>
										<input
											name="invoice_number"
											className="input-box-wrapper"
											style={{ textAlign: "right" }}
											type="text"
											placeholder="Invoice Number #"
											value={
												formik.values?.invoice_number ||
												""
											}
											disabled={true}
										/>
										{formik.submitCount > 0 && (
											<div className="validation-error">
												{formik.errors.invoice_number}
											</div>
										)}
									</div>
								) : null}
							</div>
						</div>
					</div>
					<div className="bill-details-container">
						<div className="row-item">
							<div className="label-title">Bill To</div>

							<div className="input-row-wrapper">
								<div className="input-wrapper" ref={ref}>
									<label className="input-label">
										Customer Name
									</label>
									<input
										name="buyer_name"
										className="input-box-wrapper"
										type="text"
										placeholder="Enter Customer Name"
										onChange={(e) =>
											handleCustomerInputChange(
												"buyer_name",
												e.target.value
											)
										}
										onBlur={formik.handleBlur}
										value={formik.values?.buyer_name || ""}
										autoComplete="off"
										disabled={
											!isEmptyObject(selectedCustomer)
										}
									/>

									{formik.submitCount > 0 && (
										<div className="validation-error">
											{formik.errors.buyer_name}
										</div>
									)}
								</div>
								<div className="input-wrapper">
									<label className="input-label">
										Customer Contact
									</label>
									<input
										name="customer_mobile"
										className="input-box-wrapper"
										type="tel"
										placeholder="Enter Customer Number"
										onChange={(e) =>
											handleCustomerInputChange(
												"customer_mobile",
												e.target.value
											)
										}
										onBlur={formik.handleBlur}
										value={
											formik.values?.customer_mobile || ""
										}
										autoComplete="off"
										disabled={
											!isEmptyObject(selectedCustomer)
										}
									/>
									{formik.submitCount > 0 && (
										<div className="validation-error">
											{formik.errors.customer_mobile}
										</div>
									)}
								</div>
								{Array.isArray(filteredClient) &&
									filteredClient.length > 0 && (
										<DataList
											array={filteredClient}
											onSelect={handleCustomerSelection}
										/>
									)}
							</div>
							<div className="checkbox-wrapper">
								<input
									type="checkbox"
									name="customer_type"
									id="customer_type"
									className="checkbox-container"
									onChange={(e) => handleCustomerType(e)}
									onBlur={formik.handleBlur}
									checked={
										formik.values?.customer_type ===
										"business"
									}
									disabled={!isEmptyObject(selectedCustomer)}
								/>
								<label
									htmlFor="customer_type"
									className={`checkbox-label ${
										!isEmptyObject(selectedCustomer) &&
										"disabled"
									}`}
								>
									Business Customer
								</label>
							</div>
							<div className="input-wrapper">
								<label className="input-label">VAT / PAN</label>
								<input
									name="buyer_pan"
									className="input-box-wrapper"
									type="text"
									placeholder="Enter Customer VAT/PAN #"
									onChange={(e) =>
										handleFieldChange(
											"buyer_pan",
											e.target.value
										)
									}
									onBlur={formik.handleBlur}
									value={formik.values?.buyer_pan || ""}
									disabled={!isEmptyObject(selectedCustomer)}
								/>
								{formik.submitCount > 0 && (
									<div className="validation-error">
										{formik.errors.buyer_pan}
									</div>
								)}
							</div>
						</div>
						<div
							className="row-item"
							style={{
								justifyContent: "flex-end",
							}}
						>
							{/* customer info show or add more customer info */}
							{!isEmptyObject(selectedCustomer) ? (
								<div className="customer-container">
									<div className="customer-details-container">
										<div className="customer-img-container">
											<div className="customer-img">
												{selectedCustomer.customer_image ? (
													<img
														crossOrigin="anonymous"
														src={
															selectedCustomer?.customer_image
														}
														alt="profile"
													/>
												) : (
													<i className="fa-solid fa-user fa-3x"></i>
												)}
											</div>
										</div>
										<div className="customer-info-container">
											<h4 className="info-primarytext">
												{
													selectedCustomer?.customer_name
												}
											</h4>
											<div className="info-subtext">
												<i className="fa-solid fa-phone"></i>
												&nbsp;
												{
													selectedCustomer?.customer_mobile
												}
											</div>
											{selectedCustomer?.customer_email && (
												<div className="info-subtext">
													<i className="fa-solid fa-envelope"></i>
													&nbsp;
													{
														selectedCustomer?.customer_email
													}
												</div>
											)}
										</div>
									</div>
									<div className="customer-action-container">
										<div
											className="customer-action-btn deselect"
											onClick={handleCustomerDeselect}
										>
											Deselect
										</div>
										<div
											className="customer-action-btn"
											onClick={handleAddCustomer}
										>
											Open detailed view
										</div>
									</div>
								</div>
							) : (
								<>
									{addCustomerInfo && (
										<div
											className="add-customer-btn"
											onClick={handleAddCustomer}
										>
											<div className="btn-text">
												Add More Customer Info
											</div>
										</div>
									)}
								</>
							)}
						</div>
					</div>
					<div className="bill-details-container">
						<div className="row-item">
							<div className="label-title">Bill For</div>
							<MultipleTagsInput
								label="Customer Tags"
								name="bill_for"
								onKeyDown={handleKeyDown}
								onBlur={handleTagAdd}
								onTagRemove={handleTagRemove}
								tagsArray={tags}
								autoComplete="off"
							/>
						</div>
						<div className="row-item">
							<div className="label-title">Payment Method</div>
							<div className="input-item">
								<p className="input-label">Mode of Payment</p>
								<SelectBox
									name="payment_mode"
									onChange={(e) =>
										handleFieldChange(
											"payment_mode",
											e.target.value
										)
									}
									onBlur={formik.handleBlur}
									value={formik.values?.payment_mode || ""}
									options={strings.paymentMode}
								/>
								{formik.submitCount > 0 && (
									<div className="validation-error">
										{formik?.errors?.payment_mode}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>

				<InvoiceItemsFooter
					formik={formik}
					onDiscountServiceChange={handleDiscountServiceChange}
					onBilledItemsChange={handleBilledItemsChange}
					onServiceChargeToggle={handleServiceChargeToggle}
					isServiceChargeEnabled={isServiceChargeEnabled}
					onFieldValueChange={handleFieldChange}
				/>
				<div className="divider"></div>

				<div className="invoice-actions">
					{/* <div className="checkbox-wrapper">
						<input
							type="checkbox"
							name=""
							id="template"
							className="checkbox-container"
						/>
						<label htmlFor="template" className="checkbox-label">
							Save this invoice as template
						</label>
					</div> */}
					{companyDetails?.company_data_corrupt ? (
						<div className="invoice-actions-warn">
							There may be existing duplicate bills or missing
							bills, preventing new bills from being generated.
							Please contact your service providers to resolve
							this issue.
						</div>
					) : (
						!irdStatus && (
							<div className="invoice-actions-warn">
								Bill generation is currently not possible due to
								an error with the IRD server. Please check the
								IRD status and try again later.
							</div>
						)
					)}

					<div className="invoice-form-btns">
						{props.formType === "individual" ? (
							<>
								{!irdStatus && (
									<button
										className="form-submit-btn"
										onClick={handleIRDStatusCheck}
										disabled={
											irdStatusLoading ||
											companyDetails?.company_data_corrupt
										}
									>
										{irdStatusLoading ? (
											<>
												<Spinner />{" "}
												<span>Checking...</span>
											</>
										) : (
											<>
												<i className="fa-regular fa-paper-plane"></i>
												<span>Check IRD Status</span>
											</>
										)}
									</button>
								)}

								<button
									className="form-submit-btn inverted"
									type="submit"
									onClick={(e) => handleBillClick(e)}
									disabled={
										formik.isSubmitting ||
										companyDetails?.company_data_corrupt ||
										!irdStatus
									}
								>
									{formik.isSubmitting ? (
										<>
											<Spinner /> <span>Creating...</span>
										</>
									) : (
										<>
											<i className="fa-regular fa-paper-plane"></i>
											<span>Create Bill</span>
										</>
									)}
								</button>
							</>
						) : (
							<>
								<button
									className="form-submit-btn"
									onClick={(e) => handleDraftDuplicate(e)}
									disabled={formik.isSubmitting}
								>
									<i className="fa-solid fa-copy"></i>
									Duplicate
								</button>
								<button
									className="form-submit-btn"
									type="submit"
									onClick={(e) => handleDraftClick(e)}
									disabled={formik.isSubmitting}
								>
									<i className="fa-regular fa-floppy-disk"></i>
									Save Draft
								</button>
								<button
									className="form-submit-btn inverted"
									type="submit"
									onClick={(e) => handleInvoiceClick(e)}
									disabled={formik.isSubmitting}
								>
									<i className="fa-regular fa-floppy-disk"></i>
									Ready Draft
								</button>
							</>
						)}
					</div>
				</div>
			</div>
			{isCustomerView && _renderCustomerModal()}
		</>
	);
}

export default memo(InvoiceForm);
