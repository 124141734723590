import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import { sessionInfo } from "../../global/function";

import { isEmptyObject } from "../../global/function";
import { fetchStatusRequestSuccess } from "./bill-server-status.action";

export const FETCH_LOGIN_REQUEST = "FETCH_LOGIN_REQUEST";
export const FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS";
export const FETCH_LOGIN_ERROR = "FETCH_LOGIN_ERROR";
export const FETCH_RESET_PASSWORD_SUCCESS = "FETCH_RESET_PASSWORD_SUCCESS";
export const FETCH_FORGOT_PASSWORD_SUCCESS = "FETCH_FORGOT_PASSWORD_SUCCESS";
export const FETCH_LOGOUT = "FETCH_LOGOUT";

export const SET_NAVIGATION_PATH = "SET_NAVIGATION_PATH";
export const SET_LOGIN_INFO = "SET_LOGIN_INFO";

export const fetchLoginRequest = () => {
	return {
		type: FETCH_LOGIN_REQUEST,
	};
};

export const fetchLoginSuccess = (login) => {
	return {
		type: FETCH_LOGIN_SUCCESS,
		payload: login,
	};
};

export const fetchForgotPasswordSuccess = () => {
	return {
		type: FETCH_FORGOT_PASSWORD_SUCCESS,
	};
};

export const fetchLoginError = (error) => {
	return {
		type: FETCH_LOGIN_ERROR,
		payload: error,
	};
};

export const fetchLogoutRequest = () => {
	return {
		type: FETCH_LOGOUT,
	};
};

export const setNavigationPath = (pathname) => {
	return {
		type: SET_NAVIGATION_PATH,
		payload: pathname,
	};
};

export const setLoginInfo = (companyVerified) => {
	return {
		type: SET_LOGIN_INFO,
		payload: companyVerified,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		await dispatch(fetchLoginError(error.response.data.message));
		await dispatch(setNavigationPath("/login"));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else {
		await dispatch(fetchLoginError(error.message));
		showToastMessage(error.message, "E");
	}
}

export const fetchLoginInfo = () => {
	return async (dispatch) => {
		await dispatch(fetchLoginRequest());

		let loginInfo = JSON.parse(await localStorage.getItem("loginInfo"));
		let irdStatus = JSON.parse(await localStorage.getItem("irdStatus"));
		await dispatch(fetchLoginSuccess(loginInfo));
		await dispatch(fetchStatusRequestSuccess(irdStatus));
	};
};

export const setLoginUser = (updatedUser) => {
	return async (dispatch) => {
		await dispatch(fetchLoginRequest());

		let oldloginInfo = JSON.parse(await localStorage.getItem("loginInfo"));
		let newLoginInfo = { ...oldloginInfo, ...updatedUser };
		localStorage.setItem("loginInfo", JSON.stringify(newLoginInfo));
		await dispatch(fetchLoginSuccess(newLoginInfo));
	};
};

export const processLogin = (loginInfo) => {
	return async (dispatch) => {
		dispatch(fetchLoginRequest());

		return axios
			.post(`${strings.url}user/login`, loginInfo, {
				headers: { "content-type": "application/json" },
			})
			.then(async (response) => {
				if (response.data.success) {
					localStorage.clear();
					localStorage.setItem("token", response.data.token);

					localStorage.setItem("isLoggedIn", true);
					let loginResult = { ...response.data.result };
					delete loginResult.company_info_id;
					delete loginResult.user_info_id;

					localStorage.setItem(
						"loginInfo",
						JSON.stringify(loginResult)
					);
					localStorage.setItem(
						"irdStatus",
						JSON.stringify(response.data.irdStatus)
					);

					if (response.data.errorStatus === 100) {
						showToastMessage("Please enter correct IRD credentials before logging in", "I");
					}

					await dispatch(fetchLoginSuccess(loginResult));
				} else {
					await dispatch(fetchLoginError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
				return response.data.success;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updatePassword = (passwordInfo) => {
	return async (dispatch) => {
		const sessionData = await sessionInfo();

		let passwordData = {
			...passwordInfo,
			user_info_id: sessionData?.user_info_id,
		};

		return axios
			.put(`${strings.url}user/change/password`, passwordData, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(setNavigationPath("/login"));
				} else {
					showToastMessage(response.data.message, "E");
				}
				return response.data.success;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const forgotPassword = (passwordInfo) => {
	return async (dispatch) => {
		dispatch(fetchLoginRequest());
		return axios
			.post(`${strings.url}user/forgot/password`, passwordInfo, {
				headers: { "content-type": "application/json" },
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					dispatch(fetchForgotPasswordSuccess());
					return true;
				} else {
					showToastMessage(response.data.message, "E");
					dispatch(fetchForgotPasswordSuccess());
				}
			})
			.catch(async (error) => {
				dispatch(fetchForgotPasswordSuccess());
				await _handleError(error, dispatch);
			});
	};
};

export const resetPassword = (passwordInfo) => {
	return async (dispatch) => {
		dispatch(fetchLoginRequest());
		return axios
			.put(`${strings.url}user/reset/password`, passwordInfo, {
				headers: {
					"content-type": "application/json",
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					dispatch(fetchForgotPasswordSuccess());
				} else {
					showToastMessage(response.data.message, "E");
					dispatch(fetchForgotPasswordSuccess());
				}
				return response.data.success;
			})
			.catch(async (error) => {
				dispatch(fetchForgotPasswordSuccess());
				await _handleError(error, dispatch);
			});
	};
};

export const logout = () => {
	return async (dispatch) => {
		const sessionData = await sessionInfo();
		return axios
			.delete(`${strings.url}user/logout`, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(setNavigationPath("/login"));
					await dispatch(fetchLogoutRequest());
					showToastMessage(response.data.message, "S");
				} else {
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

// company identification and ird account update
export const updateCompanyIdentifications = (updatedData) => {
	return async (dispatch) => {
		dispatch(fetchLoginRequest());
		let sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}identification/update`, updatedData, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(setLoginInfo(true));
					localStorage.setItem("token", res.data.token);
					let loginResult = { ...res.data.result };
					delete loginResult.company_info_id;
					delete loginResult.user_info_id;

					localStorage.setItem(
						"loginInfo",
						JSON.stringify(loginResult)
					);
					await dispatch(fetchLoginSuccess(loginResult));
					showToastMessage(res.data.message, "S");
				} else {
					await dispatch(fetchLoginError(res.data.message));

					showToastMessage(res.data.message, "E");
				}
				return res.data.success;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
