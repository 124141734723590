import React from "react";
import { Navigate, useLocation } from "react-router-dom";

// page
import Verification from "../pages/login/verification.page";

export default function VerificationRoute() {
	let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));

	const location = useLocation();

	if (loginInfo?.user_verified && !loginInfo?.company_verified) {
		return <Verification />;
	} else {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}
}
