import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import ReactToPrint from "react-to-print";
// import generatePDF from "react-to-pdf";

// components
import InvoiceForm from "../invoice-form/invoice-form.component";
import SideModal from "../side-modal/side-modal.component";
import EmptyRecord from "../empty-record/empty-record.component";
import Spinner from "../spinner/spinner.component";

// global functions and strings
import {
	getSelectedTemplate,
	isEmptyObject,
	splitArray,
} from "../../global/function";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
	addBill,
	setLastBillDateSuccess,
} from "../../redux/actions/bill.action";
import { updateDraft } from "../../redux/actions/drafts.action";
import { checkIRDStatus } from "../../redux/actions/bill-server-status.action";

function ReadyDraftList({ recentBillDate }) {
	const {
		// draftsList,
		readyDraftsList,
	} = useSelector((state) => state.draftsReducer);
	const { isLoading: billLoading } = useSelector(
		(state) => state.billReducer
	);

	const { companyDetails } = useSelector((state) => state.companyReducer);
	const { isLoading: irdStatusLoading, irdStatus } = useSelector(
		(state) => state.billServerStatusReducer
	);

	const [tempViewModal, setTempViewModal] = useState(false);
	const [selectedInvoice, setSelectedInvoice] = useState({});

	const [hasFailedInvoice, setHasFailedInvoice] = useState(false);

	const draftTemplateRef = useRef(null);

	const dispatch = useDispatch();

	useEffect(() => {
		if (
			Array.isArray(readyDraftsList) &&
			readyDraftsList.length > 0 &&
			readyDraftsList[0].draft_bill_status === "failed"
		) {
			setHasFailedInvoice(true);
		} else {
			setHasFailedInvoice(false);
		}
	}, [readyDraftsList]);

	const handleTemplatePreview = (invoice) => {
		if (hasFailedInvoice) {
			if (invoice?.draft_bill_status === "failed") {
				setTempViewModal(true);
				setSelectedInvoice(invoice);
			}
		} else {
			setSelectedInvoice(invoice);
			setTempViewModal(true);
		}
	};

	const handleTemplatePreviewClose = () => {
		setSelectedInvoice({});
		setTempViewModal(false);
	};

	const handleIRDStatusCheck = async () => {
		await dispatch(checkIRDStatus());
	};

	const handleBulkBillsCreate = async () => {
		if (!billLoading) {
			// let lastBillDate =
			// 	readyDraftsList[readyDraftsList.length - 1]?.invoice_date;
			let lastBillDate = moment().format("YYYY-MM-DD");

			let billToAdd = { bills: readyDraftsList };
			const result = await dispatch(addBill(billToAdd, "bulk"));
			if (result?.success) {
				await dispatch(setLastBillDateSuccess(lastBillDate));
			}
		}
	};

	const handleBillCreate = async (billData, saveTo) => {
		if (!billLoading) {
			let lastBillDate = billData?.invoice_date;
			let billToAdd = { bills: [billData] };

			// dispatch addBill with "bulk" as second parameter as individual bill request from draft page
			const result = await dispatch(addBill(billToAdd, "bulk"));
			if (result?.success) {
				handleTemplatePreviewClose();
				await dispatch(setLastBillDateSuccess(lastBillDate));
			}
		}
	};

	// bulk draft update from ready to draft status
	const handleBulkReadyToDraft = async () => {
		let drafts = {
			drafts: [...readyDraftsList],
			draft_bill_status: "draft",
		};
		await dispatch(updateDraft(drafts));
	};

	// individual draft update from ready to draft status
	const handleInvoiceReadyToDraft = async (invoiceDraft) => {
		let drafts = {
			drafts: [
				{
					...invoiceDraft,
				},
			],
			draft_bill_status: "draft",
		};
		await dispatch(updateDraft(drafts));
		handleTemplatePreviewClose();
	};

	// const handleDraftDownload = () => {
	// 	generatePDF(draftTemplateRef, { filename: "draft_invoice.pdf" });
	// };

	// date validation before sending to ird
	// function compareDates(draftDate, readyDate) {
	// 	// date1 is earliest invoice date from draftsList array
	// 	const draft_date = new Date(draftDate);
	// 	const ready_date = new Date(readyDate);

	// 	if (draft_date >= ready_date) {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// }

	const bulkIRDRequest = useMemo(() => {
		if (Array.isArray(readyDraftsList) && readyDraftsList.length > 0) {
			// if (Array.isArray(draftsList) && draftsList.length > 0) {
			// 	return compareDates(
			// 		draftsList[0]?.invoice_date,
			// 		readyDraftsList[readyDraftsList.length - 1]?.invoice_date
			// 	);
			// } else {
			const hasFailedStatus = readyDraftsList.some(
				(obj) => obj.draft_bill_status === "failed"
			);
			if (hasFailedStatus) {
				return false;
			} else {
				return true;
			}
			// }
		} else {
			return false;
		}
	}, [readyDraftsList]);

	const individualIRDRequest = useMemo(() => {
		if (Array.isArray(readyDraftsList) && readyDraftsList.length > 0) {
			// if (Array.isArray(draftsList) && draftsList.length > 0) {
			// 	return compareDates(
			// 		draftsList[0]?.invoice_date,
			// 		readyDraftsList[0]?.invoice_date
			// 	);
			// } else {
			return irdStatus;
			// }
		} else {
			return false;
		}
	}, [readyDraftsList, irdStatus]);

	const slicedArrays = useMemo(
		() =>
			!isEmptyObject(selectedInvoice) &&
			Array.isArray(selectedInvoice?.billed_items) &&
			selectedInvoice?.billed_items.length > 0 &&
			splitArray(selectedInvoice?.billed_items, 12, 3),
		[selectedInvoice]
	);

	// render invoice template with selected template ID for e.g. 1
	const _renderTemplate = (slicedArray, index) => {
		let selected_bill, showBillFooter, page, fontStyle;
		selected_bill = {
			...selectedInvoice,
			sliced_items: slicedArray,
		};
		showBillFooter = index === slicedArrays?.length - 1 ? true : false;

		page = index + 1 + " of " + slicedArrays?.length;

		fontStyle = { fontSize: "14px" };

		return getSelectedTemplate(
			1,
			selected_bill,
			showBillFooter,
			page,
			fontStyle
		);
	};

	function _renderHeaderMessage() {
		if (companyDetails?.company_data_corrupt) {
			return (
				<div className="invoice-header-message">
					There may be existing
					<span style={{ color: "#e74c3c" }}>
						{" "}
						duplicate bills or missing bills
					</span>
					, preventing new bills from being generated. Please contact
					your service providers to resolve this issue.
				</div>
			);
		} else if (!irdStatus) {
			return (
				<div className="invoice-header-message">
					Bill generation is currently not possible due to an error
					with the IRD server. Please check the IRD status and try
					again later.
				</div>
			);
		} else if (readyDraftsList.length > 0 && !bulkIRDRequest) {
			return (
				<div className="invoice-header-message">
					You might have{" "}
					<span style={{ color: "#e74c3c" }}>failed</span> to generate
					bill. Resolve that before you proceed.
				</div>
			);
		} else {
			<div className="invoice-header-message">
				Only <b>Current bills</b> can be generated.
			</div>;
		}
	}

	return (
		<>
			<div className="drafts-invoices-container">
				<div className="drafts-invoices-header">
					<div className="di-header-title">
						<div className="section-title-text">Quotations</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "8px",
							}}
						>
							{irdStatus ? (
								<>
									{bulkIRDRequest && (
										<div
											className="di-action-btn"
											onClick={handleBulkBillsCreate}
											disabled={
												billLoading ||
												companyDetails?.company_data_corrupt
											}
										>
											{billLoading ? (
												<>
													<Spinner />{" "}
													<span>Creating...</span>
												</>
											) : (
												<>
													<i className="fa-regular fa-paper-plane"></i>
													<span>
														Create All Bills
													</span>
												</>
											)}
										</div>
									)}
								</>
							) : (
								<div
									className="di-action-btn"
									onClick={handleIRDStatusCheck}
									disabled={
										irdStatusLoading ||
										companyDetails?.company_data_corrupt
									}
								>
									{irdStatusLoading ? (
										<>
											<Spinner /> <span>Checking...</span>
										</>
									) : (
										<>
											<i className="fa-regular fa-paper-plane"></i>
											<span>Check IRD Status</span>
										</>
									)}
								</div>
							)}

							{Array.isArray(readyDraftsList) &&
								readyDraftsList.length > 0 && (
									<div
										className="di-action-btn"
										onClick={handleBulkReadyToDraft}
									>
										<i className="fa-solid fa-rotate-left"></i>
										<span>Revert All</span>
									</div>
								)}
						</div>
					</div>
					{/* {readyDraftsList.length > 0 && !bulkIRDRequest ? (
						<div className="invoice-header-message">
							Bill must be generated in chronological order. You
							might have{" "}
							<span style={{ color: "#3496db" }}>drafts</span>{" "}
							pending with earlier invoice date than{" "}
							<span style={{ color: "#272727" }}>
								{
									readyDraftsList[readyDraftsList.length - 1]
										?.invoice_date
								}
							</span>{" "}
							or have{" "}
							<span style={{ color: "#e74c3c" }}>
								failed bill
							</span>{" "}
							requests pending.
						</div>
					) : (
						<div className="invoice-header-message">
							<b>Future bills</b> cannot be generated.
						</div>
					)} */}
					{_renderHeaderMessage()}
				</div>
				<div className="drafts-invoices-list">
					{readyDraftsList?.length > 0 ? (
						readyDraftsList?.map((readyDraft) => (
							<div
								key={readyDraft.invoice_number}
								className={"list-item-container"}
								onClick={() =>
									handleTemplatePreview(readyDraft)
								}
								disabled={
									hasFailedInvoice &&
									readyDraft?.draft_bill_status !== "failed"
								}
							>
								<div className="list-item-container-inner">
									<div
										className="list-details"
										style={{ flex: 1 }}
									>
										<div className="details-col">
											<div className="list-maintext">
												{readyDraft.buyer_name ||
													"[Customer Name]"}
											</div>
											<div className="list-subtext">
												Sales Date:{" "}
												<span>
													{readyDraft?.invoice_date &&
														moment(
															readyDraft?.invoice_date
														).format(
															"DD MMMM, YYYY"
														)}
												</span>
											</div>
											<div className="list-subtext">
												Invoice Date:{" "}
												<span>
													{moment().format(
														"DD MMMM, YYYY"
													)}
												</span>
											</div>
										</div>
										<div className="details-divider"></div>
										<div className="details-row">
											<div className="list-smalltext">
												{`# ${readyDraft?.invoice_number}`}
											</div>
											<div
												className="list-smalltext"
												style={{
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
												}}
											>
												{readyDraft?.updatedAt
													? `- ${moment(
															readyDraft?.updatedAt
													  ).fromNow()}`
													: null}
											</div>
										</div>
									</div>
									<div className="list-details">
										<div
											className={`bill_status ${readyDraft.draft_bill_status}`}
										>
											{readyDraft.draft_bill_status}
										</div>
										{readyDraft.draft_bill_status ===
											"ready" &&
											readyDraft?.draft_valid && (
												<div className="valid-indicator">
													<div className="valid-icon">
														<i className="fa-solid fa-check"></i>
													</div>
													<span>Validated</span>
												</div>
											)}
									</div>
								</div>
							</div>
						))
					) : (
						<EmptyRecord />
					)}
				</div>
			</div>
			<SideModal
				isVisible={tempViewModal}
				onModalClose={handleTemplatePreviewClose}
			>
				<div className="modal-template-action-container">
					<div className="modal-header">
						<div
							className="modal-action"
							onClick={handleTemplatePreviewClose}
						>
							<i className="fa-solid fa-arrow-left"></i> Back
						</div>
						{!hasFailedInvoice ? (
							<div className="action-container">
								{/* <div
								className="action-btn inverted"
								onClick={handleDraftDownload}
							>
								<i className="fa-solid fa-download"></i>
								Download
							</div> */}

								<ReactToPrint
									trigger={() => (
										<div className="action-btn inverted">
											<i className="fa-solid fa-print"></i>
											Print
										</div>
									)}
									content={() => draftTemplateRef.current}
								/>

								<div
									className="action-btn"
									onClick={() =>
										handleInvoiceReadyToDraft(
											selectedInvoice
										)
									}
								>
									<i className="fa-solid fa-rotate-left"></i>
									Revert Invoice
								</div>

								{selectedInvoice?.invoice_number ===
									readyDraftsList[0]?.invoice_number &&
									individualIRDRequest && (
										<div
											className="action-btn"
											onClick={() =>
												handleBillCreate(
													selectedInvoice,
													"bill"
												)
											}
											disabled={
												billLoading ||
												companyDetails?.company_data_corrupt
											}
										>
											{billLoading ? (
												<>
													<Spinner />{" "}
													<span>Creating...</span>
												</>
											) : (
												<>
													<i className="fa-regular fa-paper-plane"></i>
													<span>Create Bill</span>
												</>
											)}
										</div>
									)}
							</div>
						) : (
							<div className="action-container">
								<div
									className="action-btn"
									onClick={() =>
										handleInvoiceReadyToDraft(
											selectedInvoice
										)
									}
								>
									<i className="fa-solid fa-rotate-left"></i>
									Revert to Draft
								</div>
							</div>
						)}
					</div>
					<div className="modal-body">
						{!isEmptyObject(selectedInvoice) && (
							<>
								{selectedInvoice?.draft_bill_status ===
								"failed" ? (
									<div style={{ padding: "16px" }}>
										<div className="draft-form-header">
											<h2 className="form-header-text">
												Failed Invoice Draft
											</h2>
											{recentBillDate && (
												<div className="form-header-subtext">
													<div className="label">
														Last Bill Date:
													</div>
													<div className="label-info">
														{recentBillDate}
													</div>
												</div>
											)}
										</div>
										<div className="header-divider"></div>
										<InvoiceForm
											formType="individual"
											draftSave={handleBillCreate}
											currentDraft={selectedInvoice}
											onSetCurrent={() => {}}
											onUnsavedChanges={() => {}}
										/>
									</div>
								) : (
									<div
										className="modal-template-wrapper"
										ref={draftTemplateRef}
									>
										{Array.isArray(slicedArrays) &&
											slicedArrays.length > 0 &&
											slicedArrays.map(
												(slicedArray, index) => (
													<div
														className="modal-template-container"
														key={index}
													>
														<div className="modal-template-container-inner">
															{_renderTemplate(
																slicedArray,
																index
															)}
														</div>
													</div>
												)
											)}
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</SideModal>
		</>
	);
}

export default memo(ReadyDraftList);
