import * as actionTypes from "../actions/creditNote.action";

const initial_state = {
	isLoading: false,
	creditNoteList: [],
	error: "",
};

export default function creditNoteReducer(state = initial_state, action) {
	switch (action.type) {
		case actionTypes.FETCH_CREDIT_NOTE_REQUEST:
			return {
				...state,
				isLoading: true,
				error: "",
			};
		case actionTypes.FETCH_CREDIT_NOTE_SUCCESS:

			return {
				...state,
				isLoading: false,
				creditNoteList: action.payload || [],
				error: "",
			};
		case actionTypes.FETCH_ADD_CREDIT_NOTE_SUCCESS:
			return {
				...state,
				isLoading: false,
				creditNoteList: action.payload || [],
				error: "",
			};
		case actionTypes.FETCH_CREDIT_NOTE_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
