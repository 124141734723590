import React, { useEffect, useMemo, useState } from "react";
import "./bill-duplicate.style.scss";
import { useNavigate } from "react-router-dom";

// form handle
import { useFormik } from "formik";
// import * as yup from "yup";

// date purpose
import moment from "moment";

// global functions
import { isEmptyObject } from "../../global/function";

// components
import EnNpDatePicker from "../form-inputs/en-np-datePicker.component";
import EmptyRecord from "../empty-record/empty-record.component";

// redux
import { useDispatch, useSelector } from "react-redux";
import { saveDraft } from "../../redux/actions/drafts.action";
// import { getBillDates } from "../../redux/actions/bill.action";

function BillDuplicate({ onDuplicateModalClose, filterValues }) {
	const {
		billList,
		// lastBillDate
	} = useSelector((state) => state.billReducer);
	const { preferenceDetails } = useSelector((state) => state.companyReducer);
	const { isLoading } = useSelector((state) => state.draftsReducer);
	const [billsToDuplicate, setBillsToDuplicate] = useState({});

	const dispatch = useDispatch();
	const navigate = useNavigate();

	// fetch last bill date
	// useEffect(() => {
	// 	const fetchLastBillDate = async () => {
	// 		await dispatch(getBillDates());
	// 	};
	// 	fetchLastBillDate();
	// }, [dispatch]);

	const billFilter = useMemo(() => {
		let billVoid;
		if (filterValues?.bill_void === "true") {
			billVoid = "Voided Bills";
		} else if (filterValues?.bill_void === "false") {
			billVoid = "Non-void Bills";
		} else {
			billVoid = "";
		}

		let filterObject = { ...filterValues, bill_void: billVoid };

		// Remove empty string properties
		for (const key in filterObject) {
			if (filterObject[key] === "") {
				delete filterObject[key];
			}
		}

		// Handle total_sales_min and total_sales_max condition
		if (filterObject.total_sales_max === 0) {
			delete filterObject.total_sales_min;
			delete filterObject.total_sales_max;
		}
		return { ...filterObject };
	}, [filterValues]);

	// const invoiceDateSchema = () => {
	// 	return yup.object().shape({
	// 		invoice_date: yup
	// 			.string()
	// 			.required("Invoice Date is required")
	// 			.test(
	// 				"is-later-than-last-bill-date",
	// 				"Date must be later than the last bill date",
	// 				function (value) {
	// 					if (lastBillDate !== "") {
	// 						const draft_date = new Date(value);
	// 						const last_bill_date = new Date(lastBillDate);

	// 						if (draft_date >= last_bill_date) {
	// 							return true; // Date is later than the global date
	// 						} else {
	// 							return false; // Date is not later than the global date
	// 						}
	// 					} else {
	// 						return true;
	// 					}
	// 				}
	// 			)
	// 			.test(
	// 				"is-not-more-than-1-month-later",
	// 				"Date should not exceed 1 month from today",
	// 				function (value) {
	// 					const draft_date = new Date(value);
	// 					const currentDate = new Date();
	// 					const maxDate = new Date();
	// 					maxDate.setMonth(currentDate.getMonth() + 1); // Set max date to 1 month later

	// 					if (draft_date <= maxDate) {
	// 						return true; // Date is valid
	// 					}

	// 					return false; // Date exceeds 1 month from today
	// 				}
	// 			),
	// 	});
	// };

	const formik = useFormik({
		// validationSchema: invoiceDateSchema,
		initialValues: {
			invoice_date: moment().format("YYYY-MM-DD"),
		},
	});

	const handleInvoiceDateChange = (fieldName, fieldValue) => {
		formik.setFieldValue(fieldName, fieldValue);
	};

	const handleBillsDuplicate = async () => {
		if (!isLoading) {
			const formError = await formik.validateForm(); // Validate the form
			if (isEmptyObject(formError) && !isEmptyObject(billsToDuplicate)) {
				// map values of selected billsToDuplicate and adjust according to current preference setting before duplicate
				let billArray = Object.values(billsToDuplicate).map((bill) => {
					let bill_temp = { ...bill };
					let totalSales = {
						taxable:
							bill?.taxable_sales_vat +
							bill?.taxable_discount -
							bill?.taxable_service_charge,
						non_taxable:
							bill?.non_taxable_amt +
							bill?.non_taxable_discount -
							bill?.non_taxable_service_charge,
					};

					// adjust discount after taxable sales vat adjusted
					if (!preferenceDetails?.discount) {
						bill_temp.taxable_discount = 0;
						bill_temp.non_taxable_discount = 0;
					}

					// adjust service charge in taxable sales vat first
					if (preferenceDetails?.service_charge <= 0) {
						bill_temp.taxable_service_charge = 0;
						bill_temp.non_taxable_service_charge = 0;
					}

					bill_temp.taxable_sales_vat =
						totalSales?.taxable -
						bill_temp.taxable_discount +
						bill_temp.taxable_service_charge;
					bill_temp.non_taxable_amt =
						totalSales?.non_taxable -
						bill_temp.non_taxable_discount +
						bill_temp.non_taxable_service_charge;

					// adjus vat after taxable amount
					if (preferenceDetails?.vat) {
						bill_temp.vat = 0.13 * bill_temp.taxable_sales_vat;
						if (bill_temp.non_taxable_amt <= 0) {
							bill_temp.billed_items = bill_temp.billed_items.map(
								(billItem) => ({ ...billItem, vatable: true })
							);
						}
					} else {
						bill_temp.vat = 0;

						bill_temp.taxable_discount =
							bill_temp.taxable_discount +
							bill_temp.non_taxable_discount;
						bill_temp.non_taxable_discount = 0;

						bill_temp.taxable_service_charge =
							bill_temp.taxable_service_charge +
							bill_temp.non_taxable_service_charge;
						bill_temp.non_taxable_service_charge = 0;

						bill_temp.taxable_sales_vat =
							bill_temp.taxable_sales_vat +
							bill_temp.non_taxable_amt;
						bill_temp.non_taxable_amt = 0;

						bill_temp.billed_items = bill_temp.billed_items.map(
							(billItem) => ({ ...billItem, vatable: false })
						);
					}
					bill_temp.grand_total =
						bill_temp.taxable_sales_vat +
						bill_temp.vat +
						bill_temp.non_taxable_amt;

					delete bill_temp.datetimeClient;
					delete bill_temp.createdAt;
					delete bill_temp.updatedAt;
					delete bill_temp.fiscal_year;
					delete bill_temp.invoice_number;

					console.log("--------------------");
					console.log(bill_temp);
					console.log("--------------------");

					return bill_temp;
				});

				let saveDrafts = {
					drafts: billArray,
					draft_bill_status: "draft",
					duplicateBill: true,
					invoice_date: formik.values?.invoice_date,
				};
				await dispatch(saveDraft(saveDrafts));
				navigate("/invoice/create");
			} else {
				formik.submitForm();
			}
		}
	};

	const handleBillSelection = (bill, index) => {
		let state = { ...billsToDuplicate };
		if (state[index]) {
			delete state[index];
		} else {
			let selectedBill;
			if (bill?.bill_void) {
				selectedBill = {
					...bill,
					billed_items: bill?.credit_note?.billed_items,
				};
			} else {
				selectedBill = { ...bill };
			}
			state[index] = selectedBill;
		}
		setBillsToDuplicate(state);
	};

	const handleToggleAll = () => {
		let objState;
		if (Object.keys(billsToDuplicate).length === billList.length) {
			objState = {};
		} else {
			objState = billList.reduce((acc, value, index) => {
				if (value?.bill_void) {
					acc[index] = {
						...value,
						billed_items: value?.credit_note?.billed_items,
					};
				} else {
					acc[index] = value;
				}

				return acc;
			}, {});
		}
		setBillsToDuplicate(objState);
	};

	return (
		<div className="bill-duplicate-container">
			<div className="modal-header">
				<div className="modal-action" onClick={onDuplicateModalClose}>
					<i className="fa-solid fa-arrow-left"></i> Back
				</div>
				<div className="action-container">
					<div
						className="action-btn"
						onClick={handleBillsDuplicate}
						disabled={isEmptyObject(billsToDuplicate) || isLoading}
					>
						<i className="fa-solid fa-copy"></i>
						Duplicate to draft
					</div>
				</div>
			</div>

			<div className="modal-body">
				<div className="bill-filters-wrapper">
					<h2 className="section-header">Filter Values</h2>
					<div className="bill-filter-container">
						{Object.values(billFilter).map((value, i) => (
							<div
								key={i}
								className="bill-filter-container-inner"
							>
								<span>{value}</span>
							</div>
						))}
					</div>
				</div>
				<div className="divider" />

				<div className="bills-wrapper">
					<p className="section-description">
						Choose invoice date to duplicate
					</p>
					<div className="input-item">
						<p className="input-label">Sales Date</p>
						<EnNpDatePicker
							name="invoice_date"
							className="input-box-wrapper"
							type="date"
							onChange={handleInvoiceDateChange}
							onBlur={formik.handleBlur}
							value={formik.values?.invoice_date || ""}
							// min={lastBillDate}
						/>
						{formik.submitCount > 0 && (
							<div className="validation-error">
								{formik.errors?.invoice_date}
							</div>
						)}
					</div>
				</div>
				<div className="divider" />

				{Array.isArray(billList) && billList.length > 0 ? (
					<div className="bills-wrapper">
						<h2 className="section-header">Filtered Bills</h2>
						<p className="section-description">
							Select all or some of the filtered bills to
							duplicate.
						</p>
						<div
							className="bill-container"
							onClick={handleToggleAll}
						>
							<div
								className={`bill-select ${
									Object.keys(billsToDuplicate).length ===
										billList.length && "selected"
								}`}
							>
								{Object.keys(billsToDuplicate).length ===
									billList.length && (
									<i
										className="fa-solid fa-check"
										style={{
											color: "#46BE46",
											fontSize: "12px",
										}}
									></i>
								)}
							</div>
							<div className="select-btn">
								{Object.keys(billsToDuplicate).length ===
								billList.length
									? "Deselect All"
									: "Select All"}
							</div>
						</div>
						{billList.map((bill, i) => (
							<div
								className="bill-container"
								key={i}
								onClick={() => handleBillSelection(bill, i)}
							>
								<div
									className={`bill-select ${
										billsToDuplicate[i] && "selected"
									}`}
								>
									{billsToDuplicate[i] && (
										<i
											className="fa-solid fa-check"
											style={{
												color: "#46BE46",
												fontSize: "12px",
											}}
										></i>
									)}
								</div>

								<div className="bill-container-inner">
									<div className="bill-details">
										<h4 className="bill-client">
											{bill?.buyer_name}
										</h4>
										<div
											className={`bill-status ${
												bill?.bill_void
													? "credited"
													: "bill"
											}`}
										>
											{bill?.bill_void
												? "Credit Note"
												: "Bill"}
										</div>
									</div>
									<div className="bill-details">
										Rs. {bill?.grand_total?.toFixed(2)}
									</div>
								</div>
							</div>
						))}
					</div>
				) : (
					<EmptyRecord />
				)}
			</div>
		</div>
	);
}

export default BillDuplicate;
