import React from "react";
import "./form-inputs.style.scss";

function Switch({ isToggled, onToggle, name="" }) {
	return (
		<label className="switch-container">
			<input
				type="checkbox"
				name={name}
				className="switch-checkbox"
				checked={isToggled}
				onChange={onToggle}
			/>
			<span className="switch-slider" />
		</label>
	);
}

export default Switch;
