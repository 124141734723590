import React, { useEffect, useMemo, useRef, useState } from "react";
import "./invoice-bill.style.scss";
import "../../components/form-inputs/form-inputs.style.scss";
import ReactToPrint from "react-to-print";
import { useLocation, useNavigationType } from "react-router-dom";
// import Swal from "sweetalert2";
// import generatePDF from "react-to-pdf";

// components
import BillFilter from "../../components/bill-list/bill-filter.component";
import BillList from "../../components/bill-list/bill-list.component";
import SideModal from "../../components/side-modal/side-modal.component";
import EnNpDatePicker from "../../components/form-inputs/en-np-datePicker.component";
import InvoiceTemplate from "../../components/invoice-template/invoice-template.component";
import BillAuditLogTemplate from "../../components/pdf-template/bill-audit-log.component";

// form handle
import { useFormik } from "formik";
import * as yup from "yup";

// global function
import {
	getSelectedTemplate, // to render Invoice Template component
	isEmptyObject,
	splitArray,
} from "../../global/function";

// for date purpose
import moment from "moment";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
	getBillDates,
	incrementPrintCount,
	searchBills,
	setLastBillDateSuccess,
} from "../../redux/actions/bill.action";
import { addCreditNote } from "../../redux/actions/creditNote.action";

const billFilterSchema = yup.object().shape({
	customer_id: yup.number().typeError("Must be a number"),
	buyer_name: yup.string(),
	invoice_number: yup
		.string()
		.matches(
			/^(?:\d{4}|[a-zA-Z]\d{4})$/,
			"Input must be 4 digits or 1 letter followed by 4 digits."
		),
	buyer_pan: yup.number().typeError("Must be a number"),
	date_from: yup.string(),
	date_to: yup.string(),
	total_sales_min: yup.number().typeError("Must be a number"),
	total_sales_max: yup.number().typeError("Must be a number"),
	fiscal_year: yup
		.string()
		.matches(
			/^\d{4}\.\d{3}$/,
			"Enter a fiscal year in the format XXXX.XXX"
		),
	bill_void: yup.string(),
});

const initiaBillFilter = {
	date_from: "",
	date_to: "",
	customer_id: "",
	buyer_name: "",
	buyer_pan: "",
	invoice_number: "",
	total_sales_min: 0,
	total_sales_max: 0,
	fiscal_year: "",
	bill_void: "",
	bill_for: "",
};

export default function InvoiceBill() {
	const { companyDetails } = useSelector((state) => state.companyReducer);
	const { lastBillDate } = useSelector((state) => state.billReducer);
	const [selectedBill, setSelectedBill] = useState({});

	const [tempViewModal, setTempViewModal] = useState(false);
	const [isAddCreditNote, setIsAddCreditNote] = useState(false);
	const [viewCreditNote, setViewCreditNote] = useState(false);

	const [viewAuditLog, setViewAuditLog] = useState(false);

	// state for carousel effect on Invoice Page
	const [currentInvPage, setCurrentInvPage] = useState(0);

	const dispatch = useDispatch();
	const location = useLocation();
	const navType = useNavigationType();

	// ref for print of component outside the modal
	const billTemplateRef = useRef(null);
	// ref for print of component inside the modal (bill or creditNote)
	const modalTemplateRef = useRef(null);
	// ref for print of audit log template
	const auditLogTemplateRef = useRef(null);

	function convertTagToString(array) {
		const string = "#" + array.join("#");
		return string;
	}

	const filterFormik = useFormik({
		validationSchema: billFilterSchema,
		initialValues: initiaBillFilter,
		onSubmit: async (filterValues) => {
			let filterData = {
				...filterValues,
				bill_void:
					filterValues?.bill_void !== ""
						? JSON.parse(filterValues?.bill_void)
						: "",
				date_from:
					filterValues?.fiscal_year === ""
						? filterValues?.date_from
						: "",
				date_to:
					filterValues?.fiscal_year === ""
						? filterValues?.date_to
						: "",
				customer_id: filterValues?.customer_id
					? `CUS_${filterValues?.customer_id}`
					: "",
				bill_for:
					Array.isArray(filterFormik.values?.bill_for) &&
					filterFormik.values?.bill_for.length > 0
						? convertTagToString(filterFormik.values?.bill_for)
						: "",
			};

			await dispatch(searchBills(filterData));
		},
		onReset: async () => {
			await dispatch(setLastBillDateSuccess(""));
		},
	});

	useEffect(() => {
		if (lastBillDate !== "") {
			let activityFlag = false;
			navType === "PUSH" ? (activityFlag = true) : (activityFlag = false);
			const fetchBills = async () => {
				let dateTo = "",
					dateFrom = "",
					customerId = "";
				if (!isEmptyObject(location.state)) {
					customerId = location.state?.customerId;
				} else {
					dateTo = moment(lastBillDate)
						.clone()
						.endOf("month")
						.format("YYYY-MM-DD");
					dateFrom = moment(lastBillDate)
						.clone()
						.subtract(1, "month")
						.startOf("month")
						.format("YYYY-MM-DD");
				}
				let initial_values = {
					...initiaBillFilter,
					date_from: dateFrom,
					date_to: dateTo,
					customer_id: customerId,
				};
				filterFormik.setValues({
					...initial_values,
					customer_id: customerId ? customerId.split("_")[1] : "",
				});
				await dispatch(searchBills(initial_values, activityFlag));
			};
			fetchBills();
		} else {
			const fetchLastBillDate = async () => {
				await dispatch(getBillDates());
			};
			fetchLastBillDate();
		}
		// eslint-disable-next-line
	}, [dispatch, lastBillDate, navType]);

	const handleBillSelection = (bill, index) => {
		if (!isEmptyObject(bill)) {
			if (window.innerWidth <= 1190) {
				setTempViewModal(true);
			}
			// if bill is void(added to credit note) then billed items is used from creditnote
			bill?.bill_void
				? setSelectedBill({
						...bill,
						billed_items: bill?.credit_note?.billed_items,
						index,
				  })
				: setSelectedBill({ ...bill, index });
			setCurrentInvPage(0);
		} else {
			// bill is empty when clearing selection
			setSelectedBill({});
		}
	};

	const handleModalClose = () => {
		if (viewAuditLog) {
			if (window.innerWidth > 1190) {
				setTempViewModal(false);
				setIsAddCreditNote(false);
				setViewCreditNote(false);
			}
			setViewAuditLog(false);
		} else {
			setTempViewModal(false);
			setIsAddCreditNote(false);
			setViewCreditNote(false);

			setViewAuditLog(false);
			if (window.innerWidth <= 1190) {
				setSelectedBill({});
			}
		}
	};

	const createCreditNoteSchema = yup.object().shape({
		credit_note_date: yup
			.string()
			.required("Required")
			.test(
				"is-later-than-last-bill-date",
				"Date must not be earlier than the invoice date",
				function (value) {
					if (selectedBill?.invoice_date !== "") {
						const credit_date = new Date(value);
						const last_bill_date = new Date(
							selectedBill?.invoice_date
						);

						if (credit_date >= last_bill_date) {
							return true; // Date is later than the global date
						} else {
							return false; // Date is not later than the global date
						}
					} else {
						return true;
					}
				}
			),
		reason_for_return: yup.string().required("Required"),
	});

	const creditNoteFormik = useFormik({
		validationSchema: createCreditNoteSchema,
		initialValues: {
			credit_note_date: "",
			reason_for_return: "",
		},
		onSubmit: async (values, { setSubmitting, isSubmitting }) => {
			if (!isSubmitting) {
				setSubmitting(true);
				let creditDraft = {
					...selectedBill,
					ref_invoice_number: selectedBill?.invoice_number,
					...values,
				};

				const result = await dispatch(addCreditNote(creditDraft));
				if (result) {
					creditNoteFormik.resetForm();
					handleModalClose();
					setSelectedBill({});

					setSubmitting(false);
				}
			}
		},
	});

	const handleAddCreditNote = () => {
		creditNoteFormik.setFieldValue(
			"credit_note_date",
			moment().format("YYYY-MM-DD")
		);
		setIsAddCreditNote(true);
		setTempViewModal(true);
	};
	const handleCreditNoteDateChange = (fieldName, fieldValue) => {
		creditNoteFormik.setFieldValue(fieldName, fieldValue);
	};

	const handleCreditNoteCancel = () => {
		creditNoteFormik.resetForm();
		if (window.innerWidth <= 1190) {
			setIsAddCreditNote(false);
		} else {
			setIsAddCreditNote(false);
			setTempViewModal(false);
		}
	};

	const handleCreditNoteView = () => {
		setTempViewModal(true);
		setIsAddCreditNote(false);
		setViewCreditNote(true);
	};

	const handleAuditLogsView = () => {
		setTempViewModal(true);
		setViewAuditLog(true);
		setIsAddCreditNote(false);
		setViewCreditNote(false);
	};

	// increase print count of (bill/creditNote) after print or download
	const updatePrintCount = async (id, model_type, print_type, index) => {
		let updatePayload = [{ id, model_type, print_type }];
		const result = await dispatch(
			incrementPrintCount(updatePayload, index)
		);
		if (result) {
			if (model_type === "credit") {
				setSelectedBill((prev) => ({
					...prev,
					credit_note: {
						...prev.credit_note,
						print_copies: prev?.credit_note?.print_copies + 1,
					},
				}));
			} else {
				setSelectedBill((prev) => ({
					...prev,
					print_copies: prev.print_copies + 1,
				}));
			}
		}
	};

	// const handleBillDownload = () => {
	// 	Swal.fire({
	// 		title: "Download PDF",
	// 		icon: "info",
	// 		text: "A copy of bill invoice will be downloaded",
	// 		showDenyButton: true,
	// 		denyButtonText: "Cancel",
	// 		confirmButtonText: "Download",
	// 		customClass: {
	// 			popup: "alert-modal",
	// 			actions: "btn-container",
	// 			confirmButton: "alert-btn btn-confirm",
	// 			denyButton: "alert-btn btn-cancel",
	// 		},
	// 	}).then((result) => {
	// 		if (result.isConfirmed) {
	// 			generatePDF(billTemplateRef, {
	// 				filename: `bill_invoice_${selectedBill?.invoice_number}.pdf`,
	// 			});
	// 			updatePrintCount(
	// 				selectedBill?.bill_id,
	// 				"bill",
	// 				"download",
	// 				selectedBill?.index
	// 			);
	// 		}
	// 	});
	// };

	// const handleModalInvDownload = (creditNote = false) => {
	// 	Swal.fire({
	// 		title: "Download PDF",
	// 		icon: "info",
	// 		text: `A copy of ${
	// 			creditNote ? "credit note" : "bill"
	// 		} invoice will be downloaded`,
	// 		showDenyButton: true,
	// 		denyButtonText: "Cancel",
	// 		confirmButtonText: "Download",
	// 		customClass: {
	// 			popup: "alert-modal",
	// 			actions: "btn-container",
	// 			confirmButton: "alert-btn btn-confirm",
	// 			denyButton: "alert-btn btn-cancel",
	// 		},
	// 	}).then((result) => {
	// 		if (result.isConfirmed) {
	// 			if (creditNote) {
	// 				generatePDF(modalTemplateRef, {
	// 					filename: `creditNote_invoice_${selectedBill?.credit_note?.credit_note_number}.pdf`,
	// 				});
	// 				updatePrintCount(
	// 					selectedBill?.credit_note?.credit_note_id,
	// 					"credit",
	// 					"download",
	// 					selectedBill?.index
	// 				);
	// 			} else {
	// 				generatePDF(modalTemplateRef, {
	// 					filename: `bill_invoice_${selectedBill?.invoice_number}.pdf`,
	// 				});
	// 				updatePrintCount(
	// 					selectedBill?.bill_id,
	// 					"bill",
	// 					"download",
	// 					selectedBill?.index
	// 				);
	// 			}
	// 		}
	// 	});
	// };

	const handleAfterPrint = async () => {
		let creditNote = selectedBill?.bill_void && viewCreditNote;
		if (creditNote) {
			await updatePrintCount(
				selectedBill?.credit_note?.credit_note_id,
				"credit",
				"print",
				selectedBill?.index
			);
		} else {
			await updatePrintCount(
				selectedBill?.bill_id,
				"bill",
				"print",
				selectedBill?.index
			);
		}
	};

	const slicedArrays = useMemo(
		() =>
			!isEmptyObject(selectedBill) &&
			Array.isArray(selectedBill?.billed_items) &&
			selectedBill?.billed_items.length > 0 &&
			splitArray(selectedBill?.billed_items, 12),
		[selectedBill]
	);

	const nextPage = () => {
		const nextIndex = (currentInvPage + 1) % slicedArrays.length;
		setCurrentInvPage(nextIndex);
	};

	const prevPage = () => {
		const prevIndex =
			(currentInvPage - 1 + slicedArrays.length) % slicedArrays.length;
		setCurrentInvPage(prevIndex);
	};

	// render invoice template with selected template ID for e.g. 1
	const _renderTemplate = (slicedArray, index, creditNote = false) => {
		let selected_bill, showBillFooter, page, fontStyle, isCreditNote;
		if (creditNote) {
			selected_bill =
				selectedBill?.bill_void && viewCreditNote
					? {
							...selectedBill.credit_note,
							...selectedBill.customer_info,
							company: selectedBill.company,
							sliced_items: slicedArray,
					  }
					: {
							...selectedBill,
							...selectedBill.customer_info,
							sliced_items: slicedArray,
					  };
			showBillFooter = index === slicedArrays?.length - 1 ? true : false;
			page = index + 1 + " of " + slicedArrays?.length;
			fontStyle = { fontSize: "12px" };
			isCreditNote = creditNote;
		} else {
			selected_bill = {
				...selectedBill,
				...selectedBill?.customer_info,
				sliced_items: slicedArray,
			};
			showBillFooter = index === slicedArrays?.length - 1 ? true : false;
			page = index + 1 + " of " + slicedArrays?.length;
			fontStyle = { fontSize: "14px" };
			isCreditNote = false;
		}

		return getSelectedTemplate(
			1,
			selected_bill,
			showBillFooter,
			page,
			fontStyle,
			isCreditNote
		);
	};

	function billTemplateModal() {
		return (
			<SideModal
				isVisible={tempViewModal}
				onModalClose={handleModalClose}
			>
				<div className="modal-template-action-container">
					<div className="modal-header">
						<div
							className="modal-action"
							onClick={handleModalClose}
						>
							<i className="fa-solid fa-arrow-left"></i> Back
						</div>

						{viewAuditLog ? (
							<div className="action-container">
								<ReactToPrint
									trigger={() => (
										<div className="action-btn">
											<i className="fa-solid fa-print"></i>
											Print
										</div>
									)}
									content={() => auditLogTemplateRef.current}
								/>
							</div>
						) : (
							<div className="action-container">
								{/* <div
								className="action-btn inverted"
								disabled={isAddCreditNote}
								onClick={() =>
									handleModalInvDownload(
										selectedBill?.bill_void &&
											viewCreditNote
									)
								}
							>
								<i className="fa-solid fa-download"></i>
								Download
							</div> */}

								<div
									className="action-btn inverted"
									onClick={handleAuditLogsView}
								>
									<i className="fa-solid fa-eye"></i>
									Audit&nbsp;Logs
								</div>
								<ReactToPrint
									trigger={() => (
										<div
											className="action-btn inverted"
											disabled={isAddCreditNote}
										>
											<i className="fa-solid fa-print"></i>
											Print
										</div>
									)}
									content={() => modalTemplateRef.current}
									onAfterPrint={handleAfterPrint}
								/>

								{selectedBill?.bill_void ? (
									<>
										{viewCreditNote ? (
											<div
												className="action-btn"
												onClick={() =>
													setViewCreditNote(false)
												}
											>
												{/* <i className="fa-solid fa-file-circle-plus"></i> */}
												View&nbsp;Referenced&nbsp;Bill
											</div>
										) : (
											<div
												className="action-btn"
												onClick={handleCreditNoteView}
											>
												{/* <i className="fa-solid fa-file-circle-plus"></i> */}
												View&nbsp;CreditNote
											</div>
										)}
									</>
								) : (
									<div
										className="action-btn"
										onClick={handleAddCreditNote}
										disabled={isAddCreditNote}
									>
										<i className="fa-solid fa-file-circle-plus"></i>
										Credit&nbsp;note
									</div>
								)}
							</div>
						)}
					</div>
					<div className="modal-body">
						{isAddCreditNote && (
							<div className="credit-note-form">
								<h2 className="section-header">
									Create Credit Note
								</h2>
								<div className="input-form-container">
									<div className="input-row">
										<div className="input-item">
											<p className="input-label">
												Reference Invoice Number
											</p>
											<div className="input-box-wrapper">
												{selectedBill?.invoice_number}
											</div>
										</div>
										<div className="input-item">
											<p className="input-label">
												Credit Note Date
											</p>
											<EnNpDatePicker
												name="credit_note_date"
												className="input-box-wrapper"
												type="date"
												onChange={
													handleCreditNoteDateChange
												}
												onBlur={
													creditNoteFormik.handleBlur
												}
												value={
													creditNoteFormik.values
														?.credit_note_date || ""
												}
												min={selectedBill?.invoice_date}
												disabled={true}
											/>
											{creditNoteFormik.submitCount >
												0 && (
												<div className="validation-error">
													{
														creditNoteFormik.errors
															.credit_note_date
													}
												</div>
											)}
										</div>
									</div>
									<div className="input-row">
										<div className="input-item">
											<p className="input-label">
												Reasons for Credit Note Creation
											</p>
											<textarea
												name="reason_for_return"
												className="textarea-wrapper"
												rows={4}
												placeholder="Write a reason for return..."
												onChange={
													creditNoteFormik.handleChange
												}
												onBlur={
													creditNoteFormik.handleBlur
												}
												value={
													creditNoteFormik.values
														?.reason_for_return
												}
											/>
											{creditNoteFormik.submitCount >
												0 && (
												<div className="validation-error">
													{
														creditNoteFormik.errors
															.reason_for_return
													}
												</div>
											)}
										</div>
									</div>
								</div>
								{companyDetails?.company_data_corrupt && (
									<div className="action-warn">
										There may be existing duplicate bills or
										missing bills, preventing new bills from
										being generated. Please contact your
										service providers to resolve this issue.
									</div>
								)}
								<div className="action-container">
									<div
										className="action-btn inverted"
										onClick={handleCreditNoteCancel}
									>
										Cancel
									</div>
									<div
										className="action-btn"
										onClick={creditNoteFormik.submitForm}
										disabled={
											creditNoteFormik.isSubmitting ||
											companyDetails?.company_data_corrupt
										}
									>
										<i className="fa-regular fa-paper-plane"></i>
										Send Request
									</div>
								</div>
							</div>
						)}
						{viewAuditLog ? (
							<div className="sales-audit-container">
								<div className="sales-audit-container-inner">
									<div
										className="modal-template-wrapper"
										ref={auditLogTemplateRef}
									>
										<BillAuditLogTemplate
											selectedBill={{
												...selectedBill,
												...selectedBill?.customer_info,
											}}
										/>
									</div>
								</div>
							</div>
						) : (
							<div
								className="modal-template-wrapper"
								ref={modalTemplateRef}
							>
								{slicedArrays.length > 0 &&
									slicedArrays.map((slicedArray, index) => (
										<div
											className="modal-template-container"
											key={index}
										>
											<div className="modal-template-container-inner">
												{_renderTemplate(
													slicedArray,
													index,
													selectedBill?.bill_void &&
														viewCreditNote
												)}
											</div>
										</div>
									))}
							</div>
						)}
					</div>
				</div>
			</SideModal>
		);
	}

	return (
		<div className="invoice-bill-wrapper">
			<div className="invoice-bill-container">
				<BillFilter filterFormik={filterFormik} />

				<BillList
					selectedBillId={selectedBill.bill_id}
					onSelectBill={handleBillSelection}
					billFilterValues={filterFormik.values}
				/>

				<div className="bill-template-action-container">
					<div className="temp-section-header">
						<h2>Bill View</h2>
						{!isEmptyObject(selectedBill) && (
							<div className="bill-action-container">
								{/* <div
									className="action-btn inverted"
									onClick={handleBillDownload}
								>
									<i className="fa-solid fa-download"></i>
									Download
								</div> */}
								<div
									className="action-btn inverted"
									onClick={handleAuditLogsView}
								>
									<i className="fa-solid fa-eye"></i>
									Audit&nbsp;Logs
								</div>
								<ReactToPrint
									trigger={() => (
										<div
											className="action-btn inverted"
											disabled={isAddCreditNote}
										>
											<i className="fa-solid fa-print"></i>
											Print
										</div>
									)}
									content={() => billTemplateRef.current}
									onAfterPrint={handleAfterPrint}
								/>
								{selectedBill?.bill_void ? (
									<div
										className="action-btn"
										onClick={handleCreditNoteView}
									>
										View&nbsp;CreditNote
									</div>
								) : (
									<div
										className="action-btn"
										onClick={handleAddCreditNote}
										disabled={isAddCreditNote}
									>
										<i className="fa-solid fa-file-circle-plus"></i>
										Credit&nbsp;note
									</div>
								)}
							</div>
						)}
					</div>
					{!isEmptyObject(selectedBill) ? (
						<>
							<div
								className="bill-template-wrapper"
								ref={billTemplateRef}
							>
								{slicedArrays.length > 0 &&
									slicedArrays.map((slicedArray, index) => (
										<div
											className={`bill-template-container ${
												index === currentInvPage
													? "active"
													: ""
											}`}
											key={index}
										>
											<div className="bill-template-container-inner">
												{_renderTemplate(
													slicedArray,
													index
												)}
											</div>
										</div>
									))}
							</div>
							<div className="bill-page-navigation">
								<button
									className="navigation-btn"
									onClick={prevPage}
									disabled={currentInvPage === 0}
								>
									<i className="fa-solid fa-angle-left"></i>
								</button>
								<button
									className="navigation-btn"
									onClick={nextPage}
									disabled={
										currentInvPage ===
										slicedArrays.length - 1
									}
								>
									<i className="fa-solid fa-angle-right"></i>
								</button>
							</div>
						</>
					) : (
						<div className="bill-template-wrapper">
							<div className="bill-template-container active">
								<div className="bill-template-container-inner">
									<InvoiceTemplate
										showBillFooter={true}
										page="1 of 1"
										fontStyle={{ fontSize: "12px" }}
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{billTemplateModal()}
		</div>
	);
}
