import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import { sessionInfo } from "../../global/function";

import { isEmptyObject } from "../../global/function";
import { fetchLoginError, setNavigationPath } from "./auth-user.action";

export const FETCH_ACTIVITY_REQUEST = "FETCH_ACTIVITY_REQUEST";
export const FETCH_ACTIVITY_SUCCESS = "FETCH_ACTIVITY_SUCCESS";
export const FETCH_BILL_LOG_SUCCESS = "FETCH_BILL_LOG_SUCCESS";
export const FETCH_ACTIVITY_ERROR = "FETCH_ACTIVITY_ERROR";

export const fetchActivityRequest = () => {
	return {
		type: FETCH_ACTIVITY_REQUEST,
	};
};

export const fetchActivitySuccess = (activities) => {
	return {
		type: FETCH_ACTIVITY_SUCCESS,
		payload: activities,
	};
};

export const fetchBillLogSuccess = (billLog) => {
	return {
		type: FETCH_BILL_LOG_SUCCESS,
		payload: billLog,
	};
};

export const fetchActivityError = (error) => {
	return {
		type: FETCH_ACTIVITY_ERROR,
		payload: error,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		await dispatch(setNavigationPath("/login"));
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchActivityError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchActivityError(error.message));
	}
}

export const fetchActivity = (startMonth, limit = 10, page = 1) => {
	return async (dispatch) => {
		dispatch(fetchActivityRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}activity/company?startMonth=${startMonth}&limit=${limit}&page=${page}`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchActivitySuccess(res.data.result.rows));
					return res.data.result.count;
				} else {
					await dispatch(fetchActivityError(res.data.message));
					return res.data.success;
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

// for bills audit logs
export const fetchBillLogById = (billId, limit = "", page = "") => {
	return async (dispatch) => {
		dispatch(fetchActivityRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}activity/id?model_id=${billId}&limit=${limit}&page=${page}`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchBillLogSuccess(res.data.result.rows));
				} else {
					await dispatch(fetchActivityError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const fetchCustomerByEmployee = (limit, page) => {
	return async (dispatch) => {
		dispatch(fetchActivityRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}activity/employee?limit=${limit}&page=${page}`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchActivitySuccess(res.data.result.rows));
				} else {
					await dispatch(fetchActivityError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

