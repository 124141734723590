import jwt_decode from "jwt-decode";
import strings from "./strings";

// for date
import { adToBs } from "@sbmdkl/nepali-date-converter";
import moment from "moment";

// components
import InvoiceTemplate from "../components/invoice-template/invoice-template.component";

const { JWK, parse } = require("node-jose");

export function isEmptyObject(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
}

const decrypt = async (encryptedBody) => {
	// let _privateKey = process.env.PRIVATE_KEY;
	let _privateKey = `-----BEGIN RSA PRIVATE KEY-----
MIIEowIBAAKCAQEAqfa2v45/442A70xBQ+Gv10Npxx7LFJQtfM6PdAX8UR/+uy7D
SVe/taZmY4JK+qipjVDppDhB4LIoUYFgHjp1fCEQW/UC8uLtuUhupDln0jkBrNjf
4FRFHLbVG+pF30JHdBccy7CPIlJqceupy0UqJsYk8KmcmQX0HDuiNX/FUy1J10Tk
axqoCvF16xoGGyFFKGs8cV8DjlzskDWRBpslZlXV2EgYn17/nHp7xAltiCRx982U
BB6AjM8kcXtHvQy5mMT7Ygzo8Laasxr7m9kTaCictaANnWBq5Xu7OzkJ+tZB8ko3
66KaYwh+Bpk2N3JSUVk2thVXeoTxU3no92aafwIDAQABAoIBAEm7pPYfDK4cAhZN
/3ZCXzVEoovmePVZfw5I0gTkX9yVw71SgRe9QIa+RGbg7EQnjT6oHmPGoBWpVEJf
nOIgPD14Xo92ECxsc/LQUGdgHAEgB/EDOMQp6r2VN4hfrqY3K6ZaLHzVAJNEK7d2
I/fx7mJZzUa2XiAobAkfebQ51NHK+8uRU73JS6ruGoAlvRl/ig0Cb6DJ/KhHxtk1
n8kasfACfIgICcgLNfU5Z+s7HDgpWfVB6CBD5lHnU3xkN3FBZrqUOgrfleMS+TH/
ZbvUAoRb4wSuanzYE+jmxuSAe1Q1UUbWrr2Y/6oD/Bgz0VpKBIr6MvKWZfomAp7u
gGF7GNkCgYEA15YGilNoBjkIHc3BMmpl0W7IJLk1N2bm/wgDaaGYGzU7c/J6CoIS
1if9Dzzk6nmnTGjXso1O8qbax6Q0xeSRZ2dDfhZazNgsz+K5AonCQTrMaBh7gmWO
MLHqkowTCdreeMTfgA4h0qtIC32iFYvm2XRw58g4uT3Z9wOiiqeKjAMCgYEAydNG
AwLqZn92KrjP83SKwtcxlEqO1Ijh/utbA+b5mA54B+LvuUaOGZ8jy1ZEa0GCxfa6
C0Kjzmyt7X5o6fnA/LUICNDTtz4D5YMKMV9LP+rw7zaCIe3PZKpqwfO9Tz0y6+Mt
WVhVG24i2Eyn1xPNP+3WeLhGO6KtXbE/i3o6tNUCgYBEof5zCxqS4JU058wZwMWp
ZxtQlaXbfWcMygBMIKXMA6Zpw0gXEFHu28w0ja5S92fLa5U/wrzfgz8jT6N9XMbt
pHJYMcW2cRvTEtrTdAfjvFRWz3SCjtI1rm5/ORjWildJG2QL4HPkHaNSBS0UrvVP
RUKsh5GHZ6l8eivE+JGjAQKBgQCpxfB57gTxjb0AORCssddcCRtqq8E0ZaT+BEs+
oBhLYbh0oeCYERLN2MGKdZn+Q1BXaLGdcURMC73PLuvgDjjaGwqUJ95AoYkzkqSL
Xab2MJLWFxsmoV45RuWhG4Ce3FMufJ6ULqsA9QeYvfreAIlCgrZMPG7QEYsWaliS
4pHptQKBgHuV7H+YhuPHxmh0DXXPvR65Z2psGpgwjbR6+jUWY/OikUNNd03bhqdZ
DXrJBo6RTh3nzrE8yRTge+gaKcKfsxqHKedxmCQS7LPOsp9y2WfVGbl+e5BxXOv7
H4IUuxkVspMx5PMk3niSkH4oDGFmFLBBTM9cQ0tx5Sl8+GeRSlWv
-----END RSA PRIVATE KEY-----
`;

	let keystore = JWK.createKeyStore();
	await keystore.add(await JWK.asKey(_privateKey, "pem"));
	let outPut = parse.compact(encryptedBody);
	let decryptedVal = await outPut.perform(keystore);
	let claims = Buffer.from(decryptedVal.plaintext).toString();
	return claims;
};

export async function sessionInfo() {
	let encryptedToken = localStorage.getItem("token") || false;

	if (encryptedToken) {
		const jwtToken = await decrypt(encryptedToken);
		const token = jwtToken.replace(/"/g, "");
		const sessionData = await jwt_decode(jwtToken);

		// let s = jwt_decode(token); // old token

		return { ...sessionData, token };
	} else {
		return 0;
	}
}

export function nepaliDateMMMM(date) {
	return (
		date?.split("-")[2] +
		" " +
		strings.nepaliMonth[parseInt(date?.split("-")[1]) - 1].value +
		" " +
		date?.split("-")[0]
	);
}

// to get years for dropdown
export function getEnYear() {
	const currentYear = new Date().getFullYear();
	const years = [];

	for (let year = 2020; year <= currentYear; year++) {
		years.push({ id: year, value: year });
	}

	return years;
}

export function getNepYear() {
	const currentYear = +adToBs(moment().format("YYYY-MM-DD")).split("-")[0];
	let year = 2078,
		yearArray = [],
		fiscalYear = 78;

	for (var i = 2078; i <= currentYear; i++) {
		fiscalYear++;
		yearArray.push(
			{ id: year, value: year },
			{
				id: year + "-04-01/" + (+year + 1 + "-03-31"),
				value: year + "/" + fiscalYear,
			}
		);

		year++;
	}

	return yearArray;
}

// multi page invoice view
export function splitArray(arr, maxLength = 10, maxLastChunk = 4) {
	const slicedArrays = [];
	let currentIndex = 0;

	while (currentIndex < arr.length) {
		const remainingItems = arr.length - currentIndex;
		const chunkSize =
			remainingItems > maxLength ? maxLength : remainingItems;
		const chunk = arr.slice(currentIndex, currentIndex + chunkSize);
		slicedArrays.push(chunk);
		currentIndex += chunkSize;
	}

	// Ensure the last chunk has a maximum length of 6
	if (slicedArrays.length >= 1) {
		const lastChunk = slicedArrays.pop();
		if (lastChunk.length > maxLastChunk) {
			// Split the last chunk into 2 parts
			const part1 = lastChunk.slice(0, lastChunk.length - 1);
			const part2 = lastChunk.slice(lastChunk.length - 1);
			slicedArrays.push(part1, part2);
		} else {
			slicedArrays.push(lastChunk);
		}
	}

	return slicedArrays;
}

// return template component based on template ID store in DB
export function getSelectedTemplate(
	templateID,
	selectedBill,
	showBillFooter,
	page,
	fontStyle,
	isCreditNote = false
) {
	switch (templateID) {
		case 1:
			return (
				<InvoiceTemplate
					selectedBill={selectedBill}
					showBillFooter={showBillFooter}
					page={page}
					fontStyle={fontStyle}
					isCreditNote={isCreditNote}
				/>
			);
		default:
			return (
				<InvoiceTemplate
					selectedBill={selectedBill}
					showBillFooter={showBillFooter}
					page={page}
					fontStyle={fontStyle}
					isCreditNote={isCreditNote}
				/>
			);
	}
}

// Access based on user roles => returns true or false
// export async function roleAccess(model, action) {
// 	const currentUserRole = (await sessionInfo()).user_role;

// 	const roleAccessObject = {
// 		company: {
// 			edit: {
// 				owner: true,
// 				user: false,
// 			},
// 			view: {
// 				owner: true,
// 				user: true,
// 			}
// 		},
// 		employee: {
// 			add: {
// 				owner: true,
// 				user: false,
// 			},
// 			edit: {
// 				owner: true,
// 				user: false,
// 			},
// 		}
// 	}

// 	return roleAccessObject[model][action][currentUserRole];
// }

// Access based on user roles => returns true or false
export async function roleAccess(model) {
	const currentUserRole = (await sessionInfo()).user_role;

	const roleAccessObject = {
		company: {
			owner: {
				edit: true,
				view: true,
				viewIdentification: true,
			},
			user: {
				edit: false,
				view: true,
				viewIdentification: false,
			}
		},
		employee: {
			owner: {
				edit: true,
				view: true,
				delete: true,
			},
			user: {
				edit: false,
				view: true,
				delete: false,
			}
		}
	}

	return roleAccessObject[model][currentUserRole];
}

// return amount separated by commas for nepali currency format
export function rupeeConverter(amount = 0) {
	let rsAmt = parseFloat(amount);
	const rs = rsAmt.toLocaleString("en-IN", { maximumFractionDigits: 2, minimumFractionDigits: 2, });
	return rs;
}

// Function that returns nepali currency in words
// number should be fixed to atmost 2 decimal
export function converToWord(numberToConvert) {
	let number,
		no,
		point,
		hundred,
		digits_1,
		i,
		str,
		words,
		digits,
		divider,
		plural,
		counter,
		result,
		points,
		inWords;
	number = numberToConvert.toString().split(".");
	no = number[0];
	point = number[1];
	hundred = "";
	digits_1 = no.length;
	i = 0;
	str = [];
	words = {
		0: "",
		1: "One",
		2: "Two",
		3: "Three",
		4: "Four",
		5: "Five",
		6: "Six",
		7: "Seven",
		8: "Eight",
		9: "Nine",
		10: "Ten",
		11: "Eleven",
		12: "Twelve",
		13: "Thirteen",
		14: "Fourteen",
		15: "Fifteen",
		16: "Sixteen",
		17: "Seventeen",
		18: "Eighteen",
		19: "Nineteen",
		20: "Twenty",
		30: "Thirty",
		40: "Forty",
		50: "Fifty",
		60: "Sixty",
		70: "Seventy",
		80: "Eighty",
		90: "Ninety",
	};
	digits = ["", "Hundred", "Thousand", "Lakh", "Crore", "Arab", "Kharab"];
	while (i < digits_1) {
		divider = i === 2 ? 10 : 100;
		number = Math.floor(no % divider);
		no = Math.floor(no / divider);
		i += divider === 10 ? 1 : 2;
		if (number) {
			counter = str.length;
			plural = counter && number > 9 ? "s" : "";
			hundred = counter === 1 && str[0] ? "and " : "";
			str.push(
				number < 21
					? words[number] +
					" " +
					digits[counter] +
					plural +
					" " +
					hundred
					: words[Math.floor(number / 10) * 10] +
					" " +
					words[number % 10] +
					" " +
					digits[counter] +
					plural +
					" " +
					hundred
			);
		} else {
			str.push("");
		}
	}
	str = str.reverse();
	result = str.join("");
	points = point && point !== "00"
		? words[parseInt(point / 10)] + " " + words[(point = point % 10)]
		: "";
	inWords = points
		? result + "Rupees and " + points + " Paisa Only."
		: result + "Rupees Only.";
	inWords = inWords.replace(/\s+/g, " ");

	return inWords;
}
