import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import { sessionInfo } from "../../global/function";
import fileDownload from "js-file-download";

import { isEmptyObject } from "../../global/function";
import { fetchLoginError, setNavigationPath } from "./auth-user.action";
import moment from "moment";

export const FETCH_EXPORT_IMPORT_REQUEST = "FETCH_EXPORT_IMPORT_REQUEST";
export const FETCH_EXPORT_IMPORT_SUCCESS = "FETCH_EXPORT_IMPORT_SUCCESS";
export const FETCH_EXPORT_IMPORT_ERROR = "FETCH_EXPORT_IMPORT_ERROR";

export const fetchExportImportRequest = () => {
	return {
		type: FETCH_EXPORT_IMPORT_REQUEST,
	};
};

export const fetchExportImportSuccess = () => {
	return {
		type: FETCH_EXPORT_IMPORT_SUCCESS,
	};
};

export const fetchExportImportError = () => {
	return {
		type: FETCH_EXPORT_IMPORT_ERROR,
	};
};


async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		await dispatch(setNavigationPath("/login"));
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchExportImportError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchExportImportError(error.message));
	}
}

export const exportFile = (tableName, filterValues = {}) => {
	return async (dispatch) => {
		dispatch(fetchExportImportRequest());
		const sessionData = await sessionInfo();

		let string = ""
		switch (tableName) {
			case "bill":
				let billFilter = {
					...filterValues,
					bill_void:
						filterValues?.bill_void !== ""
							? JSON.parse(filterValues?.bill_void)
							: "",
					date_from:
						filterValues?.fiscal_year === ""
							? filterValues?.date_from
							: "",
					date_to:
						filterValues?.fiscal_year === ""
							? filterValues?.date_to
							: "",
					customer_id: filterValues?.customer_id
						? `CUS_${filterValues?.customer_id}`
						: "",
				};
				string = `date_from=${billFilter.date_from}&date_to=${billFilter.date_to}&buyer_name=${billFilter.buyer_name}&buyer_pan=${billFilter.buyer_pan}&invoice_number=${billFilter.invoice_number}&total_sales_min=${billFilter.total_sales_min}&total_sales_max=${billFilter.total_sales_max}&fiscal_year=${billFilter.fiscal_year}&bill_void=${billFilter.bill_void}&customer_id=${billFilter.customer_id}`;
				break;
			case "customer":
				let customerFilter = {
					...filterValues,
					customer_id: filterValues?.customer_id
						? `CUS_${filterValues?.customer_id}`
						: "",
				};
				string = `customer_id=${customerFilter.customer_id}&customer_name=${customerFilter.customer_name}&customer_gender=${customerFilter.customer_gender}&customer_type=${customerFilter.customer_type}&customer_pan_vat=${customerFilter.customer_pan_vat}&customer_mobile=${customerFilter.customer_mobile}&customer_email=${customerFilter.customer_email}`;
				break;
			case "activity":
				string = `startMonth=${filterValues.startMonth}&endMonth=${filterValues.endMonth}`;
				break;
			case "user":
				string = "";
				break;
			default:
				//vat
				string = "";
				break;
		}

		return axios
			.get(`${strings.url}multer/file/download?tableName=${tableName}&${string}`, {
				responseType: "arraybuffer",
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				let filename;
				if (tableName === "activity") {
					filename = "preference";
				} else {
					filename = tableName;
				}
				fileDownload(res.data, `BillMe-${sessionData?.company_id}-${filename}_${moment().format("YYYY-MM-DD")}.xlsx`);
				showToastMessage("File Downloaded", "S");
				await dispatch(fetchExportImportSuccess());
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
