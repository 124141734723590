import React, { useMemo, useState } from "react";
import "./user-list-item.style.scss";

// components
import CustomerProfile from "../user-profile-sidemodal/customer-profile.component";
import EmployeeProfile from "../user-profile-sidemodal/employee-profile.component";

// global functions and strings
import { isEmptyObject } from "../../global/function";

function UserListItem({ user, type, currentPageNum, empPermission }) {
	const [isUserView, setIsUserView] = useState(false);

	const userData = useMemo(
		() => ({
			id: type === "employee" ? user?.user_id : user?.customer_id,
			img: type === "employee" ? user?.user_image : user?.customer_image,
			name:
				type === "employee"
					? `${user?.first_name} ${user?.last_name}`
					: user?.customer_name,
			mobile:
				type === "employee" ? user?.user_mobile : user?.customer_mobile,
			email:
				type === "employee" ? user?.user_email : user?.customer_email,
			verified: type === "employee" ? user?.user_verified : true,
		}),
		[user, type]
	);

	return (
		<>
			{!isEmptyObject(userData) && (
				<div className="user-item-container">
					<div className="item-header-container">
						<div
							className="user-id"
							onClick={() => {
								type === "employee"
									? navigator.clipboard.writeText(
											userData?.id
									  )
									: navigator.clipboard.writeText(
											userData?.id?.split("_")[1]
									  );
							}}
						>
							# {userData?.id}
						</div>
						<div className="item-img-container">
							<div className="item_img">
								{userData.img ? (
									<img
										crossOrigin="anonymous"
										src={userData.img}
										alt="user"
									/>
								) : (
									<i className="fa-solid fa-user fa-6x"></i>
								)}
							</div>

							<div
								className={`user-verified active ${
									userData.verified ? "active" : "inactive"
								}`}
							>
								{userData.verified ? (
									<i className="fa-solid fa-check"></i>
								) : (
									<i className="fa-solid fa-exclamation"></i>
								)}
							</div>
						</div>
					</div>

					<div className="item-details-container">
						{type === "employee" && (
							<div className="user-status-role">
								<div className="status-role-container">
									<p className="sr-label">Role</p>
									<h4 className="sr-value">
										{user?.user_role}
									</h4>
								</div>
								<div className="separator"></div>
								<div className="status-role-container">
									<p className="sr-label">Status</p>
									<h4
										className={`sr-value ${user?.user_status}`}
									>
										{user?.user_status}
									</h4>
								</div>
							</div>
						)}
						<div>
							<h4 className="item-mainText">{userData?.name}</h4>
							<div className="details-divider" />
						</div>
						<div style={{ flex: 1 }}>
							<div
								className="item-info copy"
								onClick={() => {
									navigator.clipboard.writeText(
										userData?.mobile
									);
								}}
							>
								<div className="info-icon a">
									<i className="fa-solid fa-phone"></i>
								</div>
								<div className="info-details">
									<p className="subText">Contact</p>
									<p className="mainText">
										{userData?.mobile}
									</p>
								</div>
							</div>
							{userData?.email && (
								<div
									className="item-info copy"
									onClick={() => {
										navigator.clipboard.writeText(
											userData?.email
										);
									}}
								>
									<div className="info-icon b">
										<i className="fa-solid fa-envelope"></i>
									</div>
									<div className="info-details">
										<p className="subText">Email</p>
										<p className="mainText">
											{userData?.email}
										</p>
									</div>
								</div>
							)}

							{type === "customer" && (
								<div className="item-info">
									<div className="info-icon c">
										<i className="fa-solid fa-font-awesome"></i>
									</div>
									<div className="info-details">
										<p className="subText">Customer Type</p>
										<p className="mainText capitalize">
											{user?.customer_type}
										</p>
									</div>
								</div>
							)}
						</div>
						<div
							className="item-action-btn"
							onClick={() => setIsUserView(true)}
						>
							Open Detailed View
							<i className="fa-solid fa-arrow-right"></i>
						</div>
					</div>

					{user?.isNew && <div className="new-flag">New</div>}
				</div>
			)}
			{type === "customer" && isUserView && (
				<CustomerProfile
					isCustomerVisible={isUserView}
					onCustomerModalClose={() => setIsUserView(false)}
					customer={user}
					currentPageNum={currentPageNum}
					onSelectCustomer={() => {}}
				/>
			)}
			{type === "employee" && isUserView && (
				<EmployeeProfile
					isEmployeeModalVisible={isUserView}
					onEmployeeModalClose={() => setIsUserView(false)}
					employee={user}
					empPermission={empPermission}
					currentPageNum={currentPageNum}
				/>
			)}
		</>
	);
}

export default UserListItem;
