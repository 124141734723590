import * as actionTypes from "../actions/auth-user.action";

const initial_state = {
	isLoading: false,
	loginDetails: {},
	error: "",
	nav_pathname: "",
};

export default function authUserReducer(state = initial_state, action) {
	switch (action.type) {
		case actionTypes.FETCH_LOGIN_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case actionTypes.FETCH_LOGIN_SUCCESS:
			return {
				...state,
				isLoading: false,
				loginDetails: action.payload,
				error: "",
			};
		case actionTypes.FETCH_LOGIN_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		case actionTypes.FETCH_LOGOUT:
			localStorage.clear();
			return {
				...state,
				isLoading: false,
				loginDetails: {},
			};
		case actionTypes.FETCH_RESET_PASSWORD_SUCCESS:
		case actionTypes.FETCH_FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case actionTypes.SET_LOGIN_INFO:
			localStorage.setItem("isLoggedIn", true);
			let updatedLoginInfo = { ...state.loginDetails, company_verified: action.payload }
			localStorage.setItem("loginInfo", JSON.stringify(updatedLoginInfo));
			return {
				...state,
				loginDetails: updatedLoginInfo,
			}


		case actionTypes.SET_NAVIGATION_PATH:
			return {
				...state,
				nav_pathname: action.payload,
			}

		default:
			return state;
	}
}
