import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import { sessionInfo } from "../../global/function";

import { isEmptyObject } from "../../global/function";
import { fetchLoginError, setNavigationPath } from "./auth-user.action";

export const FETCH_CUSTOMER_REQUEST = "FETCH_CUSTOMER_REQUEST";
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";
export const FETCH_SINGLE_CUSTOMER_SUCCESS = "FETCH_SINGLE_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_ERROR = "FETCH_CUSTOMER_ERROR";

export const SET_CUSTOMERS_LIST = "SET_CUSTOMERS_LIST";

export const fetchCustomerRequest = () => {
	return {
		type: FETCH_CUSTOMER_REQUEST,
	};
};

export const fetchCustomerSuccess = (customers) => {
	return {
		type: FETCH_CUSTOMER_SUCCESS,
		payload: customers,
	};
};

export const fetchSingleCustomerSuccess = (customer) => {
	return {
		type: FETCH_SINGLE_CUSTOMER_SUCCESS,
		payload: customer,
	};
};

export const fetchCustomerError = (error) => {
	return {
		type: FETCH_CUSTOMER_ERROR,
		payload: error,
	};
};

export const setCustomersList = (customer) => {
	return {
		type: SET_CUSTOMERS_LIST,
		payload: customer,
	}
}

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		await dispatch(setNavigationPath("/login"));
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchCustomerError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchCustomerError(error.message));
	}
}

export const fetchCompanyCustomers = (limit, page, activityFlag = false) => {
	return async (dispatch) => {
		dispatch(fetchCustomerRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}customer/company?limit=${limit}&page=${page}&activityFlag=${activityFlag}`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCustomerSuccess(res.data.result.rows));
					return res.data.result.count;
				} else {
					await dispatch(fetchCustomerError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const fetchCustomerById = (customerInfoId, limit = 5) => {
	return async (dispatch) => {
		dispatch(fetchCustomerRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}customer/id?customer_info_id=${customerInfoId}&limit=${limit}`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchSingleCustomerSuccess(res.data.result));
				} else {
					await dispatch(fetchCustomerError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const addCustomer = (customerData) => {
	return async (dispatch) => {
		dispatch(fetchCustomerRequest());
		let sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}customer/add`, customerData, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					showToastMessage("Success! Customer added successfully", "S");
				} else {
					await dispatch(fetchCustomerError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
				return res.data; // require success and added data
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updateCustomer = (customerData, page = 1, activityFlag = false) => {
	return async (dispatch) => {
		dispatch(fetchCustomerRequest());
		let sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}customer/update?limit=${10}&page=${page}&activityFlag=${activityFlag}`, customerData, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCustomerSuccess(res.data.result.rows));
					showToastMessage("Success! Customer updated successfully", "S");
				} else {
					await dispatch(fetchCustomerError(res.data.message));

					showToastMessage(res.data.message, "E");
				}
				return res.data.success;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const searchCustomers = (searchData, limit, page, activityFlag = false) => {
	return async (dispatch) => {
		dispatch(fetchCustomerRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}customer/search?company_info_id=${sessionData.company_info_id}&customer_id=${searchData?.customer_id || ""}&customer_pan_vat=${searchData?.customer_pan_vat || ""}&customer_name=${searchData?.customer_name || ""}&customer_email=${searchData?.customer_email || ""}&customer_mobile=${searchData?.customer_mobile || ""}&customer_type=${searchData?.customer_type || ""}&customer_gender=${searchData?.customer_gender || ""}&customer_tag=${searchData?.customer_tag || ""}&limit=${limit || ""}&page=${page || ""}&activityFlag=${activityFlag}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCustomerSuccess(res.data.result.rows));
					return res.data.result.count;
				} else {
					await dispatch(fetchCustomerError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const getAllFilteredClients = (searchData) => {
	return async (dispatch) => {
		dispatch(fetchCustomerRequest());
		let sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}customer/search?company_info_id=${sessionData.company_info_id}&customer_id=${searchData?.customer_id || ""}&customer_pan_vat=${searchData?.customer_pan_vat || ""}&customer_name=${searchData?.customer_name || ""}&customer_email=${searchData?.customer_email || ""}&customer_mobile=${searchData?.customer_mobile || ""}&customer_type=${searchData?.customer_type || ""}&customer_gender=${searchData?.customer_gender || ""}&customer_tag=${searchData?.customer_tag || ""}&activityFlag=${false}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					return res.data.result.rows;
				} else {
					await dispatch(fetchCustomerError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
