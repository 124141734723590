import React from "react";
import "./pagination.style.scss";

import ReactPaginate from "react-paginate";

function Pagination({ totalPages, onPageClick, forcePage = 0 }) {
	return (
		<ReactPaginate
			breakLabel="..."
			nextLabel="next&nbsp;>"
			onPageChange={onPageClick}
			pageRangeDisplayed={3}
			pageCount={totalPages}
			previousLabel="<&nbsp;prev"
			marginPagesDisplayed={2} // Number of margin pages to display
			containerClassName={"pagination"}
			activeClassName={"active"}
			pageClassName={"pageLink"}
			breakClassName={"pageLink"}
			previousClassName={"navLink"}
			nextClassName={"navLink"}
			forcePage={forcePage}
		/>
	);
}

export default Pagination;
