import React, { useEffect, useMemo, useState, useRef, memo } from "react";
import { useNavigate } from "react-router-dom";
import "./user-profile-sidemodal.style.scss";

// for filter form handle
import { useFormik } from "formik";
import * as yup from "yup";

// for date purpose
import { adToBs } from "@sbmdkl/nepali-date-converter";
import moment from "moment";

// components
import SideModal from "../side-modal/side-modal.component";
import SelectBox from "../form-inputs/select-box.component";
import MultipleTagsInput from "../form-inputs/multiple-tags-input.component";

// global strings and functions
import strings from "../../global/strings";
import {
	isEmptyObject,
	nepaliDateMMMM,
	rupeeConverter,
} from "../../global/function";
import showToastMessage from "../../global/showToastMessage";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
	addCustomer,
	fetchCustomerById,
	setCustomersList,
	updateCustomer,
} from "../../redux/actions/customer.action";
import { uploadImage } from "../../redux/actions/image-upload.action";

const customerSchema = yup.object().shape({
	customer_name: yup
		.string()
		.nullable()
		.required("Customer Name is required"),
	customer_mobile: yup
		.string()
		.matches(/^\d{7,10}$/, "Must be a valid Phone number")
		.nullable()
		.required("Customer Mobile is required"),
	customer_type: yup.string().nullable(),
	customer_email: yup
		.string()
		.email("Please enter valid email")
		.nullable()
		.required("Customer Email is required"),
	customer_pan_vat: yup
		.string()
		.nullable()
		.when("customer_type", {
			is: (value) => value === "business",
			then: () =>
				yup
					.string()
					.matches(/^[0-9]*$/, "Please enter a valid number")
					.required("PAN / VAT number required"),
		}),
});

function CustomerProfile({
	isCustomerVisible,
	onCustomerModalClose,
	customer,
	currentPageNum,
	onSelectCustomer,
}) {
	const { customerDetails, isLoading } = useSelector(
		(state) => state.customerReducer
	);
	const [clientEditable, setClientEditable] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);

	// customer tags to store temporarily
	const [tags, setTags] = useState([]);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const fileInputRef = useRef(null);

	function convertTagToString(array) {
		const string = "#" + array.join("#");
		return string;
	}

	function convertTagToArray(string) {
		const array = string?.split("#").filter(Boolean);
		return array;
	}

	const formik = useFormik({
		validationSchema: customerSchema,
		initialValues: {
			customer_pan_vat: "",
			customer_name: "",
			customer_email: "",
			customer_mobile: "",
			customer_gender: "",
			customer_type: "",
			customer_city: "",
			customer_address: "",
			customer_tag: "",
		},
		onSubmit: async (customerData) => {
			if (customerData.customer_type === "business") {
				customerData["customer_gender"] = "other";
			}
			customerData["customer_gender"] =
				customerData.customer_gender === ""
					? null
					: customerData.customer_gender;
			customerData["customer_type"] =
				customerData.customer_type === ""
					? "individual"
					: customerData.customer_type;
			customerData["customer_pan_vat"] =
				customerData.customer_pan_vat === ""
					? null
					: customerData.customer_pan_vat;
			// function called to convert tags array to string
			customerData["customer_tag"] =
				Array.isArray(tags) && tags.length > 0
					? convertTagToString(tags)
					: "";
			delete customerData.customer_image;

			if (customerData?.company_customer_id === "") {
				delete customerData.customer_id;
				delete customerData.customer_info_id;
				delete customerData.company_customer_id;
				delete customerData.company_info_id;

				const response = await dispatch(addCustomer(customerData));

				if (response?.success) {
					let uploadResponse;
					if (selectedImage) {
						let data = {
							image: selectedImage,
							id: response.result.company_customer_id,
							type: "companyCustomer",
						};
						uploadResponse = await dispatch(uploadImage(data));
					}
					let newCustomer = {
						...response.result,
						customer_image:
							uploadResponse?.result?.customer_image || null,
						isNew: true,
					};
					await dispatch(setCustomersList(newCustomer));
					onSelectCustomer(newCustomer);
				}
				setClientEditable(false);
				onCustomerModalClose();
			} else {
				let uploadResponse,
					uploadResult = true;
				if (selectedImage) {
					let data = {
						image: selectedImage,
						id: customerData.company_customer_id,
						type: "companyCustomer",
					};
					uploadResponse = await dispatch(uploadImage(data));
					uploadResult = uploadResponse?.success;
				}
				if (uploadResult) {
					const editResult = await dispatch(
						updateCustomer(customerData, currentPageNum)
					);
					if (editResult) {
						onSelectCustomer({
							...customerData,
							customer_image:
								uploadResponse?.result?.customer_image || null,
						});
						setClientEditable(false);
					}
				}
			}
		},
	});

	const handleEditCancel = () => {
		setClientEditable(false);
		setSelectedImage(null);
		customer.company_customer_id === "" && onCustomerModalClose();
	};

	useEffect(() => {
		const getCustomerDetail = async () => {
			if (
				!isEmptyObject(customer) &&
				customer.company_customer_id !== "" &&
				customer.customer_info_id !== ""
			) {
				await dispatch(fetchCustomerById(customer?.customer_info_id));
			}
		};
		getCustomerDetail();
	}, [dispatch, customer]);

	useEffect(() => {
		if (!isEmptyObject(customer)) {
			customer.company_customer_id === "" && setClientEditable(true);
			// set tag state to display tags
			customer.customer_tag &&
				setTags(convertTagToArray(customer.customer_tag));
			formik.setValues({
				...formik.initialValues,
				...customer,
			});
		}
		// eslint-disable-next-line
	}, []);

	const _renderNepaliInvDate = (date) => {
		const nepDate = adToBs(moment(date).format("YYYY-MM-DD"));
		const nepInvDate = `${nepaliDateMMMM(nepDate).split(" ")[0]} ${
			nepaliDateMMMM(nepDate).split(" ")[1]
		}, ${nepaliDateMMMM(nepDate).split(" ")[2]}`;
		return nepInvDate;
	};
	const _renderEnglishInvDate = (date) => {
		const enInvDate = `${
			moment(date).format("DD") +
			" " +
			moment(date).format("MMMM") +
			", " +
			moment(date).format("YYYY")
		}`;
		return enInvDate;
	};

	function handleTagAdd(e) {
		// Get the value of the input
		const value = e.target.value;
		// If the value is empty, return
		if (!value.trim()) return;
		// cleaning up all hash values
		let newValue = value?.split("#").filter(Boolean);

		// Convert the arrays to Sets to remove duplicates
		const set1 = new Set(tags);
		const set2 = new Set(newValue);

		// Merge the Sets
		const mergedSet = new Set([...set1, ...set2]);

		// Convert the merged Set back to an array
		const resultArray = Array.from(mergedSet);

		// Add the value to the tags array
		setTags(resultArray);
		// Clear the input
		e.target.value = "";
	}

	function handleKeyDown(e) {
		// If user did not press enter key, return
		if (e.key !== "Enter") return;
		handleTagAdd(e);
	}

	function handleTagRemove(index) {
		setTags(tags.filter((el, i) => i !== index));
	}

	function handleViewAllBills(customerId) {
		navigate("/invoice/bills", { state: { customerId } });
	}

	const _renderClientInfo = () => {
		return (
			<div className="user-info-container">
				<h2 className="section-header">Customer Tags</h2>
				<MultipleTagsInput
					label="Customer Tags"
					name="customer_tag"
					onKeyDown={handleKeyDown}
					onBlur={handleTagAdd}
					onTagRemove={handleTagRemove}
					tagsArray={tags}
					autoComplete="off"
					disabled={!clientEditable}
				/>
				<h2 className="section-header">Customer Details</h2>
				<div className="input-wrapper">
					<label className="input-label">Customer Name</label>
					<input
						name="customer_name"
						className="input-box-wrapper"
						type="text"
						placeholder="Enter Customer Name"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values?.customer_name || ""}
						disabled={!clientEditable}
					/>
					{formik.submitCount > 0 && (
						<div className="validation-error">
							{formik?.errors?.customer_name}
						</div>
					)}
				</div>
				<div className="input-row-wrapper">
					<div className="input-wrapper">
						<label className="input-label">Mobile Number</label>
						<input
							name="customer_mobile"
							className="input-box-wrapper"
							type="tel"
							placeholder="Enter Mobile Number"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values?.customer_mobile || ""}
							disabled={!clientEditable}
						/>
						{formik.submitCount > 0 && (
							<div className="validation-error">
								{formik?.errors?.customer_mobile}
							</div>
						)}
					</div>
					<div className="input-wrapper">
						<label className="input-label">Customer Email</label>
						<input
							name="customer_email"
							className="input-box-wrapper"
							type="text"
							placeholder="Enter Email"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values?.customer_email || ""}
							disabled={!clientEditable}
						/>
						{formik.submitCount > 0 && (
							<div className="validation-error">
								{formik?.errors?.customer_email}
							</div>
						)}
					</div>
				</div>
				<div className="input-row-wrapper">
					<div className="input-wrapper">
						<label className="input-label">Customer Type</label>
						<SelectBox
							name="customer_type"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values?.customer_type || ""}
							options={strings.customerType}
							disabled={!clientEditable}
						/>
						{formik.submitCount > 0 && (
							<div className="validation-error">
								{formik?.errors?.customer_type}
							</div>
						)}
					</div>
					<div className="input-wrapper">
						<label className="input-label">Customer Gender</label>
						<SelectBox
							name="customer_gender"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values?.customer_gender || ""}
							options={strings.gender}
							disabled={
								!clientEditable ||
								formik.values.customer_type === "business"
							}
						/>
						{formik.submitCount > 0 && (
							<div className="validation-error">
								{formik?.errors?.customer_gender}
							</div>
						)}
					</div>
				</div>
				<div className="input-wrapper">
					<label className="input-label">Customer PAN / VAT</label>
					<input
						name="customer_pan_vat"
						className="input-box-wrapper"
						type="text"
						placeholder="# Enter Pan / VAT"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values?.customer_pan_vat || ""}
						disabled={!clientEditable}
					/>
					{formik.submitCount > 0 && (
						<div className="validation-error">
							{formik?.errors?.customer_pan_vat}
						</div>
					)}
				</div>
				<div className="input-wrapper">
					<label className="input-label">Customer Address</label>
					<input
						name="customer_address"
						className="input-box-wrapper"
						type="text"
						placeholder="Enter Address"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values?.customer_address || ""}
						disabled={!clientEditable}
					/>
					{formik.submitCount > 0 && (
						<div className="validation-error">
							{formik?.errors?.customer_address}
						</div>
					)}
				</div>
				<div className="input-row-wrapper">
					<div className="input-wrapper">
						<label className="input-label">City</label>
						<SelectBox
							name="customer_city"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values?.customer_city || ""}
							options={strings.city}
							disabled={!clientEditable}
						/>
						{formik.submitCount > 0 && (
							<div className="validation-error">
								{formik?.errors?.customer_city}
							</div>
						)}
					</div>
					<div className="input-wrapper">
						<label className="input-label">Country</label>
						<div
							className={`input-box-wrapper ${
								!clientEditable && "disabled"
							}`}
						>
							Nepal
						</div>
					</div>
				</div>
			</div>
		);
	};

	const _renderClientBills = () => {
		if (isLoading) {
			return <div>Loading....</div>;
		} else {
			if (
				!isEmptyObject(customerDetails) &&
				Array.isArray(customerDetails?.bills) &&
				customerDetails?.bills?.length > 0
			) {
				return (
					<div className="user-bills-container">
						<div className="section-header-row">
							<h2 className="section-header">Recent Bills</h2>
							<div
								className="section-header-btn"
								onClick={() =>
									handleViewAllBills(
										customerDetails?.customer_id
									)
								}
							>
								View All
							</div>
						</div>
						<div className="bills-table-container">
							<div className="table-grid-container header">
								<div className="table-item-text align-left">
									<b>Invoice #</b>
								</div>
								<div className="table-item-text">
									<b>Invoice Date</b>
								</div>
								<div className="table-item-text align-right">
									<b>Amount</b>
								</div>
							</div>
							{customerDetails?.bills?.map((bill, i) => (
								<div key={i} className="table-grid-container">
									<div
										className="table-item-text align-left copy"
										onClick={() =>
											navigator.clipboard.writeText(
												bill?.invoice_number?.split(
													"_"
												)[2]
											)
										}
									>
										{bill?.invoice_number}
									</div>
									<div className="table-item-text">
										{_renderEnglishInvDate(
											bill?.invoice_date
										)}
										<span>
											{_renderNepaliInvDate(
												bill?.invoice_date
											)}
										</span>
									</div>
									<div className="table-item-text align-right">
										<div
											className={`bill-price ${
												bill?.bill_void && "void"
											}`}
										>
											<b>Rs. </b>
											{rupeeConverter(bill?.total_sales)}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				);
			}
		}
	};

	const handleImageEdit = () => {
		if (clientEditable) {
			// Trigger the hidden file input element
			fileInputRef.current.click();
		}
	};

	const handleImageChange = async (e) => {
		const selectedFile = e.target.files[0];
		// Check the file size
		if (selectedFile && selectedFile.size <= 2048576) {
			setSelectedImage(selectedFile);
		} else {
			// Handle the case where the file size exceeds the limit
			showToastMessage("File size exceeds the limit", "I");
		}
	};

	const imgSrc = useMemo(() => {
		if (selectedImage) {
			return URL.createObjectURL(selectedImage);
		} else if (!isEmptyObject(customer) && customer.customer_image) {
			return customer.customer_image;
		} else {
			return "";
		}
	}, [customer, selectedImage]);

	return (
		<SideModal
			isVisible={isCustomerVisible}
			onModalClose={onCustomerModalClose}
			containerStyle={{ minWidth: "320px", maxWidth: "380px" }}
		>
			<div className="user-modal-container">
				<div className="modal-header">
					<div
						className="modal-action"
						onClick={onCustomerModalClose}
					>
						<i className="fa-solid fa-arrow-left"></i> Back
					</div>
					<div className="action-container">
						{clientEditable ? (
							<>
								<div
									className="action-btn inverted"
									onClick={handleEditCancel}
								>
									<i className="fa-solid fa-eraser"></i>
									Cancel
								</div>
								<div
									className="action-btn"
									type="submit"
									onClick={formik.submitForm}
								>
									<i className="fa-regular fa-floppy-disk"></i>
									Save
								</div>
							</>
						) : (
							<div
								className="action-btn"
								onClick={() => setClientEditable(true)}
							>
								<i className="fa-regular fa-pen-to-square"></i>
								Edit
							</div>
						)}
					</div>
				</div>
				<div className="modal-body">
					{customer?.customer_id && (
						<div className="user-id"># {customer?.customer_id}</div>
					)}
					<div
						className={`user-img-container ${
							clientEditable && "editable"
						}`}
						onClick={handleImageEdit}
					>
						<div className="item_img">
							{imgSrc ? (
								<img
									crossOrigin="anonymous"
									src={imgSrc}
									alt="client"
								/>
							) : (
								<i className="fa-solid fa-user fa-6x"></i>
							)}
						</div>
						{clientEditable ? (
							<div className="user-img-action">
								<i className="fa-regular fa-pen-to-square"></i>
							</div>
						) : (
							<div className="user-img-action active">
								<i className="fa-solid fa-check"></i>
							</div>
						)}
					</div>
					<input
						type="file"
						accept="image/*"
						ref={fileInputRef}
						style={{ display: "none" }}
						onChange={handleImageChange}
					/>
					<div className="divider" />
					{_renderClientInfo()}
					<div className="divider" />
					{customer.company_customer_id !== "" &&
						_renderClientBills()}
				</div>
			</div>
		</SideModal>
	);
}

export default memo(CustomerProfile);
