import React, { useCallback, useEffect, useState } from "react";
import "./customer-bulk-draft.style.scss";
import "../invoice-form/invoice-form.style.scss";
import { useNavigate } from "react-router-dom";

// form handle
import { useFormik } from "formik";
import * as yup from "yup";

// date purpose
import moment from "moment";

// global functions and strings
import { isEmptyObject, rupeeConverter } from "../../global/function";
import strings from "../../global/strings";

// components
import EnNpDatePicker from "../form-inputs/en-np-datePicker.component";
import SelectBox from "../form-inputs/select-box.component";
import InvoiceItemsFooter from "../invoice-form/invoice-items-footer.component";
import EmptyRecord from "../empty-record/empty-record.component";

// redux
import { useDispatch, useSelector } from "react-redux";
import { saveDraft } from "../../redux/actions/drafts.action";
// import { getBillDates } from "../../redux/actions/bill.action";
import { getAllFilteredClients } from "../../redux/actions/customer.action";

function CustomerBulkDraft({ onDraftCreateModalClose, filterValues }) {
	// const { lastBillDate } = useSelector((state) => state.billReducer);
	const { customersList } = useSelector((state) => state.customerReducer);
	const { isLoading } = useSelector((state) => state.draftsReducer);
	const { preferenceDetails } = useSelector((state) => state.companyReducer);

	const [filteredCustomers, setFilteredCustomers] = useState([]);
	const [clientsToAddDraft, setClientsToAddDraft] = useState({});

	// bill footer form state
	const [isServiceChargeEnabled, setIsServiceChargeEnabled] = useState(false);
	const [subTotal, setSubTotal] = useState(0);
	const [hideDraftDetails, setHideDraftDetails] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	// fetch last bill date
	// useEffect(() => {
	// 	const fetchLastBillDate = async () => {
	// 		await dispatch(getBillDates());
	// 	};
	// 	fetchLastBillDate();
	// }, [dispatch]);

	// fetch filtered clients
	useEffect(() => {
		if (!isEmptyObject(filterValues)) {
			const fetchFilteredClients = async () => {
				setFilteredCustomers(
					await dispatch(getAllFilteredClients(filterValues))
				);
			};
			fetchFilteredClients();
		} else {
			setFilteredCustomers(customersList);
		}
	}, [customersList, dispatch, filterValues]);

	// bill footer function start

	const customerDraftSchema = () => {
		return yup.object().shape({
			// invoice_date: yup
			// 	.string()
			// 	.required("Invoice Date is required")
			// 	.test(
			// 		"is-later-than-last-bill-date",
			// 		"Date must be later than the last bill date",
			// 		function (value) {
			// 			if (lastBillDate !== "") {
			// 				const draft_date = new Date(value);
			// 				const last_bill_date = new Date(lastBillDate);

			// 				if (draft_date >= last_bill_date) {
			// 					return true; // Date is later than the global date
			// 				} else {
			// 					return false; // Date is not later than the global date
			// 				}
			// 			} else {
			// 				return true;
			// 			}
			// 		}
			// 	)
			// 	.test(
			// 		"is-not-more-than-1-month-later",
			// 		"Date should not exceed 1 month from today",
			// 		function (value) {
			// 			const draft_date = new Date(value);
			// 			const currentDate = new Date();
			// 			const maxDate = new Date();
			// 			maxDate.setMonth(currentDate.getMonth() + 1); // Set max date to 1 month later

			// 			if (draft_date <= maxDate) {
			// 				return true; // Date is valid
			// 			}

			// 			return false; // Date exceeds 1 month from today
			// 		}
			// 	),
			payment_mode: yup
				.string()
				.nullable()
				.required("Choose the payment method"),
			total_sales: yup
				.number()
				.typeError("Must be a number")
				.required("Required"),
			billed_items: yup.array().of(
				yup.object().shape({
					count: yup
						.number()
						.typeError("Must be a number")
						.nullable(),
					item_name: yup
						.string()
						.nullable()
						.max(50, "Item name must be at most 50 characters")
						.required("Required"),
					item_price: yup
						.number()
						.typeError("Must be a number")
						.nullable()
						.required("Required")
						.positive("Required"),
					item_qty: yup
						.number()
						.typeError("Must be a number")
						.nullable()
						.required("Required")
						.positive("Required"),
					total_price: yup
						.number()
						.typeError("Must be a number")
						.nullable(),
				})
			),
		});
	};

	const formik = useFormik({
		validationSchema: customerDraftSchema,
		initialValues: {
			invoice_date: moment().format("YYYY-MM-DD"),
			payment_mode: "",
			total_sales: 0,
			taxable_total_sales: 0,
			non_taxable_total_sales: 0,
			taxable_sales_vat: 0,
			non_taxable_amt: 0,
			vat: 0,
			grand_total: 0,
			taxable_service_charge: "",
			non_taxable_service_charge: "",
			taxable_discount: "",
			non_taxable_discount: "",
			billed_items: [
				{
					hs_code: "",
					item_name: "",
					item_price: 0,
					item_qty: 0,
					total_price: 0,
					vatable: preferenceDetails?.vat,
				},
			],
			sale_info: "",
		},
		onSubmit: async (values, { isSubmitting }) => {
			if (!isSubmitting) {
				setHideDraftDetails(true);
			}
		},
	});

	// total calculation with all dependancies
	// taxableTotalSales parameter is current taxable_total_sales value
	// nonTaxableTotalSales parameter is current non_taxable_total_sales value
	// taxableDiscount parameter is current taxableDiscount value
	// nonTaxableDiscount parameter is current nonTaxableDiscount value
	// includeService parameter is service charge toggler(true/false)
	// taxableServiceCharge parameter is taxable_service_charge value that had been overridden
	// nonTaxableServiceCharge parameter is non_taxable_service_charge value that had been overridden
	const calculateWithTax = useCallback(
		(
			taxableTotalSales,
			nonTaxableTotalSales,
			taxableDiscount,
			nonTaxableDiscount,
			includeService,
			taxableServiceCharge = 0,
			nonTaxableServiceCharge = 0
		) => {
			let newTaxableServiceCharge = 0,
				newNonTaxableServiceCharge = 0,
				newTaxableGrossTotal = 0,
				newNonTaxableGrossTotal = 0;

			if (taxableServiceCharge <= 0 || nonTaxableServiceCharge <= 0) {
				if (includeService) {
					newTaxableServiceCharge =
						(taxableTotalSales - taxableDiscount) *
						(preferenceDetails?.service_charge / 100);
					newNonTaxableServiceCharge =
						(nonTaxableTotalSales - nonTaxableDiscount) *
						(preferenceDetails?.service_charge / 100);
				} else {
					newTaxableServiceCharge = 0;
					newNonTaxableServiceCharge = 0;
				}
			}
			if (taxableServiceCharge > 0) {
				newTaxableServiceCharge = parseFloat(taxableServiceCharge || 0);
			}
			if (nonTaxableServiceCharge > 0) {
				newNonTaxableServiceCharge = parseFloat(
					nonTaxableServiceCharge || 0
				);
			}

			newTaxableGrossTotal =
				taxableTotalSales - taxableDiscount + newTaxableServiceCharge;
			newNonTaxableGrossTotal =
				nonTaxableTotalSales -
				nonTaxableDiscount +
				newNonTaxableServiceCharge;

			formik.setFieldValue(
				"taxable_service_charge",
				newTaxableServiceCharge.toFixed(2)
			);
			formik.setFieldValue(
				"non_taxable_service_charge",
				newNonTaxableServiceCharge.toFixed(2)
			);

			let eat,
				vat = 0;

			formik.setFieldValue("non_taxable_amt", newNonTaxableGrossTotal);
			formik.setFieldValue("taxable_sales_vat", newTaxableGrossTotal);

			if (newTaxableGrossTotal > 0) {
				vat = 0.13 * newTaxableGrossTotal;
			} else {
				vat = 0;
			}

			eat = newNonTaxableGrossTotal + newTaxableGrossTotal + vat;

			formik.setFieldValue("vat", vat);
			formik.setFieldValue("grand_total", eat);
		},
		// eslint-disable-next-line
		[preferenceDetails?.service_charge, subTotal]
	);

	// useEffect to rerender changes in total computation when billed_item changed due to which subToatal changes
	useEffect(() => {
		calculateWithTax(
			formik.values?.taxable_total_sales,
			formik.values?.non_taxable_total_sales,
			formik.values?.taxable_discount,
			formik.values?.non_taxable_discount,
			isServiceChargeEnabled,
			parseFloat(formik.values?.taxable_service_charge || 0),
			parseFloat(formik.values?.non_taxable_service_charge || 0)
		);
		// eslint-disable-next-line
	}, [calculateWithTax]);

	const handleInvoiceDateChange = (fieldName, fieldValue) => {
		formik.setFieldValue(fieldName, fieldValue);
	};

	// track changes to billed_items, it is an array so last update was not pushed so manually pushed item value
	const handleBilledItemsChange = (fieldName, index, itemName, newValue) => {
		formik.setFieldValue(fieldName, newValue);

		let billedItems = JSON.parse(
			JSON.stringify(formik.values.billed_items)
		); // due to issue of shallow copy

		billedItems[index][itemName] = newValue;

		billedItems[index].total_price =
			billedItems[index].item_price * billedItems[index].item_qty;

		formik.setFieldValue(
			`billed_items[${index}].total_price`,
			billedItems[index].total_price
		);
	};

	const computedSubTotal = useCallback(() => {
		const { taxableSalesTotal, nonTaxableSalesTotal } =
			formik.values?.billed_items?.reduce(
				(acc, item) => {
					if (item.vatable) {
						acc.taxableSalesTotal += parseFloat(
							item?.total_price || 0
						);
					} else {
						acc.nonTaxableSalesTotal += parseFloat(
							item?.total_price || 0
						);
					}
					return acc;
				},
				{ taxableSalesTotal: 0, nonTaxableSalesTotal: 0 }
			);

		const totalSales = taxableSalesTotal + nonTaxableSalesTotal;

		formik.setFieldValue("non_taxable_total_sales", nonTaxableSalesTotal);
		formik.setFieldValue("taxable_total_sales", taxableSalesTotal);
		formik.setFieldValue("total_sales", totalSales);

		if (nonTaxableSalesTotal > 0 && taxableSalesTotal > 0) {
			setSubTotal({ totalSales, mixedTax: true });
		} else {
			setSubTotal({
				totalSales,
				mixedTax: false,
			});
		}
	}, [formik.values?.billed_items]);

	useEffect(() => {
		if (
			Array.isArray(formik.values?.billed_items) &&
			formik.values?.billed_items.length > 0
		) {
			computedSubTotal();
		}
	}, [formik.values?.billed_items, computedSubTotal]);

	// track changes in discount and service charge, also trigger total computation
	const handleDiscountServiceChange = (fieldName, fieldValue) => {
		let taxableDiscount = 0,
			nonTaxableDiscount = 0,
			taxableServiceCharge = 0,
			nonTaxableServiceCharge = 0;

		switch (fieldName) {
			case "non_taxable_discount":
				nonTaxableDiscount = fieldValue;
				calculateWithTax(
					formik.values?.taxable_total_sales,
					formik.values?.non_taxable_total_sales,
					formik.values?.taxable_discount,
					nonTaxableDiscount,
					isServiceChargeEnabled,
					parseFloat(formik.values?.taxable_service_charge),
					0
				);
				break;
			case "taxable_discount":
				taxableDiscount = fieldValue;
				calculateWithTax(
					formik.values?.taxable_total_sales,
					formik.values?.non_taxable_total_sales,
					taxableDiscount,
					formik.values?.non_taxable_discount,
					isServiceChargeEnabled,
					0,
					parseFloat(formik.values?.non_taxable_service_charge)
				);
				break;
			case "non_taxable_service_charge":
				nonTaxableServiceCharge = fieldValue;
				calculateWithTax(
					formik.values?.taxable_total_sales,
					formik.values?.non_taxable_total_sales,
					formik.values?.taxable_discount,
					formik.values?.non_taxable_discount,
					isServiceChargeEnabled,
					parseFloat(formik.values?.taxable_service_charge),
					parseFloat(nonTaxableServiceCharge)
				);
				break;
			case "taxable_service_charge":
				taxableServiceCharge = fieldValue;
				calculateWithTax(
					formik.values?.taxable_total_sales,
					formik.values?.non_taxable_total_sales,
					formik.values?.taxable_discount,
					formik.values?.non_taxable_discount,
					isServiceChargeEnabled,
					parseFloat(taxableServiceCharge),
					parseFloat(formik.values?.non_taxable_service_charge)
				);
				break;
			default:
				break;
		}

		formik.setFieldValue(fieldName, fieldValue);
	};

	const handleServiceChargeToggle = (e) => {
		setIsServiceChargeEnabled(e.target.checked);
		let taxableServiceCharge = e.target.checked
			? formik.values?.taxable_service_charge
			: 0;
		let nonTaxableServiceCharge = e.target.checked
			? formik.values?.non_taxable_service_charge
			: 0;

		calculateWithTax(
			formik.values?.taxable_total_sales,
			formik.values?.non_taxable_total_sales,
			formik.values?.taxable_discount,
			formik.values?.non_taxable_discount,
			e.target.checked,
			parseFloat(taxableServiceCharge),
			parseFloat(nonTaxableServiceCharge)
		);
	};

	// bill footer function end

	const handleClientSelection = (customer, index) => {
		let state = { ...clientsToAddDraft };
		if (state[index]) {
			delete state[index];
		} else {
			state[index] = customer;
		}
		setClientsToAddDraft(state);
	};

	const handleToggleAll = () => {
		let objState;
		if (
			Object.keys(clientsToAddDraft).length === filteredCustomers.length
		) {
			objState = {};
		} else {
			objState = filteredCustomers.reduce((acc, value, index) => {
				acc[index] = value;
				return acc;
			}, {});
		}
		setClientsToAddDraft(objState);
	};

	const handleDraftsCreate = async () => {
		if (!isLoading) {
			const formError = await formik.validateForm(); // Validate the form
			if (isEmptyObject(formError) && !isEmptyObject(clientsToAddDraft)) {
				let draftArray = Object.values(clientsToAddDraft);
				draftArray = draftArray.map((item) => {
					let draftObj = {
						...item,
						bill_for: item?.customer_tag || "",
						draft_valid: true,
						buyer_pan: item?.customer_pan_vat?.toString() || null,
						buyer_name: item?.customer_name,
						...formik.values,
						discount: parseFloat(formik.values?.discount || 0),
						service_charge: parseFloat(
							formik.values?.service_charge || 0
						),
					};
					delete draftObj.createdAt;
					delete draftObj.updatedAt;

					return draftObj;
				});

				let saveDrafts = {
					drafts: draftArray,
					draft_bill_status: "draft",
				};
				await dispatch(saveDraft(saveDrafts));
				navigate("/invoice/create");
			} else {
				formik.submitForm();
				setHideDraftDetails(false);
			}
		}
	};

	return (
		<div className="clients-draft-container">
			<div className="modal-header">
				<div className="modal-action" onClick={onDraftCreateModalClose}>
					<i className="fa-solid fa-arrow-left"></i> Back
				</div>
				<div className="action-container">
					<div
						className="action-btn"
						onClick={handleDraftsCreate}
						disabled={isEmptyObject(clientsToAddDraft) || isLoading}
					>
						<i className="fa-solid fa-file-circle-plus"></i>
						Create Draft
					</div>
				</div>
			</div>

			<div className="modal-body">
				<div className="client-filters-wrapper">
					<h2 className="section-header">Filter Values</h2>
					<div className="client-filter-container">
						{!isEmptyObject(filterValues) ? (
							Object.values(filterValues).map((value, i) => (
								<div
									key={i}
									className="client-filter-container-inner"
								>
									<span>{value}</span>
								</div>
							))
						) : (
							<div className="section-description">
								No Filter Applied
							</div>
						)}
					</div>
				</div>
				<div className="divider" />

				{Array.isArray(filteredCustomers) &&
				filteredCustomers.length > 0 ? (
					<div className="client-draft-wrapper">
						<h2 className="section-header">Filtered Clients</h2>
						<p className="section-description">
							Select all or some of the filtered bills to
							duplicate.
						</p>
						<div
							className="client-container"
							onClick={handleToggleAll}
						>
							<div
								className={`client-select ${
									Object.keys(clientsToAddDraft).length ===
										filteredCustomers.length && "selected"
								}`}
							>
								{Object.keys(clientsToAddDraft).length ===
									filteredCustomers.length && (
									<i
										className="fa-solid fa-check"
										style={{
											color: "#46BE46",
											fontSize: "12px",
										}}
									></i>
								)}
							</div>
							<div className="select-btn">
								{Object.keys(clientsToAddDraft).length ===
								filteredCustomers.length
									? "Deselect All"
									: "Select All"}
							</div>
						</div>
						{filteredCustomers.map((customer, i) => (
							<div
								className="client-container"
								key={i}
								onClick={() =>
									handleClientSelection(customer, i)
								}
							>
								<div
									className={`client-select ${
										clientsToAddDraft[i] && "selected"
									}`}
								>
									{clientsToAddDraft[i] && (
										<i
											className="fa-solid fa-check"
											style={{
												color: "#46BE46",
												fontSize: "12px",
											}}
										></i>
									)}
								</div>

								<div className="client-container-inner">
									<div className="bill-details">
										<h4 className="client-maintext">
											{customer?.customer_name}
										</h4>
										<div className="client-subtext">
											<i className="fa-solid fa-phone"></i>
											<p>{customer?.customer_mobile}</p>
										</div>
									</div>
									<div className="bill-details">
										Rs.{" "}
										{rupeeConverter(
											formik.values?.total_sales
										)}
									</div>
								</div>
							</div>
						))}
					</div>
				) : (
					<EmptyRecord />
				)}

				<div className="divider" />
				<div className="section-header-row">
					<h2 className="section-header">Draft Details</h2>
					<div
						className="section-accordion-btn"
						onClick={() => setHideDraftDetails((prev) => !prev)}
					>
						{hideDraftDetails ? "Show Details" : "Hide"}
					</div>
				</div>
				{hideDraftDetails ? (
					<div className="section-description">
						Total Bill Amount:{" "}
						<span className="section-header">
							Rs. {rupeeConverter(formik.values?.grand_total)}
						</span>
					</div>
				) : (
					<div className="client-draft-wrapper">
						<p className="section-description">
							Choose invoice date to create draft
						</p>
						<div className="input-item">
							<p className="input-label">Sales Date</p>
							<EnNpDatePicker
								name="invoice_date"
								className="input-box-wrapper"
								type="date"
								onChange={handleInvoiceDateChange}
								onBlur={formik.handleBlur}
								value={formik.values?.invoice_date || ""}
								// min={lastBillDate}
							/>
							{formik.submitCount > 0 && (
								<div className="validation-error">
									{formik.errors?.invoice_date}
								</div>
							)}
						</div>

						<p className="section-description">
							Choose the mode of payment
						</p>
						<div className="input-item">
							<p className="input-label">Payment Method</p>
							<SelectBox
								name="payment_mode"
								onChange={(e) =>
									handleInvoiceDateChange(
										"payment_mode",
										e.target.value
									)
								}
								onBlur={formik.handleBlur}
								value={formik.values?.payment_mode || ""}
								options={strings.paymentMode}
							/>
							{formik.submitCount > 0 && (
								<div className="validation-error">
									{formik?.errors?.payment_mode}
								</div>
							)}
						</div>
						{/* part from invoice form component with style */}
						<div
							className="invoice-form-container"
							style={{ padding: "0px", fontSize: "10px" }}
						>
							<InvoiceItemsFooter
								formik={formik}
								onDiscountServiceChange={
									handleDiscountServiceChange
								}
								onBilledItemsChange={handleBilledItemsChange}
								onServiceChargeToggle={
									handleServiceChargeToggle
								}
								isServiceChargeEnabled={isServiceChargeEnabled}
								onFieldValueChange={(fieldName, fieldValue) =>
									formik.setFieldValue(fieldName, fieldValue)
								}
							/>
						</div>
						{/* part from invoice form component with style */}
						<div
							className="action-container"
							style={{ marginTop: "-16px" }}
						>
							<div
								className="action-btn"
								style={{ margin: "0" }}
								onClick={formik.submitForm}
							>
								<i className="fa-solid fa-floppy-disk"></i>
								Save Details
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default CustomerBulkDraft;
