import React, { useEffect, useMemo, useState, useRef, memo } from "react";
import Swal from "sweetalert2";
import "./user-profile-sidemodal.style.scss";

// for filter form handle
import { useFormik } from "formik";
import * as yup from "yup";

// for date purpose
import { adToBs } from "@sbmdkl/nepali-date-converter";
import moment from "moment";

// components
import SideModal from "../side-modal/side-modal.component";
import SelectBox from "../form-inputs/select-box.component";
import PasswordInput from "../form-inputs/password-input.component";

// global strings and functions
import strings from "../../global/strings";
import {
	isEmptyObject,
	nepaliDateMMMM,
	rupeeConverter,
	sessionInfo,
} from "../../global/function";
import showToastMessage from "../../global/showToastMessage";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
	addEmployee,
	deleteEmployee,
	fetchEmployeeById,
	setEmployeesList,
	updateEmployee,
} from "../../redux/actions/employee.action";
import { uploadImage } from "../../redux/actions/image-upload.action";
import { setLoginUser } from "../../redux/actions/auth-user.action";

const employeeSchema = (employee) => {
	return yup.object().shape({
		first_name: yup
			.string()
			.nullable()
			.required("Employee First Name is required"),
		last_name: yup
			.string()
			.nullable()
			.required("Employee Last Name is required"),
		user_mobile: yup
			.string()
			.matches(/^\d{7,10}$/, "Must be a valid Phone number")
			.nullable()
			.required("Employee Mobile is required"),
		user_email: yup
			.string()
			.email("Please enter valid email")
			.nullable()
			.required("Employee email is required"),
		user_gender: yup.string().required("Select employee gender"),
		user_address: yup.string().required("Please enter employee address"),
		user_city: yup.string().required("Select employee city"),
		password: yup.string().when([], {
			is: () => !employee || Object.keys(employee).length === 0, // Check if props.user is empty
			then: () =>
				yup
					.string()
					.required("Password is required for new users")
					.min(8, "Password must be at least 8 characters"),
			otherwise: () => yup.string(),
		}),
	});
};

function EmployeeProfile({
	isEmployeeModalVisible,
	onEmployeeModalClose,
	employee,
	currentPageNum,
	empPermission,
}) {
	const { employeeDetails, isLoading } = useSelector(
		(state) => state.employeeReducer
	);

	const [userEditable, setUserEditable] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);

	const dispatch = useDispatch();
	const fileInputRef = useRef(null);

	const formik = useFormik({
		validationSchema: employeeSchema(employee),
		initialValues: {
			first_name: "",
			last_name: "",
			user_role: "user",
			user_status: null,
			user_gender: "",
			user_email: "",
			password: "",
			user_mobile: "",
			user_address: "",
			user_city: "",
			user_country: "Nepal",
		},
		onSubmit: async (employeeData) => {
			delete employeeData.user_image;
			if (isEmptyObject(employee)) {
				delete employeeData.user_status;
				const response = await dispatch(addEmployee(employeeData));
				if (response?.success) {
					let uploadResponse;
					if (selectedImage) {
						let data = {
							image: selectedImage,
							id: response.result.user_info_id,
							type: "user",
						};
						uploadResponse = await dispatch(uploadImage(data));
					}
					let newEmployee = {
						...response.result,
						user_image: uploadResponse?.result?.user_image || null,
						isNew: true,
					};
					await dispatch(setEmployeesList(newEmployee));
				}
				setUserEditable(false);
				onEmployeeModalClose();
			} else {
				let uploadResponse,
					uploadResult = true;
				if (selectedImage) {
					let data = {
						image: selectedImage,
						id: employeeData.user_info_id,
						type: "user",
					};
					uploadResponse = await dispatch(uploadImage(data));
					uploadResult = uploadResponse?.success;
				}
				if (uploadResult) {
					const editResult = await dispatch(
						updateEmployee(employeeData, currentPageNum)
					);
					editResult && setUserEditable(false);
				}

				// in case of loggedin user
				let sessionData = await sessionInfo();
				if (employeeData?.user_info_id === sessionData?.user_info_id) {
					let updatedUser = {
						...employeeData,
						user_image: uploadResponse?.result?.user_image,
					};
					await dispatch(setLoginUser(updatedUser));
				}
			}
		},
	});

	const handleUserDelete = (userInfoId) => {
		Swal.fire({
			title: "Delete Employee?",
			text: "Are you sure you want to delete the user?",
			showDenyButton: true,
			confirmButtonText: "Delete",
			denyButtonText: "Cancel",
			customClass: {
				popup: "alert-modal",
				actions: "btn-container",
				confirmButton: "alert-btn btn-confirm",
				denyButton: "alert-btn btn-cancel",
			},
		}).then(async (result) => {
			if (result.isConfirmed) {
				if (!isLoading) {
					await dispatch(deleteEmployee(userInfoId));
				}
			}
		});
	};

	const handleEditCancel = () => {
		setUserEditable(false);
		setSelectedImage(null);
		isEmptyObject(employee) && onEmployeeModalClose();
	};

	useEffect(() => {
		const getEmployeeDetails = async () => {
			if (!isEmptyObject(employee) && employee.user_info_id !== "") {
				await dispatch(fetchEmployeeById(employee?.user_info_id));
			}
		};
		getEmployeeDetails();
	}, [dispatch, employee]);

	useEffect(() => {
		if (!isEmptyObject(employee)) {
			formik.setValues({
				...formik.initialValues,
				...employee,
			});
		} else {
			empPermission?.edit && setUserEditable(true);
		}
		// eslint-disable-next-line
	}, [empPermission?.edit, employee]);

	const _renderEmployeeInfo = () => {
		return (
			<div className="user-info-container">
				<h2 className="section-header">Employee Details</h2>
				<div className="input-row-wrapper">
					<div className="input-wrapper">
						<label className="input-label">First Name</label>
						<input
							name="first_name"
							className="input-box-wrapper"
							type="text"
							placeholder="Enter First Name"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values?.first_name || ""}
							disabled={!userEditable}
						/>
						{formik.submitCount > 0 && (
							<div className="validation-error">
								{formik?.errors?.first_name}
							</div>
						)}
					</div>
					<div className="input-wrapper">
						<label className="input-label">Last Name</label>
						<input
							name="last_name"
							className="input-box-wrapper"
							type="text"
							placeholder="Enter Last Name"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values?.last_name || ""}
							disabled={!userEditable}
						/>
						{formik.submitCount > 0 && (
							<div className="validation-error">
								{formik?.errors?.last_name}
							</div>
						)}
					</div>
				</div>

				<div className="input-row-wrapper">
					<div className="input-wrapper">
						<label className="input-label">Mobile Number</label>
						<input
							name="user_mobile"
							className="input-box-wrapper"
							type="tel"
							placeholder="Enter Mobile Number"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values?.user_mobile || ""}
							disabled={!userEditable}
						/>
						{formik.submitCount > 0 && (
							<div className="validation-error">
								{formik?.errors?.user_mobile}
							</div>
						)}
					</div>
					{!isEmptyObject(employee) && (
						<div className="input-wrapper">
							<label className="input-label">
								Employee Email
							</label>
							<input
								name="user_email"
								className="input-box-wrapper"
								type="text"
								placeholder="Enter Email"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values?.user_email || ""}
								disabled={!userEditable}
							/>
							{formik.submitCount > 0 && (
								<div className="validation-error">
									{formik?.errors?.user_email}
								</div>
							)}
						</div>
					)}
				</div>
				<div className="input-wrapper">
					<label className="input-label">Employee Gender</label>
					<SelectBox
						name="user_gender"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values?.user_gender || ""}
						options={strings.gender}
						disabled={!userEditable}
					/>
					{formik.submitCount > 0 && (
						<div className="validation-error">
							{formik?.errors?.user_gender}
						</div>
					)}
				</div>
				<div className="input-wrapper">
					<label className="input-label">Address</label>
					<input
						name="user_address"
						className="input-box-wrapper"
						type="text"
						placeholder="Enter Address"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values?.user_address || ""}
						disabled={!userEditable}
					/>
					{formik.submitCount > 0 && (
						<div className="validation-error">
							{formik?.errors?.user_address}
						</div>
					)}
				</div>
				<div className="input-row-wrapper">
					<div className="input-wrapper">
						<label className="input-label">City</label>
						<SelectBox
							name="user_city"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values?.user_city || ""}
							options={strings.city}
							disabled={!userEditable}
						/>
						{formik.submitCount > 0 && (
							<div className="validation-error">
								{formik?.errors?.user_city}
							</div>
						)}
					</div>
					<div className="input-wrapper">
						<label className="input-label">Country</label>
						<input
							name="user_country"
							className="input-box-wrapper"
							type="text"
							placeholder="Enter Address"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values?.user_country || ""}
							disabled={!userEditable}
						/>
						{formik.submitCount > 0 && (
							<div className="validation-error">
								{formik?.errors?.user_country}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	};

	const _renderEmployeeLoginInfo = () => {
		return (
			<div className="user-info-container">
				<h2 className="section-header">Employee Login Details</h2>
				<div className="input-wrapper">
					<label className="input-label">Employee Email</label>
					<input
						name="user_email"
						className="input-box-wrapper"
						type="text"
						placeholder="Enter Email"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values?.user_email || ""}
						disabled={!userEditable}
						autoComplete="off"
					/>
					{formik.submitCount > 0 && (
						<div className="validation-error">
							{formik?.errors?.user_email}
						</div>
					)}
				</div>
				<PasswordInput
					label="New Password"
					name="password"
					placeholder="Enter login password"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values?.password || ""}
					disabled={!userEditable}
					autoComplete="off"
					error={
						formik.submitCount > 0 ? formik?.errors?.password : ""
					}
				/>
			</div>
		);
	};

	const _renderNepaliInvDate = (date) => {
		const nepDate = adToBs(moment(date).format("YYYY-MM-DD"));
		const nepInvDate = `${nepaliDateMMMM(nepDate).split(" ")[0]} ${
			nepaliDateMMMM(nepDate).split(" ")[1]
		}, ${nepaliDateMMMM(nepDate).split(" ")[2]}`;
		return nepInvDate;
	};
	const _renderEnglishInvDate = (date) => {
		const enInvDate = `${
			moment(date).format("DD") +
			" " +
			moment(date).format("MMMM") +
			", " +
			moment(date).format("YYYY")
		}`;
		return enInvDate;
	};

	const _renderEmployeeBills = () => {
		if (isLoading) {
			return <div>Loading....</div>;
		} else {
			if (
				!isEmptyObject(employeeDetails) &&
				Array.isArray(employeeDetails?.bills) &&
				employeeDetails?.bills?.length > 0
			) {
				return (
					<div className="user-bills-container">
						<h2 className="section-header">Recent Bills</h2>
						<div className="bills-table-container">
							<div className="table-grid-container header">
								<div className="table-item-text align-left">
									<b>Invoice #</b>
								</div>
								<div className="table-item-text">
									<b>Invoice Date</b>
								</div>
								<div className="table-item-text align-right">
									<b>Amount</b>
								</div>
							</div>
							{employeeDetails?.bills?.map((bill, i) => (
								<div key={i} className="table-grid-container">
									<div
										className="table-item-text align-left copy"
										onClick={() =>
											navigator.clipboard.writeText(
												bill?.invoice_number?.split(
													"_"
												)[2]
											)
										}
									>
										{bill?.invoice_number}
									</div>
									<div className="table-item-text">
										{_renderEnglishInvDate(
											bill?.invoice_date
										)}
										<span>
											{_renderNepaliInvDate(
												bill?.invoice_date
											)}
										</span>
									</div>
									<div className="table-item-text align-right">
										<div
											className={`bill-price ${
												bill?.bill_void && "void"
											}`}
										>
											<b>Rs. </b>
											{rupeeConverter(bill?.total_sales)}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				);
			}
		}
	};

	const handleImageEdit = () => {
		if (userEditable) {
			// Trigger the hidden file input element
			fileInputRef.current.click();
		}
	};

	const handleImageChange = (e) => {
		const selectedFile = e.target.files[0];
		// Check the file size
		if (selectedFile && selectedFile.size <= 2048576) {
			setSelectedImage(selectedFile);
		} else {
			// Handle the case where the file size exceeds the limit
			showToastMessage("File size exceeds the limit", "I");
		}
	};

	const imgSrc = useMemo(() => {
		if (selectedImage) {
			return URL.createObjectURL(selectedImage);
		} else if (!isEmptyObject(employee) && employee.user_image) {
			return employee.user_image;
		} else {
			return "";
		}
	}, [employee, selectedImage]);

	return (
		<SideModal
			isVisible={isEmployeeModalVisible}
			onModalClose={onEmployeeModalClose}
			containerStyle={{ minWidth: "320px", maxWidth: "380px" }}
		>
			<div className="user-modal-container">
				<div className="modal-header">
					<div
						className="modal-action"
						onClick={onEmployeeModalClose}
					>
						<i className="fa-solid fa-arrow-left"></i> Back
					</div>
					{empPermission?.edit && (
						<div className="action-container">
							{userEditable ? (
								<>
									<div
										className="action-btn inverted"
										onClick={handleEditCancel}
									>
										<i className="fa-solid fa-eraser"></i>
										Cancel
									</div>
									<div
										className="action-btn"
										onClick={formik.submitForm}
									>
										<i className="fa-regular fa-floppy-disk"></i>
										Save
									</div>
								</>
							) : (
								<>
									{empPermission?.delete &&
										employee?.user_role !== "owner" && (
											<div
												className="action-btn inverted"
												onClick={() =>
													handleUserDelete(
														employee?.user_info_id
													)
												}
											>
												<i className="fa-solid fa-user-xmark"></i>
												Delete
											</div>
										)}
									<div
										className="action-btn"
										onClick={() => setUserEditable(true)}
									>
										<i className="fa-regular fa-pen-to-square"></i>
										Edit
									</div>
								</>
							)}
						</div>
					)}
				</div>
				<div className="modal-body">
					{employee?.user_id && (
						<div className="user-id"># {employee?.user_id}</div>
					)}
					<div
						className={`user-img-container ${
							userEditable && "editable"
						}`}
						onClick={handleImageEdit}
					>
						<div className="item_img">
							{imgSrc ? (
								<img
									crossOrigin="anonymous"
									src={imgSrc}
									alt="user"
								/>
							) : (
								<i className="fa-solid fa-user fa-6x"></i>
							)}
						</div>
						{userEditable ? (
							<div className="user-img-action">
								<i className="fa-regular fa-pen-to-square"></i>
							</div>
						) : (
							<div
								className={`user-img-action ${
									employee.user_verified
										? "active"
										: "inactive"
								}`}
							>
								{employee.user_verified ? (
									<i className="fa-solid fa-check"></i>
								) : (
									<i className="fa-solid fa-exclamation"></i>
								)}
							</div>
						)}
					</div>
					<input
						type="file"
						accept="image/*"
						ref={fileInputRef}
						style={{ display: "none" }}
						onChange={handleImageChange}
					/>
					<div className="user-info-container">
						<div className="input-row-wrapper">
							{employee?.user_status && (
								<div className="input-wrapper">
									<label className="input-label">
										Status
									</label>
									<SelectBox
										name="user_status"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values?.user_status || ""}
										options={strings.userStatus}
										disabled={!userEditable}
									/>
									{formik.submitCount > 0 && (
										<div className="validation-error">
											{formik?.errors?.user_status}
										</div>
									)}
								</div>
							)}
							<div className="input-wrapper">
								<label className="input-label">
									Employee Role
								</label>
								<SelectBox
									name="user_role"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values?.user_role || ""}
									options={strings.role}
									disabled={true}
								/>
								{formik.submitCount > 0 && (
									<div className="validation-error">
										{formik?.errors?.user_role}
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="divider" />
					{_renderEmployeeInfo()}
					{isEmptyObject(employee) && (
						<>
							<div className="divider" />
							{_renderEmployeeLoginInfo()}
						</>
					)}
					<div className="divider" />
					{!isEmptyObject(employee) && _renderEmployeeBills()}
				</div>
			</div>
		</SideModal>
	);
}

export default memo(EmployeeProfile);
