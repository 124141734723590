import React, { forwardRef, useState } from "react";
import "./login.style.scss";

// navigation
import { useLocation, useNavigate } from "react-router-dom";

// form handle
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// components
import Spinner from "../../components/spinner/spinner.component";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
	forgotPassword,
	processLogin,
	resetPassword,
} from "../../redux/actions/auth-user.action";

const loginSchema = yup.object().shape({
	user_email: yup
		.string()
		.required("Email cannot be empty")
		.email("Please enter a valid email"),
	password: yup
		.string()
		.required("Password cannot be empty")
		.min(8, "Password must be atleast 8 letters"),
});

const forgotPasswordSchema = yup.object().shape({
	user_email: yup
		.string()
		.required("Email cannot be empty")
		.email("Please enter a valid email"),
});

const resetPasswordSchema = yup.object().shape({
	password: yup
		.string()
		.required("Password cannot be empty")
		.min(8, "Password must be atleast 8 letters"),
	confirm_password: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match")
		.required("Password cannot be empty"),
});

export const PasswordInputField = forwardRef((props, ref) => {
	const [pswrdVisible, setPswrdVisible] = useState(false);
	return (
		<div className="input-wrapper">
			{props.label && <p className="input-label">{props.label}</p>}
			<div className="input-wrapper-inner">
				<i className="fa-solid fa-lock"></i>
				<input
					{...props}
					ref={ref}
					type={pswrdVisible ? "text" : "password"}
					className="input-container"
				/>
				<div
					className="input-action"
					onClick={() => setPswrdVisible(!pswrdVisible)}
				>
					{pswrdVisible ? (
						<i className="fa-regular fa-eye-slash"></i>
					) : (
						<i className="fa-regular fa-eye"></i>
					)}
				</div>
			</div>
			<span className="form-validation-error">{props.error}</span>
		</div>
	);
});

export default function Login() {
	const { isLoading } = useSelector((state) => state.authUserReducer);
	const [forgotPswrd, setForgotPswrd] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	let resetParams,
		verificationToken,
		resetPasswordParam = false;

	if (location.search !== "") {
		resetParams = location.search.split("?")[1].split("&");
		verificationToken = resetParams[0].split("=")[1];
		resetPasswordParam =
			resetParams[2].split("=")[1] === "true" ? true : false;
	}

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: resetPasswordParam
			? yupResolver(resetPasswordSchema)
			: forgotPswrd
			? yupResolver(forgotPasswordSchema)
			: yupResolver(loginSchema),
	});

	const handleLogin = async (loginData) => {
		if (!isLoading) {
			let loginSuccess = await dispatch(processLogin(loginData));
			if (loginSuccess) {
				navigate("/");
			}
		}
	};

	const handleForgotPassword = async (forgotPasswordData) => {
		if (!isLoading) {
			await dispatch(forgotPassword(forgotPasswordData));
		}
	};

	const handleResetPassword = async (resetPasswordData) => {
		if (!isLoading) {
			const result = await dispatch(
				resetPassword({ ...resetPasswordData, verificationToken })
			);
			if (result) {
				reset();
				navigate("/login");
			}
		}
	};

	function _renderForgotPassword() {
		return (
			<div className="form-container">
				<h2 className="title-text">Forgot Password</h2>

				<p className="description-text">
					Enter your email address and we will send a link to reset
					your password.
				</p>

				<div className="form-inputs-container">
					<div className="input-wrapper">
						<div className="input-wrapper-inner">
							<i className="fa-regular fa-envelope"></i>
							<input
								{...register("user_email")}
								type="email"
								className="input-container"
								placeholder="Email"
							/>
						</div>
						<span className="form-validation-error">
							{errors?.user_email?.message}
						</span>
					</div>
				</div>

				<div
					className="form-change-btn"
					onClick={() => setForgotPswrd(false)}
				>
					Back to login?
				</div>
				<div
					className="form-submit-btn"
					onClick={handleSubmit(handleForgotPassword)}
					disabled={isLoading}
				>
					{isLoading && <Spinner />}
					Send Reset Link
				</div>
			</div>
		);
	}

	function _renderResetPassword() {
		return (
			<div className="form-container">
				<h2 className="title-text">Reset Password</h2>

				<p className="description-text">
					Please enter and confirm your new password to access your
					account.
				</p>

				<div className="form-inputs-container">
					<PasswordInputField
						{...register("password")}
						placeholder="Password"
						error={errors?.password?.message}
					/>
					<PasswordInputField
						{...register("confirm_password")}
						placeholder="Confirm Password"
						error={errors?.confirm_password?.message}
					/>
				</div>

				<div
					className="form-submit-btn"
					onClick={handleSubmit(handleResetPassword)}
					disabled={isLoading}
				>
					{isLoading && <Spinner />}
					Change Password
				</div>
			</div>
		);
	}

	function _renderLogin() {
		return (
			<div className="form-container">
				<h2 className="title-text">Sign in</h2>
				{/* <div className="social-icon-btns">
					<div className="icon-btn">
						<i
							className="fa-brands fa-facebook-f"
							style={{ color: "#3b5998" }}
						></i>
					</div>
					<div className="icon-btn">
						<i
							className="fa-brands fa-google-plus-g"
							style={{ color: "#c71610" }}
						></i>
					</div>
					<div className="icon-btn">
						<i
							className="fa-brands fa-apple"
							style={{ color: "#000000" }}
						></i>
					</div>
				</div> */}
				<p className="description-text">use your email account</p>

				<div className="form-inputs-container">
					<div className="input-wrapper">
						<div className="input-wrapper-inner">
							<i className="fa-regular fa-envelope"></i>
							<input
								{...register("user_email")}
								type="email"
								className="input-container"
								placeholder="Email"
							/>
						</div>
						<span className="form-validation-error">
							{errors?.user_email?.message}
						</span>
					</div>
					<PasswordInputField
						{...register("password")}
						placeholder="Password"
						error={errors?.password?.message}
					/>
				</div>

				<div
					className="form-change-btn"
					onClick={() => setForgotPswrd(true)}
				>
					Forgot your password?
				</div>
				<div
					className="form-submit-btn"
					onClick={handleSubmit(handleLogin)}
					disabled={isLoading}
				>
					{isLoading ? (
						<>
							<Spinner />
							<span>Signing in...</span>
						</>
					) : (
						"Sign in"
					)}
				</div>
			</div>
		);
	}

	return (
		<div className="login-wrapper">
			<div className="login-container">
				<div className="login-container-inner">
					{resetPasswordParam
						? _renderResetPassword()
						: forgotPswrd
						? _renderForgotPassword()
						: _renderLogin()}
					<div className="description-container">
						<h2 className="title-text inverted">Welcome Back!</h2>
						<p className="description-text">
							Enter your account details and start generating
							bills.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
